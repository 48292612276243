export const migratedLabels = [
	{
		labelKey: '1021',
		redirectUrl: 'https://mijn.coolblue-energie.nl/',
		newLabelName: 'Coolblue',
	},
	{
		labelKey: '1008',
		redirectUrl: 'https://mijn.coolblue-energie.nl/',
		newLabelName: 'Coolblue',
	},
	{
		labelKey: '1086',
		redirectUrl: 'https://mijn.coolblue-energie.nl/',
		newLabelName: 'Coolblue',
	},
	{
		labelKey: '1058',
		redirectUrl: 'https://mijn.coolblue-energie.nl/',
		newLabelName: 'Coolblue',
	},
	{
		labelKey: '1095',
		redirectUrl: 'https://mijn.coolblue-energie.nl/',
		newLabelName: 'Coolblue',
	},
	{
		labelKey: '1000',
		redirectUrl: 'https://mijn.coolblue-energie.nl/',
		newLabelName: 'Coolblue',
	},
	{
		labelKey: '1025',
		redirectUrl: 'https://mijn.coolblue-energie.nl/',
		newLabelName: 'Coolblue',
	},
];

export const excludedDomainList = [
	'mijn.my-energie.nl.ssp-site.tst.aws.servicehouse.nl',
	'mijn.my-energie.nl.ssp-site.acc.aws.servicehouse.nl',
];
