import React, { Component } from 'react';
import PropTypes from 'prop-types';

export default class IconSun extends Component {
	static displayName = 'IconCode';

	getLarge() {
		return (
			<svg
				version="1.1"
				xmlns="http://www.w3.org/2000/svg"
				width="32"
				height="32"
				viewBox="0 0 32 32">
				<path d="M16 11.008c-2.912 0-5.312 2.368-5.312 5.312 0 2.912 2.368 5.312 5.312 5.312s5.312-2.368 5.312-5.312c0-2.912-2.368-5.312-5.312-5.312zM16 19.84c-1.952 0-3.52-1.568-3.52-3.52s1.568-3.52 3.52-3.52 3.52 1.568 3.52 3.52-1.568 3.52-3.52 3.52z" />
				<path d="M16 9.792c0.512 0 0.896-0.384 0.896-0.896v-1.312c0-0.48-0.384-0.896-0.896-0.896-0.48 0-0.896 0.384-0.896 0.896v1.312c0 0.48 0.384 0.896 0.896 0.896z" />
				<path d="M10.112 11.712c0.192 0.16 0.416 0.256 0.64 0.256s0.448-0.096 0.64-0.256c0.352-0.352 0.352-0.928 0-1.28l-0.928-0.928c-0.352-0.352-0.928-0.352-1.28 0s-0.352 0.928 0 1.28l0.928 0.928z" />
				<path d="M9.472 16.32c0-0.48-0.384-0.896-0.896-0.896h-1.312c-0.48 0-0.896 0.384-0.896 0.896 0 0.48 0.384 0.896 0.896 0.896h1.312c0.512 0 0.896-0.384 0.896-0.896z" />
				<path d="M10.112 20.928l-0.928 0.928c-0.352 0.352-0.352 0.928 0 1.28 0.192 0.16 0.416 0.256 0.64 0.256s0.448-0.096 0.64-0.256l0.928-0.928c0.352-0.352 0.352-0.928 0-1.28s-0.928-0.352-1.28 0z" />
				<path d="M16 22.848c-0.48 0-0.896 0.384-0.896 0.896v1.312c0 0.48 0.384 0.896 0.896 0.896s0.896-0.384 0.896-0.896v-1.312c0-0.48-0.384-0.896-0.896-0.896z" />
				<path d="M21.888 20.928c-0.352-0.352-0.928-0.352-1.28 0s-0.352 0.928 0 1.28l0.928 0.928c0.192 0.16 0.416 0.256 0.64 0.256s0.448-0.096 0.64-0.256c0.352-0.352 0.352-0.928 0-1.28l-0.928-0.928z" />
				<path d="M24.736 15.424h-1.312c-0.48 0-0.896 0.384-0.896 0.896 0 0.48 0.384 0.896 0.896 0.896h1.312c0.512 0 0.896-0.384 0.896-0.896 0-0.48-0.384-0.896-0.896-0.896z" />
				<path d="M21.248 11.968c0.224 0 0.448-0.096 0.64-0.256l0.928-0.928c0.352-0.352 0.352-0.928 0-1.28s-0.928-0.352-1.28 0l-0.928 0.928c-0.352 0.352-0.352 0.928 0 1.28 0.192 0.16 0.416 0.256 0.64 0.256z" />
			</svg>
		);
	}

	getSmall() {
		return (
			<svg
				version="1.1"
				xmlns="http://www.w3.org/2000/svg"
				width="32"
				height="32"
				viewBox="0 0 32 32">
				<path d="M16 11.008c-2.912 0-5.312 2.368-5.312 5.312 0 2.912 2.368 5.312 5.312 5.312s5.312-2.368 5.312-5.312c0-2.912-2.368-5.312-5.312-5.312zM16 19.84c-1.952 0-3.52-1.568-3.52-3.52s1.568-3.52 3.52-3.52 3.52 1.568 3.52 3.52-1.568 3.52-3.52 3.52z" />
				<path d="M16 9.792c0.512 0 0.896-0.384 0.896-0.896v-1.312c0-0.48-0.384-0.896-0.896-0.896-0.48 0-0.896 0.384-0.896 0.896v1.312c0 0.48 0.384 0.896 0.896 0.896z" />
				<path d="M10.112 11.712c0.192 0.16 0.416 0.256 0.64 0.256s0.448-0.096 0.64-0.256c0.352-0.352 0.352-0.928 0-1.28l-0.928-0.928c-0.352-0.352-0.928-0.352-1.28 0s-0.352 0.928 0 1.28l0.928 0.928z" />
				<path d="M9.472 16.32c0-0.48-0.384-0.896-0.896-0.896h-1.312c-0.48 0-0.896 0.384-0.896 0.896 0 0.48 0.384 0.896 0.896 0.896h1.312c0.512 0 0.896-0.384 0.896-0.896z" />
				<path d="M10.112 20.928l-0.928 0.928c-0.352 0.352-0.352 0.928 0 1.28 0.192 0.16 0.416 0.256 0.64 0.256s0.448-0.096 0.64-0.256l0.928-0.928c0.352-0.352 0.352-0.928 0-1.28s-0.928-0.352-1.28 0z" />
				<path d="M16 22.848c-0.48 0-0.896 0.384-0.896 0.896v1.312c0 0.48 0.384 0.896 0.896 0.896s0.896-0.384 0.896-0.896v-1.312c0-0.48-0.384-0.896-0.896-0.896z" />
				<path d="M21.888 20.928c-0.352-0.352-0.928-0.352-1.28 0s-0.352 0.928 0 1.28l0.928 0.928c0.192 0.16 0.416 0.256 0.64 0.256s0.448-0.096 0.64-0.256c0.352-0.352 0.352-0.928 0-1.28l-0.928-0.928z" />
				<path d="M24.736 15.424h-1.312c-0.48 0-0.896 0.384-0.896 0.896 0 0.48 0.384 0.896 0.896 0.896h1.312c0.512 0 0.896-0.384 0.896-0.896 0-0.48-0.384-0.896-0.896-0.896z" />
				<path d="M21.248 11.968c0.224 0 0.448-0.096 0.64-0.256l0.928-0.928c0.352-0.352 0.352-0.928 0-1.28s-0.928-0.352-1.28 0l-0.928 0.928c-0.352 0.352-0.352 0.928 0 1.28 0.192 0.16 0.416 0.256 0.64 0.256z" />
			</svg>
		);
	}

	getIcon() {
		let svg;
		svg = this.props.size === 'large' ? this.getLarge() : svg;
		svg = this.props.size === 'small' ? this.getSmall() : svg;
		return svg;
	}

	render() {
		return this.getIcon();
	}
}

IconSun.propTypes = {
	size: PropTypes.oneOf(['small', 'large']).isRequired,
};
