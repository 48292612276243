import relations from './relations';
import agreements from './agreements';
import address from './address';
import accountUsername from './account-username';
import labels from './labels';
import connections from './connections';
import move from './move';
import usage from './usage';
import periodicalPayments from './periodical-payments';
import sustainable from './sustainable';
import invoices from './invoices';
import weather from './weather';
import emailConfirmation from './email-confirmation';
import reactivateAccount from './reactivate-account';
import account from './account';
import privacy from './privacy';

export default {
	agreements,
	address,
	accountUsername,
	relations,
	labels,
	connections,
	move,
	usage,
	periodicalPayments,
	sustainable,
	invoices,
	weather,
	emailConfirmation,
	reactivateAccount,
	account,
	privacy,
};
