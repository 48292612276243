import React from 'react';
import Link from '../link';
import Button from '../button';
import isMobileDevice from '../../utils/is-mobile-device';

import './account-navigation.css';
import { Auth } from 'aws-amplify';
import { action as rootAction } from '../../reducers';

let dispatch;

const openClassCssSelector = '.account-navigation__item--sub.open';

const closeSubMenus = (event) => {
	[].forEach.call(document.querySelectorAll(openClassCssSelector), (node) =>
		node.classList.remove('open')
	);
};

const openSubMenu = (event) => {
	event.currentTarget.classList.add('open');
};

const closeSubMenu = (event) => {
	event.currentTarget.classList.remove('open');
};

const AccountMenu = ({ children, translations, innerMenu }) => {
	return (
		<ul className="account-navigation__menu">
			{children &&
				children.map(({ children, title, uri, icon }, index) => {
					return children ? (
						isMobileDevice() ? (
							<li
								key={index}
								className="account-navigation__item account-navigation__item--sub"
								onMouseLeave={closeSubMenu}
								onTouchStart={openSubMenu}>
								<span className="account-navigation__label">
									<i className="material-icons">{icon}</i>
									<span className="account-navigation__label__text">
										{title}
									</span>
									<i className="material-icons">arrow_drop_down</i>
								</span>
								<AccountMenu
									children={children}
									translations={translations}
									innerMenu={true}
								/>
							</li>
						) : (
							<li
								key={index}
								className="account-navigation__item account-navigation__item--sub"
								onMouseEnter={openSubMenu}
								onMouseLeave={closeSubMenu}>
								<span className="account-navigation__label">
									<i className="material-icons">{icon}</i>
									<span className="account-navigation__label__text">
										{title}
									</span>
									<i className="material-icons">arrow_drop_down</i>
								</span>
								<AccountMenu
									children={children}
									translations={translations}
									innerMenu={true}
								/>
							</li>
						)
					) : (
						<li
							key={index}
							className="account-navigation__item"
							onClick={closeSubMenus}>
							<Link
								to={uri}
								tabIndex={1}
								className="account-navigation__link primary-text-color">
								<span className="account-navigation__label">
									{title}
									<i className="material-icons">{icon}</i>
								</span>
							</Link>
						</li>
					);
				})}
			{innerMenu && (
				<li>
					<Button
						className="account-navigation__link primary-text-color"
						onClick={logOut}
						title={translations.menu.myAccount.menu.logout.title}
						transparent={true}>
						<span className="account-navigation__label">
							<span className="account-navigation__label__text">
								{translations.menu.myAccount.menu.logout.title}
							</span>
							<i className="material-icons">exit_to_app</i>
						</span>
					</Button>
				</li>
			)}
		</ul>
	);
};

const logOut = () => {
	dispatch(rootAction.resetState());
	Auth.signOut();
};

const AccountNavigation = ({ children, translations, innerMenu, ...props }) => {
	dispatch = props.dispatch;
	return (
		<div className="account-navigation">
			<AccountMenu children={children} translations={translations} />
		</div>
	);
};

export default AccountNavigation;

AccountNavigation.propTypes = {};
