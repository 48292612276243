import React, { useState } from 'react';
import closeableOverlay from '../components/closeable-overlay';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Panel from '../components/panel';
import Auth from '@aws-amplify/auth';
import Input from '../components/input';
import Fieldset from '../components/fieldset';
import { action as notificationsAction } from '../reducers/notifications';
import ErrorText from '../components/error-text';

const passwordPattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9]).{6,50}$/;

const isPasswordPatternCorrect = (passwords) =>
	passwordPattern.test(passwords.newPassword);

const isConfirmPassValid = (passwords) =>
	passwords.newPassword === passwords.confirmPassword;

const isFormValid = (passwords) =>
	passwords.oldPassword &&
	passwords.newPassword &&
	passwords.confirmPassword &&
	isConfirmPassValid(passwords) &&
	isPasswordPatternCorrect(passwords);

const ChangeAccountPassword = ({ onClose }) => {
	const translations = useSelector((state) => state.translations);
	const dispatch = useDispatch();
	const [passwords, setPasswords] = useState({
		oldPassword: '',
		newPassword: '',
		confirmPassword: '',
	});

	const errorMessages = {
		matchMessage: translations.overlay.changeAccountPassword.matchMessage,
		patternMessage: translations.overlay.changeAccountPassword.patternMessage,
	};

	const updatePassword = (password) =>
		setPasswords({ ...passwords, ...password });

	const changePassword = () => {
		Auth.currentAuthenticatedUser()
			.then((user) => {
				return Auth.changePassword(
					user,
					passwords.oldPassword,
					passwords.newPassword
				);
			})
			.then((data) => {
				onClose();
				notificationsAction.add(
					dispatch,
					translations.overlay.changeAccountPassword.successMessage
				);
			})
			.catch((err) => {
				onClose();
				notificationsAction.add(
					dispatch,
					translations.amplify[err.message] || err.message
				);
			});
	};

	return (
		<Panel
			name="change-account-password"
			title={translations.overlay.changeAccountPassword.title}
			leftLinkText={translations.general.cancel}
			leftButtonHandler={onClose}
			rightLinkText={translations.general.save}
			rightButtonHandler={isFormValid(passwords) ? changePassword : null}>
			<form>
				<div className="password-fields">
					<Fieldset
						value={passwords.oldPassword}
						required
						className="text-area-placeholder"
						onChange={(value) => updatePassword({ oldPassword: value })}
						label={translations.overlay.changeAccountPassword.oldPassword}
						type="password">
						<Input />
					</Fieldset>
					<Fieldset
						value={passwords.newPassword}
						required
						className="text-area-placeholder"
						onChange={(value) => updatePassword({ newPassword: value })}
						label={translations.overlay.changeAccountPassword.newPassword}
						type="password">
						<Input />
					</Fieldset>
					<Fieldset
						value={passwords.confirmPassword}
						required
						className="text-area-placeholder"
						onChange={(value) => updatePassword({ confirmPassword: value })}
						label={translations.overlay.changeAccountPassword.confirmPassword}
						type="password">
						<Input />
					</Fieldset>
				</div>
				<div>
					<span>
						{passwords.newPassword &&
						passwords.confirmPassword &&
						!isConfirmPassValid(passwords) ? (
							<ErrorText align="left" size="small">
								{errorMessages.matchMessage}
							</ErrorText>
						) : (
							''
						)}
					</span>
					<span>
						{passwords.newPassword && !isPasswordPatternCorrect(passwords) ? (
							<ErrorText align="left" size="small">
								{errorMessages.patternMessage}
							</ErrorText>
						) : (
							''
						)}
					</span>
				</div>
			</form>
		</Panel>
	);
};

export default withRouter(closeableOverlay(ChangeAccountPassword));
