import React from 'react';
import PropTypes from 'prop-types';

import Row from '../row';
import Icon from '../icon';
import Clear from '../icons/clear';

import './overlay.css';

const Overlay = ({ children, closeHandler = () => {}, hideTitle }) => (
	<article className="overlay">
		<div className="overlay__wrapper">
			{!hideTitle && (
				<header className="overlay__header">
					<button className="overlay__close-button" onClick={closeHandler}>
						<Icon icon={Clear} size="small" className="overlay__close-icon" />
					</button>
				</header>
			)}
			<div className="overlay__content">
				<Row>{children}</Row>
			</div>
		</div>
	</article>
);

export default Overlay;

Overlay.propTypes = {
	closeHandler: PropTypes.func,
};
