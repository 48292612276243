import axios from './axios-instance';

import Auth from '@aws-amplify/auth';

/**
 * `put` is a thin wrapper around `axios.put`. Using axios directly is
 * recommended.
 *
 * @deprecated
 * @param  {String} uri     The uri to request
 * @param  {Object} headers An object containing headers
 * @param  {Object} body An object containing the body
 * @return {Promise}	    A Promise
 */
export default async function put(uri, body = {}, _headers = {}) {
	const skipLogin =
		typeof localStorage !== 'undefined' &&
		JSON.parse(localStorage.getItem('skipLogin'));
	let accessToken = '';
	let session = '';
	if (!skipLogin) {
		try {
			session = await Auth.currentSession();
		} catch (err) {
			console.log(err);
		}
		if (session.idToken) {
			accessToken = session.idToken.jwtToken;
		}
	} else {
		accessToken = localStorage.getItem('accessToken');
	}
	const authorization = accessToken
		? { Authorization: `Bearer ${accessToken}` }
		: {};
	const headers = Object.assign({}, _headers, authorization);
	return axios
		.put(uri, body, {
			headers,
		})
		.then((response) => response.data)
		.catch((err) => {
			return Promise.reject(err);
		});
}
