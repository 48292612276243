import put from './methods/put';

export default {
	/**
	 * Perform put request on the move endpoint. It returns a promise which
	 * resolves to a JSON object on success and an Error object on a failure
	 * @param {Object} headers A headers object
	 * @return {Promise}       A Promise
	 */

	put: (headers) => put('move', headers),
	emptyResponse: {
		commodities: [''],
		new_address: {
			address_type: '',
			city: '',
			house_number: '',
			house_number_extension: '',
			street: '',
			zip_code: '',
		},
		old_address: {
			address_type: '',
			city: '',
			house_number: '',
			house_number_extension: '',
			street: '',
			zip_code: '',
		},
		start_date: '',
		stop_date: '',
		is_residential: true,
	},
};
