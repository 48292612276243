import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import LangSwitch from '../../components/lang-switch';
import { useLocation, useHistory } from 'react-router-dom';
import { Stack, Container, Typography } from '@mui/material';

function useQuery() {
	const { search } = useLocation();

	return React.useMemo(() => new URLSearchParams(search), [search]);
}

const ThankYou = ({ logo, info, cancelled }) => {
	const translations = useSelector((state) => state.translations);
	const dispatch = useDispatch();

	const history = useHistory();

	let query = useQuery();

	const hashedInformation = query.get('n');
	const isCancelled = cancelled;
	const [refernce, amount] = atob(hashedInformation).split(',');

	if (!hashedInformation && !isCancelled) {
		history.push('account');
	}

	return (
		<Container>
			<Stack direction="row" spacing={2} justifyContent="flex-end">
				<LangSwitch dispatch={dispatch} translations={translations} />
			</Stack>
			<Stack
				direction="column"
				justifyContent="space-between"
				alignItems="center"
				spacing={5}>
				<Stack
					direction="column"
					justifyContent="space-between"
					alignItems="center"
					spacing={5}>
					{logo && <img src={logo} alt={info.name || 'Logo'} />}

					{isCancelled && (
						<Typography variant="h5">
							<p>{translations.marketPlace.thankYou.cancelled}</p>
							<a
								className="underline text-blue-600 hover:text-blue-800 visited:text-purple-600"
								href="/account">
								<span>{translations.marketPlace.thankYou.accountLinkText}</span>
							</a>
						</Typography>
					)}

					{!isCancelled && (
						<Typography variant="h5">
							<span>
								{translations.marketPlace.thankYou.thankYouTextFirst}
								{amount}
							</span>
							<span>
								{translations.marketPlace.thankYou.thankYouTextSecond}
							</span>
							<a
								className="underline text-blue-600 hover:text-blue-800 visited:text-purple-600"
								href="/account">
								<span>{translations.marketPlace.thankYou.accountLinkText}</span>
							</a>
							<span>
								{translations.marketPlace.thankYou.thankYouTextEnding}#
								{refernce}
							</span>
						</Typography>
					)}
				</Stack>
				<Stack
					direction="column"
					justifyContent="space-between"
					alignItems="center"
					spacing={2}>
					<span>{info.name}</span>
					<span>{info.email}</span>
					<span>{info.phone_number}</span>
				</Stack>
			</Stack>
		</Container>
	);
};

export default ThankYou;
