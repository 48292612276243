import React from 'react';
import { BeatLoader } from 'react-spinners';

import './spinner.css';

const Spinner = props => (
	<div className="spinner-component">
		<BeatLoader sizeUnit={'px'} />
	</div>
);

export default Spinner;

Spinner.propTypes = {};
