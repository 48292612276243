import flow from 'lodash/fp/flow';

function toLongHex(hex) {
	const replaceRe = /(#)([a-f\d])([a-f\d])([a-f\d])/;
	const replacePattern = '$1$2$2$3$3$4$4';
	return hex.length > 4 ? hex : hex.replace(replaceRe, replacePattern);
}

// https://stackoverflow.com/a/5624139/5985844
function hexToRgb(hex) {
	const result = /(?:#)([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})/i.exec(hex);
	const [input, r, g, b] = result;
	return {
		r: parseInt(r, 16),
		g: parseInt(g, 16),
		b: parseInt(b, 16),
		_input: input,
	};
}

// https://codepen.io/WebSeed/pen/pvgqEq
function isLight({ r, g, b }) {
	const a = 1 - (0.299 * r + 0.587 * g + 0.114 * b) / 255;
	return a < 0.5;
}

function calculateLegibleButtonColor(isLightColor) {
	return isLightColor ? '#000000' : '#ffffff';
}

export default flow(toLongHex, hexToRgb, isLight, calculateLegibleButtonColor);
