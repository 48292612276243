import React from 'react';
import PropTypes from 'prop-types';

import './footer.css';

const Footer = ({ privacyTitle, privacyLink }) => (
	<div className="footer highlight-bg-color">
		<small>
			<a href={privacyLink}>{privacyTitle}</a>
		</small>
	</div>
);

export default Footer;

Footer.propTypes = {
	privacyLink: PropTypes.string.isRequired,
};
