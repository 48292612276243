export function createReducer(namespace) {
	const SWITCH_TAB = `${namespace}/SWITCH_TAB`;

	return function(state = 0, action) {
		switch (action.type) {
			case SWITCH_TAB:
				return action.index;
			default:
				return state;
		}
	};
}

export const createActions = namespace => ({
	switch: index => ({ type: `${namespace}/SWITCH_TAB`, index }),
});
