import React, { Component } from 'react';

import mapValues from 'lodash/mapValues';
import moment from 'moment';
import Row from '../../components/row';
import PageTitle from '../../components/page-title';
import MoveWizard from '../../components/move-wizard';

import api from '../../api';
import dateIsoFormat from '../../utils/date-iso-format';
import { convertEmptyObjToString } from '../../utils/convert-empty-prop';
import lodash from 'lodash';

import { action as moveAction } from '../../reducers/ui/move';
import { action as notificationsAction } from '../../reducers/notifications';

import './move.css';
import { selector } from '../../reducers/data/agreements';
import { connect } from 'react-redux';
import { action as agreementsAction } from '../../reducers/data/agreements';
import getFullAgreementAddress from '../../utils/get-full-agreement-address';
import { Hub } from '@aws-amplify/core';

function formatDateInObj(object) {
	return mapValues(object, function(val) {
		if (moment(val, 'DD/MM/YYYY', true).isValid()) {
			return dateIsoFormat(val);
		} else if (typeof val === 'object' && !(val instanceof Array)) {
			return formatDateInObj(val);
		} else {
			return val;
		}
	});
}

class Move extends Component {
	constructor(props) {
		super(props);
		this.duplicatesChecked = false;
		Hub.listen('auth', this.handleSignOut);
	}

	handleSignOut = (e) => {
		if (e.payload.event === 'signOut') {
			this.props.dispatch(moveAction.toPage(0));
		}
	};
	extractConnectionsFromAddress = (address) => {
		const { connections, ...addressFields } = address;
		return addressFields;
	};
	putData = (data) => {
		const {
			old_address,
			new_address,
			start_date,
			stop_date,
			newInvoiceAddress,
			newPostalAddress,
			is_residential,
		} = data;

		const newAddress = convertEmptyObjToString(
			this.extractConnectionsFromAddress(new_address)
		);
		const commodities = lodash.map(old_address.connections, 'commodity');
		const body = formatDateInObj({
			...(newInvoiceAddress && { new_invoice_address: newAddress }),
			...(newPostalAddress && { new_postal_address: newAddress }),
			address_type: '',
			start_date,
			...(stop_date && { stop_date }),
			old_address: convertEmptyObjToString(
				this.extractConnectionsFromAddress(old_address)
			),
			new_address: newAddress,
			commodities,
			is_residential,
		});

		api.move
			.put(body)
			.then(() => {
				this.props.dispatch(moveAction.submitSucceeded());
				this.props.dispatch(moveAction.toPage(4));
			})
			.catch((error) => {
				this.props.dispatch(moveAction.submitFailed());
				this.props.dispatch(moveAction.toPage(1));
				notificationsAction.add(
					this.props.dispatch,
					error.response.status < 500
						? this.props.translations.form.step4.error4xx
						: this.props.translations.form.step4.error5xx
				);
			});
	};

	setPage = (page) => {
		this.props.dispatch(moveAction.toPage(page));
	};

	nextPage = () => {
		this.props.dispatch(moveAction.toPage(this.props.page + 1));
	};

	previousPage = () => {
		this.props.dispatch(moveAction.toPage(this.props.page - 1));
	};

	filterOutTerminatedAgreements(agreements) {
		return agreements.filter(
			(agreement) =>
				!(agreement.status === 'TERMINATED' || agreement.status === 'CANCELLED')
		);
	}

	findUniqueDuplicates(array) {
		return [
			...new Set(array.filter((item, index) => array.indexOf(item) !== index)),
		];
	}

	getDuplicatedAgreementAddresses(agreements) {
		return this.findUniqueDuplicates(
			agreements
				.map((agreement) =>
					agreement.addresses.map((address) => getFullAgreementAddress(address))
				)
				.flat()
		);
	}

	getAgreementsWithDuplicatedAddresses(agreements) {
		return agreements.filter((agreement) =>
			agreement.addresses.some((address) =>
				this.getDuplicatedAgreementAddresses(agreements).find(
					(duplicatedAddress) =>
						duplicatedAddress === getFullAgreementAddress(address)
				)
			)
		);
	}

	addDuplicatedAgreementAddressesToAgreementList() {
		const { agreements, dispatch } = this.props;
		const agreementsWithDuplicatedAddresses = this.getAgreementsWithDuplicatedAddresses(
			agreements
		);
		if (agreementsWithDuplicatedAddresses.length && !this.duplicatesChecked) {
			dispatch(agreementsAction.duplicates(agreementsWithDuplicatedAddresses));
			this.duplicatesChecked = true;
		}
	}

	render() {
		const { translations, page, submitSucceeded, agreements } = this.props;
		if (agreements && agreements.length && !this.duplicatesChecked) {
			this.addDuplicatedAgreementAddressesToAgreementList();
		}

		if (agreements && agreements.length) {
			return (
				<div className="view-move">
					<Row>
						<PageTitle>{translations.title}</PageTitle>
					</Row>
					<Row>
						<MoveWizard
							onSubmit={this.putData}
							translations={translations}
							page={page}
							nextPage={this.nextPage}
							setPage={this.setPage}
							previousPage={this.previousPage}
							agreements={this.filterOutTerminatedAgreements(agreements)}
							submitSucceeded={submitSucceeded}
						/>
					</Row>
				</div>
			);
		}

		return '';
	}
}

function mapStateToProps(state) {
	return {
		submitSucceeded: state.ui.move.move.submitSucceeded,
		page: state.ui.move.move.page,
		translations: Object.assign(
			state.translations.move,
			state.translations.general
		),
		agreements: selector.getAgreementsWithoutDaughterCompanies(state),
	};
}

export default connect(mapStateToProps)(Move);
