import moment from 'moment/moment';

export default function dateFormatter(value, customDateFormat) {
	const date = new Date(value);

	return moment(
		new Date(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0)
	)
		.format(customDateFormat || 'DD-MM-YYYY')
		.toString();
}
