import React from 'react';
// import PropTypes from 'prop-types';

import './section-title.css';

const SectionTitle = props => (
	<h3 className="section-title">{props.children}</h3>
);

export default SectionTitle;

SectionTitle.propTypes = {};
