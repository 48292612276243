import isMobileView from '../../../utils/is-mobile-view';
export default (state = isMobileView(), action) => {
	switch (action.type) {
		case 'MOBILE_VIEW':
			return action.data;
		default:
			return state;
	}
};

export const action = {
	view: (data) => ({ type: 'MOBILE_VIEW', data }),
};
