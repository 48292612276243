import React, { useEffect } from 'react';
import { Field, formValueSelector, reduxForm } from 'redux-form';
import Panel from '../../components/panel';
import { validateStep2 as validate } from '../../utils/move-wizard-validation';
import dateFormatter from '../../utils/date-formatter';
import './move-wizard-step-2.scss';
import { connect } from 'react-redux';
import Icon from '../icon';
import { dateInput, AddressForDisplay } from '../move-wizard/move-wizard';
import CardReveal from '../card-reveal';
import Home from '../icons/home';
let dispatch, change;

const startDateOnChange = (value) => {
	dispatch(change('start_date', dateFormatter(value, 'DD/MM/YYYY')));
};

const stopDateOnChange = (value) => {
	dispatch(change('stop_date', dateFormatter(value, 'DD/MM/YYYY')));
};

let MoveWizardStep2 = (props) => {
	const {
		handleSubmit,
		previousPage,
		translations,
		formValues,
		valid,
		isStepValid,
	} = props;
	const text = translations.form.step2;
	dispatch = props.dispatch;
	change = props.change;

	useEffect(() => {
		isStepValid(2, valid);
	}, [isStepValid, valid]);

	return (
		<div className="move-wizard-step-2">
			<Panel
				leftLinkText={translations.previous}
				leftButtonHandler={previousPage}
				rightLinkText={translations.next}
				rightButtonHandler={valid ? handleSubmit : null}>
				<form onSubmit={handleSubmit} noValidate>
					<h3>{translations.form.step2.title}</h3>
					<p>{translations.form.step2.explainMessage}</p>
					<CardReveal revelOn={true}>
						<div className="end-date">
							<h3>
								<Icon size="small" icon={Home} />
								{text.moveOutDateTitle}
							</h3>
							<AddressForDisplay
								className="old-address"
								address={formValues.oldAddress}
								twoLine
							/>
							<label className="field-label">
								{text.selectDate} <span>({text.optional})</span>
							</label>
							<Field
								name={text.stop_date.name}
								type="date"
								component={dateInput}
								dateOnChange={stopDateOnChange}
								showErrorInNewLine
							/>
						</div>
						<div className="start-date">
							<h3>
								<Icon size="small" icon={Home} />
								{text.moveInDateTitle}
							</h3>
							<AddressForDisplay
								className="new-address"
								address={formValues.newAddress}
								twoLine
							/>
							<label className="field-label">
								{text.selectDate} <span>({text.mandatory})</span>
							</label>
							<Field
								name={translations.form.step2.start_date.name}
								type="date"
								required
								showErrorInNewLine
								component={dateInput}
								dateOnChange={startDateOnChange}
							/>
						</div>
					</CardReveal>
				</form>
			</Panel>
		</div>
	);
};

MoveWizardStep2 = reduxForm({
	form: 'move-wizard',
	destroyOnUnmount: false,
	forceUnregisterOnUnmount: true,
	getFormState: (state) => state.ui.move.wizard,
	validate,
})(MoveWizardStep2);

const selector = formValueSelector(
	'move-wizard',
	(state) => state.ui.move.wizard
);

MoveWizardStep2 = connect((state) => {
	const startDate = selector(state, 'start_date') || '';
	const stopDate = selector(state, 'stop_date') || '';
	const oldAddress = selector(state, 'old_address') || '';
	const newAddress = selector(state, 'new_address') || '';
	const isResidential = selector(state, 'is_residential') ?? true;
	return {
		formValues: { startDate, stopDate, oldAddress, newAddress, isResidential },
	};
})(MoveWizardStep2);

export default MoveWizardStep2;
