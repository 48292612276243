import get from './methods/get';

export default {
	get: (agreement_id, location_id, meterNumber, headers) =>
		get(`/readings/${agreement_id}/${location_id}/${meterNumber}`),
	emptyResponse: [
		{
			ean_number: '',
			measurement_date: '',
			meterType: '', // GAS/ELECTRICITY

			// GAS only
			consumption: '',

			// ELECTRICITY only
			consumption_normal: '',
			consumption_low: '',
			feedin_normal: '',
			feedin_low: '',
		},
	],
};
