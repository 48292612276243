import axios from './axios-instance';
import Auth from '@aws-amplify/auth';
/**
 * `get` is a thin wrapper around `axios.get`. Using axios directly is
 * recommended.
 *
 * @deprecated
 * @param  {String} uri     The uri to request
 * @param  {Object} headers An object containing headers
 * @return {Promise}	    A Promise
 */
export default async function get(
	uri,
	_headers = {},
	fullResponse = false,
	_config
) {
	const skipLogin =
		typeof localStorage !== 'undefined' &&
		JSON.parse(localStorage.getItem('skipLogin'));
	let accessToken = '';
	let session = '';
	if (!skipLogin) {
		try {
			session = await Auth.currentSession();
		} catch (err) {
			// userpool config not initialized yet
		}
		if (session.idToken) {
			accessToken = session.idToken.jwtToken;
		}
	} else {
		accessToken = localStorage.getItem('accessToken');
	}

	const authorization = accessToken
		? { Authorization: `Bearer ${accessToken}` }
		: {};
	const requestConfig = Object.assign({}, _config);
	requestConfig.headers = Object.assign({}, _headers, authorization);
	return axios
		.get(uri, requestConfig)
		.then((response) => (fullResponse ? response : response.data))
		.catch((err) => {
			return Promise.reject(err);
		});
}
