import React from 'react';
import Button from '../../components/button';
import PropTypes from 'prop-types';

import './ask-a-question.css';
import { useSelector } from 'react-redux';
import { selector as userInfoSelector } from '../../reducers/data/user-info';

/**
 * AskAQuestion renders the ask-a-question form
 */
const AskAQuestion = ({
	submitButtonLabel = '',
	onSubmit = () => {},
	submitted = false,
	submitFeedback = '',
	subjectFieldSet = null,
	inputFieldSet = null,
	buttonKind = 'primary',
}) => {
	const isUserImpersonator = useSelector((state) =>
		userInfoSelector.isUserImpersonator(state.data)
	);
	return (
		<div
			className={`ask-a-question ${
				submitted ? 'ask-a-question--submitted' : ''
			}`}>
			<form className="ask-a-question__form" onSubmit={onSubmit} noValidate>
				{subjectFieldSet}
				{inputFieldSet}
				<Button
					disabled={isButtonDisabled(
						subjectFieldSet,
						inputFieldSet,
						isUserImpersonator
					)}
					kind={buttonKind}
					className="ask-a-question__submit"
					onClick={() => {}}>
					{submitButtonLabel}
				</Button>
			</form>

			{submitted && (
				<div className="ask-a-question__submit-feedback">{submitFeedback}</div>
			)}
		</div>
	);
};

const isButtonDisabled = (subjectFieldSet, inputFieldSet, isUserImpersonator) =>
	isUserImpersonator ||
	(subjectFieldSet &&
		inputFieldSet &&
		!(subjectFieldSet.props.value && inputFieldSet.props.value));

export default AskAQuestion;

AskAQuestion.propTypes = {
	submitButtonLabel: PropTypes.string,
	onSubmit: PropTypes.func,
	submitted: PropTypes.bool,
	submitFeedback: PropTypes.string,
	subjectFieldSet: PropTypes.node,
	inputFieldSet: PropTypes.node,
};
