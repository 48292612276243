import React from 'react';
import LightbulbOutline from '../../components/icons/lightbulb-outline';
import Flame from '../../components/icons/flame';
import Icon from '../icon';
import Link from '../link';
import ReactTooltip from 'react-tooltip';
import lodash from 'lodash';

import './connection-links-tooltip.css';
import withTranslationsGeneral from '../with-translations-general';
import PropTypes from 'prop-types';

const ConnectionLinksTooltip = ({
	connections,
	translationsGeneral,
	agreementId,
}) => {
	const ELECTRICITY = 'ELECTRICITY';
	const GAS = 'GAS';

	const electricity = getConnectionsByCommodity(
		connections,
		ELECTRICITY,
		translationsGeneral,
		agreementId
	);
	const gas = getConnectionsByCommodity(
		connections,
		GAS,
		translationsGeneral,
		agreementId
	);

	const groupByCommodity = lodash.groupBy(connections, 'commodity');

	return (
		<div className="connection-links-tooltip">
			{electricity.length > 0 && (
				<span
					className="tooltip-link"
					data-tip=""
					data-for={createToolTipId(ELECTRICITY, groupByCommodity)}>
					<Icon
						icon={LightbulbOutline}
						size="large"
						color="rgba(0, 0, 0, 0.54)"
					/>
					{getTooltip(
						electricity,
						createToolTipId(ELECTRICITY, groupByCommodity)
					)}
				</span>
			)}
			{gas.length > 0 && (
				<span
					className="tooltip-link"
					data-tip
					data-for={createToolTipId(GAS, groupByCommodity)}>
					<Icon icon={Flame} size="large" color="rgba(0, 0, 0, 0.54)" />
					{getTooltip(gas, createToolTipId(GAS, groupByCommodity))}
				</span>
			)}
		</div>
	);
};

const createToolTipId = (commodity, connections) =>
	`${connections[commodity][0].ean_code}-${connections[commodity][0].meter_number}`;

const getTooltip = (content, id) => (
	<ReactTooltip
		className="extra-tooltip"
		id={id}
		place="bottom"
		effect="solid"
		delayHide={100}>
		{content}
	</ReactTooltip>
);

const getConnectionsByCommodity = (
	connections,
	commodity,
	translationsGeneral,
	agreementId
) =>
	connections
		.filter((connection) => connection.commodity === commodity)
		.map((connection, index) => (
			<div
				className="connection-link"
				key={`${connection.meter_number}-${index}`}>
				<span>
					<Link
						to={{
							pathname: `/usage/${agreementId}`,
						}}>
						{connection.ean_code}
					</Link>
				</span>
				{connection.meter_type && (
					<span>{translationsGeneral.meterTypes[connection.meter_type]}</span>
				)}
			</div>
		));

ConnectionLinksTooltip.propTypes = {
	connections: PropTypes.array.isRequired,
};

export default withTranslationsGeneral(ConnectionLinksTooltip);
