import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Collapsible from '../../../components/collapsible';
import Panel from '../../../components/panel';
import Table from '../../../components/table';
import Invoice from '../../../components/invoice';
import Spinner from '../../../components/spinner';

import { action as collapsibleAction } from '../../../reducers/ui/invoices';

import './../invoices.css';
import ErrorText from '../../../components/error-text';

class AgreementInvoices extends Component {
	constructor(props) {
		super(props);

		this.dispatch = this.props.dispatch;
	}

	componentDidUpdate(prevProps) {
		if (this.isInvoicesDataReady(prevProps)) {
			this.openInvoicesCollapsible(this.createInvoiceCollapsibleId());
		}
	}

	isInvoicesDataReady = (prevProps) =>
		this.props.invoices &&
		this.props.invoices.length &&
		!(prevProps.invoices && prevProps.invoices.length);

	createInvoiceCollapsibleId = () =>
		this.props.agreementId + '_' + this.props.invoices[0].year.toString();

	toggleInvoicesCollapsible = (id) =>
		this.dispatch(collapsibleAction.toggle(id));
	openInvoicesCollapsible = (id) => this.dispatch(collapsibleAction.open(id));

	render() {
		const { expand, translations, invoices, agreementId } = this.props;

		if (!invoices) {
			return <Spinner />;
		}

		if (invoices.length === 0) {
			return (
				<ErrorText>
					{translations.error.introduction} {translations.error.noData}
				</ErrorText>
			);
		}
		return (
			<div className="invoices-per-year">
				{invoices &&
					invoices.map(({ year, items }) => {
						return (
							<Collapsible
								key={year}
								title={
									<React.Fragment>
										<i className="material-icons view-invoices__list__collapsible-icon">
											folder_open
										</i>
										&nbsp;
										<span>{year}</span>
									</React.Fragment>
								}
								id={agreementId + '_' + year.toString()}
								expand={expand.includes(agreementId + '_' + year.toString())}
								toggleCallback={this.toggleInvoicesCollapsible}>
								<Panel transparent>
									<Table
										items={items}
										headers={this.props.translations.invoicesTable.headers}
										showPagination
										itemsCountPerPage={10}
										className="view-invoices__list">
										{this.createInvoice}
									</Table>
								</Panel>
							</Collapsible>
						);
					})}
			</div>
		);
	}

	extractNumberAndTypesFromInvoices = (invoices) =>
		invoices.map((invoice) =>
			invoice.items.map((item) => ({
				invoiceType: item.invoice_type,
				invoiceNumber: item.invoice_number,
			}))
		);

	createInvoice = ({
		amount,
		invoice_type,
		invoice_link,
		balance,
		issue_date,
		due_date,
		period_start_date,
		period_end_date,
		settlement,
		invoice_id,
	}) => {
		const translations = this.props.translations;

		return (
			<Invoice
				id={invoice_id}
				type={invoice_type}
				amount={amount}
				downloadLink={invoice_link}
				downloadLinkText={translations.invoiceDownloadText}
				typeText={translations.invoiceType[invoice_type]}
				dueDate={due_date}
				issueDate={issue_date}
				periodStartDate={period_start_date}
				periodEndDate={period_end_date}
				settlement={settlement}
				numberTypes={this.extractNumberAndTypesFromInvoices(
					this.props.invoices
				)}
				balanceText={translations.invoiceBalance[balance]}
				translations={translations}
			/>
		);
	};
}

AgreementInvoices.propTypes = {
	translations: PropTypes.object.isRequired,
	invoices: PropTypes.array,
	agreementId: PropTypes.string,
};

export default AgreementInvoices;
