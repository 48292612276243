export default {
	menu: {
		home: {
			title: 'Home',
		},
		dashboard: {
			title: 'Dashboard',
		},
		usage: {
			title: 'Meter readings',
		},
		finance: {
			title: 'Finances',

			menu: {
				invoices: 'Invoices',
				agreements: 'Agreements',
				monthlyAmount: 'Term amount',
			},
		},
		administrative: {
			title: 'Submit online',

			menu: {
				// metermeasuring: 'Meter readings',
				move: 'Report relocation',
				iban: 'Edit IBAN',
			},
		},
		contact: {
			title: 'Contact',
		},
		language: {
			title: 'Choose Language',
			nl: 'Nederlands',
			en: 'English',
		},
		myAccount: {
			title: 'My account',

			menu: {
				account: {
					title: 'My personal data',
				},
				logout: {
					title: 'Log out',
				},
			},
		},
		production: {
			title: 'Production history',
		},
	},
	postCodestRoomcooperatief: {
		home: {
			title: 'Home',
		},
		yourParticipation: 'Your participation',
		contact: {
			title: 'Contact',
		},
	},
	general: {
		locale: 'en-US',
		agreementSingular: 'Agreement',
		agreementsPlural: 'Agreements',
		dateFormat: 'en-EN',
		impersonatorBannerText: 'You are using this as an impersonator',
		marketMessage_1024:
			'Great that you want to buy (additional) participations. Please keep in mind that your participations cannot generate more kWh than you currently use. Do you have solar panels or Windvangers? Substract the production of your solar panels/Windvangers (125 kWh each) from your energy usage.',
		marketMessage_1071:
			'Great that you want to buy (additional) participations. Please keep in mind that your participations cannot generate more kWh than you currently use. Do you have solar panels? Substract the production of your solar panels from your energy usage.',
		commodities: {
			GAS: 'Gas',
			ELECTRICITY: 'Electricity',
		},
		meterTypes: {
			SMART: 'Smart meter',
			CONVENTIONAL: 'Conventional meter',
		},
		clientNumber: 'Login code',
		relationNotSynced:
			'Your personal data is temporarily unavailable, please try again later.',
		iban: 'IBAN',
		cocNumber: 'COC number',
		vatNumber: 'Tax number',
		paymentMethod: 'Method of payment',
		paymentMethods: {
			DEFAULT: 'Use default method of payment',
			DIRECT_DEBIT_BUSINESS: 'SEPA Direct debit for business',
			DIRECT_DEBIT_CONSUMER: 'Direct debit',
			IDEAL: 'iDEAL',
			INVOICE: 'Digital invoice',
			LABEL: 'Method of payment',
			LABEL_DEFAULT: 'Default method of payment',
			LABEL_ITEM: 'Method of payment',
		},
		invoiceForm: 'Type of invoice',
		invoiceBalance: {
			OUTSTANDING: 'Open',
			PAID: 'Paid',
			REFUND: 'Refund',
			CANCELLED: 'Open',
			OPEN: 'Open',
			CLOSED: 'Paid',
			SETTLED: 'Settled',
			WRITE_OFF: 'Open',
			INVALID: 'Open',
			IN_SETTLEMENT: 'Open',
			OVERPAYMENT: 'Paid',
			PAYMENT_IN_PROGRESS: 'Payment in progress',
		},
		invoiceType: {
			INVOICE: 'Invoice',
			PERIODICAL: 'Term amount',
			ANNUAL: 'Annual settlement',
			FINAL: 'Final settlement',
			MAR: 'Monthly settlement',
			MEAR: 'Final settlement',
			DISCOUNT: 'Welcome discount',
		},
		error: {
			introduction: 'We are sorry,',
			noData: 'this data is unavailable',
			oops: 'Oops...',
			dateFormat: 'Enter date in the following manner: dd/mm/yyyy',
			required: 'This input field is mandatory',
			noPastDate: 'You cannot enter a date in the past',
			minTwoDays: 'You cannot enter a date earlier than 2 days from now',
			invalidEmail: 'Invalid email',
			startBeforeStop:
				'The move out date should be the same as or later than the move in date',
			badSituation: [
				'At this moment we cannot correctly display your current situation. This might happen when we are your supplier at multiple locations, for example during a relocation.',
				'Is this not the case for you? Then it is a known error and we are working at a solution, please try again in a couple of days.',
				'We are sorry for the inconvenience.',
			],
			notFound: 'Page not found',
			invalidAddress: 'Invalid address',
			invalidZipCode: 'Invalid postal code',
			noProjects:
				'There are currently no participations in a project registered.',
		},
		save: 'Save',
		cancel: 'Cancel',
		search: 'Search',
		confirm: 'Confirm',
		send: 'Send',
		edit: 'Edit',
		next: 'Next',
		previous: 'Previous',
		startDate: 'Start date',
		stopDate: 'End date',
		invoice: 'Invoice',
		invoiceDownloadText: 'INVOICE',
		required: 'This input field is mandatory',
		months: [
			'January',
			'February',
			'March',
			'April',
			'May',
			'June',
			'July',
			'August',
			'September',
			'October',
			'November',
			'December',
		],
		shortMonths: [
			'Jan',
			'Feb',
			'Mar',
			'Apr',
			'May',
			'Jun',
			'Jul',
			'Aug',
			'Sep',
			'Oct',
			'Nov',
			'Dec',
		],
		datePlaceholder: 'dd/mm/yyyy',
		onlyForSmartMeters:
			'This feature is not compatible witch conventional meters',
		footer: {
			privacyLink: 'Privacy and cookie statement',
		},
		logout: 'Log out',
		openingHoursDays: {
			'ma-vr': 'Mon-Fri',
			'maandag t/m vrijdag': 'Monday to Friday',
		},
		pendingText:
			'We have received your application for access to the portal environment and wind parts market in good order. This will be reviewed by Greenchoice, and after approval you will receive login details by e-mail. This allows you to log in directly to the portal environment and gain insight into the market.',
		downloadHistoricalDataLabel: 'Download historical data',
		newRegulations:
			'In November and December the government compensates your energy bill. This means we settle an amount of €190,- at the end of November and at the end of December. In October we withdraw your usual term amount. You have received mail with further information about the compensation.',
		wrongVatMessage:
			"If you've received an email about a wrong calculation of your VAT: You can see your correct term amount on the latest invoice under the tab 'Finances'",
		priceCapMessage:
			'The price ceiling for energy is included in the calculation below',
		sspOffline:
			'In the morning of the 29th of March the portal is having a planned maintenance and the portal will be unavailable. Sorry for the inconvenience.',
	},
	overlay: {
		changePhoneNumber: {
			title: 'Edit phone number',
			new: 'New phone number',
			invalid: 'Enter a valid phone number.',
			phoneNumberErrorMessage:
				'Something went wrong with saving the phone number.',
			phoneNumberErrorFakeNumberMessage:
				'This phone number is not valid. Please provide a valid phone number.',
			search: 'Search',
			save: 'Save',
			cancel: 'Cancel',
		},
		changeEmailAddress: {
			title: 'Edit email address',
			new: 'New email address',
			invalid: 'This email address is invalid',
		},
		changeAccountUsername: {
			title: 'Edit username',
			new: 'New username',
			invalid: {
				length: 'The username has to contain at least 6 characters',
				unique: 'This username is not available',
			},
		},
		changeAccountPassword: {
			title: 'Change account password',
			oldPassword: 'Enter old password',
			newPassword: 'Enter new password',
			confirmPassword: 'Confirm new password ',
			matchMessage: 'Please enter the same password.',
			patternMessage:
				'New password must contain at least 1 number, 1 uppercase and lowercase letter and is 6 to 50 chars long.',
			successMessage: 'Password successfully updated.',
		},
		monthlyAmountInfo: {
			title: 'Term amount',
			content: [
				'We determine the term amount based on your historical usage, which is tracked at your grid operator. With this estimation, we try to prevent that you have to pay a surcharge on your annual or final settlement.',
				"Are you expecting to use more, or less energy, or do you rather have a term amount which is slightly higher than calculated, so you are sure you don't have to pay a surcharge on your annual or final settlement, you can edit the term amount.",
			],
		},
		periodicalPayment: {
			title: 'Edit term amount',
			requested: 'Term amount',
			current: 'Current term amount',
			proposed: 'Proposed',
			errorMessage: 'Error with updating of periodical payment!',
		},
		stopDateNotification: {
			title: 'End date',
			content:
				'When you set an end date, we end the supply of energy at that date on your former address. Please contact us as soon as you know what the end date of supply needs to be.',
		},
		stopDateAcknowledgeEffects: {
			title: 'End date',
			content:
				'You have set an end date. This is optional and has consequences.',
		},
	},
	view404: {
		title: 'Page not found',
	},
	error: {
		title: 'Error',
	},
	verifyEmail: {
		verifiedMessage:
			'The email address has been successfully confirmed, thank you.',
		contactMessage:
			'If there are any questions, please contact us using the contact details below.',
		alreadyVerifiedMessage: 'This email address has already been verified.',
		unverifiedMessage:
			'Unfortunately, something went wrong. Please contact us using the contact details below.',
	},
	confirmReactivation: {
		reactivatedMessage:
			'The email with your temporary password has been successfully sent. Log in ' +
			'with these credentials and set your new password to fully activate your account.',
		contactMessage:
			'If there are any questions, please contact us using the contact details below.',
		alreadyReactivatedMessage: 'This account has already been reactivated.',
		reactivationFailedMessage:
			'Unfortunately, something went wrong. Please contact us using the contact details below.',
	},
	underConstruction: {
		website: 'The website',
		mode: 'is in maintenance mode',
		message:
			"We'll be back online on monday. Thank you for your understanding.",
	},
	signIn: {
		signIn: 'Login',
		username: 'User name',
		password: 'Password',
		forgotPassword: 'Forgotten your password?',
		reactivateAccount: 'Reactivate account',
		migratedLabelLinkMsg: 'You can login to your personal account of',
		migratedLabelDetailsMsg: 'Credentials have been sent to you via email.',
		migratedLabelLinkMsg_1024:
			'Did you purchase energy from Windcentrale? You can find everything about energy in your personal ',
		migratedLabelLink_1024: 'Coolblue account',
		migratedLabelDetailsMsg_1024:
			'. Login details have been sent to you by email.',
		impersonatorMessage:
			'To login as an impersonator you need to start impersonation at Multiforce.',
	},
	requireNewPassword: {
		title: 'Set new password',
		changePassword: 'Set new password',
		newPassword: 'New password',
		submit: 'Save',
	},
	passwordConfirm: {
		placeholder: 'Confirm password',
		matchMessage: 'Please enter the same password',
	},
	forgotPassword: {
		sendCodeTitle: 'Forgotten your password',
		newPasswordTitle: 'set new password',
		enterUsernameText:
			'Enter your user name. We will send an email to the email address which is known to us, containing a verification code to set a new password.',
		username: 'User name',
		lostUsername:
			'Did you forget your user name? Please contact our customer support: ',
		sendCode: 'send verification code',
		securityCode: 'Verification code',
		newPassword: 'New password',
		changePassword: 'Check code, and save password',
		backToSignInLink: 'Return to login page',
		resendCode: 'Resend verification code',
		verificationCodeText:
			'Enter verification code from the email, and set a new password.',
	},
	reactivateAccount: {
		title: 'Start using your account',
		successMessage:
			'If the email address is correct, you will receive an email with instructions to (re)activate your account.',
		emailAddressErrorMessage:
			'Sorry, we can’t process your request, please contact support desk.',
		placeholderText: 'Enter your email',
		buttonText: 'Send activation email',
		invalidEmailMessage: 'Please enter valid email address.',
		explainMessage:
			'Has you temporary password to activate your account expired? Please enter your email address to receive a new activation mail.' +
			"If you've forgotten your password, please go back and click on 'Forgot password?'.",
	},
	dashboard: {
		title: 'Dashboard',
		customerService: {
			title: 'Customer support',
			contactForm: 'Contact form',
		},
		latestInvoices: {
			title: 'Invoices',
			buttonText: 'All invoices',
		},
		quickstart: {
			title: 'Quick start',
			agreements: 'Agreements',
			account: 'My personal data',
			periodicalPayments: 'Edit term amount',
			move: 'Submit relocation',
			invoices: 'Invoices',
		},
	},
	account: {
		title: 'My personal data',
		collapsibles: {
			contactInfo: {
				title: 'Personal data',
				changedEmail: 'Email address is being changed to',
				changedPhoneNumber: 'Phone number is being changed to',
			},
			companyInfo: {
				title: 'Company details',
			},
			privacyInfo: {
				title: 'Privacy',
				privacyConsentButtonLabel: 'Historical data',
				privacySectionTitleHistorical: 'Historical data consent',
				privacyConsentTooltip:
					'By clicking this button you’re giving consent to retrieving all historical data for up to 24 months for all connections that have been used in your contracts with us.',
				privacyConsentHelper: "You've already given consent.",
				privacyGiveConsent: 'Give consent',
				privacyRevokeConsent: 'Revoke consent',
				privacyConsentGivenButtonLabel: 'Retrieve most recent historical data',
				privacyConsentNotGivenButtonLabel: 'Retrieve historical data',
				privacyConsentDateLabel: 'Previously retrieved at ',
			},
			shippingAddress: {
				title: 'Supply address',
			},
			paymentDetails: {
				title: 'Payment details',
				bankAccountName: 'Ascription',
				debtorNumber: 'Debtor number',
			},
			movings: {
				title: 'Movings',
				contactAddress: 'Contact address',
				newAddress: 'New address',
				movingDate: 'Moving date',
				noMoves: 'There are no moves yet',
			},
			orders: {
				ordersToExpire: 'Orders to expire',
			},
		},
	},
	contact: {
		title: 'Contact',
		form: {
			title: 'Ask a question',
			customerServiceTitle: 'Customer support',
			subject: 'Choose a subject',
			yourMessage: 'Your message',
			subjectOptions: [
				{ label: 'Payments', value: 'Payments' },
				{ label: 'Products', value: 'Products' },
				{ label: 'Usages', value: 'Usages' },
				{ label: 'Relocation', value: 'Relocation' },
				{
					label: 'Suggestion/complaint',
					value: 'Suggestion/complaint',
				},
				{ label: 'Other', value: 'Other' },
			],
		},
		feedback: 'The message has been sent',
	},
	home: {
		title: 'Home',
		contactInfo: {
			title: 'My personal data',
			rightLinkText: 'more',
			changedEmail: 'Email address is being changed to',
			changedPhoneNumber: 'Phone number is being changed to',
		},
		periodicalPayment: {
			title: 'Term amount',
			rightLinkText: 'more',
			changeRequested: 'Is being changed to',
		},
		contact: {
			title: 'Contact',
			rightLinkText: 'Contact form',
		},
		invoices: {
			title: 'Invoices',
			rightLinkText: 'See all invoices',
			noInvoices: 'There are no invoices yet',
		},
		move: {
			title: 'Relocate',
			rightLinkText: 'Submit',
			content:
				'We take care of everything, so you are always insured of energy!',
		},
		metermeasurements: {
			metermeasurements: 'Meter readings',
			rightLinkText: 'more',
		},
	},
	invoices: {
		title: 'Invoices',
		invoicePeriodDate: 'term',
		agreement: 'Agreement',
		invoicesTable: {
			headers: [
				{
					period: 'Term',
					type: 'Type',
					price: 'Amount',
					checked: '',
					link: '',
				},
			],
			body: {
				balance: 'Status',
				period: 'Term',
				type: 'Type',
				price: 'Amount',
			},
		},
		invoiceTooltip: {
			title: 'Details of settlement',
			invoice: 'Invoice',
			price: 'Amount',
			type: 'Type',
			restAmount: 'Residual amount',
			paymentReference: 'Payment reference',
		},
	},
	agreements: {
		title: 'Agreements',
		downloadLinkText: 'Download price fact sheet',
		fromDate: 'The term of the agreement is from',
		throughDate: 'till',
	},
	metermeasuring: {
		title: 'Meter readings',
	},
	monthlyAmount: {
		title: 'Term amount',
		agreement: {
			fromDate: 'Term',
			throughDate: 'till',
		},
		periodicalPayment: {
			title: 'Current term amount',
			edit: 'Edit',
			viewMore: 'See more',
			changeRequested: 'Is being changed to',
			info:
				'With the term amount, a part of the annual bill is already being paid monthly.',
		},
	},
	move: {
		title: 'Relocate',
		noAgreementsMessage:
			'All agreements are terminated. Do you want to sign up for a new contract on a different address? Please contact our customer service.',
		connections: {
			electricity: 'Electricity',
			gas: 'Gas',
		},
		form: {
			chooseAgreementAddress: 'Agreement',
			chooseOldAddress: 'Addresses',
			enterNewAddress: 'Dates',
			confirm: 'Confirm',
			step0: {
				title: 'Agreement',
				chooseAgreementTitle: 'Choose agreement',
				showDuplicates: 'Show Agreement with same address',
				agreementMsg:
					'Choose the agreement for which you want to add new address. With a new ' +
					'address you will also receive a new agreement, under the same conditions. ' +
					'You will receive this by e-mail after confirming the change of address.',
				chooseAgreementHeadline: 'Choose the agreement for the relocation.',
				multipleAddressMsg: {
					part1:
						"It's not possible to relocate multiple addresses for one agreement online.",
					part2: 'Please contact',
					part3:
						' customer service to help you with relocating ' +
						'the address for agreement ',
				},
				multipleAddress: {
					part1:
						'There already exists an agreement with the same address, we can not relocate this address online.',
					part2: 'Please contact',
					part3:
						' customer service to help you with relocating ' +
						'the address for agreement ',
				},
			},
			step1: {
				title: 'Current address',
				electricity: 'Electricity',
				gas: 'Gas',
				findAddress: 'Find address based on postal code.',
				postalCode: 'Postal Code',
				houseNumber: 'House Number',
				connections: 'connections',
				connection: 'connection',
				newAddress: 'New address',
				selectExtensionPlaceholder: 'Select addition',
				noActiveAddresses: "You don't have currently active supply addresses",
				movePostalAddress: 'Move postal address to new address',
				moveInvoiceAddress: 'Move invoice address to new address',
				residentialAddress: 'Residential',
				newAddressMsg: 'Enter the postal code and house number below.',
				multipleAddressConnection:
					'We can not process moving to an address with multiple connections online, please contact customer service to help you with moving to this address.',
				differentAddressConnection:
					'Unfortunately we can not process the relocation to this address online, please contact the helpdesk to help you with this.',
				searchedAddressTitle: 'Address for this portal code.',
				availableConnections: 'Available connections at this address:',
				nonExistingAddressError:
					'Address not found. If you are sure this address is valid, please contact our customer service.',
				multipleAddressFound: 'Please select correct addition.',
				addresses: {
					label: 'For which address do you want to submit a relocation?',
					name: 'old_address',
				},
				altAddressMsg:
					'Multiple addresses were found for entered address. Please choose the correct address for relocation from the list below.',
				addressFields: [
					{
						label: 'Zip code',
						name: 'new_address.zip_code',
					},
					{
						label: 'Number',
						name: 'new_address.house_number',
					},
					{
						label: 'Extension',
						name: 'new_address.house_number_extension',
					},
					{
						label: 'Street',
						name: 'new_address.street',
					},
					{
						label: 'City',
						name: 'new_address.city',
					},
				],
				commodityList: 'At this address we are currently delivering',
				whichOldAddress: 'Your old address',
				selectAddress: 'Select address',
				optional: 'Optional',
			},
			step2: {
				title: 'Select moving dates',
				explainMessage:
					'When you enter a date, we will end the delivery at your old address. ' +
					'If you do not yet know when you want to end the delivery at the old address, do not fill ' +
					'anything. The delivery will then continue as usual, also to your old address. If you know when the ' +
					'delivery has to be ended, fill deactivation field.',
				whatsTheNewAddress: 'What is the new address?',
				moveOutDateTitle: 'Move out date (deactivate)',
				moveInDateTitle: 'Move in date (activate)',
				shipping: 'Supply',
				selectDate: 'Select date',
				optional: 'optional',
				mandatory: 'mandatory*',
				endDateTitle:
					'When do you want to deactivate the connection to your current address?',
				endDateMessage:
					'In case you do not know end date yet, leave this input field empty',
				start_date: {
					label: 'At what date should the delivery on this address start?',
					name: 'start_date',
				},
				stop_date: {
					label:
						'When you set an end date, we will end the supply at your address on that date. In the case you do not know the end date yet, leave this input field empty',
					name: 'stop_date',
				},
				confirm: 'We will stop the supply of energy at the following date',
			},
			step3: {
				title: 'Check and send',
				infoMessage:
					'You will receive energy at your new address under the same conditions.',
				noEndDate: 'No date is specified when delivery should start.',
				noEndDateSelected: 'Move out date is not selected.',
				moveOut: 'Move out',
				moveIn: 'Move in',
				until: 'Until',
				from: 'From',
				deliveryMsg: 'you want to have energy delivered at:',
				willBeCanceledWithDate: 'Delivery will be stopped at',
				willBeCanceledWithoutDate:
					'There is no date set on which the delivery should be ended',
				on: 'on',
				willReceive: 'Do you want to have energy delivered on',
				currentAddress: 'Current address',
				newAddress: 'New address',
				stopDateMsg:
					'Date at which the connection to the current address will be deactivated',
				startDateMsg:
					'Date at which the connection to the new address will be activated',
			},
			step4: {
				title: 'Request has been sent',
				thankYou:
					'Thank you for submitting this. We will take care of our relocation as soon as possible.',
				info:
					'Keep an eye out for your mail / we will contact you / there is no action required on your part.',
				error4xx:
					'Something seems to be wrong in the values you entered. Please verify.',
				error5xx: 'Something went wrong, please try again later.',
			},
		},
	},
	products: {
		title: 'Products',
	},
	usage: {
		title: 'Meter readings',

		meterDataMissing: 'There are no meter readings available for this meter',

		ean: 'EAN',
		meterType: 'Meter type',
		meterNumber: 'Meter number',
		capacity: 'Capacity',

		smartMeter: 'Smart meter',
		conventionalMeter: 'Conventional meter',

		electricity: 'Electricity',
		gas: 'Gas',

		connectionDetails: 'Connection details',
		measurements: 'Meter readings',
		date: 'Date',
		usage: 'Usage',
		production: 'Feed in',
		low: 'Low',
		standard: 'Normal',
		unit: {
			KWH: 'kWh',
			KWT: 'KW',
			MTQ: 'm³',
			M3N: 'm³',
		},
		usageTable: {
			headers: [
				{
					source: '',
					ean: 'EAN',
					standard: 'Normal',
					low: 'Low',
					details: '',
				},
			],
			body: {
				meterDetailsHistory: 'More details & history',
			},
		},
		usageHistory: {
			title: 'Details & History',
			cancel: 'Close',
			connectionDetails: 'Connection details',
			connectionHistory: 'Connection history',
			historyTable: {
				headers: [
					{
						usage: 'Usage',
						production: 'Feed in',
						date: 'Date',
						low: 'Low',
						standard: 'Normal',
					},
				],
			},
		},
	},
	iban: {
		title: 'Edit IBAN',
		ibanLabel: 'IBAN',
		bankAccountName: 'Ascription',
		fromDate: 'Select date',
		validations: {
			ibanRequired: 'Submit an IBAN.',
			ibanNotValid:
				'This IBAN seems invalid. Check again, or contact our customer support department.',
			bankAccountNameRequired: 'Submit ascription.',
			bankAccountNameToLong: 'This ascription is too long.',
			startDateRequired: 'Select a start date',
			startDateMessage: 'The first possible start date is tomorrow',
			requestIsBeingProcessed: 'The request is being processed.',
		},
		ibanStatus: 'IBAN is being changed to: ',
		ibanStatusAt: 'at',
		priceAgreementNotFound: 'This information is not available.',
	},
	sustainable: {
		participation: {
			title: 'Your participation',
			peak: 'Normal',
			offPeak: 'Low',
			endDate: 'Till',
			startDate: 'From',
			peakUnitOfMesure: 'kWh',
			offPeakUnitOfMesure: 'kWh',
			normalUnitOfMesure: 'kWh',
			project: 'Project',
			mijnParticipation: 'My participation',
			greenchoice_id: 'Greenchoice Number',
			participation_identifier: 'Participation number',
			mutation_date: 'Mutation date',
			address: 'Address',
			ean: 'Project connection',
			share: 'Number of certificates',
			supplier: 'Supplier for electricity',
			supplierFromDate: 'From',
			supplierToDate: 'Till',
			currentPower: 'Past hour',
			energyToday: 'Production today',
			totalEnergy: 'Total production project',
			energyMonth: 'Your production this month',
			period: 'Your production',
			weatherStatus: 'Weather status',
			temperature: 'Temperature',
			wind: 'Wind force',
			noWeatherData: 'Weather data not available at the moment',
			projectEndDate: 'Project end date',
		},
		moveShares: {
			title: 'Move shares',
			existingAddress: 'Existing address',
			newAddress: 'New address',
			addressFields: {
				postcode: 'Postcode',
				houseNumber: 'House number',
				numberExtension: 'Extension',
				street: 'Street',
				city: 'City',
			},
		},
	},
	weatherStatusMap: {
		onbewolkt: 'clear',
		zon: 'sun',
		Zonnig: 'sunny',
		'licht bewolkt': 'Partially cloudy',
		'half bewolkt': 'half-cloudy',
		bewolkt: 'mostly clouded',
		'geheel bewolkt': 'overcast',
		'zwaar bewolkt': 'heavy clouds',
		'droog na regen': 'dry after rain',
		regen: 'rain',
		'lichte regen': 'light rain',
		motregen: 'drizzle',
		'dichte motregen': 'dense drizzle',
		'motregen en regen': 'drizzle and rain',
		'lichte motregen en regen': 'light drizzle and rain',
		'af en toe lichte regen': 'occasional light rain',
		onweer: 'thunderstorm',
		bliksem: 'lightning',
		maan: 'moon',
		sneeuw: 'snow',
		mist: 'fog',
		hagel: 'hail',
	},
	amplify: {
		'User does not exist': 'User name or password incorrect',
		'Username/client id combination not found.': 'User not found',
		'Incorrect username or password': 'User name or password incorrect',
		'null failed with error Generate callenges lambda cannot be called..':
			"Input field 'password' cannot be empty",
		'Username cannot be empty': "Input field 'username' cannot be empty",
		'Code cannot be empty': "Input field 'code' cannot be empty",
		'Password cannot be empty': "Input field 'user name' cannot be empty",
		'Custom auth lambda trigger is not configured for the user pool.':
			'User name or password incorrect',
		'Temporary password has expired and must be reset by an administrator.':
			'The temporary password has expired, and must be reset by an administrator, please contact us.',
		'Invalid verification code provided, please try again.':
			'Invalid verification code provided, please try again.',
		'Invalid password format': 'Invalid characters used for password',
		'Password does not conform to policy: Password not long enough':
			'Password does not conform to policy; password not long enough (at least 8 characters).',
		'Password does not conform to policy: Password must have lowercase characters':
			'Password does not conform to policy; password must contain lowercase characters.',
		'Password does not conform to policy: Password must have uppercase characters':
			'Password does not conform to policy: password must contain uppercase characters.',
		'Password does not conform to policy: Password must have numeric characters':
			'Password does not conform to policy: password must contain numeric characters.',
		'Password attempts exceeded': 'Maximum password attempts exceeded',
		'Invalid session for the user, session is expired.':
			'Invalid session, the session is expired.',
		'Attempt limit exceeded, please try after some time.':
			'Attempt limit exceeded, please try again later.',
		'User password cannot be reset in the current state.':
			'Invalid action; pleas login first with the temporary password we have sent you by email.',
		'CustomMessage invocation failed due to error Socket timeout while invoking Lambda function.':
			'Something went wrong with sending the email, please try again.',
		'Incorrect username or password.': 'Incorrect username or password.',
		'PreAuthentication failed with error You are unable to log in into your self-service portal. For questions, please contact our customer service.':
			'You are unable to log in into your self-service portal. For questions, please contact our customer service.',
	},
	marketPlace: {
		addToCart: 'To my basket',
		myCart: 'My basket',
		title: 'Market',
		menu: {
			availaleOffers: 'Available certificates',
			myOffers: 'My certificate offers',
			createOffer: 'Create Offer',
			myOrders: 'My Orders',
			personalOffers: 'Personal offers',
		},
		proceeds: 'Amount to be received after deduction of administration costs',
		administrationCosts: 'Administration costs per certificate',
		minimumSellPrice: 'The lowest price per certificate currently available',
		maximumSellPrice: 'The highest price per certificate currently available',
		economicValue: 'The economic value of the certificate',
		sellingNotAllowed: 'Selling not allowed for this project',
		pricePerShare: 'Price per certificate',
		numberOfShares: 'Certificates',
		date: 'Publish Date',
		transferDate: 'Transfer date',
		myDate: 'Publish Date',
		project: 'Project',
		projectEndDate: 'Project end date',
		expirationDate: 'Expiration date',
		status: {
			label: 'Status',
			CREATED: 'Created',
			APPROVED: 'Approved',
			ACTIVE: 'Active',
			SOLD: 'Sold',
			REJECTED: 'Rejected',
			REVOKED: 'Revoked',
			AVAILABLE: 'Available',
			RESERVED: 'Reserved',
			CLOSED: 'Closed',
			AWAITING_PAYMENT: 'Awaiting payment',
			BOUGHT: 'Bought',
			COMPLETED: 'Completed',
			TRANSFERRED: 'Transferred',
			CANCELLED: 'Cancelled',
			EXPIRED: 'Expired',
		},
		offerPublicationType: {
			PUBLIC: 'Public',
			DIRECT: 'Direct',
			PRIVATE: 'Private',
		},
		reason: {
			label: 'Reject reason',
			PARTICIPATION_UNKNOWN: 'No participation exists',
			MULTIPLE_PARTICIPATIONS_PROJECT: 'Multiple participation exist',
			INSUFFICIENT_SHARES: "The participation doesn't have shares",
			REJECTED_GREENCHOICE: 'GreenChoice rejected the creation of the offer',
			SELLING_NOT_ALLOWED: 'Selling not allowed for this project',
		},
		isOfferOnMartket: 'Is offer on market?',
		sharesToBuy: 'Buy number of units',
		items: 'item(s)',
		orderSummary: 'Order summary',
		productDetails: 'Product details',
		quantity: 'Quantity',
		price: 'Price',
		total: 'Total',
		totalCost: 'Total cost',
		order: 'Order',
		createOfferSuccess:
			'Your offer has been set. You can find it in `My certificate offers`',
		reference: 'Offer Reference',
		participation_identifier: 'Participation id',
		yes: 'Yes',
		no: 'No',
		adres: 'Address',
		old_address: 'Old address',
		transfer_date: 'Transfer Date',
		termsconditions: 'Terms & conditions for project',
		iAccept: 'I accept ',
		iRead: ', and declare that I have read these.',
		ofProject: 'for project',
		member_agreement: 'membership agreement ',
		statutes: 'the articles of association ',
		placeOrder: 'Request order',
		cancelOrder: 'Cancel Order',
		orderCancelled: 'Order cancelled',
		retractOffer: 'Retract Offer',
		withdrawelPeriodCheckboxContent:
			'My purchase is subject to a 14-day withdrawal period from the moment the payment has been made.',
		retractNumberOfUnits: 'Retract number of units',
		noOrders:
			'Currently no orders have been placed yet. For an overview of your windshares, go to your Participation.',
		noOffers: 'Currently no offers have been placed yet.',
		maxPriceError: 'This amount exceeds the maximum set price',
		minPriceError: 'This amount exceeds the minumum set price',
		thankYou: {
			thankYouTextFirst: 'Your payment of € ',
			thankYouTextSecond:
				' has been successfully received by us. Here you can go back to ',
			thankYouTextEnding: ', there you will find an overview of your order.',
			accountLinkText: 'your personal account',
			cancelled:
				'You have canceled the payment of your wind part, we are of course very sorry. You can go back to ',
		},
		paylink: 'Pay',
		personalOfferEmailDisclaimer:
			'Emailaddress of the person you want to personally transfers your windparts to. This person will receive a notification.',
		personalOfferDisclaimer:
			'Transferring personal windparts is only allowed when (new) member has an active energyagreement for electricity.',
		personalOfferDisclaimer_1024:
			'Please note: The transfer of personal wind parts from Windcentrale is only allowed if the (new) member has an active energy contract for electricity with Greenchoice.',
		personalOfferDisclaimer_1071:
			'Please note: The transfer of personal wind parts is only allowed if the (new) member has an active energy contract for electricity with cooperating energy suppliers of Winddelen.',
		publicOffer: 'Public offer',
		personalOffer: 'Personal offer',
		offerType: 'Offer type',
		personalOffers: 'Personal offers',
		directOfferCode: 'Personal offer code',
		expireMessage:
			"Don't forget to make your payment for the wind parts order. The reservation of your order will remain for a maximum of 14 days. If you clicked on pay, the payment link remains valid for a maximum of 7 days. After that, your reservation will expire.",
		expiredOrderWarning:
			'Your windparts order has expired. As a result, your reservation for these specific wind parts has been cancelled. Check the Market to see if these and/or other wind parts are still available.',
		redeemOffer: 'Redeem offer',
		noMyOrders:
			'No orders have been placed at this time. For an overview of your wind parts, go to Your Participation.',
		noMyOffers:
			'No offer has been placed on the market at this time. For an overview of your wind parts, go to Your Participation.',
		noPersonalOffers:
			'There is no personal offer to your email address. Did you receive a personal offer code? Enter this code to view your personal offer.',
		get: 'Get',
		notEnoughShares: 'There are not enough units available. Adjust the number.',
	},
	register: {
		title: 'Create account',
		notAvailable: 'Registration form is not available for this label.',
		ageValidation: 'Age cannot be lower than 18 years.',
		fields: {
			queryText: 'Search',
			address: 'Address',
			relatedRelations: 'Related relations',
			relatedCustomers: 'Related customers',
			relatedCustomerCompany: 'Parent company', // Request change by PO: 'Customer company'
			relatedLegalGuardian: 'Legal guardian',
			relatedEmployer: 'Employer',
			relatedParentCompany: 'Parent company',
			countryCode: 'Country code',
			lastName: 'Last name',
			gender: 'Gender',
			genders: {
				male: 'Male',
				female: 'Female',
			},
			salutation: 'Addressing',
			salutations: {
				MR: 'Mr.',
				MRS: 'Ms.',
				THEHEIRSOF: 'The heirs of',
			},
			type: 'Type',
			domain: 'Domain',
			domains: 'Domains',
			birthDate: 'Date of birth',
			street: 'Street',
			city: 'City',
			phone: 'Phone',
			label: 'Label',
			locationDescription: 'Location description',
			debtorNumber: 'Debtor number',
			status: 'Status',
			relationStatus: 'Relation status',
			verificationStatus: 'Verification status',
			verificationSendDate: 'Email send date',
			processType: 'Process type',
			reminderSendDate: 'Reminder send date',
			action: 'Action',
			phoneNumber: 'Phone number',
			labelName: 'Label name',
			companyName: 'Company name',
			RelationId: 'Relation ID',
			labelKey: 'Label key',
			customerCompany: 'Customer company',
			parentCompany: 'Parent company',
			company: {
				name: 'Company name',
				cocNumber: 'CoC Number',
				cocNumberFull: 'Chamber of Commerce number',
				vatNumber: 'VAT number',
				eanCode: 'EAN code',
				captarCodePrefix: 'CapTar code prefix',
				timeOfUsePlaceholder: 'Select time of use',
				timeOfUse: 'Time of use',
				timesOfUse: {
					T_7_21: '7-21h',
					T_7_23: '7-23h',
					T_0_24: '0-24h',
				},
			},
			cocNumber: 'CoC number',
			cocNumberFull: 'Chamber of Commerce number',
			vatNumber: 'VAT number',
			eanCode: 'EAN code',
			streetName: 'Street name',
			houseNumber: 'House number',
			house: 'House Number',
			houseExtension: 'Extension',
			debtor: 'Debtor number',
			seller: 'Seller',
			email: 'Email',
			emailType: 'Email type',
			emailVerification: 'Email verification',
			emailInvoice: 'Invoice email',
			emailInvoiceVerification: 'Invoice email verification',
			nickname: 'Nickname',
			postcode: 'Postcode',
			client: 'Client Number',
			bankAccountName: 'Ascription bank account',
			bankAccountNumber: 'Bank account number (IBAN)',
			bankAccount: 'Bank account',
			initials: 'Initials',
			shippingAddress: 'Contact address',
			invoiceAddress: 'Invoice address',
			postalAddress: 'Postal address',
			prefix: 'Prefix',
			validFrom: 'Start date',
			validTo: 'End date',
			debtorName: 'Debtor name',
			documentUri: 'Vat Exceptions PDF',
			name: 'Relation',
			vatExceptions: 'VAT exceptions',
			preferredName: 'Preferred Name',
			membershipReference: 'Membership reference',
			labelCustomerNumber: 'External customer number',
			sendEnergyTermInvoice:
				'This relation wishes to receive all energy invoices by e-mail including terms.',
			listEnergyTermInvoice: 'Energy invoice',
			zipcode: 'Postal code',
			search: 'Search',
			communicationByMail: 'Prefer by post',
		},
	},
	production: {
		totalWind: 'Total Wind Turbine',
		totalShares: 'Total of my windshares',
		total: 'Total',
		perHour: 'Per hour',
		perWeek: 'Per week',
		perMonth: 'Per month',
		perDay: 'Per day',
		perYear: 'Per year',
		noData: 'No data',
		noHourlyDataOne: 'The data of ',
		noHourlyDataTwo: ' are not yet in. Come back later.',
		period: 'Period',
		title: 'Production history',
	},
	notifications: {
		title: 'Notifications',
		noNotices: 'Currently there are no messages for you.',
	},
};
