import React from 'react';

import Panel from '../components/panel';
import closeableOverlay from '../components/closeable-overlay';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

class MonthlyAmountInfo extends React.Component {
	render() {
		const { translations } = this.props;
		return (
			<Panel title={translations.title} name="monthly-amount-info">
				{translations.content.map((string, index) => (
					<p key={index}>{string}</p>
				))}
			</Panel>
		);
	}
}

function mapStateToProps(state) {
	return {
		translations: Object.assign(
			state.translations.overlay.monthlyAmountInfo,
			state.translations.general
		),
	};
}

export default withRouter(
	connect(mapStateToProps)(closeableOverlay(MonthlyAmountInfo))
);
