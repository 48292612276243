import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import Address from '../address';
import Email from '../email';
import Phonenumber from '../phonenumber';
import EditButton from '../edit-button';

import './contact-info.css';
import AccountUsername from '../account-username';
import AccountPassword from '../account-password';

/**
 * The `ContactInfo` component is used to display contact information
 */
const ContactInfo = ({
	editable = false,
	phoneEditable = false,
	emailEditable = false,
	accountUsernameEditable = false,
	relations = {},
	accountUsername,
	editButtonTitle,
	changeEmailLink,
	changePhoneNumberLink,
	changeAccountPasswordLink,
}) => (
	<section className="contact-info">
		<Address
			addresses={relations.addresses}
			type="INVOICE"
			fallbackType="SHIPPING"
		/>

		{accountUsername && (
			<Fragment>
				<AccountUsername username={accountUsername}></AccountUsername>
				<AccountPassword>
					{editable && (
						<EditButton
							title={editButtonTitle}
							linkUrl={changeAccountPasswordLink}
							disabled={!(accountUsernameEditable && editable)}
						/>
					)}
				</AccountPassword>
			</Fragment>
		)}

		{relations.email && (
			<Email text={<span>{relations.email}</span>}>
				{editable && (
					<EditButton
						title={editButtonTitle}
						linkUrl={changeEmailLink}
						disabled={!(emailEditable && editable)}
					/>
				)}
			</Email>
		)}
		{relations.phone_number && (
			<Phonenumber phone_number={relations.phone_number}>
				{editable && (
					<EditButton
						title={editButtonTitle}
						linkUrl={changePhoneNumberLink}
						disabled={!(phoneEditable && editable)}
					/>
				)}
			</Phonenumber>
		)}
	</section>
);

export default ContactInfo;

ContactInfo.propTypes = {
	relations: PropTypes.shape({
		addresses: PropTypes.arrayOf(PropTypes.object),
		email: PropTypes.string,
		initials: PropTypes.string,
		last_name: PropTypes.string,
		phone_number: PropTypes.string,
		/** Last name prefix */
		prefix: PropTypes.string,
		salutation: PropTypes.string,
	}).isRequired,
};
