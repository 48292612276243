import React from 'react';
import { RequireNewPassword } from 'aws-amplify-react';
import Button from '../button';
import FieldSet from '../fieldset';
import './cognito-require-new-password.css';
import { connect } from 'react-redux';
import LangSwitch from '../lang-switch';

class CognitoRequireNewPassword extends RequireNewPassword {
	constructor(props) {
		super(props);
		this._validAuthStates = ['requireNewPassword'];
		this.state = {
			...this.state,
			matched: false,
		};
	}

	preHandleInputChange = (e) => {
		this.handleInputChange(e);
		this.setState({
			matched: this.inputs.password === this.inputs.passwordConfirm,
		});
	};

	showComponent(theme) {
		const { dispatch, translations, labelName, logo, email } = this.props;
		return (
			<div className="cognito-require-new-password">
				<LangSwitch dispatch={dispatch} translations={translations} />
				<div className="logo">
					{logo && <img src={logo} alt={labelName || 'Logo'} />}
				</div>
				<h1 className="title">{translations.requireNewPassword.title}</h1>
				<form onKeyPress={(e) => e.key === 'Enter' && this.change()}>
					<div className="new-password-field">
						<span>{translations.requireNewPassword.changePassword}</span>
						<FieldSet
							name="password"
							placeholder={translations.requireNewPassword.newPassword}>
							<input
								id="password"
								onChange={this.preHandleInputChange}
								type="password"
							/>
						</FieldSet>
						<FieldSet
							name="passwordConfirm"
							placeholder={translations.passwordConfirm.placeholder}>
							<input
								id="passwordConfirm"
								onChange={this.preHandleInputChange}
								type="password"
							/>
						</FieldSet>
					</div>
					<div>
						<span>
							{!this.state.matched &&
							this.inputs.password &&
							this.inputs.passwordConfirm ? (
								<span className="passwords-not-match">
									{translations.passwordConfirm.matchMessage}
								</span>
							) : (
								<br />
							)}
						</span>
						<Button
							disabled={!this.state.matched}
							className="signin"
							kind="primary"
							type="button"
							onClick={this.change}>
							{translations.requireNewPassword.submit}
						</Button>
					</div>
				</form>
				<footer>
					<span>{email}</span>
				</footer>
			</div>
		);
	}
}

function mapStateToProps(state, ownProps) {
	return Object.assign({}, { translations: state.translations }, ownProps);
}

const CognitoForgotPasswordConnected = connect(mapStateToProps)(
	CognitoRequireNewPassword
);
export default CognitoForgotPasswordConnected;
