const initialState = null;

const platform = (state = initialState, action) => {
	switch (action.type) {
		case 'SET_PLATFORM':
			return action.value;
		default:
			return state;
	}
};

export default platform;

export const action = {
	platform: value => ({ type: 'SET_PLATFORM', value }),
};
