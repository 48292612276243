import React from 'react';
import PropTypes from 'prop-types';

import Icon from '../icon';
import IconLock from '../icons/lock';
import './account-password.css';

const AccountPassword = ({ children, iconSize }) => (
	<div className="accountPassword">
		<div>
			<Icon icon={IconLock} size={iconSize || 'inline'} />
			<span className="password">***********</span>
		</div>{' '}
		{children}
	</div>
);

export default AccountPassword;

AccountPassword.propTypes = {
	children: PropTypes.any.isRequired,
};
