import React from 'react';
import PropTypes from 'prop-types';

import './button.css';

const Button = ({ className, transparent, ...props }) => {
	const kindClass = props.kind ? `${props.kind}-bg-color` : '';
	const transparentClass = transparent ? 'button--transparent' : '';
	return (
		<button
			className={`button ${transparentClass} ${kindClass} ${className ||
				''}`.trim()}
			{...props}>
			{props.children}
		</button>
	);
};

export default Button;

Button.propTypes = {
	kind: PropTypes.oneOf([
		'primary',
		'secondary',
		'tertiary',
		'highlight',
		'transparent',
	]),
	transparent: PropTypes.bool,
};
