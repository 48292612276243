import React, { Fragment } from 'react';
import { SignIn } from 'aws-amplify-react';
import Button from '../button';
import FieldSet from '../fieldset';
import './cognito-signin.css';
import { connect } from 'react-redux';
import LangSwitch from '../lang-switch';
import { Hub } from '@aws-amplify/core';
import { isMultiforceReferrer } from '../../utils/checkReferrer';
import ReactivateAccount from '../../components/reactivate-account';
import isUserImpersonator from '../../utils/is-impersonator';
import isLabelMigrated from '../../utils/is-label-migrated';
import MigratedLabel from '../../components/migrated-label/migrated-label';

class CognitoSignIn extends SignIn {
	constructor(props) {
		super(props);
		this.prefix = 'impersonator_';
		this._validAuthStates = ['signIn', 'signedOut', 'signedUp'];
		Hub.listen('auth', this.handleImpersonatorSignOut);
		this.isfirstReferrerFromMultiforce = isMultiforceReferrer();
		this.state = {
			...this.state,
			showReactivate: false,
		};
	}

	removeImpersonatorParam() {
		if (window.location.search.includes('impersonator')) {
			window.history.replaceState({}, document.title, '/');
		}
	}

	isSignedOutUserImpersonator = (event, data) =>
		event === 'signOut' && isUserImpersonator(data);

	showSignOutMessage(props) {
		this.error(props.translations.signIn.impersonatorMessage);
	}

	handleImpersonatorSignOut = ({ payload: { event, data } }) =>
		this.isSignedOutUserImpersonator(event, data) &&
		!this.isfirstReferrerFromMultiforce &&
		this.showSignOutMessage(this.props);

	beforeSignIn() {
		if (this.inputs.username) {
			this.inputs.username = this.inputs.username.trim().toLowerCase();
		}
		if (isMultiforceReferrer()) {
			this.inputs.username = (this.prefix + this.inputs.username).toLowerCase();
		}
		this.signIn();
	}

	hasUpperCase(str) {
		return /[A-Z]/.test(str);
	}

	showReactivateAccount() {
		this.setState({ showReactivate: true });
	}

	showComponent(theme) {
		this.removeImpersonatorParam();
		const {
			translations,
			dispatch,
			logo,
			labelName,
			email,
			labelKey,
		} = this.props;

		return (
			<div className="cognito-signin">
				<LangSwitch dispatch={dispatch} translations={translations} />
				<div
					className="logo"
					onClick={() => this.setState({ showReactivate: false })}>
					{logo && <img src={logo} alt={labelName || 'Logo'} />}
				</div>
				<h1 className="title">
					{!this.state.showReactivate
						? translations.signIn.signIn
						: translations.reactivateAccount.title}
				</h1>
				{isLabelMigrated(labelKey) ? (
					<MigratedLabel labelKey={labelKey} translations={translations} />
				) : !this.state.showReactivate ? (
					<form onKeyPress={(e) => e.key === 'Enter' && this.beforeSignIn()}>
						{isLabelMigrated(labelKey) && (
							<MigratedLabel labelKey={labelKey} translations={translations} />
						)}
						<div>
							{isMultiforceReferrer() ? (
								<FieldSet
									name="username"
									placeholder={translations.signIn.username}
									className="impersonator">
									<div className="input-group-prefix">impersonator_</div>
									<input
										autoFocus
										id="username"
										onChange={this.handleInputChange.bind(this)}
										type="email"
									/>
								</FieldSet>
							) : (
								<FieldSet
									name="username"
									placeholder={translations.signIn.username}>
									<input
										autoFocus
										id="username"
										onChange={this.handleInputChange.bind(this)}
										type="email"
									/>
								</FieldSet>
							)}
						</div>
						<div>
							<FieldSet
								name="password"
								placeholder={translations.signIn.password}>
								<input
									id="password"
									onChange={this.handleInputChange}
									type="password"
								/>
							</FieldSet>
						</div>
						<div>
							<Button
								className="signin"
								kind="primary"
								type="button"
								onClick={() => this.beforeSignIn()}>
								{translations.signIn.signIn}
							</Button>
						</div>
						{!isMultiforceReferrer() && (
							<span>
								<span
									className="forgot-password"
									onClick={() => this.changeState('forgotPassword')}>
									{translations.signIn.forgotPassword}
								</span>
								<span
									className="reactivate-account"
									onClick={() =>
										this.showReactivateAccount('reactivateAccount')
									}>
									{translations.signIn.reactivateAccount}
								</span>
							</span>
						)}
					</form>
				) : (
					<Fragment>
						<ReactivateAccount {...this.props} />
						{this.state.showReactivate && (
							<span
								className="back-to-signin"
								onClick={() => this.setState({ showReactivate: false })}>
								{translations.forgotPassword.backToSignInLink}
							</span>
						)}
					</Fragment>
				)}
				<footer>
					<span>{email}</span>
				</footer>
			</div>
		);
	}
}

function mapStateToProps(state, ownProps) {
	return Object.assign(
		{},
		{
			translations: state.translations,
		},
		ownProps
	);
}

const CognitoSignInConnected = connect(mapStateToProps)(CognitoSignIn);
export default CognitoSignInConnected;
