import React, { Component } from 'react';
import PropTypes from 'prop-types';

export default class IconSunCloud extends Component {
	static displayName = 'IconCode';

	getLarge() {
		return (
			<svg
				version="1.1"
				xmlns="http://www.w3.org/2000/svg"
				width="32"
				height="32"
				viewBox="0 0 32 32">
				<path d="M23.648 16.96c0-0.128 0-0.256 0-0.384 0-3.296-2.688-5.952-5.952-5.952-0.512 0-1.024 0.064-1.536 0.192-0.704-1.216-1.984-1.984-3.456-1.984-2.208 0-4 1.792-4 4 0 0.48 0.032 0.896 0.192 1.312-1.44 0.992-2.4 2.624-2.4 4.512 0 0 0 0 0 0s0 0 0 0.032c0 3.008 2.432 5.44 5.44 5.44h9.44c2.176 0 3.968-1.792 4-3.904 0-1.312-0.672-2.528-1.728-3.264zM10.496 12.832c0-1.216 0.992-2.208 2.208-2.208 0.704 0 1.376 0.352 1.792 0.928-0.704 0.448-1.28 1.024-1.76 1.76-0.256-0.064-0.512-0.096-0.8-0.096-0.48 0-0.96 0.064-1.376 0.192-0.064-0.16-0.064-0.32-0.064-0.576zM21.408 22.304h-9.472c-1.984 0-3.552-1.536-3.648-3.488 0-0.032 0.032-0.096 0.032-0.128 0-2.016 1.632-3.648 3.648-3.648 0.032 0 0.064 0 0.064 0 0.064 0 0.128 0 0.192 0 0 0 0 0 0 0 0.64 0.096 1.28 0.48 1.728 0.768 0.416 0.256 0.96 0.16 1.248-0.256 0.256-0.416 0.16-0.96-0.256-1.248-0.16-0.096-0.32-0.224-0.512-0.32 0.768-0.992 1.952-1.6 3.264-1.6 2.272 0 4.128 1.856 4.128 4.128 0 0.224-0.032 0.448-0.064 0.704-0.064 0.384 0.128 0.8 0.512 0.96 0.768 0.352 1.248 1.12 1.248 1.952 0.032 1.216-0.928 2.176-2.112 2.176z" />
				<path d="M12.704 7.744c0.48 0 0.896-0.384 0.896-0.896v-0.928c0-0.48-0.416-0.896-0.896-0.896s-0.896 0.384-0.896 0.896v0.928c0 0.48 0.384 0.896 0.896 0.896z" />
				<path d="M7.84 9.248c0.192 0.16 0.416 0.256 0.64 0.256s0.448-0.096 0.64-0.256c0.352-0.352 0.352-0.928 0-1.28l-0.64-0.64c-0.352-0.352-0.928-0.352-1.28 0s-0.352 0.928 0 1.28l0.64 0.64z" />
				<path d="M7.616 12.832c0-0.48-0.384-0.896-0.896-0.896h-0.928c-0.48 0-0.896 0.416-0.896 0.896s0.384 0.896 0.896 0.896h0.928c0.48 0 0.896-0.416 0.896-0.896z" />
				<path d="M16.928 9.504c0.224 0 0.448-0.096 0.64-0.256l0.64-0.64c0.352-0.352 0.352-0.928 0-1.28s-0.928-0.352-1.28 0l-0.64 0.64c-0.352 0.352-0.352 0.928 0 1.28 0.192 0.16 0.416 0.256 0.64 0.256z" />
			</svg>
		);
	}

	getSmall() {
		return (
			<svg
				version="1.1"
				xmlns="http://www.w3.org/2000/svg"
				width="32"
				height="32"
				viewBox="0 0 32 32">
				<path d="M23.648 16.96c0-0.128 0-0.256 0-0.384 0-3.296-2.688-5.952-5.952-5.952-0.512 0-1.024 0.064-1.536 0.192-0.704-1.216-1.984-1.984-3.456-1.984-2.208 0-4 1.792-4 4 0 0.48 0.032 0.896 0.192 1.312-1.44 0.992-2.4 2.624-2.4 4.512 0 0 0 0 0 0s0 0 0 0.032c0 3.008 2.432 5.44 5.44 5.44h9.44c2.176 0 3.968-1.792 4-3.904 0-1.312-0.672-2.528-1.728-3.264zM10.496 12.832c0-1.216 0.992-2.208 2.208-2.208 0.704 0 1.376 0.352 1.792 0.928-0.704 0.448-1.28 1.024-1.76 1.76-0.256-0.064-0.512-0.096-0.8-0.096-0.48 0-0.96 0.064-1.376 0.192-0.064-0.16-0.064-0.32-0.064-0.576zM21.408 22.304h-9.472c-1.984 0-3.552-1.536-3.648-3.488 0-0.032 0.032-0.096 0.032-0.128 0-2.016 1.632-3.648 3.648-3.648 0.032 0 0.064 0 0.064 0 0.064 0 0.128 0 0.192 0 0 0 0 0 0 0 0.64 0.096 1.28 0.48 1.728 0.768 0.416 0.256 0.96 0.16 1.248-0.256 0.256-0.416 0.16-0.96-0.256-1.248-0.16-0.096-0.32-0.224-0.512-0.32 0.768-0.992 1.952-1.6 3.264-1.6 2.272 0 4.128 1.856 4.128 4.128 0 0.224-0.032 0.448-0.064 0.704-0.064 0.384 0.128 0.8 0.512 0.96 0.768 0.352 1.248 1.12 1.248 1.952 0.032 1.216-0.928 2.176-2.112 2.176z" />
				<path d="M12.704 7.744c0.48 0 0.896-0.384 0.896-0.896v-0.928c0-0.48-0.416-0.896-0.896-0.896s-0.896 0.384-0.896 0.896v0.928c0 0.48 0.384 0.896 0.896 0.896z" />
				<path d="M7.84 9.248c0.192 0.16 0.416 0.256 0.64 0.256s0.448-0.096 0.64-0.256c0.352-0.352 0.352-0.928 0-1.28l-0.64-0.64c-0.352-0.352-0.928-0.352-1.28 0s-0.352 0.928 0 1.28l0.64 0.64z" />
				<path d="M7.616 12.832c0-0.48-0.384-0.896-0.896-0.896h-0.928c-0.48 0-0.896 0.416-0.896 0.896s0.384 0.896 0.896 0.896h0.928c0.48 0 0.896-0.416 0.896-0.896z" />
				<path d="M16.928 9.504c0.224 0 0.448-0.096 0.64-0.256l0.64-0.64c0.352-0.352 0.352-0.928 0-1.28s-0.928-0.352-1.28 0l-0.64 0.64c-0.352 0.352-0.352 0.928 0 1.28 0.192 0.16 0.416 0.256 0.64 0.256z" />
			</svg>
		);
	}

	getIcon() {
		let svg;
		svg = this.props.size === 'large' ? this.getLarge() : svg;
		svg = this.props.size === 'small' ? this.getSmall() : svg;
		return svg;
	}

	render() {
		return this.getIcon();
	}
}

IconSunCloud.propTypes = {
	size: PropTypes.oneOf(['small', 'large']).isRequired,
};
