import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useDispatch } from 'react-redux';
import { OffersAccordion, AddressChange } from './components/offers-accordion';

import api from '../../api';

import { action as collapsibleAction } from '../../reducers/ui/participation';

import {
	Stack,
	Typography,
	Box,
	Divider,
	Skeleton,
	Button,
	Snackbar,
	Alert,
} from '@mui/material';
import MuiAccordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import dateFormatter from '../../utils/date-formatter';
import { DoNotDisturb } from '@mui/icons-material';

function LineItem({ label, value, show = true }) {
	if (!show) {
		return <></>;
	}
	return (
		<>
			<Stack
				direction="row"
				justifyContent="space-between"
				alignItems="center"
				spacing={2}
				height={50}>
				<span>{label}</span>
				<span>{value}</span>
			</Stack>
			<Divider />
		</>
	);
}

function MyOrders({ translations, expand }) {
	const [loading, setLoading] = useState(true);
	const [paylinkLoading, setPaylinkLoading] = useState(false);
	const [data, setData] = useState([]);

	const [open, setOpen] = useState(false);
	const [severity, setSeverity] = useState('success');
	const [message, setMessage] = useState(translations.orderCancelled);

	const paymentEnabled = true;

	const handleClose = (_, reason) => {
		if (reason === 'clickaway') {
			return;
		}

		setOpen(false);
	};

	const dispatch = useDispatch();

	const toggleCollapsible = (reference) =>
		dispatch(collapsibleAction.toggle(reference));

	const cancelOrder = async (order, event) => {
		event.stopPropagation();
		try {
			await api.sustainable.sustainableOffers.orders.cancel(
				order.deal_identifier
			);
			setMessage(translations.orderCancelled);
			setSeverity('success');
			fetchData();
		} catch (err) {
			setSeverity('error');
			setMessage('Something went wrong');
			console.log(err);
		} finally {
			setOpen(true);
		}
	};

	const paylink = async (order, event) => {
		event.stopPropagation();
		setPaylinkLoading(true);
		try {
			const result = await api.sustainable.sustainableOffers.orders.paylink(
				order.deal_identifier
			);
			window.open(result, '_blank');
		} catch (err) {
			setSeverity('error');
			setMessage('Something went wrong');
			setOpen(true);
		} finally {
			setPaylinkLoading(false);
		}
	};

	const fetchData = async () => {
		setLoading(true);

		const response = await api.sustainable.sustainableOffers.orders.get();
		setData(response);
		setLoading(false);
	};

	useEffect(() => {
		async function fetchMyOrders() {
			await fetchData();
		}
		fetchMyOrders();
	}, []);

	return (
		<div className="w-full px-4 pt-16">
			<Snackbar
				open={open}
				autoHideDuration={5000}
				onClose={handleClose}
				anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
				<Alert onClose={handleClose} severity={severity} sx={{ width: '100%' }}>
					{message}
				</Alert>
			</Snackbar>
			<Stack
				direction="row"
				justifyContent="space-between"
				alignItems="center"
				spacing={2}
				height={50}>
				<Typography variant="h4">{translations.menu.myOrders}</Typography>
			</Stack>
			{loading && (
				<>
					<Skeleton height={52} />
					<Skeleton height={52} />
					<Skeleton height={52} />
				</>
			)}

			{!loading && data.length === 0 && (
				<Stack
					direction="column"
					justifyContent="space-between"
					alignItems="center"
					spacing={2}
					height={50}>
					<DoNotDisturb />
					<Typography variant="h4">{translations.noMyOrders}</Typography>
				</Stack>
			)}

			{!loading &&
				data.length > 0 &&
				data.map((order) => (
					<MuiAccordion key={order.deal_reference}>
						<AccordionSummary
							expandIcon={
								<ExpandMoreIcon
									sx={{
										pointerEvents: 'auto',
									}}
								/>
							}
							aria-controls="panel1a-content"
							sx={{
								pointerEvents: 'none',
							}}
							id="panel1a-header">
							{(order.status === 'CREATED' ||
								order.status === 'AWAITING_PAYMENT' ||
								order.status === 'BOUGHT') && (
								<Button
									variant="outlined"
									color="error"
									size="small"
									sx={{
										pointerEvents: 'auto',
									}}
									onClick={(event) => cancelOrder(order, event)}>
									{translations.cancelOrder}
								</Button>
							)}
							<Divider
								orientation="vertical"
								flexItem
								sx={{ marginLeft: 1, marginRight: 1 }}
							/>
							{paymentEnabled
								? order.status === 'AWAITING_PAYMENT' && (
										<>
											<Button
												variant="outlined"
												color="success"
												size="small"
												loading={paylinkLoading}
												sx={{
													pointerEvents: 'auto',
												}}
												onClick={(event) => paylink(order, event)}>
												{translations.paylink}
											</Button>
											<Divider
												orientation="vertical"
												flexItem
												sx={{ marginLeft: 1, marginRight: 1 }}
											/>
										</>
								  )
								: null}
							<Typography sx={{ pointerEvents: 'auto' }} variant="h5">
								{order.private_deal && AddressChange()}
								{`${order.deal_reference}`}
							</Typography>
						</AccordionSummary>
						<Box mb={2} p={2}>
							{order.status === 'EXPIRED' && (
								<Alert sx={{ width: '100%' }} severity="warning">
									{translations.expiredOrderWarning}
								</Alert>
							)}
							{!order.private_deal && (
								<LineItem
									show={!order.private_order}
									label={`${translations.totalCost}`}
									value={`€ ${order.amount}`}
								/>
							)}
							<LineItem
								label={translations.transfer_date}
								value={dateFormatter(order.transfer_date)}
							/>
							{order.expiration_date && (
								<LineItem
									label={translations.expirationDate}
									value={dateFormatter(order.expiration_date)}
								/>
							)}
							<LineItem
								label={translations.numberOfShares}
								value={order.shares}
							/>
							<LineItem
								label={translations.status.label}
								value={translations.status[order.status]}
							/>
						</Box>
						<AccordionDetails>
							<Box sx={{ borderLeft: 2, borderColor: 'primary.main' }}>
								<Typography variant="h6" sx={{ pl: 1 }}>
									{translations.menu.myOrders}:
								</Typography>
								<Divider />
								{order.offers.map((offer) => (
									<OffersAccordion
										myOffer
										translations={translations}
										expand={expand.includes(offer.reference)}
										toggle={toggleCollapsible}
										key={offer.reference}
										offer={offer}
									/>
								))}
							</Box>
						</AccordionDetails>
					</MuiAccordion>
				))}
		</div>
	);
}

function mapStateToProps(state) {
	return {
		translations: Object.assign(
			state.translations.marketPlace,
			state.translations.general
		),
		isMobile: state.ui.mobileView,
		expand: state.ui.participation.collapsibles,
	};
}

export default connect(mapStateToProps)(MyOrders);
