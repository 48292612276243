import React from 'react';
import Panel from '../components/panel';
import RequestPeriodicalPayment from '../components/request-periodical-payment';
import CurrencyStepper from '../components/currency-stepper';
import SliderLabeled from '../components/slider-labeled';
import closeableOverlay from '../components/closeable-overlay';
import { connect } from 'react-redux';

import api from '../api';

import { action as requestPeriodicalPaymentAction } from '../reducers/ui/overlays/request-periodical-payment';
import { action as periodicalPaymentsAction } from '../reducers/data/periodical-payments';
import { action as notificationsAction } from '../reducers/notifications';
import InputTermAmount from '../components/input/input-termamount';
import { selector } from '../reducers/data/periodical-payments';
import { withRouter } from 'react-router-dom';

class PeriodicalPayment extends React.Component {
	componentWillMount() {
		this.props.dispatch(requestPeriodicalPaymentAction.change(null));
	}

	onChange = (value) => {
		this.props.dispatch(requestPeriodicalPaymentAction.change(value));
	};

	periodicalAmountInputOnBlur = (value) => {
		this.props.dispatch(requestPeriodicalPaymentAction.blur(value));
	};

	cancel = () => {
		this.props.onClose();
	};

	savePeriodicalAmount = () => {
		const { dispatch, periodicalPayment, translations } = this.props;

		this.props.onClose();

		const periodicalPut = api.periodicalPayments
			.put(periodicalPayment.agreement_identifier_with_sequence, {
				request_periodical_payment_amount: this.getEnteredPeriodicalAmount(),
			})
			.catch((err) => {
				notificationsAction.add(dispatch, translations.errorMessage);
			});

		periodicalPut
			.then(() => api.periodicalPayments.get())
			.then((data) => dispatch(periodicalPaymentsAction.data(data)))
			.catch(console.error);
	};

	getEnteredPeriodicalAmount() {
		return this.props.ui.overlays.requestPeriodicalPayment;
	}

	getSavedPeriodicalAmount() {
		const { periodicalPayment } = this.props;
		if (!periodicalPayment.current_amount) periodicalPayment.current_amount = 0;
		return periodicalPayment.current_amount;
	}

	getMostRecentPeriodicalAmount() {
		return this.getEnteredPeriodicalAmount() !== null
			? this.getEnteredPeriodicalAmount()
			: this.getSavedPeriodicalAmount();
	}

	hasChanged() {
		const { periodicalPayment } = this.props;
		const periodicalAmount = this.getEnteredPeriodicalAmount();
		return (
			periodicalAmount !== null &&
			periodicalAmount !== this.getSavedPeriodicalAmount() &&
			periodicalAmount !== this.getSavedPeriodicalAmount().toFixed(2) &&
			periodicalAmount >= periodicalPayment.min_amount &&
			periodicalAmount <= periodicalPayment.max_amount
		);
	}

	requestedPeriodicalAmountStepper = () => {
		const { periodicalPayment } = this.props;
		return (
			<CurrencyStepper
				amount={Number(this.getMostRecentPeriodicalAmount())}
				min={periodicalPayment.min_amount}
				max={periodicalPayment.max_amount}
				onChange={this.periodicalAmountInputOnBlur}
			/>
		);
	};

	slider = () => {
		const { periodicalPayment, translations } = this.props;
		return (
			<SliderLabeled
				min={periodicalPayment.min_amount}
				max={periodicalPayment.max_amount}
				markers={[
					{
						value: periodicalPayment.proposed_amount,
						label: translations.proposed,
					},
				]}
				value={this.getMostRecentPeriodicalAmount()}
				onChange={this.periodicalAmountInputOnBlur}
			/>
		);
	};

	inputFieldNewPeriodicalAmount = () => {
		const { periodicalPayment, translations } = this.props;
		return (
			<InputTermAmount
				onChange={(e) => this.onChange(e.target.value)}
				newAmount={this.getMostRecentPeriodicalAmount()}
				minAmount={periodicalPayment.min_amount}
				maxAmount={periodicalPayment.max_amount}
				termAmountName={translations.requested}
				stepper={this.requestedPeriodicalAmountStepper()}
			/>
		);
	};

	render() {
		const { periodicalPayment, translations } = this.props;
		return (
			<Panel
				name="periodical-payments"
				title={translations.title}
				leftLinkText={translations.cancel}
				leftButtonHandler={this.cancel}
				rightLinkText={translations.save}
				rightButtonHandler={
					this.hasChanged() ? this.savePeriodicalAmount : null
				}>
				{periodicalPayment && (
					<RequestPeriodicalPayment
						requestedAmount={this.inputFieldNewPeriodicalAmount()}
						slider={this.slider()}
					/>
				)}
			</Panel>
		);
	}
}

const mapStateToProps = (state, ownProps) => ({
	...ownProps,
	periodicalPayment: selector.getPeriodicalPayment(state)(
		ownProps.selectedPeriodicalPayment
	),
	ui: state.ui,
	translations: Object.assign(
		state.translations.overlay.periodicalPayment,
		state.translations.general
	),
});

export default withRouter(
	closeableOverlay(connect(mapStateToProps)(PeriodicalPayment))
);
