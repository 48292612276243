import React from 'react';
import Icon from '../icon';
import FileDownload from '../icons/file-download';
import PropTypes from 'prop-types';
import get from './../../api/methods/get';
import urlJoin from '../../utils/url-join';
import { saveAs } from 'file-saver';
import './download-link.css';

const handleClick = (event, link) => {
	event.preventDefault();
	get(link, null, true, { responseType: 'blob' })
		.then((response) => {
			// header example: attachment; filename="factuur-00000002.pdf"
			const header = response.headers['content-disposition'];
			const fileName = header.split('filename=')[1].replace(/"/g, '');
			saveAs(
				new Blob([response.data], {
					type: response.headers['content-type'],
				}),
				fileName
			);
		})
		.catch((error) => {
			console.log(error);
		});
};

const DownloadLink = ({ link, className, children }) => (
	<a
		href={urlJoin(link, process.env.REACT_APP_API)}
		rel="noopener noreferrer"
		className={`download-link ${className ? className : ''}`.trim()}
		target="_blank"
		onClick={(event) => handleClick(event, link)}
		onContextMenu={(event) => handleClick(event, link)}
		download>
		<Icon icon={FileDownload} size="inline" />{' '}
		{children ? <span className="download-link__label">{children}</span> : null}
	</a>
);

export default DownloadLink;

DownloadLink.propTypes = {
	link: PropTypes.string.isRequired,
};
