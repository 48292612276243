import React from 'react';
import PropTypes from 'prop-types';
import Link from '../link';
import Icon from '../icon';
import ArrowRight from '../icons/keyboard-arrow-right';
import closeableByKeyboard from '../closeable-by-keyboard';

import './main-navigation.css';

const openClassCssSelector = '.main-navigation__item--sub.open';

const closeSubMenus = (event) => {
	[].forEach.call(document.querySelectorAll(openClassCssSelector), (node) =>
		node.classList.remove('open')
	);
};

const openSubMenu = (event) => {
	event.currentTarget.classList.add('open');
};

const closeSubMenu = (event) => {
	event.currentTarget.classList.remove('open');
};

const Menu = ({ children, isShown }) => (
	<ul className="main-navigation__menu">
		{children.map(({ id, children, title, uri }, index) => {
			return children ? (
				<li
					key={index}
					className="main-navigation__item main-navigation__item--sub"
					onMouseEnter={openSubMenu}
					onTouchStart={openSubMenu}
					onMouseLeave={closeSubMenu}>
					<span className="main-navigation__label">
						{title}
						<i className="material-icons">arrow_drop_down</i>
					</span>
					<Menu children={children} isShown={isShown} />
				</li>
			) : (
				<li
					key={index}
					className="main-navigation__item"
					onClick={closeSubMenus}>
					<Link
						to={uri}
						tabIndex={isShown ? 0 : -1}
						className="main-navigation__link primary-text-color">
						<span className="main-navigation__label">{title}</span>
						<Icon
							icon={ArrowRight}
							size={'small'}
							className="main-navigation__icon"
						/>
					</Link>
				</li>
			);
		})}
	</ul>
);

/**
 * MainNavigation displays the main navigation links for the site
 */
class MainNavigation extends React.Component {
	constructor() {
		super();
		this.drawer = null;
	}

	onClickNav = (e) => {
		if (this.drawer !== e.target && !this.drawer.contains(e.target)) {
			this.props.onClose();
		}
	};

	render() {
		const { items, className, isShown = false } = this.props;

		return (
			<div
				onClick={this.onClickNav}
				className={`main-navigation ${className || ''} ${
					isShown ? 'main-navigation--show' : ''
				}`.trim()}>
				<nav
					className="main-navigation__drawer"
					ref={(el) => (this.drawer = el)}>
					<Menu children={items} isShown={isShown} />
				</nav>
			</div>
		);
	}
}

export default closeableByKeyboard(MainNavigation);

const menuShape = {
	title: PropTypes.string.isRequired,
	uri: PropTypes.string,
	id: PropTypes.string,
};

menuShape.children = PropTypes.arrayOf(PropTypes.shape(menuShape));

MainNavigation.propTypes = {
	items: PropTypes.arrayOf(PropTypes.shape(menuShape)).isRequired,
	className: PropTypes.string,
	isShown: PropTypes.bool,
};
