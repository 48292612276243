import React, { Fragment } from 'react';
import Icon from '../../icon';

import Clouds from '../../icons/clouds';
import CloudRain from '../../icons/cloud-rain';
import Fog from '../../icons/fog';
import Hail from '../../icons/hail';
import Lightning from '../../icons/lightning';
import Moon from '../../icons/moon';
import SnowFlake from '../../icons/snowflake';
import SunCloud from '../../icons/sun-cloud';
import Sun from '../../icons/sun';
import Thermometer from '../../icons/thermometer';
import Wind from '../../icons/wind';
import Cached from '../../icons/cached';
import SyncProblem from '../../icons/sync-problem';
import { debounce } from 'lodash';
import './weather-box.css';

const weatherStatusIcons = {
	onbewolkt: Sun,
	zon: Sun,
	Zonnig: Sun,
	'licht bewolkt': SunCloud,
	'half bewolkt': SunCloud,
	bewolkt: Clouds,
	'geheel bewolkt': Clouds,
	'zwaar bewolkt': Clouds,
	'droog na regen': Clouds,
	regen: CloudRain,
	'lichte regen': CloudRain,
	motregen: CloudRain,
	'dichte motregen': CloudRain,
	'motregen en regen': CloudRain,
	'lichte motregen en regen': CloudRain,
	'af en toe lichte regen': CloudRain,
	onweer: Lightning,
	bliksem: Lightning,
	maan: Moon,
	sneeuw: SnowFlake,
	mist: Fog,
	hagel: Hail,
};

const getStatusIcon = (status) => weatherStatusIcons[status] || SunCloud;

const refreshData = (e, onclick) => {
	let element = e.target;
	element.classList.add('rotate');
	onclick().then(() => element.classList.remove('rotate'));
};

const refreshDataDebounced = debounce(refreshData, 500);

const refresh = (onclick) => (e) => {
	e.persist();
	refreshDataDebounced(e, onclick);
};

const WeatherBox = ({
	overcast: status,
	temperature,
	wind_strength: wind,
	translations,
	onclick,
	error,
}) => {
	return (
		<div className="weather-box">
			<span className="refresh" onClick={refresh(onclick)}>
				<Icon icon={Cached} size="small" />
			</span>

			{!error ? (
				<Fragment>
					<div className="status">
						<span className="title">
							{translations.participation.weatherStatus}
						</span>
						<Icon icon={getStatusIcon(status)} size="large" />
						<span className="value">{translations[status]}</span>
					</div>
					<div className="temperature">
						<span className="title">
							{translations.participation.temperature}
						</span>
						<Icon icon={Thermometer} size="large" />
						<span className="value">{temperature} &#8451;</span>
					</div>
					<div className="wind">
						<span className="title">{translations.participation.wind}</span>
						<Icon icon={Wind} size="large" />
						<span className="value">{wind} m/s</span>
					</div>
				</Fragment>
			) : (
				<div className="no-weather-data">
					<Icon icon={SyncProblem} size="large" />
					<h4>{translations.participation.noWeatherData}</h4>
				</div>
			)}
		</div>
	);
};

export default WeatherBox;
