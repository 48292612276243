import React, { Component } from 'react';
import PropTypes from 'prop-types';

export default class IconLightning extends Component {
	static displayName = 'IconCode';

	getLarge() {
		return (
			<svg
				version="1.1"
				xmlns="http://www.w3.org/2000/svg"
				width="32"
				height="32"
				viewBox="0 0 32 32">
				<path d="M23.744 15.648c0-0.128 0-0.256 0-0.384 0-3.296-2.688-5.952-5.952-5.952-1.984 0-3.808 0.96-4.928 2.624-0.256-0.064-0.544-0.096-0.832-0.096-3.008 0-5.44 2.432-5.44 5.44 0 0 0 0.032 0 0.032 0 0.032 0 0.032 0 0.064 0 3.008 2.432 5.44 5.44 5.44 0.48 0 0.896-0.416 0.896-0.896s-0.384-0.896-0.896-0.896c-1.984 0-3.584-1.568-3.648-3.552 0-0.064 0.032-0.096 0.032-0.16 0-0.608 0.16-1.184 0.416-1.696 0.608-1.12 1.824-1.888 3.2-1.888 0.256 0 0.576 0.032 0.832 0.096 0.448 0.16 0.896 0.416 1.216 0.64 0.416 0.256 0.96 0.16 1.248-0.256 0.256-0.416 0.16-0.96-0.256-1.248-0.16-0.096-0.32-0.224-0.512-0.32 0.768-0.96 1.952-1.568 3.232-1.568 2.272 0 4.128 1.856 4.128 4.128 0 0.224-0.032 0.448-0.064 0.704-0.064 0.384 0.128 0.8 0.512 0.96 0.768 0.352 1.248 1.12 1.248 1.952 0 1.184-0.96 2.144-2.144 2.144-0.48 0-0.896 0.416-0.896 0.896s0.384 0.896 0.896 0.896c2.176 0 3.968-1.76 4-3.84 0-1.312-0.672-2.528-1.728-3.264z" />
				<path d="M17.856 21.12h-0.768l1.504-2.176c0.288-0.416 0.192-0.96-0.224-1.248s-0.96-0.192-1.248 0.224l-2.496 3.584c-0.192 0.288-0.224 0.64-0.064 0.928s0.48 0.48 0.8 0.48h0.864l-1.632 2.56c-0.256 0.416-0.128 0.992 0.288 1.248 0.16 0.096 0.32 0.128 0.48 0.128 0.288 0 0.576-0.16 0.768-0.384l2.496-3.936c0.192-0.288 0.192-0.64 0.032-0.928s-0.48-0.48-0.8-0.48z" />
			</svg>
		);
	}

	getSmall() {
		return (
			<svg
				version="1.1"
				xmlns="http://www.w3.org/2000/svg"
				width="32"
				height="32"
				viewBox="0 0 32 32">
				<path d="M23.744 15.648c0-0.128 0-0.256 0-0.384 0-3.296-2.688-5.952-5.952-5.952-1.984 0-3.808 0.96-4.928 2.624-0.256-0.064-0.544-0.096-0.832-0.096-3.008 0-5.44 2.432-5.44 5.44 0 0 0 0.032 0 0.032 0 0.032 0 0.032 0 0.064 0 3.008 2.432 5.44 5.44 5.44 0.48 0 0.896-0.416 0.896-0.896s-0.384-0.896-0.896-0.896c-1.984 0-3.584-1.568-3.648-3.552 0-0.064 0.032-0.096 0.032-0.16 0-0.608 0.16-1.184 0.416-1.696 0.608-1.12 1.824-1.888 3.2-1.888 0.256 0 0.576 0.032 0.832 0.096 0.448 0.16 0.896 0.416 1.216 0.64 0.416 0.256 0.96 0.16 1.248-0.256 0.256-0.416 0.16-0.96-0.256-1.248-0.16-0.096-0.32-0.224-0.512-0.32 0.768-0.96 1.952-1.568 3.232-1.568 2.272 0 4.128 1.856 4.128 4.128 0 0.224-0.032 0.448-0.064 0.704-0.064 0.384 0.128 0.8 0.512 0.96 0.768 0.352 1.248 1.12 1.248 1.952 0 1.184-0.96 2.144-2.144 2.144-0.48 0-0.896 0.416-0.896 0.896s0.384 0.896 0.896 0.896c2.176 0 3.968-1.76 4-3.84 0-1.312-0.672-2.528-1.728-3.264z" />
				<path d="M17.856 21.12h-0.768l1.504-2.176c0.288-0.416 0.192-0.96-0.224-1.248s-0.96-0.192-1.248 0.224l-2.496 3.584c-0.192 0.288-0.224 0.64-0.064 0.928s0.48 0.48 0.8 0.48h0.864l-1.632 2.56c-0.256 0.416-0.128 0.992 0.288 1.248 0.16 0.096 0.32 0.128 0.48 0.128 0.288 0 0.576-0.16 0.768-0.384l2.496-3.936c0.192-0.288 0.192-0.64 0.032-0.928s-0.48-0.48-0.8-0.48z" />
			</svg>
		);
	}

	getIcon() {
		let svg;
		svg = this.props.size === 'large' ? this.getLarge() : svg;
		svg = this.props.size === 'small' ? this.getSmall() : svg;
		return svg;
	}

	render() {
		return this.getIcon();
	}
}

IconLightning.propTypes = {
	size: PropTypes.oneOf(['small', 'large']).isRequired,
};
