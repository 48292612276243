import get from './methods/get';

export default {
	get: (headers) => get('connections', headers),
	emptyResponse: [
		{
			address: {
				address_type: '',
				street: '',
				house_number: '',
				house_number_extension: '',
				zip_code: '',
				city: '',
			},
			commodity: '',
			ean_code: '',
			ean_group_number: '',
			location_id: '',
			meter_type: '',
			capacity: '',
		},
	],
};
