import React from 'react';
import PropTypes from 'prop-types';
import padStart from 'lodash/padStart';

import withTranslationsGeneral from '../../components/with-translations-general';
import dateIsoFormat from '../../utils/date-iso-format';

import './date-time.css';

export const FORMAT_WRITTEN = 'format_written';
export const FORMAT_MMMY = 'format_mmmy';
export const FORMAT_DMY = 'format_dmy';
export const FORMAT_MY = 'format_my';
export const FORMAT_Y = 'format_y';
export const FORMAT_DM = 'format_dm';
export const FORMAT_DM_UTC = 'format_dm_utc';
export const FORMAT_NO_DATE = 'format_no_date';
export const FROMAT_M = 'format_m';

function validDate(dateObj) {
	return !isNaN(+dateObj);
}

const formatHandlers = {
	[FORMAT_Y](dateObj, locale) {
		return dateObj.getFullYear();
	},

	[FORMAT_DMY](dateObj, locale, months) {
		const day = dateObj.getUTCDate();
		const month = months[dateObj.getUTCMonth()];
		const year = dateObj.getUTCFullYear();
		return `${day} ${month} ${year}`;
	},
	[FORMAT_MY](dateObj, locale) {
		const month = dateObj.getUTCMonth() + 1;
		const year = dateObj.getUTCFullYear();
		return `${padStart(month, 2, '0')}-${year}`;
	},
	[FROMAT_M](dateObj, locale, months) {
		const month = months[dateObj.getUTCMonth()];
		return `${month}`;
	},
	[FORMAT_WRITTEN](dateObj, locale, months) {
		const day = dateObj.getUTCDate();
		const month = months[dateObj.getUTCMonth()];
		const year = dateObj.getUTCFullYear();
		return `${day} ${month} ${year}`;
	},
	[FORMAT_MMMY](dateObj, locale, months) {
		const month = months[dateObj.getUTCMonth()];
		const year = dateObj.getUTCFullYear();
		return `${month} ${year}`;
	},
	[FORMAT_DM](dateObj, locale, months) {
		const month = months[dateObj.getMonth()];
		const day = dateObj.getDay();
		return `${day} ${month}`;
	},
	[FORMAT_DM_UTC](dateObj, locale, months) {
		const month = months[dateObj.getUTCMonth()];
		const day = dateObj.getUTCDate();
		return `${day} ${month}`;
	},
	[FORMAT_NO_DATE]() {
		return ``;
	},
};

/**
 * Renders a date based on a date object
 */
const DateTime = (props) => {
	const {
		date,
		format = FORMAT_DMY,
		locale = 'nl-NL',
		className,
		shortMonths = false,
	} = props;
	const dateObj = new Date(dateIsoFormat(date));

	return (
		<span className={`date-time ${className || ''}`.trim()}>
			{validDate(dateObj)
				? formatHandlers[format](
						dateObj,
						locale,
						shortMonths
							? props.translationsGeneral.shortMonths
							: props.translationsGeneral.months
				  )
				: ''}
		</span>
	);
};

export default withTranslationsGeneral(DateTime);

DateTime.propTypes = {
	/** Date string formatted: yyyy-MM-dd */
	date: PropTypes.string,
};
