import { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { OffersAccordion } from './components/offers-accordion';

import api from '../../api';

import { action as collapsibleAction } from '../../reducers/ui/participation';

import { Skeleton, Stack, Typography } from '@mui/material';
import { DoNotDisturb } from '@mui/icons-material';

function MyOffers({ translations, expand }) {
	const [loading, setLoading] = useState(true);
	const [data, setData] = useState([]);

	const dispatch = useDispatch();

	const toggleCollapsible = (reference) =>
		dispatch(collapsibleAction.toggle(reference));

	useEffect(() => {
		async function fetchMyOrders() {
			await fetchData();
		}
		fetchMyOrders();
	}, []);

	const fetchData = async () => {
		setLoading(true);

		const response = await api.sustainable.sustainableOffers.get();
		setData(response);
		setLoading(false);
	};

	const retractOffer = async (offer) => {
		try {
			await api.sustainable.sustainableOffers.retract(offer);
			await fetchData();
		} catch (err) {
			console.log(err);
			await fetchData();
		}
	};

	return (
		<div className="w-full px-4 pt-16">
			<Stack
				direction="row"
				justifyContent="space-between"
				alignItems="center"
				spacing={2}
				height={50}>
				<Typography variant="h4">{translations.menu.myOffers}</Typography>
			</Stack>
			{loading && (
				<>
					<Skeleton height={52} />
					<Skeleton height={52} />
					<Skeleton height={52} />
				</>
			)}

			{!loading && data.length === 0 && (
				<Stack
					direction="column"
					justifyContent="space-between"
					alignItems="center"
					spacing={2}
					height={50}>
					<DoNotDisturb />
					<Typography variant="h4">{translations.noMyOffers}</Typography>
				</Stack>
			)}

			{!loading &&
				data.length > 0 &&
				data.map((offer) => (
					<OffersAccordion
						myOffer
						translations={translations}
						expand={expand.includes(offer.reference)}
						toggle={toggleCollapsible}
						key={offer.reference}
						offer={offer}
						retract={retractOffer}
					/>
				))}
		</div>
	);
}

function mapStateToProps(state) {
	return {
		translations: Object.assign(
			state.translations.marketPlace,
			state.translations.general
		),
		isMobile: state.ui.mobileView,
		expand: state.ui.participation.collapsibles,
	};
}

export default connect(mapStateToProps)(MyOffers);
