import React from 'react';

import Icon from '../icon';
import IconEmail from '../icons/email';

import './email.css';

/**
 * This component renders an email address
 */
const Email = ({ children, text, iconSize }) => (
	<div className="email">
		<div>
			<Icon icon={IconEmail} size={iconSize || 'inline'} />
			{text}
		</div>{' '}
		{children}
	</div>
);

export default Email;

Email.propTypes = {};
