import moment from 'moment';
export default (date) =>
	typeof date === 'string' && moment(date, 'DD/MM/YYYY').isValid()
		? moment(date, 'DD/MM/YYYY')
				.format('DD-MM-YYYY')
				.replace(/^(\d{2})-(\d{2})-(\d{4})$/, '$3-$2-$1')
		: typeof date === 'string' && moment(date, 'YYYY-MM-DD').isValid()
		? moment(date, 'YYYY-MM-DD')
				.format('DD-MM-YYYY')
				.replace(/^(\d{2})-(\d{2})-(\d{4})$/, '$3-$2-$1')
		: date;
