import React from 'react';
import DownloadLink from '../../components/download-link';
import urlJoin from '../../utils/url-join';
import PropTypes from 'prop-types';

import './tariff-sheet.css';

const TariffSheet = ({ retrievalId, downloadLinkText }) => (
	<span className="tariff-sheet">
		{retrievalId && (
			<DownloadLink
				className="tariff-sheet__link primary-text-color"
				link={urlJoin(retrievalId, '/tariffsheets')}>
				{downloadLinkText}
			</DownloadLink>
		)}
	</span>
);

export default TariffSheet;

TariffSheet.propTypes = {
	downloadLinkText: PropTypes.string.isRequired,
	retrievalId: PropTypes.string.isRequired,
};
