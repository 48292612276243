import React from 'react';
import Panel from '../components/panel';
import ErrorText from '../components/error-text';
import Input, { Validity } from '../components/input';
import Fieldset from '../components/fieldset';
import closeableOverlay from '../components/closeable-overlay';
import isEmailValid from '../utils/is-email-valid';

import api from '../api';

import { action as emailAddressAction } from '../reducers/ui/overlays/change-email-address';
import { action as relationsEmailAddressAction } from '../reducers/data/relations.email-address';
import { action as relationsAction } from '../reducers/data/relations';
import { action as notificationsAction } from '../reducers/notifications';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

class ChangeEmailAddress extends React.Component {
	componentWillMount() {
		this.props.dispatch(emailAddressAction.change(null));
	}

	saveEmailAddress = () => {
		const { dispatch } = this.props;

		const email = this.getEnteredEmailAddress();

		const emailPut = api.relations.emailAddress
			.put({
				change_email: email,
			})
			.catch((err) => {
				notificationsAction.add(dispatch, 'Error saving email address!');
			});

		const emailGet = emailPut
			.then(() => api.relations.emailAddress.get())
			.then((emailData) =>
				dispatch(relationsEmailAddressAction.data(emailData))
			);

		const relationsGet = emailPut
			.then(() => api.relations.get())
			.then((relationsData) => dispatch(relationsAction.data(relationsData)));

		Promise.all([emailGet, relationsGet]).then(() => {
			this.props.onClose();
		});
	};

	cancel = () => {
		this.props.onClose();
	};

	onChange = (value) => {
		this.props.dispatch(emailAddressAction.change(value));
	};

	onBlur = (e) => {
		const email = this.cleanEmailAddress(e.target.value);
		this.props.dispatch(emailAddressAction.change(email));
	};

	cleanEmailAddress(email) {
		return email ? email.replace(/[\s]/g, '') : '';
	}

	hasChanged() {
		const enteredEmailAddress = this.getEnteredEmailAddress();
		return (
			this.getEnteredEmailAddress() !== null &&
			this.cleanEmailAddress(enteredEmailAddress) !==
				this.getSavedEmailAddress()
		);
	}

	getEnteredEmailAddress() {
		return this.props.ui.overlays.changeEmailAddress;
	}

	getSavedEmailAddress() {
		const { data } = this.props;
		return data.relationsEmailAddress.email || data.relations.email;
	}

	getMostRecentEmailAddress() {
		return this.getEnteredEmailAddress() !== null
			? this.getEnteredEmailAddress()
			: this.getSavedEmailAddress();
	}

	isValid() {
		const cleanedEmailAddress = this.cleanEmailAddress(
			this.getMostRecentEmailAddress()
		);

		// This regex is the same as used on the back-end.
		return isEmailValid(cleanedEmailAddress);
	}

	render() {
		const { translations } = this.props;

		const email = this.getMostRecentEmailAddress();
		const isValid = this.hasChanged() && this.isValid();

		return (
			<Panel
				name="change-email-address"
				title={translations.title}
				leftLinkText={translations.cancel}
				leftButtonHandler={this.cancel}
				rightLinkText={translations.save}
				rightButtonHandler={isValid ? this.saveEmailAddress : null}>
				<Fieldset
					label={translations.new}
					value={email}
					onBlur={this.onBlur}
					onChange={this.onChange}
					type="email"
					className="text-area-placeholder"
					validity={isValid ? Validity.VALID : Validity.UNKOWN}>
					<Input />
				</Fieldset>

				{this.getEnteredEmailAddress() !== null && !this.isValid() && (
					<ErrorText
						className="change-email-address__error"
						align="left"
						size="small">
						{translations.invalid}
					</ErrorText>
				)}
			</Panel>
		);
	}
}

function mapStateToProps(state) {
	return {
		data: state.data,
		ui: state.ui,
		translations: Object.assign(
			{},
			state.translations.overlay.changeEmailAddress,
			state.translations.general
		),
	};
}

export default withRouter(
	connect(mapStateToProps)(closeableOverlay(ChangeEmailAddress))
);
