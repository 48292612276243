import React from 'react';
import PropTypes from 'prop-types';
import ReactTooltip from 'react-tooltip';
import { useEffect, useState } from 'react';
import { Button } from '@mui/material';

import api from '../../api';

import './privacy-info.css';
import getLanguage from '../../utils/get-language';

/**
 * The `ContactInfo` component is used to display contact information
 */
export default function PrivacyInfo(props) {
	const {
		historicalDataConsent = {},
		relations = {},
		privacyConsentGivenButtonLabel,
		privacyConsentNotGivenButtonLabel,
		privacyConsentTooltip,
		privacyConsentDateLabel,
	} = props;

	const [historicalConsent, sethistoricalConsent] = useState(
		historicalDataConsent.consentPresent
	);
	const [historicalConsentDate, sethistoricalConsentDate] = useState(
		historicalDataConsent.consentDateTime
	);
	const submitHistoricalDataConsent = () => {
		api.privacy.sendHistoricalConsent(true);
		sethistoricalConsent(true);
		sethistoricalConsentDate(new Date());
	};

	const privacyDataTranslations = () => {
		const now = new Date();
		const from = formatDate(
			`${now.getFullYear() - 2}-${now.getMonth() + 1}-${now.getDate()}`
		);
		const to = formatDate(
			`${now.getFullYear()}-${now.getMonth() + 1}-${now.getDate()}`
		);

		const texts = {
			nl: `Wanneer u op bovenstaande knop klikt, halen wij uw historische verbruik op van ${from} tot en met ${to}.\n
					Loopt uw huidige contract bij ons nog geen twee jaar? Dan halen we uw verbruik op tot en met de begindatum van uw contract.\n
					U kunt dit bestand iedere dag opnieuw downloaden om uw meest actuele verbruik in te zien. Het ophalen van het bestand kan soms een paar uur duren.`,
			en: `When you click on the button above, we retrieve your historical consumption from ${from} to ${to}.\n
					Is your current contract with us less than two years old? Then we collect your consumption from the start date of your contract.\n
					You can download this file every day to view your most current usage. Retrieving the file can sometimes take a few hours.`,
		};

		const locale = getLanguage();

		return texts[locale];
	};

	useEffect(() => {
		sethistoricalConsent(historicalDataConsent.consentPresent);
		sethistoricalConsentDate(historicalDataConsent.consentDateTime);
	}, [historicalDataConsent]);

	return (
		<section className="privacy-info">
			{relations.debtor_number && (
				<div>
					<div>
						<Button
							data-tip
							data-for="historical-consent-tooltip"
							variant="contained"
							onClick={submitHistoricalDataConsent}>
							{historicalConsent
								? privacyConsentGivenButtonLabel
								: privacyConsentNotGivenButtonLabel}
						</Button>
						<span className="m-l-12">
							{historicalConsent
								? privacyConsentDateLabel + formatDate(historicalConsentDate)
								: ''}
						</span>
						<ReactTooltip
							className="privacy-tooltip-width"
							id="historical-consent-tooltip"
							place="top"
							effect="solid">
							{privacyConsentTooltip}
						</ReactTooltip>
						{historicalConsent && (
							<p className="helper-text-size">{privacyDataTranslations()}</p>
						)}
					</div>
				</div>
			)}
		</section>
	);
}

const formatDate = (date) => {
	return date
		? new Date(date).toLocaleDateString('nl-NL', {
				day: '2-digit',
				month: '2-digit',
				year: 'numeric',
		  })
		: null;
};

PrivacyInfo.propTypes = {
	relations: PropTypes.shape({
		debtor_number: PropTypes.string,
	}).isRequired,
};
