import React from 'react';
import PropTypes from 'prop-types';
import DatePeriod from '../date-period/date-period';
import _ from 'lodash';

import './agreement-title.css';

const AgreementTitle = ({
	agreementIdentifier,
	agreementName,
	agreementPeriod,
	withIcon = true,
	prefix,
}) => (
	<div className="agreement-title">
		{withIcon && <i className="material-icons">description</i>}
		<span className="agreement-title-id">
			{prefix && prefix + ' '}
			{agreementIdentifier}
		</span>
		{agreementName && (
			<span className="agreement-title-name">
				{_.truncate(agreementName, { length: 50 })}
			</span>
		)}
		{agreementPeriod && (
			<span className="agreement-title-period">
				<DatePeriod
					periodStart={agreementPeriod.from}
					periodEnd={agreementPeriod.to}
				/>
			</span>
		)}
	</div>
);

AgreementTitle.propTypes = {
	agreementIdentifier: PropTypes.string.isRequired,
	agreementName: PropTypes.string,
	agreementPeriod: PropTypes.object,
	withIcon: PropTypes.bool,
	prefix: PropTypes.string,
};

export default AgreementTitle;
