import React, { Component } from 'react';
// import Panel from '../../components/panel';
import EnergyInfoBox from '../../components/participation-items/energy-info';
import MijnParticipationBox from '../../components/participation-items/mijn-participation';
import WeatherBox from '../../components/participation-items/weather';
import Row from '../../components/row';
import PageTitle from '../../components/page-title';
import api from '../../api';
import './participation.css';
import Icon from '../../components/icon';
import WbSunny from '../../components/icons/wb-sunny';
import Wind from '../../components/icons/wind';
import Calendar from '../../components/icons/calendar';
import Accordion from '../../components/accordion';
import { connect } from 'react-redux';
// import { isLabelWind } from '../../constants';
import { Card, CardContent, CardHeader, Divider } from '@mui/material';

class Participation extends Component {
	constructor(props) {
		super(props);

		this.state = {
			sustainableData: [],
			weatherData: null,
		};

		api.sustainable
			.get()
			.then((response) => {
				this.setState({ sustainableData: response });
			})
			.catch((err) => {
				console.error(err);
			});

		this.getWeatherData();
	}

	getWeatherData = () => {
		return new Promise((resolve) => {
			api.weather
				.get()
				.then(
					(response) => {
						this.setState(
							response.stations && response.stations.length
								? {
										weatherData: response.stations.filter(
											(item) => item.station === 'De Bilt'
										)[0],
								  }
								: {
										weatherData: {
											error: true,
										},
								  }
						);
						resolve();
					},
					() => {
						this.setState({
							weatherData: {
								error: true,
							},
						});
					}
				)
				.catch((err) => {
					console.log(err);
				});
		});
	};

	render() {
		const { isMobile, translations, labels } = this.props;
		const { sustainableData } = this.state;
		// this.state.sustainableData && this.state.sustainableData[0];
		const { weatherData } = this.state;
		return (
			<div className="view-sustainable">
				<Row>
					<PageTitle>{translations.participation.title}</PageTitle>
				</Row>
				{!isMobile &&
					sustainableData.map((project, i) => (
						<ParticipationDesktopView
							key={i}
							weatherData={weatherData}
							project={project}
							labels={labels}
							weatherApi={this.getWeatherData}
							{...this.props}
						/>
					))}
				{isMobile &&
					sustainableData.map((project, i) => (
						<ParticipationMobileView
							key={i}
							weatherData={weatherData}
							project={project}
							weatherApi={this.getWeatherData}
							labels={labels}
							{...this.props}
						/>
					))}
			</div>
		);
	}
}

const ParticipationMobileView = ({
	weatherData,
	project,
	translations,
	weatherApi,
	labels,
}) => {
	const { production, participations } = project;
	return participations.map((participation, i) => (
		<Accordion key={i}>
			<div
				title={project.project_name}
				icon={project.project_type === 'WIND' ? Wind : WbSunny}
				expanded={true}>
				<EnergyInfoBox
					translations={translations.participation}
					{...project}
					{...production}
				/>
			</div>
			<div
				title={translations.participation.mijnParticipation}
				icon={project.project_type === 'WIND' ? Wind : WbSunny}>
				<MijnParticipationBox
					translations={translations.participation}
					{...project}
					participation={participation}
				/>
			</div>
			<div title={getTitleForWeatherBox(translations)} icon={Calendar}>
				<WeatherBox
					translations={translations}
					{...weatherData}
					onclick={weatherApi}
				/>
			</div>
		</Accordion>
	));
};

const ParticipationDesktopView = ({
	weatherData,
	project,
	translations,
	weatherApi,
	labels,
}) => {
	const { production, participations } = project;
	return participations.map((participation, i) => (
		<div className="widgets" key={i}>
			<Card>
				<CardHeader
					title={project.project_name}
					avatar={
						<Icon
							icon={project.project_type === 'WIND' ? Wind : WbSunny}
							size="small"
						/>
					}></CardHeader>
				<CardContent>
					<Divider />
					<EnergyInfoBox
						translations={translations.participation}
						{...project}
						{...production}
					/>
				</CardContent>
			</Card>
			<Card key={i}>
				<CardHeader
					title={translations.participation.mijnParticipation}
					avatar={
						<Icon
							icon={project.project_type === 'WIND' ? Wind : WbSunny}
							size="small"
						/>
					}
				/>
				<CardContent>
					<Divider />
					<MijnParticipationBox
						translations={translations.participation}
						{...project}
						participation={participation}
					/>
				</CardContent>
			</Card>

			{!i && (
				<Card>
					<CardHeader
						title={getTitleForWeatherBox(translations)}
						avatar={<Icon icon={Calendar} size="small" />}
					/>
					<CardContent>
						<Divider />
						<WeatherBox
							translations={translations}
							{...weatherData}
							onclick={weatherApi}
						/>
					</CardContent>
				</Card>
			)}
		</div>
	));
};

const getTitleForWeatherBox = ({ locale = 'nl-NL' }) => {
	return (
		new Date()
			.toLocaleDateString(locale, {
				weekday: 'long',
			})
			.split('')
			.map((letter, i) => (i === 0 ? letter.toUpperCase() : letter))
			.join('') +
		', ' +
		new Date().toLocaleDateString(locale, {
			day: 'numeric',
			month: 'long',
		})
	);
};

function mapStateToProps(state) {
	return {
		expand: state.ui.participation.collapsibles,
		translations: Object.assign(
			state.translations.sustainable,
			state.translations.general,
			state.translations.weatherStatusMap
		),
		isMobile: state.ui.mobileView,
		labels: state.data.labels,
	};
}

export default connect(mapStateToProps)(Participation);
