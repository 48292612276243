import { Redirect, Route, Switch, withRouter } from 'react-router-dom';

import Account from '../views/account';
import Dashboard from '../views/dashboard';
import Agreements from '../views/agreements';
import Contact from '../views/contact';
import Home from '../views/home';
import IbanComponent from '../views/iban';
import Invoices from '../views/invoices';
import Metermeasuring from '../views/metermeasuring';
import Move from '../views/move';
import Participation from '../views/participation';
import PeriodicalPayments from '../views/periodical-payments';
import Products from '../views/products';
import Usage from '../views/usage';
import AvailableOffers from '../views/marketplace/offers';
import MyOffers from '../views/marketplace/my-offers';
import MyOrders from '../views/marketplace/my-orders';
import Checkout from '../views/marketplace/checkout';

// Independent views
import EmailConfirmation from '../components/email-confirmation';
import ConfirmReactivation from '../components/confirm-reactivation';
import UnderConstruction from '../views/under-construction';
import ThankYou from '../views/thank-you';

// Overlays
import UsageHistoryDetails from '../overlays/usage-history-details';
import ChangeEmailAddress from '../overlays/change-email-address';
import ChangePhoneNumber from '../overlays/change-phonenumber';
import MonthlyAmountInfo from '../overlays/monthly-amount-info';
import PeriodicalPayment from '../overlays/periodical-payment';
import StopDateNotification from '../overlays/stop-date-notification';
import ChangeAccountPassword from '../overlays/change-account-password';
import RedeemOffer from '../overlays/redeem-offer';
import CreateOffer from '../overlays/create-offer';

import { selector } from '../reducers/data/periodical-payments';
import { connect } from 'react-redux';
import Register from '../views/account/register';
import PersonalOffers from '../views/marketplace/personalOffers';
import MoveShares from '../views/move-shares';
import PendingPage from '../views/account/pending';
import { isLabelSustainable } from '../constants';
import ProductionHistory from '../views/production-history';
import Notifications from '../views/notifications';
import View404 from '../views/404';

const underConstruction = process.env.REACT_APP_UNDER_CONSTRUCTION === 'true';

const getHomeRoute = (label_key) =>
	isLabelSustainable(label_key) ? '/account' : '/dashboard';

const ROUTES = [
	{
		path: '/',
		key: 'root',
		exact: true,
		component: ({ data }) => (
			<Redirect to={getHomeRoute(data.labels.id.label_key)} />
		),
	},
	{
		path: '/dashboard',
		key: 'dashboard',
		component: () => <Dashboard />,
	},
	{
		path: '/home',
		key: 'home',
		component: () => <Home />,
	},
	{
		path: '/account',
		key: 'account',
		component: () => <Account />,
	},
	{
		path: '/agreements/:agreementId?',
		key: 'agreements',
		component: ({ match }) => (
			<Agreements selectedAgreement={match.params.agreementId} />
		),
	},
	{
		path: '/contact',
		key: 'contact',
		component: () => <Contact />,
	},
	{
		path: '/iban',
		key: 'iban',
		component: () => <IbanComponent />,
	},
	{
		path: '/invoices',
		key: 'invoices',
		component: () => <Invoices />,
	},
	{
		path: '/metermeasuring',
		key: 'metermeasuring',
		component: () => <Metermeasuring />,
	},
	{
		path: '/move',
		key: 'move',
		component: () => <Move />,
	},
	{
		path: '/participation',
		key: 'participation',
		component: () => <Participation />,
	},
	{
		path: '/periodical-payments',
		key: 'periodical',
		component: () => <PeriodicalPayments />,
	},
	{
		path: '/products',
		key: 'products',
		component: () => <Products />,
	},
	{
		path: '/usage/:agreementId?',
		key: 'usage',
		component: ({ match }) => (
			<Usage selectedAgreement={match.params.agreementId} />
		),
	},
	{
		path: '/offers',
		key: 'offers',
		component: () => <AvailableOffers />,
	},
	{
		path: '/personal-offers',
		key: 'personal-offers',
		component: () => <PersonalOffers />,
	},
	{
		path: '/my-offers',
		key: 'my-offers',
		component: () => <MyOffers />,
	},
	{
		path: '/my-orders',
		key: 'my-orders',
		component: () => <MyOrders />,
	},
	{
		path: '/checkout',
		key: 'checkout',
		component: () => <Checkout />,
	},
	{
		path: '/overview',
		key: 'overview',
		component: () => <ProductionHistory />,
	},
	{
		path: '/notifications',
		key: 'notifications',
		component: () => <Notifications />,
	},
	{
		path: '/notfound',
		key: 'notfound',
		component: (props) => <View404 {...props} />,
	},
];

const OVERLAY_ROUTES = [
	{
		path: '/:view/usage-history/:id',
		key: 'usage-history',
		component: () => <UsageHistoryDetails />,
	},
	{
		path: '/:view/change-email-address',
		key: 'change-email',
		component: () => <ChangeEmailAddress />,
	},
	{
		path: '/:view/change-phonenumber',
		key: 'change-phonenumber',
		component: () => <ChangePhoneNumber />,
	},
	{
		path: '/:view/monthly-amount-info',
		key: 'monthly-amount',
		component: () => <MonthlyAmountInfo />,
	},
	{
		path: '/:view/update/:agreementId',
		key: 'periodical-payment',
		component: () => <ConnectedPeriodicalPayment />,
	},
	{
		path: '/:view/stop-date-notification',
		key: 'stop-date-notification',
		component: () => <StopDateNotification />,
	},
	{
		path: '/:view/change-account-password',
		key: 'change-account-password',
		component: () => <ChangeAccountPassword />,
	},
	{
		path: '/:view/create-offer',
		key: 'create-offer',
		component: () => <CreateOffer />,
	},
	{
		path: '/:view/redeem-offer',
		key: 'redeem-offer',
		component: () => <RedeemOffer />,
	},
	{
		path: '/:view/move-shares',
		key: 'move-shares',
		component: () => <MoveShares />,
	},
];

const INDEPENDENT_ROUTES = underConstruction
	? [
			{
				path: '/',
				key: 'construction1',
				exact: true,
				component: (props) => <UnderConstruction {...props} />,
			},
			{
				path: '/under-construction',
				key: 'construction',
				component: (props) => <UnderConstruction {...props} />,
			},
	  ]
	: [
			{
				path: '/verify/:id',
				key: 'verify',
				component: (props) => <EmailConfirmation {...props} />,
			},
			{
				path: '/reactivation/:id',
				key: 'reactivation',
				component: (props) => <ConfirmReactivation {...props} />,
			},
			{
				path: '/under-construction',
				key: 'construction',
				component: (props) => <UnderConstruction {...props} />,
			},
			{
				path: '/thank-you',
				key: 'thank-you',
				component: (props) => <ThankYou {...props} />,
			},
			{
				path: '/payment-cancelled',
				key: 'payment-cancelled',
				component: (props) => <ThankYou {...props} cancelled />,
			},
			{
				path: '/register',
				key: 'register',
				component: (props) => <Register {...props} />,
			},
			{
				path: '/pending-page',
				key: 'pending-page',
				component: (props) => <PendingPage {...props} />,
			},
			{
				path: '/notfound',
				key: 'notfound',
				component: (props) => <View404 {...props} />,
			},
	  ];

const resolvedPeriodicalPayment = (props) =>
	props.periodicalPayments.length ? (
		<PeriodicalPayment
			selectedPeriodicalPayment={props.match.params.agreementId}
		/>
	) : null;

const mapStateToProps = (state) => ({
	periodicalPayments: selector.getPeriodicalPayments(state),
});

const ConnectedPeriodicalPayment = withRouter(
	connect(mapStateToProps)(resolvedPeriodicalPayment)
);

export const RenderRoutes = ({ routes, ...props }) => (
	<Switch>
		{routes.map((route) => (
			<Route
				key={route.key}
				path={route.path}
				exact={route.exact}
				render={(renderProps) => (
					<route.component {...renderProps} {...props} />
				)}
			/>
		))}
		<Route
			component={() => (
				<Redirect to={getHomeRoute(props.data.labels.id.label_key)} />
			)}
		/>
	</Switch>
);

export const RenderOverlayRoutes = ({ routes, ...props }) =>
	routes.map((route) => (
		<Route
			key={route.key}
			path={route.path}
			exact={route.exact}
			render={(renderProps) => <route.component {...renderProps} {...props} />}
		/>
	));

export const RenderIndependentRoutes = ({ routes, ...props }) => (
	<Switch>
		{routes.map((route) => (
			<Route
				key={route.key}
				path={route.path}
				exact={route.exact}
				render={(renderProps) => (
					<route.component {...renderProps} {...props} />
				)}
			/>
		))}
		<Route component={() => <Redirect to={'/'} />} />
	</Switch>
);

export { ROUTES, OVERLAY_ROUTES, INDEPENDENT_ROUTES };
