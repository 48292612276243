import React from 'react';
import { CSSTransition } from 'react-transition-group';
import './card-reveal.scss';

const CardReveal = ({ children, revelOn, minHeight }) => {
	return (
		<div className="card-reveal">
			{React.Children.map(children, (child, i) =>
				i < 2 && i === 1 ? (
					<CSSTransition
						in={revelOn}
						timeout={300}
						classNames="reveal-node"
						unmountOnExit>
						<div
							style={{ minHeight: minHeight + 'px' }}
							className={`card-${i}`}>
							{child}
						</div>
					</CSSTransition>
				) : (
					<div style={{ minHeight: minHeight + 'px' }} className={`card-${i}`}>
						{child}
					</div>
				)
			)}
		</div>
	);
};

export default CardReveal;
