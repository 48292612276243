import { NavigateBefore, NavigateNext } from '@mui/icons-material';
import { IconButton, Paper, Stack, Typography } from '@mui/material';
import {
	LineChart,
	Line,
	XAxis,
	YAxis,
	CartesianGrid,
	Tooltip,
	Legend,
	ResponsiveContainer,
	Label,
} from 'recharts';
import getLanguage from '../../../utils/get-language';

const CustomTooltip = ({ active, payload, unit }) => {
	if (active && payload && payload.length) {
		return (
			<Paper
				variant="outlined"
				square
				sx={{
					backgroundColor: '#0294FF',
					p: 1,
				}}>
				{payload[0].payload.record && (
					<Typography color="white" variant="h6">
						Record
					</Typography>
				)}
				<Typography
					color="white"
					variant="h6">{`${payload[0].value} ${unit}`}</Typography>
			</Paper>
		);
	}

	return null;
};

const CustomizedAxisTick = (props) => {
	const { x, y, payload, type } = props;
	const value =
		type === 'YEAR_MONTHS'
			? monthNameByNumber(payload.value)
			: type === 'MONTH_DAYS'
			? payload.value
			: type === 'WEEK4_WEEKS'
			? payload.value
			: type === 'DAY_HOURS'
			? payload.value
			: payload.value;

	return (
		<g transform={`translate(${x - 15},${y})`}>
			<text fontSize={12} x={0} y={0} dy={16} fill="#666">
				{value}
			</text>
		</g>
	);
};

const labelKey = (type) => {
	let key = '';

	switch (type) {
		case 'YEAR_MONTHS':
			key = 'labels.month';
			break;
		case 'MONTH_DAYS':
			key = 'labels.date.day';
			break;
		case 'YEAR3_YEARS':
			key = 'labels.year';
			break;
		case 'WEEK4_WEEKS':
			key = 'labels.week';
			break;
		case 'DAY_HOURS':
			key = 'labels.hour';
			break;
		default:
			key = '';
	}

	return key;
};

const legendKey = (type) => {
	let key = '';

	switch (type) {
		case 'YEAR_MONTHS':
			key = 'year';
			break;
		case 'MONTH_DAYS':
			key = 'month';
			break;
		case 'YEAR3_YEARS':
			key = 'year';
			break;
		case 'WEEK4_WEEKS':
			key = 'week';
			break;
		case 'DAY_HOURS':
			key = 'hour';
			break;
		default:
			key = '';
	}

	return key;
};

const legendText = (
	type,
	label = {},
	lastEntryLabel = {},
	noData = false,
	translations
) => {
	const key = legendKey(type);
	const value = label[key];
	const lastEntryLabelValue = lastEntryLabel[key];
	const { date } = label;

	if (noData && type !== 'DAY_HOURS') {
		return translations.production.noData;
	} else {
		switch (type) {
			case 'YEAR_MONTHS':
				return value;
			case 'MONTH_DAYS':
				return date && monthNameByNumber(label.date.month, 'long');
			case 'YEAR3_YEARS':
				return `${value}-${lastEntryLabelValue}`;
			case 'WEEK4_WEEKS':
				return `week ${value}-${lastEntryLabelValue}`;
			case 'DAY_HOURS':
				const language = getLanguage('language');
				var options = {
					weekday: 'long',
					year: 'numeric',
					month: 'long',
					day: 'numeric',
				};
				const today = new Date();
				const _date = date || {
					year: today.getFullYear(),
					month: today.getMonth() + 1,
					day: today.getDate(),
				};
				const dataDate = new Intl.DateTimeFormat(language, options).format(
					new Date(_date.year, _date.month - 1, _date.day)
				);
				return noData
					? `${translations.production.noHourlyDataOne} ${dataDate} ${translations.production.noHourlyDataTwo}`
					: dataDate;
			default:
				return '';
		}
	}
};

const renderLegend = (
	props,
	clickNext,
	clickPrev,
	type,
	labels,
	lastEntryLabels,
	isDataEmpty,
	translations,
	prevDisabled,
	nextDisabled
) => {
	return (
		<Stack justifyContent="space-between" direction="row" spacing={2}>
			<IconButton
				disabled={prevDisabled}
				style={{
					borderWidth: 1,
					borderStyle: 'solid',
					width: 25,
					height: 25,
					borderRadius: 2,
				}}
				variant="outlined"
				onClick={clickPrev}>
				<NavigateBefore />
			</IconButton>
			<Typography color="#666" variant="body2">
				{legendText(type, labels, lastEntryLabels, isDataEmpty, translations)}
			</Typography>
			<IconButton
				disabled={nextDisabled}
				style={{
					borderWidth: 1,
					borderStyle: 'solid',
					width: 25,
					height: 25,
					borderRadius: 2,
				}}
				variant="outlined"
				onClick={clickNext}>
				<NavigateNext />
			</IconButton>
		</Stack>
	);
};

const monthNameByNumber = (number, _month = 'short') => {
	if (number && Number(number)) {
		const lang = getLanguage();
		const formatter = new Intl.DateTimeFormat(lang, { month: _month });
		const month = formatter.format(new Date(2022, number - 1, 1));

		return month;
	} else {
		return number;
	}
};

export default ({
	data: chartData,
	type,
	onNext,
	onPrev,
	translations,
	dataKey,
	prevDisabled,
	nextDisabled,
}) => {
	const clickNext = () => onNext();
	const clickPrev = () => onPrev();
	const key = labelKey(type);
	const data = chartData || [];
	const isDataEmpty = data ? data.length === 0 : true;
	const firstEntry = !isDataEmpty ? data[0] : null;
	const lastEntry = data[data.length - 1];
	const { labels } = firstEntry || {};
	const lastEntryLabels = !isDataEmpty ? lastEntry.labels : {};

	const unit = type === 'DAY_HOURS' ? 'wH' : 'kWh';

	return (
		<ResponsiveContainer width="100%" height={300}>
			<LineChart
				data={data}
				margin={{
					top: 25,
					right: 5,
					left: 5,
					bottom: 5,
				}}>
				<CartesianGrid vertical={false} strokeDasharray="1 1" />
				<XAxis
					dataKey={key}
					minTickGap={10}
					tick={<CustomizedAxisTick type={type} />}
				/>
				<YAxis fontSize={12}>
					<Label value={unit} offset={15} position="top" />
				</YAxis>
				<Tooltip content={<CustomTooltip unit={unit} />} />
				<Legend
					width="100%"
					wrapperStyle={{
						bottom: 0,
						left: 0,
					}}
					content={(props) =>
						renderLegend(
							props,
							clickNext,
							clickPrev,
							type,
							labels,
							lastEntryLabels,
							isDataEmpty,
							translations,
							prevDisabled,
							nextDisabled
						)
					}
				/>
				<Line
					type="linear"
					dataKey={dataKey}
					stroke="#0294FF"
					strokeWidth={2}
					dot={{
						r: 3,
						fill: '#0294FF',
					}}
					activeDot={{ r: 6 }}
				/>
			</LineChart>
		</ResponsiveContainer>
	);
};
