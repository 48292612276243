import get from './methods/get';

export default {
	get: (agreement_id, headers) =>
		get(`/agreements/${agreement_id}/invoices`, headers),
	latest: {
		get: (headers) => get('latestInvoices', headers),
		emptyResponse: [
			{
				invoice_type: '',
				amount: 0,
				payment_status: '',
			},
		],
	},
};
