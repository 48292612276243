import get from './methods/get';

export default {
	get: (zipCode, houseNumber, headers) =>
		get('/address', headers, false, {
			params: {
				zipCode,
				houseNumber,
			},
		}),
};
