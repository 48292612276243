import { Container, Stack, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import LangSwitch from '../../components/lang-switch';

const PendingPage = ({ logo, info }) => {
	const translations = useSelector((state) => state.translations);
	const dispatch = useDispatch();

	return (
		<Container>
			<Stack direction="row" spacing={2} justifyContent="flex-end">
				<LangSwitch dispatch={dispatch} translations={translations} />
			</Stack>
			<Stack
				direction="column"
				justifyContent="space-between"
				alignItems="center"
				spacing={5}>
				<Stack
					direction="column"
					justifyContent="space-between"
					alignItems="center"
					spacing={5}>
					{logo && <img src={logo} alt={info.name || 'Logo'} />}
					<Typography variant="h5">
						<span>{translations.general.pendingText}</span>
					</Typography>
				</Stack>
				<Stack
					direction="column"
					justifyContent="space-between"
					alignItems="center"
					spacing={2}>
					<span>{info.name}</span>
					<span>{info.email}</span>
					<span>{info.phone_number}</span>
				</Stack>
			</Stack>
		</Container>
	);
};

export default PendingPage;
