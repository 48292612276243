import React, { Component } from 'react';

import Row from '../../components/row';
import PageTitle from '../../components/page-title';
import Spinner from '../../components/spinner';

import periodicalPayments from '../../api/periodical-payments';
import { action as periodicalPaymentsAction } from '../../reducers/data/periodical-payments';
import Link from '../../components/link';
import PeriodicalPayment from './periodical-payment';

import './periodical-payments.css';
import { selector } from '../../reducers/data/periodical-payments';
import { connect } from 'react-redux';
import { selector as userInfoSelector } from '../../reducers/data/user-info';

class PeriodicalPayments extends Component {
	dispatch = this.props.dispatch;

	constructor(props) {
		super(props);
		this.state = {
			tooltipPosition: window.innerWidth <= 767 ? 'left' : 'bottom',
		};
	}

	componentDidMount() {
		window.addEventListener('resize', this.updateTooltipPositionOnResize);
		periodicalPayments
			.get()
			.then((data) => this.dispatch(periodicalPaymentsAction.data(data)))
			.catch((err) => err);
	}

	updateTooltipPositionOnResize = () =>
		window.innerWidth <= 767 && this.state.tooltipPosition === 'bottom'
			? this.setState({ tooltipPosition: 'left' })
			: window.innerWidth > 767 &&
			  this.state.tooltipPosition === 'left' &&
			  this.setState({ tooltipPosition: 'bottom' });

	render() {
		const { translations, periodicalPayments, isUserImpersonator } = this.props;

		if (!periodicalPayments) {
			return <Spinner />;
		}

		return (
			<div className="view-periodical-payments">
				<Row>
					<PageTitle>{translations.title}</PageTitle>
				</Row>

				<Row>
					<div>
						<span>{translations.periodicalPayment.info}</span>{' '}
						<Link
							to={'/periodical-payments/monthly-amount-info'}
							className="primary-text-color">
							{translations.periodicalPayment.viewMore}
						</Link>
					</div>
				</Row>

				<Row>
					<div className="periodical-payments">
						{periodicalPayments &&
							periodicalPayments.map((periodicalPayment) => (
								<PeriodicalPayment
									key={periodicalPayment.agreement_identifier_with_sequence}
									periodicalPayment={periodicalPayment}
									translations={translations}
									tooltipPosition={this.state.tooltipPosition}
									isUserImpersonator={isUserImpersonator}
								/>
							))}
					</div>
				</Row>
			</div>
		);
	}
}

function mapStateToProps(state, ownProps) {
	return {
		periodicalPayments: selector.getPeriodicalPayments(state),
		isUserImpersonator: userInfoSelector.isUserImpersonator(state.data),
		translations: Object.assign(
			state.translations.monthlyAmount,
			state.translations.general
		),
		...ownProps,
	};
}

export default connect(mapStateToProps)(PeriodicalPayments);
