const initialState = {
	page: 0,
	submitSucceeded: null,
};

const move = (state = initialState, action) => {
	switch (action.type) {
		case 'MOVE_WIZARD_TO_PAGE':
			return Object.assign({}, state, { page: action.value });
		case 'MOVE_WIZARD_SUBMIT_SUCCEEDED':
			return Object.assign({}, state, { submitSucceeded: true });
		case 'MOVE_WIZARD_SUBMIT_FAILED':
			return Object.assign({}, state, { submitSucceeded: false });
		default:
			return state;
	}
};

export default move;

export const action = {
	toPage: (value) => ({ type: 'MOVE_WIZARD_TO_PAGE', value }),
	submitSucceeded: () => ({ type: 'MOVE_WIZARD_SUBMIT_SUCCEEDED' }),
	submitFailed: () => ({ type: 'MOVE_WIZARD_SUBMIT_FAILED' }),
};
