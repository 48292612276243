import React, { Component } from 'react';
import PropTypes from 'prop-types';
import MoveWizardStep0 from '../move-wizard-step-0';
import MoveWizardStep1 from '../move-wizard-step-1';
import MoveWizardStep2 from '../move-wizard-step-2';
import MoveWizardStep3 from '../move-wizard-step-3';
import MoveWizardStep4 from '../move-wizard-step-4';

import './move-wizard.scss';
import Icon from '../icon';
import LightbulbOutline from '../icons/lightbulb-outline';
import Flame from '../icons/flame';
import withTranslationsGeneral from '../with-translations-general';
import withPlatform from '../with-platform';
import Fieldset from '../fieldset';
import Input from '../input';
import Time from '../icons/access-time';

export const dateInput = withTranslationsGeneral(
	withPlatform(
		({
			input,
			label,
			showErrorInNewLine,
			type,
			meta: { valid, touched, active, error },
			platform,
			translationsGeneral,
			dateOnChange,
			required,
		}) => {
			let fieldType = input.value || active ? type : 'text';
			fieldType = platform === 'ios' ? type : fieldType;
			return (
				<>
					<Fieldset
						className="move-wizard__fieldset move-wizard__date-input"
						label={label}
						focus={true}
						required={required}
						hasValue={!!input.value}
						type={fieldType}
						placeholderText={translationsGeneral.datePlaceholder}
						validity={!valid && touched ? 'invalid' : null}
						error={!valid && touched && translationsGeneral.error[error]}
						{...input}>
						<Input
							type={'custom-date'}
							placeholder={translationsGeneral.datePlaceholder}
							{...input}
							onChange={dateOnChange}
						/>
						<Icon size="small" icon={Time} />
					</Fieldset>
					{showErrorInNewLine && !valid && touched && (
						<span className="error">{translationsGeneral.error[error]}</span>
					)}
				</>
			);
		}
	)
);

export const AddressForDisplay = ({ address, twoLine, ...props }) => {
	return (
		<div {...props}>
			<span style={twoLine && { display: 'block' }}>
				{address.street} {address.house_number}
				{address.house_number_extension
					? ` ${address.house_number_extension}, `
					: ', '}
			</span>
			<span style={twoLine && { display: 'block' }}>
				{address.zip_code} {address.city}
			</span>
		</div>
	);
};

class MoveWizard extends Component {
	constructor(props) {
		super(props);
		this.state = {
			stepValid0: false,
			stepValid1: false,
			stepValid2: false,
			stepValid3: false,
		};
		this.isStepValid = this.isStepValid.bind(this);
	}

	componentDidMount() {
		this.setInitialPage(this.props.page, this.props.agreements);
	}

	isStepValid(stepNumber, valid) {
		this.setState({ ['stepValid' + stepNumber]: valid });
	}

	isOneAgreement(agreements) {
		return (
			agreements &&
			agreements.length === 1 &&
			agreements[0].addresses.length === 1
		);
	}

	setInitialPage(page, agreements) {
		if (this.isOneAgreement(agreements)) {
			this.props.setPage(1);
		}
	}

	getCommodityIcons(commodities) {
		return commodities.map((commodity, index) => {
			if (commodity === 'ELECTRICITY') {
				return (
					<span
						key={`commodity-icon-${index}`}
						className="move-wizard-step-0__commodity-icon">
						<Icon
							icon={LightbulbOutline}
							size="small"
							color="rgba(0, 0, 0, 0.54)"
						/>
					</span>
				);
			} else {
				return (
					<span
						key={`commodity-icon-${index}`}
						className="move-wizard-step-0__commodity-icon">
						<Icon icon={Flame} size="small" color="rgba(0, 0, 0, 0.54)" />
					</span>
				);
			}
		});
	}

	render() {
		const {
			onSubmit,
			translations,
			page,
			nextPage,
			previousPage,
			agreements,
			setPage,
			submitSucceeded,
		} = this.props;
		return (
			<div className="move-wizard">
				<div
					className={`move-wizard__stepper-horizontal orange ${
						page > 3 ? 'move-wizard__stepper-horizontal__hide' : ''
					}`}>
					{!this.isOneAgreement(agreements) && (
						<div
							className={`stepper-horizontal_step ${
								page === 0 ? 'active' : ''
							}`}>
							<div
								className="stepper-horizontal_step-circle valid"
								onClick={() => setPage(0)}>
								<span>0</span>
							</div>
							<div className="stepper-horizontal_step-title">
								{translations.form.chooseAgreementAddress}
							</div>
							<div className="stepper-horizontal_step-bar-left" />
							<div className="stepper-horizontal_step-bar-right" />
						</div>
					)}
					<div
						className={`stepper-horizontal_step ${page === 1 ? 'active' : ''}`}>
						<div
							className={`stepper-horizontal_step-circle ${
								this.state.stepValid0 || this.isOneAgreement(agreements)
									? 'valid'
									: ''
							}`}
							onClick={() =>
								(this.state.stepValid0 || this.isOneAgreement(agreements)) &&
								setPage(1)
							}>
							<span>1</span>
						</div>
						<div className="stepper-horizontal_step-title">
							{translations.form.chooseOldAddress}
						</div>
						<div className="stepper-horizontal_step-bar-left" />
						<div className="stepper-horizontal_step-bar-right" />
					</div>
					<div
						className={`stepper-horizontal_step ${page === 2 ? 'active' : ''}`}>
						<div
							className={`stepper-horizontal_step-circle ${
								this.state.stepValid1 ? 'valid' : ''
							}`}
							onClick={() => this.state.stepValid1 && setPage(2)}>
							<span>2</span>
						</div>
						<div className="stepper-horizontal_step-title">
							{translations.form.enterNewAddress}
						</div>
						<div className="stepper-horizontal_step-bar-left" />
						<div className="stepper-horizontal_step-bar-right" />
					</div>
					<div
						onClick={() => this.state.stepValid2 && setPage(3)}
						className={`stepper-horizontal_step ${page === 3 ? 'active' : ''}`}>
						<div
							className={`stepper-horizontal_step-circle ${
								this.state.stepValid2 ? 'valid' : ''
							}`}
							onClick={() => this.state.stepValid2 && setPage(3)}>
							<span>3</span>
						</div>
						<div className="stepper-horizontal_step-title">
							{translations.form.confirm}
						</div>
						<div className="stepper-horizontal_step-bar-left" />
						<div className="stepper-horizontal_step-bar-right" />
					</div>
				</div>
				{page === 0 && (
					<MoveWizardStep0
						translations={translations}
						isStepValid={this.isStepValid}
						agreements={agreements}
						onSubmit={nextPage}
						getCommodityIcons={this.getCommodityIcons}
					/>
				)}
				{page === 1 && (
					<MoveWizardStep1
						translations={translations}
						isStepValid={this.isStepValid}
						agreements={this.isOneAgreement(agreements) ? agreements : []}
						previousPage={previousPage}
						onSubmit={nextPage}
						getCommodityIcons={this.getCommodityIcons}
					/>
				)}
				{page === 2 && (
					<MoveWizardStep2
						previousPage={previousPage}
						isStepValid={this.isStepValid}
						onSubmit={nextPage}
						translations={translations}
					/>
				)}
				{page === 3 && (
					<MoveWizardStep3
						previousPage={previousPage}
						isStepValid={this.isStepValid}
						onSubmit={onSubmit}
						translations={translations}
						agreements={agreements}
					/>
				)}
				{page === 4 && (
					<MoveWizardStep4 translations={translations} done={submitSucceeded} />
				)}
			</div>
		);
	}
}

export default MoveWizard;

MoveWizard.propTypes = {
	onSubmit: PropTypes.func.isRequired,
};
