import {
	Box,
	Button,
	FormControl,
	Skeleton,
	Stack,
	Typography,
} from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import api from '../../api';
import ChartContainer from './components/chart-container';

// - YEAR3_YEARS
// - YEAR_MONTHS
// - MONTH_DAYS
// - WEEK_DAYS
// - DAY_HOURS
// - WEEK4_WEEKS

function ProductionHistory({ translations, onClose }) {
	const [projects, setProjects] = useState([]);
	const [totalProjectData, setTotalProjectData] = useState(null);
	const [sharesInProjectData, setSharesInProjectData] = useState(null);
	const [selectedProject, setSelectedProject] = useState(null);
	const [projectsLoading, setProjectsLoading] = useState(false);
	const [projectDataLoading, setProjectDataLoading] = useState(false);
	const [type, setType] = useState('WEEK4_WEEKS');
	const [offset, setOffset] = useState(0);

	useEffect(() => {
		setProjectsLoading(true);
		api.sustainable
			.get()
			.then((response) => {
				setProjects(response);
				handleSetSelectedProject(response[0]);
			})
			.catch(() => {
				setProjects([]);
			})
			.finally(() => {
				setProjectsLoading(false);
			});
	}, []);

	useEffect(() => {
		if (selectedProject) {
			setProjectDataLoading(true);
			Promise.all([
				api.sustainable.production
					.get(selectedProject.project_code, 'TOTAL_PROJECT', type, offset)
					.catch((error) => error),
				api.sustainable.production
					.get(selectedProject.project_code, 'SHARES_IN_PROJECT', type, offset)
					.catch((error) => error),
			])
				.then((response) => {
					if (!(response[0] instanceof Error)) {
						setTotalProjectData(response[0]);
					}
					if (!(response[1] instanceof Error)) {
						setSharesInProjectData(response[1]);
					}
				})
				.finally(() => {
					setProjectDataLoading(false);
				});
		}
	}, [selectedProject, offset, type]);

	const handleSetSelectedProject = (project) => {
		setSelectedProject(project);
	};

	const handleTypeChange = (type) => {
		setOffset(0);
		setType(type);
	};
	const onNextClick = () => {
		if (offset < 0) {
			setOffset(offset + 1);
		}
	};

	const onPrevClick = () => {
		// determine minimum offset
		setOffset(offset - 1);
	};

	const prevDisabled = type === 'YEAR3_YEARS' && true;
	const nextDisabled = type === 'YEAR3_YEARS' ? true : offset === 0;

	return (
		<Box
			sx={{
				flexGrow: 1,
				pt: 4,
			}}>
			<Typography variant="h4">{translations.production.title}</Typography>
			{projectsLoading && (
				<Skeleton
					variant="rectangular"
					width={220}
					height={33}
					sx={{
						mb: 2,
						mt: 2,
					}}
				/>
			)}
			{projects.length === 0 && (
				<Typography variant="h4">
					{translations.general.error.noProjects}
				</Typography>
			)}
			{!projectsLoading && projects.length > 0 && (
				<Stack
					direction="row"
					spacing={2}
					justifyContent="space-between"
					sx={{
						pb: 2,
						pt: 2,
					}}>
					<Stack
						direction="row"
						spacing={2}
						sx={{
							pb: 2,
							pt: 2,
						}}>
						{projects.map((project) => (
							<Button
								onClick={() => handleSetSelectedProject(project)}
								color="info"
								variant={
									project.project_code === selectedProject.project_code
										? 'contained'
										: 'outlined'
								}
								sx={{
									pointerEvents:
										project.project_code === selectedProject.project_code
											? 'none'
											: 'auto',
								}}
								key={project.project_code}>
								{project.project_name}
							</Button>
						))}
					</Stack>
					<FormControl>
						<Select
							value={type}
							id="view_type"
							label={translations.period}
							size="small"
							onChange={(event) => handleTypeChange(event.target.value)}>
							<MenuItem value={'YEAR3_YEARS'}>
								{translations.production.perYear}
							</MenuItem>
							<MenuItem value={'YEAR_MONTHS'}>
								{translations.production.perMonth}
							</MenuItem>
							<MenuItem value={'WEEK4_WEEKS'}>
								{translations.production.perWeek}
							</MenuItem>
							<MenuItem value={'MONTH_DAYS'}>
								{translations.production.perDay}
							</MenuItem>
							<MenuItem value={'DAY_HOURS'}>
								{translations.production.perHour}
							</MenuItem>
						</Select>
					</FormControl>
				</Stack>
			)}
			{projects.length > 0 && (
				<Stack
					direction={{ xs: 'column', sm: 'column', md: 'row' }}
					spacing={2}>
					<ChartContainer
						title={translations.production.totalWind}
						loading={projectDataLoading}
						data={totalProjectData}
						type={type}
						onNext={onNextClick}
						onPrev={onPrevClick}
						translations={translations}
						dataKey="rounded_value"
						prevDisabled={prevDisabled}
						nextDisabled={nextDisabled}
					/>
					<ChartContainer
						title={translations.production.totalShares}
						loading={projectDataLoading}
						data={sharesInProjectData}
						type={type}
						onNext={onNextClick}
						onPrev={onPrevClick}
						translations={translations}
						dataKey="value"
						prevDisabled={prevDisabled}
						nextDisabled={nextDisabled}
					/>
				</Stack>
			)}
		</Box>
	);
}

function mapStateToProps(state) {
	return {
		translations: {
			menu: state.translations.menu,
			general: state.translations.general,
			production: state.translations.production,
		},
	};
}

export default connect(mapStateToProps)(ProductionHistory);
