import React from 'react';
import PropTypes from 'prop-types';

import './panel-header.css';

/**
 * The `PanelHeader` component is used in the `Panel` component to render its
 * header.
 */
const PanelHeader = ({ title, topBorder, children }) => {
	const isEmptyClass = !title && !children ? ' panel-header--is-empty' : '';
	return (
		<header
			className={`panel-header${isEmptyClass}${
				topBorder ? ' top-border' : ''
			}`}>
			<div className={'panel-header-inner'}>
				{title ? <h3>{title}</h3> : null}

				{children}
			</div>
		</header>
	);
};

export default PanelHeader;

PanelHeader.propTypes = {
	/** The title of the `Panel` */
	title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
	topBorder: PropTypes.bool,
};
