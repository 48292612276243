import React from 'react';
import PropTypes from 'prop-types';
import DateTime from '../../components/date-time';
import withTranslationsGeneral from '../../components/with-translations-general';

import './meter-measurements-gas.css';

const removeZeroValues = (mm) => !!mm.consumption_normal || !!mm.feedin_normal;

const MeterMeasurementsGas = ({ measurements, itemsLimit, translations }) => (
	<table className="meter-measurements-gas">
		<thead>
			<tr>
				<th>{translations.date}</th>
				<th>{translations.usage}</th>
			</tr>
		</thead>
		<tbody>
			{measurements
				.filter(removeZeroValues)
				.slice(0, itemsLimit || measurements.length)
				.map((measurement, i) => (
					<tr key={i}>
						<td>
							<DateTime
								date={measurement.measurement_date}
								format="format_written"
							/>
						</td>
						<td>
							{measurement.consumption_normal}{' '}
							{translations.unit[measurement.unit]}
						</td>
					</tr>
				))}
		</tbody>
	</table>
);

export default withTranslationsGeneral(MeterMeasurementsGas);

MeterMeasurementsGas.propTypes = {
	translations: PropTypes.object.isRequired,
	measurements: PropTypes.array.isRequired,
	meterType: PropTypes.string.isRequired,
};
