import React from 'react';
import CardHeader from './card-header';

import './card.css';

const Card = ({ children, title }) => (
	<div className="card">
		<CardHeader>{title}</CardHeader>
		<div className="card__body">{children}</div>
	</div>
);

export default Card;

Card.propTypes = {};
