import { createReducer, createActions } from '../collapsibles';

const initialState = {
	collapsibles: [],
};

const collapsibleReducer = createReducer('INVOICES');

export default (state = initialState, action) => {
	return Object.assign({}, state, {
		collapsibles: collapsibleReducer(state.collapsibles, action),
	});
};

export const action = createActions('INVOICES');
