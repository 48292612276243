import React, { Component } from 'react';
import PropTypes from 'prop-types';

export default class IconHail extends Component {
	static displayName = 'IconCode';

	getLarge() {
		return (
			<svg
				version="1.1"
				xmlns="http://www.w3.org/2000/svg"
				width="32"
				height="32"
				viewBox="0 0 32 32">
				<path d="M23.744 16.128c0-0.128 0-0.256 0-0.384 0-3.296-2.688-5.952-5.952-5.952-2.016 0-3.84 0.992-4.928 2.656-0.256-0.064-0.544-0.096-0.832-0.096-3.008 0-5.44 2.432-5.44 5.44 0 0 0 0 0 0.032 0 0 0 0.032 0 0.032 0 3.008 2.432 5.44 5.44 5.44 0.48 0 0.896-0.416 0.896-0.896s-0.384-0.896-0.896-0.896c-1.984 0-3.584-1.568-3.648-3.52 0-0.064 0.032-0.096 0.032-0.16 0-0.992 0.416-1.92 1.056-2.56 0.672-0.64 1.568-1.056 2.56-1.056 0.192 0 0.352 0 0.544 0.032 0.576 0.128 1.088 0.448 1.504 0.736 0.416 0.256 0.96 0.16 1.248-0.256 0.256-0.416 0.16-0.96-0.256-1.248-0.16-0.096-0.352-0.224-0.544-0.352 0.768-0.992 1.952-1.568 3.264-1.568 2.272 0 4.128 1.856 4.128 4.128 0 0.224-0.032 0.448-0.064 0.704-0.064 0.384 0.128 0.8 0.512 0.96 0.768 0.352 1.248 1.12 1.248 1.952 0 1.184-0.96 2.144-2.144 2.144-0.48 0-0.896 0.416-0.896 0.896s0.384 0.896 0.896 0.896c2.176 0 3.968-1.76 4-3.84 0-1.312-0.672-2.528-1.728-3.264z" />
				<path d="M16.32 25.792c0 0.495-0.401 0.896-0.896 0.896s-0.896-0.401-0.896-0.896c0-0.495 0.401-0.896 0.896-0.896s0.896 0.401 0.896 0.896z" />
				<path d="M19.616 24.416c0 0.495-0.401 0.896-0.896 0.896s-0.896-0.401-0.896-0.896c0-0.495 0.401-0.896 0.896-0.896s0.896 0.401 0.896 0.896z" />
				<path d="M15.424 18.848c-0.48 0-0.896 0.384-0.896 0.896v3.296c0 0.48 0.384 0.896 0.896 0.896s0.896-0.416 0.896-0.928v-3.264c0-0.48-0.416-0.896-0.896-0.896z" />
				<path d="M18.72 17.216c-0.48 0-0.896 0.384-0.896 0.896v3.264c0 0.48 0.384 0.896 0.896 0.896 0.48 0 0.896-0.384 0.896-0.896v-3.264c0-0.48-0.416-0.896-0.896-0.896z" />
			</svg>
		);
	}

	getSmall() {
		return (
			<svg
				version="1.1"
				xmlns="http://www.w3.org/2000/svg"
				width="32"
				height="32"
				viewBox="0 0 32 32">
				<path d="M23.744 16.128c0-0.128 0-0.256 0-0.384 0-3.296-2.688-5.952-5.952-5.952-2.016 0-3.84 0.992-4.928 2.656-0.256-0.064-0.544-0.096-0.832-0.096-3.008 0-5.44 2.432-5.44 5.44 0 0 0 0 0 0.032 0 0 0 0.032 0 0.032 0 3.008 2.432 5.44 5.44 5.44 0.48 0 0.896-0.416 0.896-0.896s-0.384-0.896-0.896-0.896c-1.984 0-3.584-1.568-3.648-3.52 0-0.064 0.032-0.096 0.032-0.16 0-0.992 0.416-1.92 1.056-2.56 0.672-0.64 1.568-1.056 2.56-1.056 0.192 0 0.352 0 0.544 0.032 0.576 0.128 1.088 0.448 1.504 0.736 0.416 0.256 0.96 0.16 1.248-0.256 0.256-0.416 0.16-0.96-0.256-1.248-0.16-0.096-0.352-0.224-0.544-0.352 0.768-0.992 1.952-1.568 3.264-1.568 2.272 0 4.128 1.856 4.128 4.128 0 0.224-0.032 0.448-0.064 0.704-0.064 0.384 0.128 0.8 0.512 0.96 0.768 0.352 1.248 1.12 1.248 1.952 0 1.184-0.96 2.144-2.144 2.144-0.48 0-0.896 0.416-0.896 0.896s0.384 0.896 0.896 0.896c2.176 0 3.968-1.76 4-3.84 0-1.312-0.672-2.528-1.728-3.264z" />
				<path d="M16.32 25.792c0 0.495-0.401 0.896-0.896 0.896s-0.896-0.401-0.896-0.896c0-0.495 0.401-0.896 0.896-0.896s0.896 0.401 0.896 0.896z" />
				<path d="M19.616 24.416c0 0.495-0.401 0.896-0.896 0.896s-0.896-0.401-0.896-0.896c0-0.495 0.401-0.896 0.896-0.896s0.896 0.401 0.896 0.896z" />
				<path d="M15.424 18.848c-0.48 0-0.896 0.384-0.896 0.896v3.296c0 0.48 0.384 0.896 0.896 0.896s0.896-0.416 0.896-0.928v-3.264c0-0.48-0.416-0.896-0.896-0.896z" />
				<path d="M18.72 17.216c-0.48 0-0.896 0.384-0.896 0.896v3.264c0 0.48 0.384 0.896 0.896 0.896 0.48 0 0.896-0.384 0.896-0.896v-3.264c0-0.48-0.416-0.896-0.896-0.896z" />
			</svg>
		);
	}

	getIcon() {
		let svg;
		svg = this.props.size === 'large' ? this.getLarge() : svg;
		svg = this.props.size === 'small' ? this.getSmall() : svg;
		return svg;
	}

	render() {
		return this.getIcon();
	}
}

IconHail.propTypes = {
	size: PropTypes.oneOf(['small', 'large']).isRequired,
};
