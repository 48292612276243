/**
 * Utility to group a list of items by the key returned by keyFactory.
 * The group itself is determined by the groupFactory and how items are added to the group is determined by itemAdder
 *
 * @param {Array} items
 * @param {Function} keyFactory has the following signature: (item) => key
 * @param {Function} groupFactory has the following signature: (key, item) => group object
 * @param {Function} itemAdder has the following signature: (group, item)
 */
export default (items, keyFactory, groupFactory, itemAdder) => {
	const byKey = {},
		groups = [];
	for (let item of items) {
		const key = keyFactory(item);
		let group = byKey[key];
		if (!group) {
			group = byKey[key] = groupFactory(key, item);
			groups.push(group);
		}
		itemAdder(group, item);
	}
	return groups;
};
