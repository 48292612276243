import get from './methods/get';
import put from './methods/put';

export default {
	/**
	 * Perform get request on the agreements endpoint. It retuns a promise which
	 * resolves to a JSON object on success and an Error object on a failure
	 * @param {Object} headers A headers object
	 * @return {Promise}       A Promise
	 */
	get: headers => get('agreements', headers),
	emptyResponse: [
		{
			agreement_id: '',
			periodical_payment: {
				details: {
					current_amount: 0,
					proposed_amount: 0,
					min_amount: 0,
					max_amount: 0,
				},
				items: [
					{
						category: '',
						description: '',
						amount: 0,
					},
				],
				payment_method: '',
				bank_account_number: '',
			},
			invoices: [
				{
					invoice_type: 'PERIODICAL',
					amount: 0,
					balance: '',
					invoice_link: '',
				},
			],
			price_agreements: [
				{
					be_price_agreement_id: '',
					name: '',
					current: true,
					tariff_sheets: [
						{
							tariff_sheet_link: '',
							createion_date: '',
							send_date: '',
						},
					],
				},
			],
		},
	],
	periodical: {
		get: headers => get('agreements/periodical?request=status', headers),
		emptyResponse: {
			request_periodical_payment_amount: null,
		},

		/**
		 * Perform put request on the agreements/periodical endpoint. It retuns a
		 * promise which resolves on success and returns an Error object on
		 * failure
		 * @param {Object} body    An object
		 * @param {Object} headers A flat object containing the headers
		 * @return {Promise}       A Promise
		 */
		put: (body, headers) => put('agreements/periodical', body, headers),
	},
};
