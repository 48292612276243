import { Alert, AlertTitle, Link } from '@mui/material';
import { migratedLabels } from '../../migrated-labels';
import './migrated-lable.css';

const migratedLabel = ({ translations, labelKey }) => {
	const label = migratedLabels.find((label) => label.labelKey === labelKey);
	return labelKey === '1024' ? (
		<Alert severity="info" sx={{ marginTop: 2 }}>
			<AlertTitle>Info</AlertTitle>
			<p>
				{translations.signIn.migratedLabelLinkMsg_1024}{' '}
				<Link href={label.redirectUrl}>
					<strong>{translations.signIn.migratedLabelLink_1024}</strong>
				</Link>
				{translations.signIn.migratedLabelDetailsMsg_1024}
			</p>
		</Alert>
	) : (
		<div className="migrated-label">
			<p>
				{translations.signIn.migratedLabelLinkMsg}{' '}
				<a href={label.redirectUrl}>{label.newLabelName}</a>.{' '}
				{translations.signIn.migratedLabelDetailsMsg}
			</p>
		</div>
	);
};

export default migratedLabel;
