import relations from '../../api/relations';

export default (state = relations.emptyResponse, action) => {
	switch (action.type) {
		case 'RELATIONS_DATA':
			return action.data;
		default:
			return state;
	}
};

export const action = {
	data: data => ({ type: 'RELATIONS_DATA', data }),
};
