import { createSelector } from 'reselect';
import memoize from 'lodash.memoize';

export default (state = null, action) => {
	switch (action.type) {
		case 'PERIODICAL_PAYMENTS_DATA':
			return action.data;
		default:
			return state;
	}
};

const getPeriodicalPayments = state => state.data.periodicalPayments;

const getPeriodicalPayment = createSelector(
	getPeriodicalPayments,
	periodicalPayments =>
		memoize(agreementId =>
			periodicalPayments.find(
				item => item.agreement_identifier_with_sequence === agreementId
			)
		)
);

export const selector = {
	getPeriodicalPayments,
	getPeriodicalPayment,
};

export const action = {
	data: data => ({ type: 'PERIODICAL_PAYMENTS_DATA', data }),
};
