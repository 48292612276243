import { createSelector } from 'reselect';
import group from '../../utils/group';
import _ from 'lodash';
import replaceObject from '../../utils/replace-object-in-array';
import getFullAgreementAddress from '../../utils/get-full-agreement-address';

export default (state = null, action) => {
	switch (action.type) {
		case 'AGREEMENTS_DATA':
			return action.data;
		case 'ADD_DUPLICATES':
			const stateCopy = _.cloneDeep(state);
			return replaceObject(
				stateCopy,
				agreementsWithDuplicatedAgreementAddress(stateCopy, action.data),
				'agreement_identifier_with_sequence'
			);
		default:
			return state;
	}
};

const getAgreements = (state) => state.data.agreements;

const agreementsWithDuplicatedAgreementAddress = (state, actionData) => {
	actionData.forEach((AgreementWithDuplicatedAddress) => {
		AgreementWithDuplicatedAddress.duplicates = [];
		state.forEach((item) => {
			if (
				item.agreement_identifier_with_sequence !==
				AgreementWithDuplicatedAddress.agreement_identifier_with_sequence
			) {
				if (
					AgreementWithDuplicatedAddress.addresses.some((intAddress) =>
						item.addresses.some(
							(extAddress) =>
								getFullAgreementAddress(intAddress) ===
								getFullAgreementAddress(extAddress)
						)
					)
				) {
					AgreementWithDuplicatedAddress.duplicates.push(item);
				}
			}
		});
	});
	return actionData;
};

const getAgreementsOrderedByStartDate = createSelector(
	[(state) => state.data.agreements],
	(agreements) => {
		return orderAgreementsByStartDate(agreements);
	}
);

const orderAgreementsByStartDate = (agreements) => {
	if (agreements) {
		return agreements.sort((a, b) => {
			const bDate = b.period ? new Date(b.period.from) : null;
			const aDate = a.period ? new Date(a.period.from) : null;

			return bDate - aDate;
		});
	}

	return null;
};

const getAgreementsWithGroupedConnectionsOrderedByStartDate = createSelector(
	[(state) => state.data.agreements],
	(agreements) => {
		let agreementsWithGroupedConnections = null;

		if (agreements) {
			const orderedAgreements = orderAgreementsByStartDate(
				agreements.filter(
					(agreement) => agreement.product_category === 'ENERGY'
				)
			);
			agreementsWithGroupedConnections = group(
				orderedAgreements,
				(item) => item.agreement_identifier,
				(key, item) => ({
					agreement_identifier: key,
					addresses: [],
				}),
				(group, item) => {
					for (let address of item.addresses) {
						const existingAddress = group.addresses.find(
							(a) =>
								a.city === address.city &&
								a.street === address.street &&
								a.house_number === address.house_number &&
								a.house_number_extension === address.house_number_extension &&
								a.zip_code === address.zip_code
						);
						if (existingAddress) {
							for (let connection of address.connections) {
								if (
									!existingAddress.connections.find(
										(c) => c.ean_code === connection.ean_code
									)
								) {
									existingAddress.connections.push(connection);
								}
							}
						} else {
							group.addresses.push(_.cloneDeep(address));
						}
					}
				}
			);
		}

		return agreementsWithGroupedConnections;
	}
);

const groupAgreementsByAddress = (agreements) => {
	let addresses = [];

	if (agreements) {
		agreements.forEach((agreement) => {
			agreement.addresses.forEach((address) => {
				const fullAddress = `${address.city}-${address.zip_code}-${address.street}-${address.house_number}-${address.house_number_extension}`.replace(
					/ /g,
					''
				);
				const fullAddressEl = addresses.find(
					(addressEl) => addressEl.name === fullAddress
				);
				if (!fullAddressEl) {
					const addressAgreement = Object.assign(address, {
						name: fullAddress,
						agreements: [
							{
								agreement_identifier: agreement.agreement_identifier,
								agreement_identifier_with_sequence:
									agreement.agreement_identifier_with_sequence,
								name: agreement.name,
								company_name: agreement.company_name,
								period: agreement.period,
								product_category: agreement.product_category,
								tariff_sheets: agreement.tariff_sheets,
								status: agreement.status,
							},
						],
					});
					addresses.push(addressAgreement);
				} else if (
					fullAddressEl &&
					!fullAddressEl.agreements.find(
						(agr) =>
							agr.agreement_identifier_with_sequence ===
							agreement.agreement_identifier_with_sequence
					)
				) {
					fullAddressEl.agreements.push({
						agreement_identifier: agreement.agreement_identifier,
						agreement_identifier_with_sequence:
							agreement.agreement_identifier_with_sequence,
						name: agreement.name,
						company_name: agreement.company_name,
						period: agreement.period,
						product_category: agreement.product_category,
						tariff_sheets: agreement.tariff_sheets,
						status: agreement.status,
					});
				}
			});
		});
	}

	return addresses;
};

const getNonTerminatedAgreements = createSelector(
	[(state) => state.data.agreements],
	(agreements) =>
		agreements &&
		agreements.filter((agreement) => agreement.status !== 'TERMINATED')
);

const getAgreementsGroupedByAddress = createSelector(
	getNonTerminatedAgreements,
	groupAgreementsByAddress
);

const getAgreementsWithoutDaughterCompanies = createSelector(
	[(state) => state.data.agreements],
	(agreements) =>
		agreements && agreements.filter((agreement) => !agreement.company_name)
);

const getAgreementsGroupedByAddressWithoutDaughterCompanies = createSelector(
	getAgreementsWithoutDaughterCompanies,
	groupAgreementsByAddress
);

/**
 * Selector to collect all invoices from all agreements and sort them by date
 */
const getInvoices = createSelector(
	[(state) => state.data.agreements],
	(agreements) => {
		const invoices = agreements.reduce(
			(prev, agreement) => [...prev, ...agreement.invoices],
			[]
		);

		invoices.sort(
			(a, b) =>
				new Date(b.issue_date).valueOf() - new Date(a.issue_date).valueOf()
		);

		return invoices;
	}
);

const getAgreementByAgreementIdentifierWithSequence = (
	state,
	agreementIdentifierWithSequence
) => {
	let agreements = getAgreements(state);
	return (
		agreements &&
		agreements.find(
			(a) =>
				a.agreement_identifier_with_sequence === agreementIdentifierWithSequence
		)
	);
};

/**
 * Selector to get all the agreements which are not the current agreement
 */
const getPastAgreements = createSelector(
	[(state) => state.data.agreements],
	(agreements) => agreements.filter((agreement) => !agreement.current)
);

export const selector = {
	getAgreements,
	getPastAgreements,
	getAgreementsGroupedByAddress,
	getAgreementsWithoutDaughterCompanies,
	getAgreementsOrderedByStartDate,
	getAgreementsWithGroupedConnectionsOrderedByStartDate,
	getAgreementsGroupedByAddressWithoutDaughterCompanies,
	getInvoices,
	getAgreementByAgreementIdentifierWithSequence,
};

export const action = {
	data: (data) => ({ type: 'AGREEMENTS_DATA', data }),
	duplicates: (data) => ({ type: 'ADD_DUPLICATES', data }),
};
