import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Row from '../../components/row';
import PageTitle from '../../components/page-title';
import Collapsible from '../../components/collapsible';
import Agreement from './agreement';
import { action as collapsibleAction } from '../../reducers/ui/agreements';
import AgreementTitle from '../../components/agreement-title/agreement-title';
import Spinner from '../../components/spinner';

import './agreements.css';
import { selector } from '../../reducers/data/agreements';
import { connect } from 'react-redux';

class Agreements extends Component {
	constructor(props) {
		super(props);

		this.dispatch = this.props.dispatch;
	}

	toggleCollapsible = (id) => this.dispatch(collapsibleAction.toggle(id));
	openCollapsible = (id) => this.dispatch(collapsibleAction.open(id));
	closeAllCollapsible = () => this.dispatch(collapsibleAction.closeAll());

	componentDidMount() {
		const { selectedAgreement, agreements } = this.props;
		this.closeAllCollapsible();
		if (selectedAgreement) {
			this.openCollapsible(selectedAgreement);
		} else {
			if (agreements && agreements.length) {
				this.openCollapsible(agreements[0].agreement_identifier_with_sequence);
			}
		}
	}

	componentDidUpdate(prevProps) {
		if (
			this.isAgreementsDataReady(prevProps) &&
			!this.props.selectedAgreement
		) {
			this.openCollapsible(
				this.props.agreements[0].agreement_identifier_with_sequence
			);
		}
	}

	isAgreementsDataReady = (prevProps) =>
		this.props.agreements &&
		this.props.agreements.length &&
		!(prevProps.agreements && prevProps.agreements.length);

	render() {
		const { translations, agreements, expand } = this.props;
		if (!agreements) {
			return <Spinner />;
		}

		return (
			<div className="view-agreements">
				<Row>
					<PageTitle>{translations.title}</PageTitle>
				</Row>

				<Row>
					<div>
						{agreements &&
							agreements.map((agreement) => (
								<Collapsible
									key={agreement.agreement_identifier_with_sequence}
									title={
										<AgreementTitle
											agreementIdentifier={agreement.agreement_identifier}
											agreementName={agreement.name}
											agreementPeriod={agreement.period}
										/>
									}
									id={agreement.agreement_identifier_with_sequence}
									expand={expand.includes(
										agreement.agreement_identifier_with_sequence
									)}
									toggleCallback={this.toggleCollapsible}>
									<Agreement
										translations={translations}
										agreement={agreement}
									/>
								</Collapsible>
							))}
					</div>
				</Row>
			</div>
		);
	}
}

Agreements.propTypes = {
	selectedAgreement: PropTypes.string,
};

function mapStateToProps(state, ownProps) {
	return {
		ui: state.ui.agreements,
		agreements: selector.getAgreementsOrderedByStartDate(state),
		expand: state.ui.agreements.collapsibles,
		translations: Object.assign(
			state.translations.agreements,
			state.translations.general
		),
		...ownProps,
	};
}

export default connect(mapStateToProps)(Agreements);
