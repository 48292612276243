import React from 'react';
import ReactDOM from 'react-dom';
import ReactTooltip from 'react-tooltip';
import Link from '../link';
import withTranslationsGeneral from '../with-translations-general';
import PropTypes from 'prop-types';

import './agreement-links-tooltip.css';

class AgreementLinksTooltip extends React.Component {
	render() {
		const { tooltipId, agreements, translationsGeneral } = this.props;
		const tooltipText =
			agreements &&
			agreements.map((agreement, index) => {
				return (
					<div key={`shipping-address-${index}`}>
						<div>
							<Link
								to={{
									pathname: `agreements/${
										agreement.agreement_identifier_with_sequence
									}`,
								}}>
								{agreement.agreement_identifier}
							</Link>
							{agreement.name && <span> | {agreement.name}</span>}
							{agreement.period && (
								<span> | {agreement.period.from.split('-')[0]}</span>
							)}
							{agreement.period &&
								agreement.period.to && (
									<span> - {agreement.period.to.split('-')[0]}</span>
								)}
						</div>
					</div>
				);
			});
		return (
			<div className="agreement-links-tooltip">
				{agreements && (
					<div className="tooltip-link" data-tip data-for={`${tooltipId}`}>
						<span>{agreements.length}</span>{' '}
						<span>
							{agreements.length > 1
								? translationsGeneral.agreementsPlural.toLowerCase()
								: translationsGeneral.agreementSingular.toLowerCase()}
						</span>
					</div>
				)}
				<ReactTooltip
					className="extra-tooltip"
					id={`${tooltipId}`}
					ref={`${tooltipId}`}
					place="bottom"
					effect="solid"
					delayHide={100}
					afterHide={() => {
						var node = ReactDOM.findDOMNode(this.refs[`${tooltipId}`]);
						node.style.left = null;
						node.style.top = null;
					}}>
					{tooltipText}
				</ReactTooltip>
			</div>
		);
	}
}

AgreementLinksTooltip.propTypes = {
	agreements: PropTypes.array,
};

export default withTranslationsGeneral(AgreementLinksTooltip);
