import languages from '../../translations';
import { I18n } from '@aws-amplify/core';

const translations = (state, action) => {
	switch (action.type) {
		case 'TRANSLATION':
			localStorage.setItem('language', action.language);
			setAmplifyLanguage(action.language, languages[action.language].amplify);
			return languages[action.language];
		default:
			const language =
				(typeof localStorage !== 'undefined' &&
					localStorage.getItem('language')) ||
				getBrowserLocale();
			setAmplifyLanguage(
				language,
				languages[language]
					? languages[language].amplify
					: languages['nl'].amplify
			);
			return languages[language] || languages['nl'];
	}
};

const getBrowserLocale = () => {
	return navigator.languages
		? shorten(navigator.languages[0])
		: navigator.language || navigator.userLanguage;
};

const setAmplifyLanguage = (language, translations) => {
	I18n.putVocabulariesForLanguage(language, translations);
	I18n.setLanguage(language);
};

const shorten = (language) => {
	return language && language.includes('-') ? language.split('-')[0] : language;
};

export default translations;
