import React from 'react';
import PropTypes from 'prop-types';
import kebabCase from 'lodash/kebabCase';

import PanelFooter from '../panel-footer';
import PanelHeader from '../panel-header';
import Collapsible from '../../components/collapsible';

import { action as collapsibleAction } from '../../reducers/ui/panels';

import './panel.css';

/**
 * The `Panel` component is used to warp other components and display them.
 */
class Panel extends React.Component {
	toggleCollapsible = id => this.props.dispatch(collapsibleAction.toggle(id));
	openCollapsible = id => this.props.dispatch(collapsibleAction.open(id));

	render() {
		const {
			transparent,
			additionalClass,
			title,
			topBorder,
			headerContent,
			children,
			leftLinkText,
			leftLinkUrl,
			leftButtonHandler,
			rightLinkText,
			rightLinkUrl,
			rightButtonHandler,
			name,
			collapsable,
			collapsableId,
			expand = [],
		} = this.props;

		const transparentClass = transparent ? 'panel--transparent' : '';

		return (
			<article
				className={`panel ${transparentClass} ${additionalClass}`.trim()}
				data-name={kebabCase(name)}>
				<PanelHeader title={title} topBorder={topBorder}>
					{headerContent}
				</PanelHeader>
				{children &&
					(collapsable ? (
						<Collapsible
							id={collapsableId}
							toggleCallback={() => this.toggleCollapsible(collapsableId)}
							expand={expand.includes(collapsableId)}>
							{children}
						</Collapsible>
					) : (
						<div className="panel__content">{children}</div>
					))}
				<PanelFooter
					leftLinkText={leftLinkText}
					leftLinkUrl={leftLinkUrl}
					leftButtonHandler={leftButtonHandler}
					rightLinkText={rightLinkText}
					rightLinkUrl={rightLinkUrl}
					rightButtonHandler={rightButtonHandler}
				/>
			</article>
		);
	}
}

export default Panel;

Panel.propTypes = {
	title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
	topBorder: PropTypes.bool,
	leftLinkText: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
	leftLinkUrl: PropTypes.string,
	leftButtonHandler: PropTypes.func,
	rightLinkText: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
	rightLinkUrl: PropTypes.string,
	rightButtonHandler: PropTypes.func,
	headerContent: PropTypes.node,
	cssClass: PropTypes.string,

	/** Setting this flag renders a panel without background and shadow */
	transparent: PropTypes.bool,

	/** The name of the panel, is used in cucumber test */
	name: PropTypes.string,
};
