import React, { Component } from 'react';
// import PropTypes from 'prop-types';
import languages from '../../translations';
import './lang-switch.css';
import PropTypes from 'prop-types';

class LangSwitch extends Component {
	dispatch = this.props.dispatch;

	openSubMenu = (event) => {
		event.currentTarget.classList.add('open');
	};

	closeSubMenu = (event) => {
		event.currentTarget.classList.remove('open');
	};

	changeLanguage = (language) => {
		this.dispatch({
			type: 'TRANSLATION',
			language: language,
		});
	};

	render() {
		const { translations } = this.props;
		return (
			<div className="lang-switch-navigation">
				<ul className="lang-switch-navigation__menu">
					<li
						className="lang-switch-navigation__item lang-switch-navigation__item--sub"
						onMouseLeave={this.closeSubMenu}
						onMouseEnter={this.openSubMenu}
						onTouchStart={this.openSubMenu}>
						<span className="lang-switch-navigation__label">
							<svg
								xmlns="http://www.w3.org/2000/svg"
								className="h-8 w-8"
								viewBox="0 0 20 20"
								fill="currentColor">
								<path
									fillRule="evenodd"
									d="M4.083 9h1.946c.089-1.546.383-2.97.837-4.118A6.004 6.004 0 004.083 9zM10 2a8 8 0 100 16 8 8 0 000-16zm0 2c-.076 0-.232.032-.465.262-.238.234-.497.623-.737 1.182-.389.907-.673 2.142-.766 3.556h3.936c-.093-1.414-.377-2.649-.766-3.556-.24-.56-.5-.948-.737-1.182C10.232 4.032 10.076 4 10 4zm3.971 5c-.089-1.546-.383-2.97-.837-4.118A6.004 6.004 0 0115.917 9h-1.946zm-2.003 2H8.032c.093 1.414.377 2.649.766 3.556.24.56.5.948.737 1.182.233.23.389.262.465.262.076 0 .232-.032.465-.262.238-.234.498-.623.737-1.182.389-.907.673-2.142.766-3.556zm1.166 4.118c.454-1.147.748-2.572.837-4.118h1.946a6.004 6.004 0 01-2.783 4.118zm-6.268 0C6.412 13.97 6.118 12.546 6.03 11H4.083a6.004 6.004 0 002.783 4.118z"
									clipRule="evenodd"
								/>
							</svg>
							<i className="material-icons">arrow_drop_down</i>
						</span>
						<ul className="lang-switch-navigation__menu">
							{Object.keys(languages).map((lang) => (
								<li className="lang-switch-navigation__item" key={lang}>
									<button
										className="button button--transparent lang-switch-navigation__link primary-text-color"
										title={lang}
										onClick={(e) => this.changeLanguage(lang)}>
										<span className="lang-switch-navigation__label">
											<span className="lang-switch-navigation__label__text">
												{translations.menu.language[lang]}
											</span>
										</span>
									</button>
								</li>
							))}
						</ul>
					</li>
				</ul>
			</div>
		);
	}
}

export default LangSwitch;

LangSwitch.propTypes = {
	translations: PropTypes.object.isRequired,
};
