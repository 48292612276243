import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import Overlay from '../overlay';
import closeableByKeyboard from '../closeable-by-keyboard';

/**
 * Wraps the overlay component and adds the onClose prop to close the overlay
 * Note: it is important to wrap this component with withRouter so we have access to the history and location props
 * For example:
 *
 * withRouter(
 * 		closeableOverlay(
 * 			OverlayContents
 * 		)
 * )
 */
const closeableOverlay = (OverlayContents) => {
	return class extends React.Component {
		constructor(props) {
			super(props);

			this.state = {
				hideTitle: null,
			};
		}

		closeOverlay = () => {
			this.props.history.goBack();
		};

		hideOverlayTitle = () => {
			this.setState({ hideTitle: true });
		};

		handleClose = () => {
			const { match, history } = this.props;
			if (match.params.view) {
				history.push(`/${match.params.view}`);
			} else {
				history.goBack();
			}
		};

		render() {
			const { history } = this.props;
			return (
				<Overlay
					closeHandler={this.handleClose}
					hideTitle={this.state.hideTitle}>
					<OverlayContents
						{...this.props}
						onClose={history.goBack}
						hideOverlayTitle={this.hideOverlayTitle}
					/>
				</Overlay>
			);
		}
	};
};

export default compose(
	closeableOverlay,
	closeableByKeyboard
);

closeableOverlay.propTypes = {
	location: PropTypes.object.isRequired,
	router: PropTypes.object.isRequired,
};
