import { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTheme } from '@mui/material/styles';
import dateFormatter from '../../../utils/date-formatter';

import Icon from '../../../components/icon';
import IconHome from '../../../components/icons/home';
import IconArrowForward from '../../../components/icons/arrow-forward';

import api from '../../../api';

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Stack from '@mui/material/Stack';
import Button from '@mui/lab/LoadingButton';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Divider from '@mui/material/Divider';

import {
	FormControl,
	InputLabel,
	MenuItem,
	Select,
} from '@mui/material';

function AddressLineItem ({ label, address })  {
	return <LineItem 
		label= {label} 
		value= {`${address.street} ${address.house_number}${address.house_number_extension?' '+address.house_number_extension:''}, ${address.zip_code} ${address.city}`}
	/>
}

export function AddressChange() {
	return (
		<>
			<Icon size="inline" className="home" icon={IconHome} />
			<Icon size="inline" className="home" icon={IconArrowForward} />
			<Icon size="inline" className="home" icon={IconHome} />
		</>
	);
}


function LineItem({ label, value }) {
	return (
		<>
			<Stack
				direction="row"
				justifyContent="space-between"
				alignItems="center"
				spacing={2}
				height={50}>
				<span>{label}</span>
				<span>{value}</span>
			</Stack>
			<Divider />
		</>
	);
}

export function OffersAccordion({
	offer,
	translations,
	myOffer,
	retract,
	addresses = [],
}) {
	const {
		date,
		project_code,
		shares,
		price_per_share,
		status,
		rejection_reason,
		reference,
		project_name = '',
		project_end_date = '',
	} = offer;

	const [sharesToBuy, setSharesToBuy] = useState(shares);
	const [sharesToRetract, setSharesToRetract] = useState(shares);

	const [address, setAddress] = useState('');

	const marketPlaceState = useSelector((state) => state.marketplace);

	const dispatch = useDispatch();
	const theme = useTheme();

	const addToBasket = async () => {
		const selectedAddress = addresses.find((a) => a.value === address);
		dispatch({
			type: 'TOGGLE_LOADING',
		});
		try {
			const offers = marketPlaceState.basket;
			const response = await api.sustainable.sustainableOffers.basket.post({
				offer: {
					reference,
					shares: sharesToBuy,
					project_code,
					identifier: offer.identifier,
					address: selectedAddress.address,
				},
				basket: offers,
			});
			dispatch({
				type: 'TOGGLE_LOADING',
			});
			dispatch({
				type: 'ADD_OFFER',
				basket: response,
			});
		} catch {
			dispatch({
				type: 'TOGGLE_LOADING',
			});
		}
	};

	const handleInput = (value, min, max) => {
		const newValue = Math.min(Math.max(value, min), max);
		setSharesToBuy(newValue);
	};
	const handleRetractInput = (value, min, max) => {
		const newValue = Math.min(Math.max(value, min), max);
		setSharesToRetract(newValue);
	};

	return (
		<Accordion
			TransitionProps={{ unmountOnExit: true }}
			sx={{
				boxShadow: theme.customShadows.z16,
			}}>
			<AccordionSummary
				expandIcon={<ExpandMoreIcon />}
				aria-controls="panel1a-content"
				id="panel1a-header">
				<Typography variant="h5">
					{offer.private_offer && (AddressChange())}
					{`${project_code} - ${project_name}`}
				</Typography>
			</AccordionSummary>
			<AccordionDetails>
				<Box mb={2}>
					<LineItem label={translations.numberOfShares} value={shares} />
					{myOffer && status && (
						<LineItem
							label={translations.isOfferOnMartket}
							value={
								status === 'AVAILABLE' ? translations.yes : translations.no
							}
						/>
					)}
					{offer.offer_publication_type && (
						<LineItem
							label={translations.offerType}
							value={`${
								translations.offerPublicationType[
									`${offer.offer_publication_type || 'PUBLIC'}`
								]
							}`}
						/>
					)}
					{offer.direct_offer_code && (
						<LineItem
							label={translations.directOfferCode}
							value={`${offer.direct_offer_code}`}
						/>
					)}
					{ !offer.private_offer && (
						<LineItem
							label={translations.pricePerShare}
							value={`€ ${price_per_share}`}
						/>
					)}
					{date &&
						(myOffer ? (
							<LineItem
								label={translations.myDate}
								value={dateFormatter(date)}
							/>
						) : (
							<LineItem label={translations.date} value={dateFormatter(date)} />
						))}
					<LineItem
						label={translations.project}
						value={`${project_code} - ${project_name}`}
					/>
					{!offer.private_offer && project_end_date && (
						<LineItem
							label={translations.projectEndDate}
							value={dateFormatter(project_end_date)}
						/>
					)}
					{myOffer && offer.participation_identifier && (
						<LineItem
							label={translations.participation_identifier}
							value={`${offer.participation_identifier}`}
						/>
					)}
					{!offer.private_offer && (
						<LineItem
							label={translations.reference}
							value={`${offer.reference}`}
						/>						
					)}
					{offer.address && (
						<AddressLineItem
							label = {translations.adres}
							address={offer.address}
						/>
					)}
					{offer.old_address && (
						<AddressLineItem
							label = {translations.old_address}
							address={offer.old_address}
						/>
					)}
					{status && (
						<LineItem
							label={translations.status.label}
							value={translations.status[`${status}`]}
						/>
					)}

					{rejection_reason && (
						<LineItem
							label={translations.reason.label}
							value={translations.reason[`${rejection_reason}`]}
						/>
					)}
				</Box>
				{myOffer &&
					retract &&
					!['COMPLETED', 'RESERVED', 'TRANSFERRED', 'CLOSED'].includes(
						status
					) && (
						<Stack spacing={2} direction="row">
							<TextField
								id="outlined-basic"
								label={translations.retractNumberOfUnits}
								variant="outlined"
								type="number"
								value={sharesToRetract}
								size="small"
								onChange={(e) => handleRetractInput(e.target.value, 1, shares)}
							/>
							<Button
								loading={marketPlaceState.isLoading}
								variant="outlined"
								size="small"
								color="error"
								onClick={() =>
									retract({
										...offer,
										retracted_shares: sharesToRetract,
										remaining_shares: shares - sharesToRetract,
									})
								}>
								{translations.retractOffer}
							</Button>
						</Stack>
					)}

				{!myOffer && (
					<Stack spacing={2} direction="row">
						<TextField
							disabled={myOffer}
							id="outlined-basic"
							label={translations.sharesToBuy}
							variant="outlined"
							type="number"
							value={sharesToBuy}
							size="small"
							onChange={(e) => handleInput(e.target.value, 1, shares)}
						/>
						<FormControl
							disabled={myOffer}
							sx={{
								width: 300,
							}}
							required
							margin="normal">
							<InputLabel htmlFor="project-code-select" size="small">
								Adres
							</InputLabel>
							<Select
								id="project-code-select"
								label="Adres"
								size="small"
								value={address}
								onChange={(e) => setAddress(e.target.value)}>
								{addresses.map((a) => (
									<MenuItem key={a.value} value={a.value}>
										{`${a.label}`}
									</MenuItem>
								))}
							</Select>
						</FormControl>
						<Button
							disabled={!address}
							loading={marketPlaceState.isLoading}
							variant="outlined"
							size="small"
							onClick={() => addToBasket()}>
							{translations.addToCart}
						</Button>
					</Stack>
				)}
			</AccordionDetails>
		</Accordion>
	);
}
