import React from 'react';
import PropTypes from 'prop-types';

import './date-period.css';

const DatePeriod = ({ periodStart, periodEnd }) => {
	const startYear = periodStart && new Date(periodStart).getFullYear();
	const endYear = periodEnd && new Date(periodEnd).getFullYear();

	return (
		<span className="date-period">
			{startYear === endYear || !endYear
				? startYear
				: `${startYear} - ${endYear}`}
		</span>
	);
};

export default DatePeriod;

DatePeriod.propTypes = {
	periodStart: PropTypes.string,
	periodEnd: PropTypes.string,
};
