const addNotifications = (state = [], action) => {
	switch (action.type) {
		case 'ADD_NOTIFICATION':
			state.push([action.notification, action.cssClass]);
			return state;
		case 'REMOVE_NOTIFICATION':
			state.shift();
			return state;
		default:
			return state;
	}
};

export default addNotifications;

const getNotifications = state => state.notifications;

export const selector = {
	getNotifications,
};

export const action = {
	add: (dispatch, notification, cssClass) => {
		setTimeout(function() {
			dispatch({ type: 'REMOVE_NOTIFICATION' });
		}, 6000);

		dispatch({ type: 'ADD_NOTIFICATION', notification, cssClass });
	},
};
