import { isLabelSustainable, isLabelWind } from './constants';
import isMobileView from './utils/is-mobile-view';

export default (translations, code = null) => {
	const {
		menu,
		postCodestRoomcooperatief,
		marketPlace,
		sustainable,
	} = translations;

	const participationMenu =
		code === '1071'
			? {
					title: postCodestRoomcooperatief.yourParticipation,
					uri: '/participation',
			  }
			: {
					title: postCodestRoomcooperatief.yourParticipation,
					children: [
						{
							uri: '/participation',
							title: postCodestRoomcooperatief.yourParticipation,
						},
						{
							uri: '/participation/move-shares',
							title: sustainable.moveShares.title,
						},
					],
			  };
	if (code !== null && isLabelSustainable(code)) {
		const menuItems = [
			{
				uri: '/overview',
				title: menu.production.title,
			},
			participationMenu,
			{
				title: marketPlace.title,
				children: [
					{ uri: '/offers', title: marketPlace.menu.availaleOffers },
					{ uri: '/personal-offers', title: marketPlace.menu.personalOffers },
					{ uri: '/my-offers', title: marketPlace.menu.myOffers },
					{ uri: '/my-orders', title: marketPlace.menu.myOrders },
					{
						uri: '/my-offers/create-offer',
						title: marketPlace.menu.createOffer,
					},
				],
			},
		];
		if (isLabelWind(code)) {
			menuItems.push({
				uri: '/notifications',
				title: translations.notifications.title,
			});
		}
		menuItems.push({
			uri: '/contact',
			title: postCodestRoomcooperatief.contact.title,
		});
		return menuItems;
	} else {
		return [
			isMobileView() && {
				uri: '/dashboard',
				title: menu.dashboard.title,
			},
			{
				uri: '/usage',
				title: menu.usage.title,
			},
			{
				title: menu.finance.title,
				children: [
					{
						uri: '/invoices',
						title: menu.finance.menu.invoices,
					},
					{
						uri: '/agreements',
						title: menu.finance.menu.agreements,
					},
					{
						uri: '/periodical-payments',
						title: menu.finance.menu.monthlyAmount,
					},
				],
			},
			{
				title: menu.administrative.title,
				children: [
					{ uri: '/move', title: menu.administrative.menu.move },
					{
						uri: '/iban',
						title: menu.administrative.menu.iban,
					},
				],
			},
			{
				uri: '/contact',
				title: menu.contact.title,
			},
		].filter(Boolean);
	}
};

export const getMyAccountMenu = (translations) => {
	const { menu } = translations;
	return [
		{
			title: menu.myAccount.title,
			icon: 'account_circle',
			children: [
				{
					uri: '/account',
					title: menu.myAccount.menu.account.title,
					icon: 'assignment_ind',
				},
			],
		},
	];
};
