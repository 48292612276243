const initialState = {
	value: '',
	name: 'select-name',
	required: true,
	validity: null,
};

const select = (state = initialState, action) => {
	switch (action.type) {
		case 'CONTACT_SELECT_VALUE':
			return Object.assign({}, state, { value: action.value });
		case 'CONTACT_SELECT_VALIDITY':
			return Object.assign({}, state, { validity: action.value });
		case 'CONTACT_RESET':
			return initialState;
		default:
			return state;
	}
};

export default select;

export const action = {
	value: value => ({ type: 'CONTACT_SELECT_VALUE', value }),
	validity: value => ({
		type: 'CONTACT_SELECT_VALIDITY',
		value,
	}),
};
