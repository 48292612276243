import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { selector } from '../../reducers/data/agreements';
import invoices from '../../api/invoices';

import Row from '../../components/row';
import PageTitle from '../../components/page-title';
import Collapsible from '../../components/collapsible';
import AgreementInvoices from './agreement-invoices/agreement-invoices';
import Spinner from '../../components/spinner';

import { action as invoicesAction } from '../../reducers/data/invoices';
import { action as collapsibleAction } from '../../reducers/ui/invoices';

import './invoices.css';
import AgreementTitle from '../../components/agreement-title/agreement-title';
import DatePeriod from '../../components/date-period/date-period';
import group from '../../utils/group';

class Invoices extends Component {
	constructor(props) {
		super(props);

		this.dispatch = this.props.dispatch;
		this.invoices = invoices;
		this.selector = selector;
	}

	componentDidMount() {
		if (this.props.agreements && this.props.agreements.length) {
			this.openCollapsible(
				this.props.agreements[0].agreement_identifier_with_sequence
			);
		}
	}

	componentDidUpdate(prevProps) {
		if (this.isAgreementsDataReady(prevProps)) {
			this.openCollapsible(
				this.props.agreements[0].agreement_identifier_with_sequence
			);
		}
	}

	isAgreementsDataReady = (prevProps) =>
		this.props.agreements &&
		this.props.agreements.length &&
		!(prevProps.agreements && prevProps.agreements.length);

	toggleCollapsible = (id) => {
		this.getInvoiceById(id);
		return this.dispatch(collapsibleAction.toggle(id));
	};

	openCollapsible = (id) => {
		this.getInvoiceById(id);
		return this.dispatch(collapsibleAction.open(id));
	};

	getInvoiceById = (id) => {
		if (!this.props.invoicesMap || !this.props.invoicesMap[id]) {
			this.invoices
				.get(id)
				.then((response) => {
					this.dispatch(invoicesAction.data(response, id));
				})
				.catch((err) => {
					this.dispatch(invoicesAction.data([], id));
				});
		}
	};

	render() {
		const { translations, agreements, expand, invoicesMap } = this.props;

		if (!agreements) {
			return <Spinner />;
		}

		return (
			<div className="view-invoices">
				<Row>
					<PageTitle>{translations.title}</PageTitle>
				</Row>

				{agreements &&
					agreements.map((agreement, index) => {
						const invoices =
							invoicesMap[agreement.agreement_identifier_with_sequence];
						return (
							<Collapsible
								className={'mobile-wide'}
								key={`${index}-${agreement.agreement_identifier}`}
								title={
									<AgreementTitle
										agreementIdentifier={agreement.agreement_identifier}
										agreementName={agreement.name}
										agreementPeriod={agreement.period}
										withIcon={false}
										prefix={translations.agreementSingular}
									/>
								}
								id={`${agreement.agreement_identifier_with_sequence}`}
								expand={expand.includes(
									`${agreement.agreement_identifier_with_sequence}`
								)}
								toggleCallback={this.toggleCollapsible.bind(this)}>
								<div className="invoices__agreement-details">
									{agreement.name && (
										<span className="invoices__agreement-name">
											{agreement.name}
										</span>
									)}
									{agreement.period && (
										<span className="invoices__agreement-period">
											<DatePeriod
												periodStart={agreement.period.from}
												periodEnd={agreement.period.to}
											/>
										</span>
									)}
								</div>
								<AgreementInvoices
									dispatch={this.dispatch}
									expand={expand}
									translations={translations}
									invoices={this.getInvoicesGroupedPerYear(invoices)}
									agreementId={agreement.agreement_identifier_with_sequence}
								/>
							</Collapsible>
						);
					})}
			</div>
		);
	}

	sortInvoicesByDueDateDesc = (groupedInvoices) => {
		return groupedInvoices.map((invoices) => {
			return invoices.items.sort(
				(a, b) => new Date(b.due_date) - new Date(a.due_date)
			);
		});
	};

	getInvoicesGroupedPerYear = (invoices) => {
		if (!invoices) {
			return null;
		}

		const grouped = group(
			invoices,
			(item) => new Date(item.due_date).getFullYear(),
			(key, item) => ({ year: key, items: [] }),
			(group, item) => group.items.push(item)
		);

		grouped.sort((a, b) => b.year - a.year);
		this.sortInvoicesByDueDateDesc(grouped);

		return grouped;
	};

	getAgreementPeriod = (agreement, period) => {
		return agreement.period && agreement.period[period]
			? agreement.period[period].split('-')[0]
			: '';
	};
}

Invoices.propTypes = {
	/** Object containing the translations for this view and the general
	 * translations combined */
	translations: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
	return {
		ui: state.ui.invoices,
		agreements: selector.getAgreementsOrderedByStartDate(state),
		invoicesMap: state.data.invoices,
		translations: Object.assign(
			state.translations.invoices,
			state.translations.general
		),
		expand: state.ui.invoices.collapsibles,
	};
}

export default connect(mapStateToProps)(Invoices);
