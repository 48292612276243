import { createReducer, createActions } from '../collapsibles';

const initialState = {
	collapsibles: [],
};

const collapsibleReducer = createReducer('ACCOUNT');

export default (state = initialState, action) => {
	return Object.assign({}, state, {
		collapsibles: collapsibleReducer(state.collapsibles, action),
	});
};

export const action = createActions('ACCOUNT');
