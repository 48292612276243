import React from 'react';
import PropTypes from 'prop-types';

import './relation-summary.css';

const RelationSummary = ({
	salutation,
	initials,
	prefix,
	last_name: lastName,
	debtor_number_label: debtorNumberLabel,
	debtor_number: debtorNumber,
}) => (
	<div>
		<span>
			{initials} {prefix} {lastName}
		</span>
	</div>
);

export default RelationSummary;

RelationSummary.propTypes = {
	salutation: PropTypes.string,
	initials: PropTypes.string,
	prefix: PropTypes.string,
	last_name: PropTypes.string,
	debtor_number_label: PropTypes.string,
	relation_since: PropTypes.any,
	debtor_number: PropTypes.string,
};
