import React from 'react';
import PropTypes from 'prop-types';
import kebabCase from 'lodash/kebabCase';

import './collapsible.css';

/**
 *
 */
const Collapsible = ({
	className,
	expand = true,
	title,
	id,
	toggleCallback = () => {},
	hideArrow,
	children,
}) => (
	<article
		id={id || kebabCase(title)}
		className={`collapsible light-bg-color ${
			expand ? 'collapsible--expanded' : ''
		} ${className || ''}`.trim()}>
		<header className="collapsible__header">
			<div className="collapsible__header-background" />
			<div
				className="collapsible__header-button"
				onClick={() => toggleCallback(id || kebabCase(title))}>
				{title && <h3>{title}</h3>}
				{!hideArrow && <i className="material-icons">keyboard_arrow_down</i>}
			</div>
		</header>
		<div className="collapsible__content">{children}</div>
	</article>
);

export default Collapsible;

Collapsible.propTypes = {
	expand: PropTypes.bool,
	title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
	/**
	 *	@param {String} id the id of the clicked collapsible
	 */
	/**
	 * The id given to the html element
	 */
	id: PropTypes.string,
	toggleCallback: PropTypes.func,
	hideArrow: PropTypes.bool,
};
