export default phoneNumber => {
	if (!phoneNumber) {
		return;
	}
	return phoneNumber.includes('+3131')
		? phoneNumber.replace('+3131', '0')
		: phoneNumber.includes('+31')
			? phoneNumber.replace('+31', '0')
			: phoneNumber;
};
