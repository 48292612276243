import React from 'react';
import PropTypes from 'prop-types';

const ESCAPE_KEY = 27;

/**
 * Makes the given component 'closeable' by responding to the escape key and
 * calling the onClose property.
 */
const closeableByKeyboard = Component => {
	return class extends React.Component {
		onKeyDown = e => {
			if (
				e.keyCode === ESCAPE_KEY &&
				typeof this.props.onClose === 'function'
			) {
				this.props.onClose();
			}
		};

		addKeyBindings() {
			document.documentElement.addEventListener(
				'keydown',
				this.onKeyDown,
				false
			);
		}

		removeKeyBindings() {
			document.documentElement.removeEventListener(
				'keydown',
				this.onKeyDown,
				false
			);
		}

		componentWillReceiveProps(newProps) {
			if (newProps.isShown !== this.props.isShown) {
				newProps.isShown ? this.addKeyBindings() : this.removeKeyBindings();
			}
		}

		componentWillUnmount() {
			this.removeKeyBindings();
		}

		componentWillMount() {
			if (this.props.isShown === undefined || !!this.props.isShown) {
				this.addKeyBindings();
			}
		}

		render() {
			return <Component {...this.props} />;
		}
	};
};

export default closeableByKeyboard;

closeableByKeyboard.propTypes = {
	isShown: PropTypes.bool,
	onClose: PropTypes.func,
};
