import React, { useEffect, useState } from 'react';
import './email-confirmation.css';
import api from '../../api';
import Spinner from '../spinner';
import { useSelector, useDispatch } from 'react-redux';
import LangSwitch from '../lang-switch';
import isUuidValid from 'uuid-validate';

const getUuidFromUrl = (url) => url.substring(url.lastIndexOf('/') + 1);

const EmailConfirmation = ({ logo, info }) => {
	const states = {
		unverified: false,
		verified: false,
		alreadyVerified: false,
	};
	const translations = useSelector((state) => state.translations);
	const dispatch = useDispatch();
	const [verify, setVerify] = useState(states);

	useEffect(() => {
		const uuid = getUuidFromUrl(window.location.href);
		if (!isUuidValid(uuid)) {
			setVerify({ ...states, unverified: true });
			return;
		}
		api.emailConfirmation
			.post(uuid)
			.then((res) => {
				return res.status === 200
					? setVerify({ ...states, verified: true, unverified: false })
					: setVerify({ ...states, unverified: true });
			})
			.catch((err) =>
				err.response.status === 412
					? setVerify({ ...states, alreadyVerified: true, unverified: false })
					: setVerify({ ...states, unverified: true })
			);
		// eslint-disable-next-line
	}, []);

	return (
		<div className="email-confirmation">
			<LangSwitch dispatch={dispatch} translations={translations} />
			<a href={window.location.origin}>
				<div className="logo">
					{logo && <img src={logo} alt={info.name || 'Logo'} />}
				</div>
			</a>
			{verify.verified ? (
				<div className="message">
					<p>{translations.verifyEmail.verifiedMessage}</p>
					<p>{translations.verifyEmail.contactMessage}</p>
				</div>
			) : verify.alreadyVerified ? (
				<div className="message">
					{translations.verifyEmail.alreadyVerifiedMessage}
				</div>
			) : verify.unverified ? (
				<div className="error-message">
					{translations.verifyEmail.unverifiedMessage}
				</div>
			) : (
				<Spinner />
			)}

			<footer>
				<span>{info.name}</span>
				<span>{info.email}</span>
				<span>{info.phone_number}</span>
			</footer>
		</div>
	);
};

export default EmailConfirmation;
