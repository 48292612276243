import React from 'react';
import FieldSet from '../fieldset';
import isEmailValid from '../../utils/is-email-valid';
import Button from '../button';
import Spinner from '../spinner';
import './reactivate-account.css';
import api from '../../api';
import { AuthPiece } from 'aws-amplify-react';

class ReactivateAccount extends AuthPiece {
	constructor(props) {
		super(props);
		this.state = {
			...this.state,
			email: '',
			invalidEmail: false,
			waitingForResponse: false,
			showEmailSentMessage: false,
		};
	}

	handleInputChanges() {
		return (event) => {
			if (isEmailValid(event.target.value)) {
				this.setState({ email: event.target.value });
				this.setState({ invalidEmail: false });
			} else {
				this.setState({ invalidEmail: true });
			}
		};
	}

	sendActivationMail() {
		this.setState({ waitingForResponse: true });
		api.reactivateAccount
			.begin(this.state.email, this.props.labelKey)
			.finally(() => {
				this.setState({ waitingForResponse: false });
				this.setState({ showEmailSentMessage: true });
			});
	}

	render() {
		const { translations } = this.props;
		return (
			<div className="reactivate-account">
				<p>{translations.reactivateAccount.explainMessage}</p>
				<FieldSet
					name="email"
					placeholder={translations.reactivateAccount.placeholderText}>
					<input id="email" onChange={this.handleInputChanges()} type="email" />
				</FieldSet>
				{this.state.invalidEmail && (
					<span className="error-message">
						{translations.reactivateAccount.invalidEmailMessage}
					</span>
				)}
				{!this.state.showEmailSentMessage ? (
					<Button
						kind="primary"
						type="button"
						disabled={
							!this.state.email ||
							this.state.invalidEmail ||
							this.state.waitingForResponse
						}
						onClick={() => this.sendActivationMail()}>
						{translations.reactivateAccount.buttonText}
						{this.state.waitingForResponse && <Spinner />}
					</Button>
				) : (
					<div className="email-sent">
						{translations.reactivateAccount.successMessage}
					</div>
				)}
			</div>
		);
	}
}

export default ReactivateAccount;
