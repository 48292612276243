import React, { Component } from 'react';

import Row from '../../components/row';
import AgreementTitle from '../../components/agreement-title';
import Panel from '../../components/panel';
import PageTitle from '../../components/page-title';
import Collapsible from '../../components/collapsible';
import Card from '../../components/card';
import Table from '../../components/table';
import UsageRow from './usage-row';
import Spinner from '../../components/spinner';
import UsageSwitch from './usage-switch';

import getAtLeastOneSmartMeter from '../../utils/get-at-least-one-smart-meter';

import { action as collapsibleAction } from '../../reducers/ui/panels';

import './usage.css';
import { connect } from 'react-redux';
import { selector } from '../../reducers/data/agreements';

class Usage extends Component {
	dispatch = this.props.dispatch;

	constructor(props) {
		super(props);

		this.state = {
			delivery: {},
		};
	}

	toggleCollapsible = (id) => this.dispatch(collapsibleAction.toggle(id));
	openCollapsible = (id) => this.dispatch(collapsibleAction.open(id));
	closeAllCollapsible = () => this.dispatch(collapsibleAction.closeAll());

	componentDidMount() {
		const { agreements, selectedAgreement } = this.props;
		this.closeAllCollapsible();
		if (selectedAgreement) {
			this.openCollapsible(selectedAgreement);
		} else {
			if (agreements && agreements.length) {
				this.openCollapsible(agreements[0].agreement_identifier);
			}
		}
	}

	componentDidUpdate(prevProps) {
		if (
			this.isAgreementsDataReady(prevProps) &&
			!this.props.selectedAgreement
		) {
			this.openCollapsible(this.props.agreements[0].agreement_identifier);
		}
	}

	isAgreementsDataReady = (prevProps) =>
		this.props.agreements &&
		this.props.agreements.length &&
		!(prevProps.agreements && prevProps.agreements.length);

	getHouseNumber = (address) =>
		address.house_number_extension
			? `${address.house_number} ${address.house_number_extension}`
			: address.house_number;

	cardTitle = (address) => {
		return (
			<div className="flex-row">
				<i className="material-icons">home</i>&nbsp;
				{`${address.street}
				${this.getHouseNumber(address)} |
				${address.zip_code} |
				${address.city}`}
			</div>
		);
	};

	tableRows = (connections, checked) => {
		const { translations } = this.props;

		return connections.map((connection, index) => (
			<UsageRow
				connection={connection}
				translations={translations}
				delivery={this.state.delivery}
			/>
		));
	};

	changeDelivery = (sequence) => {
		if (this.state.delivery && this.state.delivery[sequence]) {
			this.setState({
				delivery: {
					...this.state.delivery,
					[sequence]: !this.state.delivery[sequence],
				},
			});
		} else {
			this.setState({ delivery: { ...this.state.delivery, [sequence]: true } });
		}
	};

	render() {
		const { translations, agreements, expand } = this.props;

		if (!agreements) {
			return <Spinner />;
		}

		return (
			<div className="view-usage">
				<Row>
					<PageTitle>{translations.title}</PageTitle>
				</Row>
				{agreements.map((agreement) => (
					<Collapsible
						key={agreement.agreement_identifier}
						title={
							<AgreementTitle
								agreementIdentifier={agreement.agreement_identifier}
								prefix={translations.agreementSingular}
								withIcon={false}
							/>
						}
						id={agreement.agreement_identifier}
						expand={expand.includes(agreement.agreement_identifier)}
						toggleCallback={this.toggleCollapsible}>
						{expand.includes(agreement.agreement_identifier) && (
							<Panel transparent>
								{getAtLeastOneSmartMeter(
									agreement.addresses,
									'ELECTRICITY'
								) && (
									<UsageSwitch
										delivery={this.state.delivery}
										agreement={agreement}
										translations={translations}
										changeDelivery={this.changeDelivery}
									/>
								)}
								{agreement.addresses.map((address, index) => {
									return (
										<Card
											title={this.cardTitle(address)}
											key={`${address.house_number}-${address.zip_code}-${index}`}>
											<Panel transparent>
												<Table
													items={this.tableRows(address.connections)}
													headers={translations.usageTable.headers}
													className="usage__table"
												/>
											</Panel>
										</Card>
									);
								})}
							</Panel>
						)}
					</Collapsible>
				))}
			</div>
		);
	}
}

function mapStateToProps(state) {
	return {
		agreements: selector.getAgreementsWithGroupedConnectionsOrderedByStartDate(
			state
		),
		expand: state.ui.panels.collapsibles,
		translations: Object.assign(
			state.translations.usage,
			state.translations.general
		),
		usage: state.data.usage,
	};
}

export default connect(mapStateToProps)(Usage);
