import calculateLegibleButtonColor from './utils/calculate-legible-button-color';

const createColorCodes = (style, name, hex) => {
	style.setProperty(name, hex);
	style.setProperty(`${name}RGB`, hex);
};

// eslint-disable-next-line
const hexColorToRGB = (hexVal) => {
	// turn 3-HEX to 6-HEX
	if (hexVal.length === 3) {
		hexVal =
			hexVal[1] + hexVal[1] + hexVal[2] + hexVal[2] + hexVal[3] + hexVal[3];
	}

	// extracting the hex values for RGB
	var red = hexVal.substr(1, 2),
		green = hexVal.substr(3, 2),
		blue = hexVal.substr(5, 2);

	// converting in decimal values
	var red10 = parseInt(red, 16),
		green10 = parseInt(green, 16),
		blue10 = parseInt(blue, 16);

	// stitching it together
	var rgb = red10 + ',' + green10 + ',' + blue10;

	// the final rgba CSS ouptut
	return rgb;
};

export function applyLabelStyle(styleResponse) {
	const style = document.documentElement.style;
	createColorCodes(style, '--primary-color', styleResponse.primary_color);
	createColorCodes(style, '--secondary-color', styleResponse.secondary_color);
	createColorCodes(style, '--tertiary-color', styleResponse.tertiary_color);
	createColorCodes(style, '--highlight-color', styleResponse.header_bg_color);
	createColorCodes(
		style,
		'--table-even-row-background-color',
		styleResponse.table_even_row_background_color
	);
	createColorCodes(
		style,
		'--table-odd-row-background-color',
		styleResponse.table_odd_row_background_color
	);
	createColorCodes(
		style,
		'--email-button-background-color',
		styleResponse.email_button_background_color
	);
	createColorCodes(
		style,
		'--email-button-font-color',
		styleResponse.email_button_font_color
	);
	createColorCodes(
		style,
		'--primary-color-text-color',
		styleResponse.primary_color_text_color ||
			calculateLegibleButtonColor(styleResponse.primary_color)
	);
}

export function setFavicon(label) {
	const faviconLink = document.getElementById('theme-favicon');
	faviconLink.parentElement.removeChild(faviconLink);
	faviconLink.href = `/api/v0/labels/${label.label_key}/favicon`;
	document.head.appendChild(faviconLink);
}
