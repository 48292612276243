import agreements from '../../api/agreements';

export default (state = agreements.periodical.emptyResponse, action) => {
	switch (action.type) {
		case 'AGREEMENTS_PERIODICAL_DATA':
			return action.data;
		case 'AGREEMENTS_PERIODICAL_SET':
			return Object.assign({}, state, {
				request_periodical_payment_amount: action.value,
			});
		default:
			return state;
	}
};

export const action = {
	data: data => ({ type: 'AGREEMENTS_PERIODICAL_DATA', data }),
	set: value => ({ type: 'AGREEMENTS_PERIODICAL_SET', value }),
};
