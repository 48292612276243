import React from 'react';
import PropTypes from 'prop-types';

import './address.css';

/**
 * Accepts a list of addresses, displays the one with `address_type` matching
 * the `type` prop or (if a fallbackType is provided) the one with `address_type` matching the `fallbackType` prop
 */
const Address = ({ addresses, type, fallbackType }) => {
	let address = addresses.find(address => {
		if (address.address_type) {
			return address.address_type === type;
		}
		return true;
	});

	if (!address && fallbackType) {
		address = addresses.find(address => address.address_type === fallbackType);
	}

	if (!address) {
		address = {};
	}

	return (
		<p className="address">
			{address.street} {address.house_number} {address.house_number_extension}
			<br />
			{address.zip_code} {address.city}
		</p>
	);
};

export default Address;

Address.propTypes = {
	addresses: PropTypes.arrayOf(
		PropTypes.shape({
			/** One of: 'INVOICE', 'VISIT', 'SHIPPING', 'POSTAL' */
			address_type: PropTypes.string,
			city: PropTypes.string,
			house_number: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
			/** Example: II or b or 2 Hoog , */
			house_number_extension: PropTypes.string,
			street: PropTypes.string,
			zip_code: PropTypes.string,
		})
	),
	type: PropTypes.oneOf(['INVOICE', 'VISIT', 'SHIPPING', 'POSTAL']).isRequired,
	fallbackType: PropTypes.oneOf(['INVOICE', 'VISIT', 'SHIPPING', 'POSTAL']),
};
