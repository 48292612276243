import React, { useState, useEffect, useCallback } from 'react';
import { Field, reduxForm, formValueSelector } from 'redux-form';
import { connect } from 'react-redux';
import Panel from '../../components/panel';
import Input from '../../components/input';
import Fieldset from '../../components/fieldset';
import withPlatform from '../../components/with-platform';
import withTranslationsGeneral from '../../components/with-translations-general';
import { validateStep1 as validate } from '../../utils/move-wizard-validation';
import getAddressCommodityMap from '../../utils/get-address-commodity-map';
import Spinner from '../spinner';
import api from '../../api';
import './move-wizard-step-1.scss';
import Icon from '../icon';
import Home from '../icons/home';
import LightbulbOutline from '../icons/lightbulb-outline';
import checkBoxIcon from '../icons/check-box';
import checkBoxEmptyIcon from '../icons/check-box-outline-blank';
import Flame from '../icons/flame';
import Select from 'react-select';
import isMobileView from '../../utils/is-mobile-view';
import CardReveal from '../card-reveal';
import { AddressForDisplay } from '../move-wizard/move-wizard';
let dispatch, change;

const requiredFields = (input) => {
	return (
		input.name === 'new_address.zip_code' ||
		input.name === 'new_address.house_number'
	);
};

const updateNewAddress = (address) => {
	dispatch(change('new_address', address));
};

const updateResidentalFlag = (residentialFlag) => {
	dispatch(change('is_residential', residentialFlag));
};

const updateAltAddresses = (addresses, connections) => {
	dispatch(
		change(
			'alternative_addresses',
			addresses.map((address) => {
				return {
					...address,
					connections,
				};
			})
		)
	);
};

export const input = withTranslationsGeneral(
	withPlatform(
		({
			input,
			label,
			type,
			meta: { valid, touched, active, error },
			platform,
			translationsGeneral,
		}) => {
			let fieldType = input.value || active ? type : 'text';
			fieldType = platform === 'ios' ? type : fieldType;
			fieldType =
				input.name === 'new_address.house_number' ? 'number' : fieldType;
			const pattern = fieldType === 'number' ? { pattern: '\\d*' } : {};
			return (
				<Fieldset
					className={`move-wizard__fieldset ${
						input.name.split('.')[1]
					} text-area-placeholder`}
					label={label}
					focus={type === 'date' ? true : active}
					hasValue={!!input.value}
					type={fieldType}
					required={requiredFields(input)}
					validity={!valid && touched ? 'invalid' : null}
					error={!valid && touched && translationsGeneral.error[error]}
					{...input}
					{...pattern}>
					<Input />
				</Fieldset>
			);
		}
	)
);

const CheckBox = ({ label, ...rest }) => {
	return (
		<div className="checkbox">
			<label>
				<input
					type="checkbox"
					input={rest.input}
					onChange={rest.input?.onChange}
					checked={rest.checked}
				/>
				<Icon className="unchecked" size="small" icon={checkBoxEmptyIcon} />
				<Icon className="checked" size="small" icon={checkBoxIcon} />
				{label}
			</label>
		</div>
	);
};

const AcceptedAddress = ({ address, translation }) => (
	<div className="accepted-address-wrapper">
		<div className="accepted-address">
			<label>
				<Icon size="small" className="home" icon={Home} />
				<span>{translation.searchedAddressTitle}</span>
			</label>
			<AddressForDisplay
				className="accepted-address-text"
				address={address}
				twoLine
			/>
		</div>
		<div className="connections">
			<div className="title">{translation.availableConnections}</div>
			<div className="connection-icons">
				{address.connections.electricity && (
					<div className="connection">
						{address.connections.electricity.length > 1 && (
							<span className="number-of-connections">
								{address.connections.electricity.length}
							</span>
						)}
						<Icon size="small" className="home" icon={LightbulbOutline} />
						<label>
							{translation.electricity}{' '}
							{address.connections.electricity.length > 1
								? translation.connections
								: translation.connection}
						</label>
					</div>
				)}
				{address.connections.gas && (
					<div className="connection">
						{address.connections.gas.length > 1 && (
							<span className="number-of-connections">
								{address.connections.gas.length}
							</span>
						)}
						<Icon size="small" className="home" icon={Flame} />
						<label>
							{translation.gas}{' '}
							{address.connections.gas.length > 1
								? translation.connections
								: translation.connection}
						</label>
					</div>
				)}
			</div>
		</div>
	</div>
);

const SelectedOldAddress = ({ address, getCommodityIcons }) => (
	<div className="address-wrapper">
		<div className="address">
			<Icon size="inline" className="home" icon={Home} />
			<AddressForDisplay className="address-text" address={address} />
		</div>
		<div className="connection-icons">
			{getCommodityIcons(getAddressCommodityMap(address))}
		</div>
	</div>
);

const shouldAddressBeChecked = (newAddress, name) =>
	isChangeTriggeredFromCorrectFields(name) &&
	newAddress.zip_code &&
	newAddress.house_number;

const isChangeTriggeredFromCorrectFields = (fields) =>
	fields.includes('zip_code') || fields.includes('house_number');

const doesAddressContainMultipleConnectionsOfSameType = (formValues) => {
	return (
		formValues.newAddress &&
		formValues.newAddress.connections &&
		Object.values(formValues.newAddress.connections).some(
			(eans) => eans.length > 1
		)
	);
};
const doesAddressContainDifferentConnections = ({ newAddress, oldAddress }) => {
	if (
		newAddress &&
		newAddress.connections &&
		Object.keys(newAddress.connections).length
	) {
		const oldCommoditys = getCommoditiesFromOldAddress(oldAddress);
		const newCommoditys = getCommoditiesFromNewAddress(newAddress);

		if (oldCommoditys.size !== newCommoditys.size) {
			if (oldCommoditys.size > newCommoditys.size) {
				return false;
			}
			return true;
		} else {
			const _intersection = new Set();
			for (const elem of newCommoditys) {
				if (oldCommoditys.has(elem)) {
					_intersection.add(elem);
				}
			}
			return _intersection.size === 0;
		}
	}

	// return true;
};

const getCommoditiesFromOldAddress = (oldAddress) => {
	if (
		oldAddress &&
		oldAddress.connections &&
		oldAddress.connections.length > 0
	) {
		const commodities = oldAddress.connections?.map((connection) => {
			return connection.commodity.toLowerCase();
		});
		const commoditySet = new Set(commodities);
		return commoditySet;
	}
};

const getCommoditiesFromNewAddress = (newAddress) => {
	if (
		newAddress &&
		newAddress.connections &&
		Object.keys(newAddress.connections).length > 0
	) {
		return new Set(Object.keys(newAddress.connections));
	}
};
const isOneAgreement = (agreements) => agreements && agreements.length === 1;

let MoveWizardStep1 = (props) => {
	const {
		translations,
		formValues,
		handleSubmit,
		getCommodityIcons,
		previousPage,
		agreements,
		isStepValid,
	} = props;

	const [state, setState] = useState({
		addressLoading: false,
		selectedExtensionValue: '',
		showAltAddresses: false,
		showAddressErrorMessage: false,
	});

	const {
		selectedExtensionValue,
		addressLoading,
		showAltAddresses,
		showAddressErrorMessage,
	} = state;

	const isFormValid = () => {
		return (
			props.valid &&
			!doesAddressContainMultipleConnectionsOfSameType(formValues) &&
			!doesAddressContainDifferentConnections(formValues) &&
			!showAddressErrorMessage &&
			formValues.newAddress.city &&
			formValues.newAddress.street
		);
	};
	// eslint-disable-next-line
	const isFormValidCb = useCallback(isFormValid, [
		formValues,
		showAddressErrorMessage,
	]);

	useEffect(() => {
		isStepValid(1, !!isFormValidCb());
	}, [
		isStepValid,
		isFormValidCb,
		formValues.newAddress.zip_code,
		formValues.newAddress.house_number,
	]);

	const text = translations.form.step1;
	dispatch = props.dispatch;
	change = props.change;
	const addressFields = [
		{
			label: text.postalCode,
			name: 'new_address.zip_code',
			component: input,
		},
		{
			label: text.houseNumber,
			name: 'new_address.house_number',
			component: input,
		},
	];

	if (isOneAgreement(agreements)) {
		dispatch(props.change('old_address', agreements[0].addresses[0]));
	}

	const updateState = (update) => {
		setState({
			...state,
			...update,
		});
	};

	const selectAltAddress = ({ value }) => {
		updateState({ selectedExtensionValue: value });
		handleExistingAddress(
			formValues.alternativeAddresses.find(
				(address) => address.houseNumberExtension === value
			)
		);
	};

	const handleAlternativeAddresses = (altAddresses, connections) => {
		updateAltAddresses(altAddresses, connections);
		updateState({ showAltAddresses: true });
		if (showAddressErrorMessage) {
			updateState({ showAddressErrorMessage: false });
		}
	};

	const handleExistingAddress = (address, connections) => {
		updateNewAddress({
			house_number: address.houseNumber,
			house_number_extension: address.houseNumberExtension,
			zip_code: address.zipCode,
			street: address.street,
			city: address.city,
			...((connections || address.connections) && {
				connections: connections || address.connections,
			}),
		});
		if (showAddressErrorMessage) {
			updateState({ showAddressErrorMessage: false });
		}
	};

	const clearNewAddress = () => {
		updateState({ selectedExtensionValue: '' });
		updateAltAddresses([]);
		updateNewAddress({
			...formValues.newAddress,
			house_number_extension: '',
			street: '',
			city: '',
			connections: '',
		});
	};

	const handleNonExistingAddress = () => {
		if (showAltAddresses) {
			updateState({ showAltAddresses: false });
		}
		updateState({ showAddressErrorMessage: true });
	};

	const checkForAddress = (newAddress, name) => {
		const { zip_code, house_number } = newAddress;
		clearNewAddress();
		if (shouldAddressBeChecked(newAddress, name)) {
			updateState({ addressLoading: true });
			api.address
				.get(zip_code, house_number)
				.then((res) => {
					updateState({ addressLoading: false });
					if (res.alternatives && res.alternatives.length) {
						handleAlternativeAddresses(res.alternatives, res.eansPerCommodity);
					} else if (
						res.address &&
						(!res.alternatives || !res.alternatives.length)
					) {
						handleExistingAddress(res.address, res.eansPerCommodity);
					} else {
						handleNonExistingAddress();
					}
				})
				.catch((err) => {
					console.error(err);
					updateState({
						addressLoading: false,
						showAltAddresses: false,
						showAddressErrorMessage: true,
					});
				});
		}
	};

	const handleResidential = (residentialFlag) => {
		updateResidentalFlag(residentialFlag);
	};

	return (
		<div className="move-wizard-step-1">
			<Panel
				rightLinkText={formValues.oldAddress && translations.next}
				leftLinkText={!isOneAgreement(agreements) ? translations.previous : ''}
				leftButtonHandler={previousPage}
				rightButtonHandler={isFormValid() ? handleSubmit : null}>
				<div className="selected-address">
					<h3>{text.title}</h3>
					<SelectedOldAddress
						address={formValues.oldAddress}
						getCommodityIcons={getCommodityIcons}
					/>
				</div>
				<form>
					<section className="move-wizard__section">
						<div className="title">
							<h3>{text.newAddress}</h3>
							{addressLoading && <Spinner />}
						</div>
						<p>{text.findAddress}</p>
						<div className="address-fields">
							<CardReveal
								minHeight="230"
								revelOn={
									!!formValues.newAddress.city && !showAddressErrorMessage
								}>
								<div className="address-fields-input">
									<p>{text.newAddressMsg}</p>
									{addressFields.map((field, index) => (
										<div
											className={`address-field ${field.name.split('.')[1]}`}
											key={field.name}>
											<label>{field.label}</label>
											<Field
												name={field.name}
												type="text"
												loading={addressLoading}
												component={field.component}
												onBlur={(event, newValue, previousValue, name) =>
													checkForAddress(formValues.newAddress, name, dispatch)
												}
											/>
										</div>
									))}
									<div className="extension-dropdown">
										<Select
											className="additions"
											value={
												selectedExtensionValue
													? {
															value: selectedExtensionValue,
															label: selectedExtensionValue,
													  }
													: null
											}
											classNamePrefix="additions"
											controlShouldRenderValue={!showAddressErrorMessage}
											placeholder={
												!isMobileView() ? text.selectExtensionPlaceholder : ''
											}
											options={formValues.alternativeAddresses.map(
												(address) => ({
													value: address.houseNumberExtension,
													label: address.houseNumberExtension,
												})
											)}
											isSearchable={true}
											isDisabled={
												!formValues.alternativeAddresses.length ||
												showAddressErrorMessage
											}
											onChange={selectAltAddress}
										/>
									</div>
									{showAddressErrorMessage && (
										<div className="error-message">
											{translations.form.step1.nonExistingAddressError}
										</div>
									)}
									{formValues.alternativeAddresses.length &&
									!showAddressErrorMessage &&
									!formValues.newAddress.house_number_extension ? (
										<div className="multiple-addition">
											{translations.form.step1.multipleAddressFound}
										</div>
									) : (
										''
									)}
									{doesAddressContainMultipleConnectionsOfSameType(
										formValues
									) && (
										<div className="multiple-connections error-message">
											{text.multipleAddressConnection}
										</div>
									)}
									{doesAddressContainDifferentConnections(formValues) && (
										<div className="multiple-connections error-message">
											{text.differentAddressConnection}
										</div>
									)}
								</div>
								{formValues.newAddress.city && !showAddressErrorMessage && (
									<AcceptedAddress
										address={formValues.newAddress}
										translation={text}
									/>
								)}
							</CardReveal>
						</div>
						<div className="additional-move">
							<Field
								name="is_residential"
								label={text.residentialAddress}
								type="checkbox"
								onChange={(e) => handleResidential(!!e.target.checked)}
								format={(e) => !!e}
								checked={formValues.isResidential}
								component={CheckBox}
							/>
							<Field
								name="newPostalAddress"
								label={text.movePostalAddress}
								type="checkbox"
								format={(e) => !!e}
								component={CheckBox}
							/>
							<Field
								name="newInvoiceAddress"
								label={text.moveInvoiceAddress}
								type="checkbox"
								format={(e) => !!e}
								component={CheckBox}
							/>
						</div>
					</section>
				</form>
			</Panel>
		</div>
	);
};

MoveWizardStep1 = reduxForm({
	form: 'move-wizard',
	destroyOnUnmount: false,
	forceUnregisterOnUnmount: false,
	getFormState: (state) => state.ui.move.wizard,
	validate,
})(MoveWizardStep1);

const selector = formValueSelector(
	'move-wizard',
	(state) => state.ui.move.wizard
);
MoveWizardStep1 = connect((state) => {
	const oldAddress = selector(state, 'old_address') || '';
	const newAddress = selector(state, 'new_address') || '';
	const newPostalAddress = selector(state, 'newPostalAddress') || false;
	const newInvoiceAddress = selector(state, 'newInvoiceAddress') || false;
	const alternativeAddresses = selector(state, 'alternative_addresses') || [];
	const isResidential = selector(state, 'is_residential') ?? true;
	return {
		formValues: {
			oldAddress,
			newAddress,
			alternativeAddresses,
			newPostalAddress,
			newInvoiceAddress,
			isResidential,
		},
	};
})(MoveWizardStep1);

export default MoveWizardStep1;
