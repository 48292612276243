import { Component } from 'react';
import { withRouter } from 'react-router';
import PropTypes from 'prop-types';

/**
 * This component notifies when the location changes. It does not render
 * anything.
 */
class LocationNotifier extends Component {
	constructor(props) {
		super(props);
		props.history.listen(props.onLocationChange);
	}

	render() {
		return null;
	}
}

export default withRouter(LocationNotifier);

LocationNotifier.propTypes = {
	/**
	 * The callback to listen for location changes
	 * @return {Object} A location object as described [here](https://reacttraining.com/react-router/web/api/location)
	 */
	onLocationChange: PropTypes.func.isRequired,
};
