import { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { OffersAccordion } from './components/offers-accordion';

import api from '../../api';

import { action as collapsibleAction } from '../../reducers/ui/participation';
import {
	Typography,
	Stack,
	Skeleton,
	Pagination,
	Select,
	MenuItem,
	InputLabel,
	FormControl,
} from '@mui/material';
import { DoNotDisturb } from '@mui/icons-material';

function AvailabeOffers({ translations, expand, relations, labels }) {
	const [loading, setLoading] = useState(true);
	const [data, setData] = useState([]);
	const [addresses, setAddresses] = useState([]);
	const [projects, setProjects] = useState(null);
	const [page, setPage] = useState(1);
	const [projectCode, setProjectCode] = useState('');

	const dispatch = useDispatch();

	const toggleCollapsible = (reference) =>
		dispatch(collapsibleAction.toggle(reference));

	const stringfy = (value) => String(value);

	const handleProjectCode = (value) => {
		if (projectCode !== value) {
			setPage(1);
		}
		setProjectCode(value);
	};

	useEffect(() => {
		async function fetchData() {
			setLoading(true);

			try {
				const projects = await api.sustainable.get();

				const addresses = projects
					.map((project) =>
						project.participations.map((participation) => {
							const { address } = participation;
							const {
								city,
								house_number,
								house_postfix,
								postal_code,
								street_name,
							} = address;

							const label = `${street_name || ''} ${house_number ||
								''} ${house_postfix || ''}, ${postal_code || ''} ${city || ''}`;
							const value = `${stringfy(postal_code)}${stringfy(
								house_number
							)}${stringfy(house_postfix)}`;

							return (
								address && {
									label,
									value,
									address,
								}
							);
						})
					)
					.flat()
					.filter((v, i, s) => s.findIndex((sa) => sa.value === v.value) === i);

				setAddresses(addresses);

				const response = await api.sustainable.sustainableOffers.marketPlace();
				setData(response);
				setLoading(false);
			} catch (error) {
				if (error.response.status === 404) {
					const relationAddresses = relations.addresses
						.filter((address) => address.address_type === 'SHIPPING')
						.map((address) => {
							const {
								city,
								house_number,
								house_number_extension,
								zip_code,
								street,
							} = address;

							return {
								...address,
								label: `${street || ''} ${house_number ||
									''} ${house_number_extension || ''}, ${zip_code ||
									''} ${city || ''}`,
								value: `${stringfy(zip_code)}${stringfy(
									house_number
								)}${stringfy(house_number_extension)}`,
								address: {
									city,
									house_number,
									house_postfix: house_number_extension,
									postal_code: zip_code,
									street_name: street,
								},
							};
						});
					setAddresses(relationAddresses);
				} else {
					throw error;
				}
			}
		}
		fetchData();
	}, [relations]);

	useEffect(() => {
		async function fetchMarkt() {
			setLoading(true);
			const response = await api.sustainable.sustainableOffers.marketPlace(
				projectCode ? projectCode : null,
				page
			);
			setData(response);
			setLoading(false);
		}
		fetchMarkt();
	}, [projectCode, page]);

	useEffect(() => {
		api.sustainable.sustainableOffers.marketProjects
			.get()
			.then((response) => {
				const options = response.map((project) => ({
					label: `${project.project_code} - ${project.project_name} (${project.offer_count})`,
					...project,
				}));
				setProjects(options);
			})
			.catch((err) => {
				console.error(err);
			});
	}, []);

	const marketDisclaimer = () => {
		const labelKey = labels.id.label_key;
		if (labelKey === '1024' || labelKey === '1071') {
			return translations[`marketMessage_${labelKey}`];
		}
		return '';
	};

	return (
		<div className="w-full px-4 pt-16">
			<Stack
				direction={{ xs: 'column', sm: 'row' }}
				justifyContent="space-between"
				alignItems="center"
				spacing={1}>
				<Stack>
					<Typography variant="h4">{translations.title}</Typography>
				</Stack>
				<Stack
					direction={{ xs: 'column', sm: 'row' }}
					alignItems="center"
					spacing={2}
					height={50}>
					<FormControl>
						<InputLabel htmlFor="participation_identifier" size="small">
							Project
						</InputLabel>
						<Select
							id="participation_identifier"
							name="participation_identifier"
							label="Project"
							placeholder="Project"
							size="small"
							sx={{
								minWidth: 150,
							}}
							autoWidth
							value={projectCode}
							onChange={(event) => handleProjectCode(event.target.value)}>
							<MenuItem value={''}>All</MenuItem>
							{projects &&
								projects.map((project) => {
									return [
										<MenuItem
											key={project.project_code}
											value={project.project_code}>
											{`${project.label}`}
										</MenuItem>,
									];
								})}
						</Select>
					</FormControl>
					<Pagination
						size="small"
						page={page}
						onChange={(event, number) => setPage(number)}
						count={data.totalPages}
					/>
				</Stack>
			</Stack>
			<Stack mt={5}>
				<Typography variant="body1">{marketDisclaimer()}</Typography>
			</Stack>
			{loading && (
				<>
					<Skeleton height={52} />
					<Skeleton height={52} />
					<Skeleton height={52} />
				</>
			)}

			{!loading && data.content.length === 0 && (
				<Stack
					direction="column"
					justifyContent="space-between"
					alignItems="center"
					spacing={2}
					height={50}>
					<DoNotDisturb />
					<Typography variant="h4">{translations.noOffers}</Typography>
				</Stack>
			)}

			{!loading &&
				data.content.length > 0 &&
				data.content.map((offer) => (
					<OffersAccordion
						myOffer={offer.myOffer}
						translations={translations}
						expand={expand.includes(offer.reference)}
						toggle={toggleCollapsible}
						key={offer.identifier}
						offer={offer}
						addresses={addresses}
					/>
				))}
		</div>
	);
}

function mapStateToProps(state) {
	return {
		translations: Object.assign(
			state.translations.marketPlace,
			state.translations.general
		),
		isMobile: state.ui.mobileView,
		expand: state.ui.participation.collapsibles,
		marketplace: state.marketplace,
		relations: state.data.relations,
		labels: state.data.labels,
	};
}

export default connect(mapStateToProps)(AvailabeOffers);
