import React from 'react';
import Panel from '../../components/panel';
import Icon from '../../components/icon';
import Done from '../../components/icons/done';
import Error from '../../components/icons/error';

import './move-wizard-step-4.scss';

const MoveWizardStep4 = (props) => (
	<div className="move-wizard-step-4">
		<Panel title={props.translations.form.step4.title} transparent>
			<Icon icon={props.done ? Done : Error} size={'large'} />
			{props.done ? (
				<div>
					<p>{props.translations.form.step4.thankYou}</p>
					<p>{props.translations.form.step4.info}</p>
				</div>
			) : (
				<div>
					<p>{props.translations.form.step4.error5xx}</p>
				</div>
			)}
		</Panel>
	</div>
);

export default MoveWizardStep4;
