import React from 'react';
import Currency from '../currency';
import Slider from '../slider';
import PropTypes from 'prop-types';

import './slider-labeled.css';

/**
 * SliderLabeled component is used to display a slider with marker values.
 * The min and max values are required and displayed at the bottom of the slider.
 * The markers property allows you to specify a list of values and labels which are displayed at the top of the slider.
 */
const SliderLabeled = (props) => {
	const {
		value = 0,
		step = 0.01,
		min,
		max,
		markers = null,
		className = '',
		showValue,
		onChange = () => {},
	} = props;

	const getMarkerLinePos = (value) => {
		if (value >= max) {
			return 'slider-labeled__marker-value--line-end';
		} else if (value <= min) {
			return 'slider-labeled__marker-value--line-start';
		}
		return '';
	};

	const getPosition = (value) => ((value - min) / (max - min)) * 100;

	const getMarkerPos = (value) => {
		if (value >= max) {
			return {
				marginLeft: 'auto',
			};
		} else {
			return {
				marginLeft: getPosition(value).toFixed(2) + '%',
			};
		}
	};

	const createMarkers = () => {
		return Array.isArray(markers)
			? markers.map(({ value, label }, index) => {
					const position = getPosition(value);
					const side = position > 50 ? 'right' : 'left';
					return (
						<div
							key={index}
							className={`slider-labeled__marker ${getMarkerLinePos(value)}`}
							style={getMarkerPos(value)}>
							<button
								onClick={() => onChange(parseFloat(value).toFixed(2))}
								className={`slider-labeled__marker-block slider-labeled__marker-block--${side} highlight-bg-color`}>
								<small className="slider-labeled__marker-label">{label}</small>
								<Currency amount={value} />
							</button>
						</div>
					);
			  })
			: null;
	};

	return (
		<div className={'slider-labeled ' + className}>
			<div className="slider-labeled__markers slider-labeled__markers--top">
				{createMarkers()}
			</div>

			<Slider
				min={min}
				max={max}
				value={parseFloat(value)}
				step={step}
				onChange={onChange}
				showValue={showValue}
			/>

			<div className="slider-labeled__markers slider-labeled__markers--bottom">
				{min !== undefined && (
					<div className="slider-labeled__marker slider-labeled__marker--min slider-labeled__marker--line-start">
						<Currency amount={min} />
					</div>
				)}
				{false && (
					<div className="slider-labeled__marker slider-labeled__marker--center">
						<Currency amount={value} />
					</div>
				)}
				{max !== undefined && (
					<div className="slider-labeled__marker slider-labeled__marker--max slider-labeled__marker--line-end">
						<Currency amount={max} />
					</div>
				)}
			</div>
		</div>
	);
};

export default SliderLabeled;

SliderLabeled.propTypes = {
	onChange: PropTypes.func,
	value: PropTypes.any,
	step: PropTypes.number,
	min: PropTypes.number.isRequired,
	max: PropTypes.number.isRequired,
	markers: PropTypes.arrayOf(
		PropTypes.shape({
			value: PropTypes.number.isRequired,
			label: PropTypes.string,
		})
	),
};
