const initialState = {
	value: '',
	focus: false,
	name: 'input-name',
	required: true,
	validity: null,
};

const inputfield = (state = initialState, action) => {
	switch (action.type) {
		case 'CONTACT_INPUT_FIELD_VALUE':
			return Object.assign({}, state, { value: action.value });
		case 'CONTACT_INPUT_FIELD_FOCUS':
			return Object.assign({}, state, { focus: true });
		case 'CONTACT_INPUT_FIELD_BLUR':
			return Object.assign({}, state, { focus: false });
		case 'CONTACT_INPUT_FIELD_VALIDITY':
			return Object.assign({}, state, { validity: action.value });
		case 'CONTACT_RESET':
			return initialState;
		default:
			return state;
	}
};

export default inputfield;

export const action = {
	value: value => ({ type: 'CONTACT_INPUT_FIELD_VALUE', value }),
	focus: () => ({ type: 'CONTACT_INPUT_FIELD_FOCUS' }),
	blur: () => ({ type: 'CONTACT_INPUT_FIELD_BLUR' }),
	validity: value => ({ type: 'CONTACT_INPUT_FIELD_VALIDITY', value }),
};
