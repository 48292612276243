import React from 'react';
import Panel from '../components/panel';
import closeableOverlay from '../components/closeable-overlay';
import LightbulbOutline from '../components/icons/lightbulb-outline';
import Flame from '../components/icons/flame';
import Icon from '../components/icon';
import Clear from '../components/icons/clear';
import Table from '../components/table';
import DateTime from '../components/date-time';
import { FORMAT_DMY, FORMAT_NO_DATE } from '../components/date-time/date-time';
import RenderValue from '../components/render-value';
import Spinner from '../components/spinner';

import api from '../api';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

class UsageHistoryDetails extends React.Component {
	connection = JSON.parse(atob(this.props.match.params.id));
	constructor(props) {
		super(props);
		const context = this;

		this.state = {
			usage: [],
			dataInCsv: null,
		};

		props.hideOverlayTitle();

		api.usage
			.get(
				this.connection.agreement_identifier,
				this.connection.location_id,
				this.connection.ean_code
			)
			.then((response) => context.setState({ usage: response }))
			.catch((err) => console.log(err));

		api.privacy
			.getHistoricalData(this.connection.ean_code)
			.then((response) => {
				if (Number(response.headers['content-length']) >= 80) {
					context.setState({ dataInCsv: response.data });
				}
			})
			.catch((err) => context.setState({ dataInCsv: null }));
	}

	cancel = () => {
		this.props.onClose();
	};

	getTitle = () => {
		const { translations } = this.props;
		return (
			<React.Fragment>
				<h2 className="flex-inline">
					{this.getCommodityIcon(this.connection.commodity)}
					<span>{translations.usageHistory.title}</span>
				</h2>
				<button onClick={this.cancel}>
					<Icon icon={Clear} size="small" className="overlay__close-icon" />
				</button>
			</React.Fragment>
		);
	};

	getCommodityIcon = (commodity) => {
		return commodity === 'ELECTRICITY' ? (
			<Icon icon={LightbulbOutline} size="small" color="rgba(0, 0, 0, 0.54)" />
		) : (
			<Icon icon={Flame} size="small" color="rgba(0, 0, 0, 0.54)" />
		);
	};

	getTableHeaders = () => {
		const { translations } = this.props;
		return (
			<React.Fragment>
				<tr>
					<th />
					<th colSpan="2">
						{this.connection.delivery[this.connection.agreement_identifier]
							? translations.usageHistory.historyTable.headers[0].production
							: translations.usageHistory.historyTable.headers[0].usage}
					</th>
				</tr>
				<tr>
					<th>{translations.usageHistory.historyTable.headers[0].date}</th>
					<th>{translations.usageHistory.historyTable.headers[0].standard}</th>
					<th>{translations.usageHistory.historyTable.headers[0].low}</th>
				</tr>
			</React.Fragment>
		);
	};

	sortUsageByDateDesc(usage) {
		return usage.sort(
			(a, b) => new Date(b.measurement_date) - new Date(a.measurement_date)
		);
	}

	tableRows = () => {
		return (
			this.state.usage &&
			this.sortUsageByDateDesc(this.state.usage).map((usage) => {
				return (
					<tr>
						<td>
							<DateTime
								date={usage.measurement_date}
								format={usage.measurement_date ? FORMAT_DMY : FORMAT_NO_DATE}
							/>
						</td>
						<td>{this.getReadings(usage, 'normal')}</td>
						<td>{this.getReadings(usage, 'low')}</td>
					</tr>
				);
			})
		);
	};

	getReadings = (usage, reading) => {
		return this.connection.delivery[this.connection.agreement_identifier]
			? this.getReadingsValue(usage[`feedin_${reading}`])
			: this.getReadingsValue(usage[`consumption_${reading}`]);
	};

	getReadingsValue = (value) => {
		const commodityUnitOfMesure = this.getCommodityUnitOfMesure(
			this.connection.commodity
		);

		return value ? (
			value + ' ' + commodityUnitOfMesure
		) : (
			<RenderValue value="" />
		);
	};

	getCommodityUnitOfMesure = (commodity) => {
		const { translations } = this.props;
		return commodity === 'GAS' ? translations.unit.M3N : translations.unit.KWH;
	};

	getConnectionMeterType = (meterType) => {
		const { translations } = this.props;
		return meterType === 'SMART'
			? translations.smartMeter
			: translations.conventionalMeter;
	};

	render() {
		const { translations } = this.props;

		return (
			<Panel
				name="usage-history-details"
				additionalClass="usage-history-panel"
				headerContent={this.getTitle()}
				leftLinkText={translations.usageHistory.cancel}
				leftButtonHandler={this.cancel}>
				<div className="usage-history">
					<div className="history-table-wrapper">
						<h3>{translations.usageHistory.connectionHistory}</h3>
						{this.state.usage.length ? (
							<Table
								items={this.tableRows()}
								headerContent={this.getTableHeaders()}
								className="history-table-wrapper__table"
								showPagination
								itemsCountPerPage={10}
							/>
						) : (
							<Spinner />
						)}
					</div>
					<div>
						<h3>{translations.usageHistory.connectionDetails}</h3>
						<table className="table table-meter-details">
							<tbody>
								<tr>
									<td>{translations.ean}:</td>
									<td>{this.connection.ean_code}</td>
								</tr>
								<tr>
									<td>{translations.meterType}:</td>
									<td>
										{this.getConnectionMeterType(this.connection.meter_type)}
									</td>
								</tr>
								<tr>
									<td>{translations.meterNumber}:</td>
									<td>{this.connection.meter_number}</td>
								</tr>
								<tr>
									<td>{translations.capacity}:</td>
									<td>{<RenderValue value={this.connection.capacity} />}</td>
								</tr>
							</tbody>
						</table>
						{this.state.dataInCsv && (
							<a
								className="primary-text-color"
								href={`data:text/csv;charset=ISO-8859-1,${escape(
									this.state.dataInCsv
								)}`}
								download={`historical-data-${this.connection.ean_code}.csv`}>
								{translations.downloadHistoricalDataLabel}
							</a>
						)}
					</div>
				</div>
			</Panel>
		);
	}
}

function mapStateToProps(state) {
	return {
		translations: Object.assign(
			state.translations.usage,
			state.translations.general
		),
	};
}

export default withRouter(
	connect(mapStateToProps)(closeableOverlay(UsageHistoryDetails))
);
