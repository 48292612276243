import React, { Fragment } from 'react';
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useDispatch } from 'react-redux';
import _ from 'lodash';

import AddressBox from '../../components/address-box';
import AgreementLinksTooltip from '../../components/agreement-links-tooltip';
import Collapsible from '../../components/collapsible';
import ContactInfo from '../../components/contact-info';
import PrivacyInfo from '../../components/privacy-info';
import EditButton from '../../components/edit-button';
import DescriptionList from '../../components/description-list';
import Row from '../../components/row';
import Panel from '../../components/panel';
import PageTitle from '../../components/page-title';
import ErrorText from '../../components/error-text';
import RelationSummary from '../../components/relation-summary';
import Spinner from '../../components/spinner';
import convertPhoneNumber from '../../utils/convert-phone-number';
import './account.css';

import { selector } from '../../reducers/data/agreements';

import bankAccountDetailsApi from '../../api/bank.account.details';
import accountUsernameApi from '../../api/account-username';
import relationsApi from '../../api/relations';
import sustainableApi from '../../api/sustainable';
import privacyApi from '../../api/privacy';

import { action as collapsibleAction } from '../../reducers/ui/account';
import { action as bankAccountAction } from '../../reducers/data/bank.account.details';
import { action as accountUsernameAction } from '../../reducers/data/account-username';
import { selector as userInfoSelector } from '../../reducers/data/user-info';
import { SUST_LABELS } from '../../constants';
import {
	Alert,
	Divider,
	Snackbar,
	Stack,
	Step,
	StepLabel,
	Stepper,
} from '@mui/material';
import { ApartmentOutlined, CalendarTodaySharp } from '@mui/icons-material';
import { blue, green, orange } from '@mui/material/colors';
import { OfferComponent } from '../marketplace/components/offer';

export function Account(props) {
	const {
		translations,
		relations,
		labels,
		relationsPhoneNumber = {},
		relationsEmailAddress = {},
		accountUsernameUpdate = {},
		expand = [],
		agreements,
		bankAccountDetails,
		accountUsername,
		isUserImpersonator,
		marketTranslations,
	} = props;

	const dispatch = useDispatch();

	const [futureAddresses, setFutureAddresses] = useState([]);
	const [ordersToExpire, setOrdersToExpire] = useState([]);
	const [open, setOpen] = useState(false);
	const [severity, setSeverity] = useState('success');
	const [message, setMessage] = useState('');
	const [historicalConsent, sethistoricalConsent] = useState({});

	const [paylinkLoading, setPaylinkLoading] = useState(false);

	const toggleCollapsible = (id) => dispatch(collapsibleAction.toggle(id));
	const openCollapsible = (id) => dispatch(collapsibleAction.open(id));

	useEffect(() => {
		openCollapsible('contact-info');
		openCollapsible('orders');
		openCollapsible('shipping-address');
		// eslint-disable-next-line
	}, []);

	useEffect(() => {
		if (!SUST_LABELS.includes(labels.id.label_key)) {
			bankAccountDetailsApi
				.get()
				.then((response) => {
					dispatch(bankAccountAction.data(response));
				})
				.catch((err) => err);
		}
	}, [dispatch, labels.id.label_key]);

	useEffect(() => {
		accountUsernameApi
			.get()
			.then((response) => {
				dispatch(accountUsernameAction.data(response));
			})
			.catch((error) => error);
		// eslint-disable-next-line
	}, []);

	useEffect(() => {
		relationsApi.addresses
			.get()
			.then((response) => {
				setFutureAddresses(
					response.sort(
						(lhs, rhs) =>
							(lhs.participation_identifier || '').length -
							(rhs.participation_identifier || '').length
					)
				);
				if (futureAddresses?.length) {
					openCollapsible('future-addresses');
				}
			})
			.catch((e) => {
				setFutureAddresses([]);
			});
		// eslint-disable-next-line
	}, []);

	useEffect(() => {
		sustainableApi.sustainableOffers.orders.get().then((response) => {
			setOrdersToExpire(response);
		});
	}, []);

	useEffect(() => {
		if (agreements[0]?.agreements[0]?.agreement_identifier) {
			privacyApi
				.getHistoricalConsent(agreements[0].agreements[0].agreement_identifier)
				.then((respone) => {
					sethistoricalConsent(respone);
				});
		}
	}, [agreements, relations, labels]);

	const handleClose = (_, reason) => {
		if (reason === 'clickaway') {
			return;
		}

		setOpen(false);
	};

	const cancelOrder = async (order, event) => {
		event.stopPropagation();
		try {
			await sustainableApi.sustainableOffers.orders.cancel(
				order.deal_identifier
			);
			setMessage(translations.orderCancelled);
			setSeverity('success');
		} catch (err) {
			setSeverity('error');
			setMessage('Something went wrong');
			console.log(err);
		} finally {
			setOpen(true);
		}
	};

	const paylink = async (order, event) => {
		event.stopPropagation();
		setPaylinkLoading(true);
		try {
			const result = await sustainableApi.sustainableOffers.orders.paylink(
				order.deal_identifier
			);
			window.open(result, '_blank');
		} catch (err) {
			setSeverity('error');
			setMessage('Something went wrong');
			setOpen(true);
		} finally {
			setPaylinkLoading(false);
		}
	};

	const changedPhoneNumber = convertPhoneNumber(
		relationsPhoneNumber.phone_number
	);
	const changedEmailAddress = relationsEmailAddress.change_email;
	const changedAccountUsername = accountUsernameUpdate.change_username;

	return (
		<div className="view-account">
			<Snackbar
				open={open}
				autoHideDuration={5000}
				onClose={handleClose}
				anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
				<Alert onClose={handleClose} severity={severity} sx={{ width: '100%' }}>
					{message}
				</Alert>
			</Snackbar>
			<Row>
				<PageTitle>{translations.title}</PageTitle>
			</Row>

			<Collapsible
				id="contact-info"
				title={translations.collapsibles.contactInfo.title}
				toggleCallback={() => toggleCollapsible('contact-info')}
				expand={expand.includes('contact-info')}>
				<Panel name="contact-info" transparent>
					{relations && (
						<React.Fragment>
							{relations && (
								<RelationSummary
									{...relations}
									debtor_number_label={translations.clientNumber}
								/>
							)}
							<br />
							<ContactInfo
								phoneEditable={!changedPhoneNumber && !isUserImpersonator}
								emailEditable={!changedEmailAddress && !isUserImpersonator}
								accountUsernameEditable={
									!changedAccountUsername && !isUserImpersonator
								}
								editable
								relations={relations}
								accountUsername={accountUsername}
								editButtonTitle={translations.edit}
								changeEmailLink={'/account/change-email-address'}
								changePhoneNumberLink={'/account/change-phonenumber'}
								changeAccountPasswordLink={'/account/change-account-password'}
							/>
						</React.Fragment>
					)}

					{changedPhoneNumber && (
						<p>
							{translations.collapsibles.contactInfo.changedPhoneNumber}:{' '}
							<strong>{changedPhoneNumber}</strong>
						</p>
					)}

					{changedEmailAddress && (
						<p>
							{translations.collapsibles.contactInfo.changedEmail}:{' '}
							<strong>{changedEmailAddress}</strong>
						</p>
					)}

					{changedAccountUsername && (
						<p>
							{translations.collapsibles.contactInfo.changedAccountUsername}:{' '}
							<strong>{changedAccountUsername}</strong>
						</p>
					)}

					{!relations && (
						<ErrorText>
							{translations.error.introduction} {translations.error.noData}
						</ErrorText>
					)}
				</Panel>
			</Collapsible>
			{SUST_LABELS.includes(labels.id.label_key) && (
				<Collapsible
					id="orders"
					title={translations.collapsibles.orders.ordersToExpire}
					toggleCallback={() => toggleCollapsible('orders')}
					expand={expand.includes('orders')}>
					{ordersToExpire.filter((order) => order.status === 'AWAITING_PAYMENT')
						.length > 0 && (
						<Alert sx={{ width: '100%' }} severity="info">
							{marketTranslations.expireMessage}
						</Alert>
					)}
					{ordersToExpire.filter((order) => order.status === 'AWAITING_PAYMENT')
						.length > 0
						? ordersToExpire
								.filter((order) => order.status === 'AWAITING_PAYMENT')
								.map((order) => (
									<OfferComponent
										key={order.deal_reference}
										order={order}
										expand={expand}
										translations={marketTranslations}
										cancelOrder={cancelOrder}
										paylink={paylink}
										paylinkLoading={paylinkLoading}
									/>
								))
						: `${marketTranslations.noOrders}`}
				</Collapsible>
			)}
			{labels && labels.id && !SUST_LABELS.includes(labels.id.label_key) && (
				<Collapsible
					id="shipping-address"
					title={translations.collapsibles.shippingAddress.title}
					toggleCallback={() => toggleCollapsible('shipping-address')}
					expand={expand.includes('shipping-address')}>
					<Row>
						<Panel name="shipping-address" transparent>
							{!agreements.length && <Spinner />}
							{agreements &&
								_.chunk(agreements, 3).map((agrement, index) => (
									<div
										className="agreement-address-wrapper"
										key={`agreement-wrapper-${agrement.name}-${index}`}>
										{agrement.map((address) => (
											<AddressBox
												key={`agreement-${address.name}`}
												translations={translations}
												address={address}
												companies={address.agreements.map(
													(agreement) => agreement.company_name
												)}>
												<AgreementLinksTooltip
													agreements={address.agreements}
													tooltipId={address.name}
												/>
											</AddressBox>
										))}
									</div>
								))}
							{!relations && (
								<ErrorText>
									{translations.error.introduction} {translations.error.noData}
								</ErrorText>
							)}
						</Panel>
					</Row>
				</Collapsible>
			)}
			{relations && relations.company && (
				<Collapsible
					id="company-info"
					title={translations.collapsibles.companyInfo.title}
					toggleCallback={() => toggleCollapsible('company-info')}
					expand={expand.includes('company-info')}>
					<Panel name="company" transparent>
						<p>{relations.company.name}</p>
						<DescriptionList>
							<dt>{translations.cocNumber}</dt>
							<dd>{relations.company.coc_number}</dd>
							<dt>{translations.vatNumber}</dt>
							<dd>{relations.company.vat_number}</dd>
						</DescriptionList>
					</Panel>
				</Collapsible>
			)}

			<Collapsible
				id="privacy-info"
				title={translations.collapsibles.privacyInfo.title}
				toggleCallback={() => toggleCollapsible('privacy-info')}
				expand={expand.includes('privacy-info')}>
				<Panel name="privacy-info" transparent>
					{relations && (
						<PrivacyInfo
							historicalDataConsent={historicalConsent}
							relations={relations}
							privacyConsentButtonLabel={
								translations.collapsibles.privacyInfo.privacyConsentButtonLabel
							}
							privacyConsentTooltip={
								translations.collapsibles.privacyInfo.privacyConsentTooltip
							}
							privacyConsentHelper={
								translations.collapsibles.privacyInfo.privacyConsentHelper
							}
							privacyDataLoading={
								translations.collapsibles.privacyInfo.privacyDataLoading
							}
							privacyGiveConsent={
								translations.collapsibles.privacyInfo.privacyGiveConsent
							}
							privacyRevokeConsent={
								translations.collapsibles.privacyInfo.privacyRevokeConsent
							}
							privacySectionTitleHistorical={
								translations.collapsibles.privacyInfo
									.privacySectionTitleHistorical
							}
							privacyConsentGivenButtonLabel={
								translations.collapsibles.privacyInfo
									.privacyConsentGivenButtonLabel
							}
							privacyConsentNotGivenButtonLabel={
								translations.collapsibles.privacyInfo
									.privacyConsentNotGivenButtonLabel
							}
							privacyConsentDateLabel={
								translations.collapsibles.privacyInfo.privacyConsentDateLabel
							}
						/>
					)}
					{!relations && (
						<ErrorText>
							{translations.error.introduction} {translations.error.noData}
						</ErrorText>
					)}
				</Panel>
			</Collapsible>
			{labels && (
				<Collapsible
					id="payment-details"
					title={translations.collapsibles.paymentDetails.title}
					toggleCallback={() => toggleCollapsible('payment-details')}
					expand={expand.includes('payment-details')}>
					<Row>
						<Panel name="payment-details" transparent>
							{SUST_LABELS.includes(labels.id.label_key) && (
								<DescriptionList>
									<dt>
										{translations.collapsibles.paymentDetails.bankAccountName}
									</dt>
									<dd>{relations.bank_account_name}</dd>
									<dt>
										{translations.collapsibles.paymentDetails.debtorNumber}
									</dt>
									<dd>{relations.debtor_number}</dd>
									<dt>{translations.iban}</dt>
									<dd className="text-overflow edit-row">
										<span className="text-overflow">
											{relations.bank_account_number}
										</span>
										<span>
											<EditButton title={translations.edit} linkUrl={'/iban'} />
										</span>
									</dd>
								</DescriptionList>
							)}
							{!SUST_LABELS.includes(labels.id.label_key) &&
								bankAccountDetails && (
									<DescriptionList>
										<dt>
											{translations.collapsibles.paymentDetails.bankAccountName}
										</dt>
										<dd>{bankAccountDetails.bank_account_name}</dd>
										<dt>
											{translations.collapsibles.paymentDetails.debtorNumber}
										</dt>
										<dd>{relations.debtor_number}</dd>
										<dt>{translations.iban}</dt>
										<dd className="text-overflow edit-row">
											<span className="text-overflow">
												{bankAccountDetails.bank_account_number}
											</span>
											<span>
												<EditButton
													title={translations.edit}
													linkUrl={'/iban'}
												/>
											</span>
										</dd>
										<dt>{translations.paymentMethod}</dt>
										<dd>
											{
												translations.paymentMethods[
													bankAccountDetails.payment_method
												]
											}
										</dd>
									</DescriptionList>
								)}
							{!SUST_LABELS.includes(labels.id.label_key) &&
								!bankAccountDetails && (
									<ErrorText>
										{translations.error.introduction}{' '}
										{translations.error.noData}
									</ErrorText>
								)}
						</Panel>
					</Row>
				</Collapsible>
			)}
			{labels && labels.id && SUST_LABELS.includes(labels.id.label_key) && (
				<Collapsible
					id="future-addresses"
					title={translations.collapsibles.movings.title}
					toggleCallback={() => toggleCollapsible('future-addresses')}
					expand={expand.includes('future-addresses')}>
					<Panel name="future-addresses" transparent>
						{futureAddresses?.length === 0 && (
							<label>{translations.collapsibles.movings.noMoves}</label>
						)}
						{futureAddresses && futureAddresses.length >= 0 && (
							<Stack sx={{ width: '100%' }} spacing={4}>
								{futureAddresses.map((fa, i) => (
									<Fragment key={i}>
										<Stepper alternativeLabel>
											<Step active>
												<StepLabel
													StepIconComponent={() => (
														<ApartmentOutlined
															sx={{
																color: orange[500],
															}}
														/>
													)}>
													<Stack>
														<span>
															<b>
																{fa.participation_identifier ||
																	translations.collapsibles.movings
																		.contactAddress}
															</b>
														</span>
														<span>
															{fa.old_address.postal_code}{' '}
															{fa.old_address.house_number}{' '}
															{fa.old_address.house_number_extension || ''}
														</span>
														<span>
															{fa.old_address.street_name} {fa.old_address.city}
														</span>
														<span></span>
													</Stack>
												</StepLabel>
											</Step>
											<Step active>
												<StepLabel
													StepIconComponent={() => (
														<CalendarTodaySharp
															sx={{
																color: blue[500],
															}}
														/>
													)}>
													<Stack>
														<span>
															<b>
																{translations.collapsibles.movings.movingDate}
															</b>
														</span>
														<span>{formatDate(fa.date)}</span>
													</Stack>
												</StepLabel>
											</Step>
											<Step active>
												<StepLabel
													StepIconComponent={() => (
														<ApartmentOutlined
															sx={{
																color: green[500],
															}}
														/>
													)}>
													<Stack>
														<span>
															<b>
																{translations.collapsibles.movings.newAddress}
															</b>
														</span>
														<span>
															{fa.address.postal_code} {fa.address.house_number}{' '}
															{fa.address.house_number_extension || ''}
														</span>
														<span>
															{fa.address.street_name} {fa.address.city}
														</span>
														<span></span>
													</Stack>
												</StepLabel>
											</Step>
										</Stepper>
										<Divider />
									</Fragment>
								))}
							</Stack>
						)}
					</Panel>
				</Collapsible>
			)}
		</div>
	);
}

function mapStateToProps(state) {
	return {
		ui: state.ui.account,
		agreements: selector.getAgreementsGroupedByAddress(state),
		bankAccountDetails: state.data.bankAccountDetails,
		accountUsername: state.data.accountUsername.username,
		expand: state.ui.account.collapsibles,
		relations: state.data.relations,
		labels: state.data.labels,
		relationsPhoneNumber: state.data.relationsPhoneNumber,
		relationsEmailAddress: state.data.relationsEmailAddress,
		accountUsernameUpdate: state.data.accountUsernameUpdate,
		isUserImpersonator: userInfoSelector.isUserImpersonator(state.data),
		translations: Object.assign(
			state.translations.account,
			state.translations.general
		),
		marketTranslations: {
			...state.translations.marketPlace,
		},
	};
}

const formatDate = (date) =>
	date
		? new Date(date).toLocaleDateString('nl-NL', {
				day: '2-digit',
				month: '2-digit',
				year: 'numeric',
		  })
		: null;

export default connect(mapStateToProps)(Account);
