import React, { Fragment } from 'react';
import { ForgotPassword } from 'aws-amplify-react';
import Button from '../button';
import FieldSet from '../fieldset';
import Spinner from '../spinner';
import './cognito-forgot-password.css';
import { connect } from 'react-redux';
import LangSwitch from '../lang-switch';

class CognitoForgotPassword extends ForgotPassword {
	constructor(props) {
		super(props);
		this._validAuthStates = ['forgotPassword'];
		this.state = {
			...this.state,
			spinner: false,
			matched: false,
		};
	}

	preHandleInputChange = (e) => {
		this.handleInputChange(e);
		this.setState({
			matched: this.inputs.password === this.inputs.passwordConfirm,
		});
	};

	sendVerificationCode() {
		this.inputs.username = this.inputs.username
			? this.inputs.username.toLowerCase()
			: '';
		this.setState({ spinner: true });
		this.send();
	}

	error(err) {
		this.setState({ spinner: false });
		this.triggerAuthEvent({
			type: 'error',
			data: this.errorMessage(err),
		});
	}

	sendVerificationCodeView() {
		const { translations, email, phone } = this.props;
		return (
			<Fragment>
				{this.state.spinner && <Spinner />}
				<h1 className="title">{translations.forgotPassword.sendCodeTitle}</h1>
				<p>{translations.forgotPassword.enterUsernameText}</p>
				<form
					onKeyPress={(e) => e.key === 'Enter' && this.sendVerificationCode()}
					onSubmit={(e) => {
						e.preventDefault();
					}}>
					<FieldSet
						placeholder={translations.forgotPassword.username}
						name="username">
						<input
							autoFocus
							onChange={this.handleInputChange}
							key="username"
							type="text"
						/>
					</FieldSet>
					<p>
						{translations.forgotPassword.lostUsername}
						<a
							className="label-email"
							tabIndex="-1"
							href={`mailto:${email}`}
							target="_top">
							{email}
						</a>
						<a
							className="label-phone"
							tabIndex="-1"
							href={`tel:${phone}`}
							target="_top">
							{phone}
						</a>
					</p>
					<Button
						kind="primary"
						type="button"
						onClick={() => this.sendVerificationCode()}>
						{translations.forgotPassword.sendCode}
					</Button>
					<span
						className="back-to-signin"
						onClick={() => this.changeState('signIn')}>
						{translations.forgotPassword.backToSignInLink}
					</span>
				</form>
			</Fragment>
		);
	}

	newPasswordView() {
		const { translations } = this.props;

		if (this.state.spinner) {
			this.setState({ spinner: false });
		}
		return (
			<Fragment>
				<h1 className="title">
					{translations.forgotPassword.newPasswordTitle}
				</h1>
				<p>{translations.forgotPassword.verificationCodeText}</p>
				<form
					onKeyPress={(e) => e.key === 'Enter' && this.submit()}
					onSubmit={(e) => {
						e.preventDefault();
					}}>
					<FieldSet
						placeholder={translations.forgotPassword.securityCode}
						name="code">
						<input
							autoFocus
							type="text"
							autoComplete="off"
							key="code"
							onChange={this.handleInputChange}
						/>
					</FieldSet>
					<FieldSet
						placeholder={translations.forgotPassword.newPassword}
						name="password">
						<input
							type="password"
							onChange={this.preHandleInputChange}
							key="password"
						/>
					</FieldSet>
					<FieldSet
						name="passwordConfirm"
						placeholder={translations.passwordConfirm.placeholder}>
						<input
							id="passwordConfirm"
							onChange={this.preHandleInputChange}
							type="password"
						/>
					</FieldSet>
					<span>
						{!this.state.matched &&
						this.inputs.password &&
						this.inputs.passwordConfirm ? (
							<span className="passwords-not-match">
								{translations.passwordConfirm.matchMessage}
							</span>
						) : (
							<br />
						)}
					</span>
					<Button
						disabled={!this.state.matched}
						kind="primary"
						type="button"
						onClick={() => this.submit()}>
						{translations.forgotPassword.changePassword}
					</Button>
					<span className="resend-code" onClick={() => this.send()}>
						{translations.forgotPassword.resendCode}
					</span>
				</form>
			</Fragment>
		);
	}

	showComponent(theme) {
		const {
			authData = {},
			logo,
			labelName,
			email,
			dispatch,
			translations,
		} = this.props;

		return (
			<div className="cognito-forgot-password">
				<LangSwitch dispatch={dispatch} translations={translations} />
				<div className="logo" onClick={() => this.changeState('signIn')}>
					{logo && <img src={logo} alt={labelName || 'Logo'} />}
				</div>
				{this.state.delivery || authData.username
					? this.newPasswordView()
					: this.sendVerificationCodeView()}
				<footer>
					<span>{email}</span>
				</footer>
			</div>
		);
	}
}

function mapStateToProps(state, ownProps) {
	return Object.assign({}, { translations: state.translations }, ownProps);
}

const CognitoForgotPasswordConnected = connect(mapStateToProps)(
	CognitoForgotPassword
);
export default CognitoForgotPasswordConnected;
