import React, { Fragment, Component } from 'react';
import ReactTooltip from 'react-tooltip';
import PropTypes from 'prop-types';
import { renderToStaticMarkup } from 'react-dom/server';

import Icon from '../icon';
import ExpandMore from '../icons/expand-more';
import ExpandLess from '../icons/expand-less';

import './tooltip.css';

export default class Tooltip extends Component {
	constructor(props) {
		super(props);
		this.tooltipRef = null;
		this.arrowClasses = {
			top: 'top-arrow',
			bottom: 'bottom-arrow',
		};
	}

	getTooltipOffsetEdge = () => ({
		topOffset: 20,
		leftOffset: 20,
		bottomOffset: 20,
		rightOffset: 20,
	});

	isScrollPresent = nodeRef => nodeRef.scrollHeight > nodeRef.clientHeight;

	setTooltipRef = el => (this.tooltipRef = el.tooltipRef);

	// todo add html sanitization if tooltip content comes from user input.
	getTooltipContent = content =>
		renderToStaticMarkup(
			<Fragment>
				<Icon icon={ExpandLess} size="small" color="rgba(0, 0, 0, 0.54)" />
				<Icon icon={ExpandMore} size="small" color="rgba(0, 0, 0, 0.54)" />
				{content}
			</Fragment>
		);

	onTooltipScroll = e => {
		if (e.target.scrollTop > 20) {
			e.target.classList.add(this.arrowClasses.top);
		} else {
			e.target.classList.remove(this.arrowClasses.top);
		}
		if (
			e.target.scrollTop >
			e.target.scrollHeight - e.target.offsetHeight - 50
		) {
			e.target.classList.remove(this.arrowClasses.bottom);
		} else {
			e.target.classList.add(this.arrowClasses.bottom);
		}
	};

	removeScrollListener(tooltipRef) {
		tooltipRef.removeEventListener('scroll', this.onTooltipScroll);
	}

	addScrollIndicators(tooltipNodeRef, arrowClasses) {
		const { clientHeight, classList, scrollTop, childNodes } = tooltipNodeRef;
		if (this.isScrollPresent(tooltipNodeRef)) {
			classList.add(arrowClasses.bottom);
			if (scrollTop > 0) {
				classList.add(arrowClasses.top);
			}
			childNodes[1].style.top = clientHeight - 43 + 'px';
			tooltipNodeRef.addEventListener('scroll', this.onTooltipScroll);
		}
	}

	repositionTooltipVertical(tooltipRef, top, bottom) {
		const { style } = tooltipRef;
		const { topOffset, bottomOffset } = this.getTooltipOffsetEdge();
		if (top < 0) {
			style.top = `${topOffset}px`;
		} else if (bottom > window.innerHeight) {
			style.top = `${top - (bottom - window.innerHeight + bottomOffset)}px`;
		} else if (top < 0 && bottom > window.innerHeight) {
			style.top = `${topOffset}px`;
			style.bottom = `${bottomOffset}px`;
		}
	}

	repositionTooltipHorizontal(tooltipRef, left, right) {
		const { style } = tooltipRef;
		const { leftOffset, rightOffset } = this.getTooltipOffsetEdge();
		if (left < 0) {
			style.left = `${leftOffset}px`;
		} else if (right > window.innerWidth) {
			style.left = `${left - (right - window.innerWidth + rightOffset)}px`;
		} else if (left < 0 && right > window.innerWidth) {
			style.left = `${leftOffset}px`;
			style.right = `${rightOffset}px`;
		}
	}

	doesLeftOffsetExists(event, height, position) {
		return (
			window.innerHeight - event.y < height &&
			(position === 'bottom' || position === 'top') &&
			this.props.offset.left > 0
		);
	}

	// Used to adjust tooltip position when there is no space to place it in top or
	// bottom position and tooltip have offset value present.
	clearLeftOffset(tooltipRef, evt, height, width) {
		if (window.innerHeight > window.innerWidth) return;
		if (this.doesLeftOffsetExists(evt, height, this.props.position)) {
			tooltipRef.style.left = `${evt.clientX - width - 20}px`;
		}
	}

	adjustTooltip(tooltipRef, arrowClasses, evt) {
		const {
			top,
			left,
			bottom,
			right,
			height,
			width,
		} = tooltipRef.getBoundingClientRect();
		this.repositionTooltipVertical(tooltipRef, top, bottom);
		this.repositionTooltipHorizontal(tooltipRef, left, right);
		this.addScrollIndicators(tooltipRef, arrowClasses);
		this.clearLeftOffset(tooltipRef, evt, height, width);
	}

	render() {
		const { id, content, position, className, children, offset } = this.props;
		return (
			<ReactTooltip
				ref={el => el && this.setTooltipRef(el)}
				className={`extra-tooltip ${className}`}
				id={id}
				event={window.innerWidth <= 767 ? 'click' : ''}
				globalEventOff={window.innerWidth <= 767 ? 'click' : ''}
				place={position}
				effect="solid"
				type="light"
				html={true}
				isCapture={true}
				offset={offset}
				border={true}
				afterShow={evt =>
					this.adjustTooltip(this.tooltipRef, this.arrowClasses, evt)
				}
				afterHide={() => {
					this.removeScrollListener(this.tooltipRef, this.arrowClasses);
				}}
				delayHide={100}
				getContent={() => this.getTooltipContent(content || children)}
			/>
		);
	}
}

Tooltip.propTypes = {
	id: PropTypes.string.isRequired,
	content: PropTypes.any,
	position: PropTypes.oneOf(['left', 'bottom', 'top', 'right']),
	className: PropTypes.string,
	offset: PropTypes.object,
};
