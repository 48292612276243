import React from 'react';
import convertPhoneNumber from '../../utils/convert-phone-number';
import Icon from '../icon';
import IconPhone from '../icons/phone';

import './phonenumber.css';

/**
 * This component renders a Phonenumber
 */
const Phonenumber = ({ phone_number, children, isLink = false, iconSize }) => (
	<div className="phonenumber">
		<div>
			<Icon icon={IconPhone} size={iconSize || 'inline'} />
			{isLink ? (
				<a className="primary-text-color" href={`tel:${phone_number}`}>
					<span>{convertPhoneNumber(phone_number)}</span>
				</a>
			) : (
				<span>{convertPhoneNumber(phone_number)}</span>
			)}
		</div>
		{children}
	</div>
);

export default Phonenumber;

Phonenumber.propTypes = {};
