import React from 'react';
import PropTypes from 'prop-types';

import './fieldset.css';

/**
 * A wrapper around the HTML5 [fieldset](https://developer.mozilla.org/en-US/docs/Web/HTML/Element/fieldset)
 * element.
 *
 * Use it to wrap form elements. All props are passed to the children, so you
 * should place the `value`, `onFocus`, `onBlur` etc. props to the fieldset
 * instead of the child form elements
 */
const Fieldset = ({
	children,
	label,
	name,
	disabled,
	required,
	focus,
	hasValue,
	validity,
	className = '',
	error,
	...props
}) => {
	const focusClass = focus ? 'fieldset--has-focus' : '';
	const valueClass =
		hasValue || (props.value && props.value.length)
			? 'fieldset--has-value'
			: '';
	const requiredClass = required ? 'fieldset--is-required' : '';
	const validityClass = validity ? `fieldset--${validity}` : '';

	return (
		<fieldset
			className={`fieldset ${valueClass} ${focusClass} ${requiredClass} ${
				validityClass
			} ${className}`.trim()}
			disabled={disabled}
			required={required}>
			{label && (
				<label className="fieldset__label" htmlFor={name}>
					<span className="fieldset__label-text">{label}</span>
					{error && (
						<span className="fieldset__error-message">&nbsp;{error}</span>
					)}
				</label>
			)}

			{React.Children.map(children, child => {
				return React.cloneElement(
					child,
					Object.assign(
						{
							name,
							disabled,
							required,
							validity,
							...props,
						},
						child.props
					)
				);
			})}
		</fieldset>
	);
};

export default Fieldset;

Fieldset.propTypes = {
	name: PropTypes.string,
	label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
	disabled: PropTypes.bool,
	required: PropTypes.bool,
	className: PropTypes.string,
};
