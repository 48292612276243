// commodity = ELECTRICITY || GAS

export default (addresses, commodity) => {
	return addresses.find(address => {
		return address.connections.find(
			connection =>
				connection.commodity === commodity && connection.meter_type === 'SMART'
		);
	});
};
