import { useState, useEffect, useMemo } from 'react';
import closeableOverlay from '../../components/closeable-overlay';
import { connect, useDispatch } from 'react-redux';
import { withRouter } from 'react-router-dom';
import moment from 'moment';

import api from '../../api';

import { action as notificationsAction } from '../../reducers/notifications';

// MaterialUI
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import FormGroup from '@mui/material/FormGroup';
import Select from '@mui/material/Select';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';

import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';

import {
	ListSubheader,
	FormControlLabel,
	FormLabel,
	RadioGroup,
	Radio,
	TextField,
} from '@mui/material';

import { useFormik, FormikProvider, Form } from 'formik';

import DatePickerMobile from '../../components/formik-components/datepicker';
import nl from 'date-fns/locale/nl';

import * as yup from 'yup';

const stringfy = (value) => String(value);

const offerFormSchema = yup.object().shape({
	city: yup.string().when('address_type', {
		is: (value) => value && value.length > 0 && value === 'new',
		then: yup.string().required(),
	}),
	house_number: yup.string().when('address_type', {
		is: (value) => value && value.length > 0 && value === 'new',
		then: yup.string().required(),
	}),
	house_postfix: yup.string(),
	postal_code: yup.string().when('address_type', {
		is: (value) => value && value.length > 0 && value === 'new',
		then: yup.string().required(),
	}),
	street_name: yup.string().when('address_type', {
		is: (value) => value && value.length > 0 && value === 'new',
		then: yup.string().required(),
	}),
	number_of_shares: yup.number().required(),
	participation_identifier: yup.string().required(),
	address_type: yup.string().required(),
	address_key: yup.string().when('address_type', {
		is: (value) => value && value.length > 0 && value === 'existing',
		then: yup.string().required(),
	}),
	date: yup.string().required(),
});

function dateFormater(value) {
	const date = new Date(value);

	return moment(
		new Date(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0)
	)
		.format('YYYY-MM-DD')
		.toString();
}

function MoveShares({ translations, onClose }) {
	const dispatch = useDispatch();
	const [projectsResponse, setProjectsResponse] = useState([]);
	const [projects, setProjects] = useState(null);
	const [addresses, setAddresses] = useState([]);

	const formik = useFormik({
		initialValues: {
			city: '',
			house_number: '',
			house_postfix: '',
			postal_code: '',
			street_name: '',
			number_of_shares: 0,
			participation_identifier: '',
			address_type: 'existing',
			address_key: '',
			date: new Date().toString(),
		},
		validationSchema: offerFormSchema,
		onSubmit: () => {
			submit();
		},
	});

	useEffect(() => {
		api.sustainable
			.get()
			.then((response) => {
				const options = response.map((project) => ({
					label: `${project.project_code} - ${project.project_name}`,
					...project,
					participations: project.participations.map((participation) => ({
						project_code: project.project_code,
						...participation,
						value: {
							project_code: project.project_code,
							participation_identifier: participation.participation_identifier,
						},
					})),
				}));
				const addresses = response
					.map((project) =>
						project.participations.map((participation) => {
							const { address } = participation;
							const {
								city,
								house_number,
								house_postfix,
								postal_code,
								street_name,
							} = address;

							const label = `${street_name || ''} ${house_number ||
								''} ${house_postfix || ''}, ${postal_code || ''} ${city || ''}`;
							const value = `${stringfy(postal_code)}${stringfy(
								house_number
							)}${stringfy(house_postfix)}`;

							return (
								address && {
									label,
									value,
									address,
									participation_identifier:
										participation.participation_identifier,
								}
							);
						})
					)
					.flat()
					.filter((v, i, s) => s.findIndex((sa) => sa.value === v.value) === i);

				setAddresses(addresses);
				setProjects(options);
				setProjectsResponse(response);
			})
			.catch((err) => {
				console.error(err);
			});
	}, []);

	const submit = () => {
		const address =
			formik.values.address_type === 'existing'
				? selectedAddress.address
				: {
						city: formik.values.city,
						house_number: formik.values.house_number,
						house_postfix: formik.values.house_postfix,
						postal_code: formik.values.postal_code,
						street_name: formik.values.street_name,
				  };

		const to_participation_identifier =
			formik.values.address_type === 'existing'
				? selectedAddress.participation_identifier
				: null;

		api.sustainable.sustainableOffers
			.moveShares({
				date: dateFormater(formik.values.date),
				items: [
					{
						participationIdentifier: formik.values.participation_identifier,
						shares: formik.values.number_of_shares,
						toParticipationIdentifier: to_participation_identifier,
						address: address,
					},
				],
			})
			.then(() => {
				onClose();
			})
			.catch(() => {
				onClose();
				notificationsAction.add(dispatch, 'Error');
			});
	};

	const selectedAddress = useMemo(() => {
		const { address_key } = formik.values;
		return address_key && addresses.find((a) => a.value === address_key);
	}, [formik, addresses]);

	const selectedProject = useMemo(() => {
		const projects = projectsResponse;
		const project = projects.filter(
			(project) =>
				project.participations.findIndex(
					(p) =>
						p.participation_identifier ===
						formik.values.participation_identifier
				) > -1
		)[0];

		if (project) {
			const { member_offer_settings, administration_costs } = project;
			project.member_offer_settings = member_offer_settings
				.map((setting) => {
					setting.from_date = new Date(setting.from_date);
					return setting;
				})
				.sort((a, b) => b.from_date - a.from_date);
			project.administration_costs = administration_costs
				.map((cost) => {
					cost.from_date = new Date(cost.from_date);
					return cost;
				})
				.sort((a, b) => b.from_date - a.from_date);
		}

		return project;
	}, [projectsResponse, formik]);

	const selectedParticipation = useMemo(() => {
		return (
			selectedProject &&
			selectedProject.participations.find(
				(p) =>
					p.participation_identifier === formik.values.participation_identifier
			)
		);
	}, [selectedProject, formik]);

	const selectedProjectMemberOfferSettingAllowSelling = useMemo(() => {
		return selectedProject && selectedProject.member_offer_settings[0]
			? selectedProject.member_offer_settings[0].allow_selling
			: false;
	}, [selectedProject]);

	function between(x, min, max) {
		return x >= min && x <= max;
	}

	const isNumberOfSharesValid = useMemo(() => {
		return !selectedParticipation
			? true
			: between(formik.values.number_of_shares, 1, selectedParticipation.share);
	}, [formik, selectedParticipation]);

	return (
		<FormikProvider value={formik}>
			<Form>
				<Card>
					<CardContent>
						<Typography gutterBottom variant="h5" component="div">
							{translations.moveShares.title}
						</Typography>
						<FormGroup row>
							<FormControl fullWidth required margin="normal">
								<InputLabel htmlFor="project-code-select" size="small">
									Project
								</InputLabel>
								<Select
									id="participation_identifier"
									name="participation_identifier"
									label="Project"
									size="small"
									value={formik.values.participation_identifier}
									error={
										formik.touched.participation_identifier &&
										Boolean(formik.errors.participation_identifier)
									}
									onChange={formik.handleChange}>
									{projects &&
										projects.map((project) => {
											const { participations } = project;
											return [
												<ListSubheader>{project.label}</ListSubheader>,
												participations.map((participation, j) => {
													return (
														<MenuItem
															key={j}
															value={participation.participation_identifier}>
															{`${participation.participation_identifier} | ${participation.supplier} (${participation.share})`}
														</MenuItem>
													);
												}),
											];
										})}
								</Select>
							</FormControl>
							<FormControl fullWidth margin="normal">
								<DatePickerMobile
									disabled={!selectedProjectMemberOfferSettingAllowSelling}
									size="small"
									name="date"
									id="date"
									onChange={(date) => {
										formik.setFieldValue('date', date.toString());
									}}
									minDate={new Date()}
									locale={nl}></DatePickerMobile>
							</FormControl>
							<FormControl
								fullWidth
								required
								error={!isNumberOfSharesValid}
								margin="normal">
								<TextField
									disabled={!selectedProjectMemberOfferSettingAllowSelling}
									id="number_of_shares"
									name="number_of_shares"
									size="small"
									label={translations.numberOfShares}
									type="number"
									error={!isNumberOfSharesValid}
									helperText={
										!isNumberOfSharesValid ? 'Ongeldige invoer' : null
									}
									value={formik.values.number_of_shares}
									onChange={formik.handleChange}
								/>
							</FormControl>
							<FormControl
								fullWidth
								component="fieldset"
								style={{
									paddingLeft: 5,
									marginTop: 10,
								}}>
								<FormLabel component="legend">Adres</FormLabel>
								<RadioGroup
									id="address_type"
									name="address_type"
									onChange={formik.handleChange}
									row
									value={formik.values.address_type}>
									<FormControlLabel
										value="existing"
										control={<Radio />}
										label={translations.moveShares.existingAddress}
									/>
									<FormControlLabel
										value="new"
										control={<Radio />}
										label={translations.moveShares.newAddress}
									/>
								</RadioGroup>
							</FormControl>
							{!(formik.values.address_type === 'new') && (
								<FormControl fullWidth required margin="normal">
									<InputLabel htmlFor="address_key" size="small">
										Adres
									</InputLabel>
									<Select
										id="address_key"
										name="address_key"
										label="Adres"
										size="small"
										value={formik.values.address_key}
										onChange={formik.handleChange}>
										{addresses.map((a) => (
											<MenuItem key={a.value} value={a.value}>
												{`${a.label}`}
											</MenuItem>
										))}
									</Select>
								</FormControl>
							)}
							{formik.values.address_type === 'new' && (
								<>
									<FormControl fullWidth margin="normal">
										<TextField
											required
											onChange={formik.handleChange}
											size="small"
											name="postal_code"
											id="postal_code"
											label={translations.moveShares.addressFields.postcode}
											value={formik.values.postal_code}
										/>
									</FormControl>
									<Stack direction="row" spacing={2}>
										<FormControl fullWidth>
											<TextField
												required
												onChange={formik.handleChange}
												size="small"
												name="house_number"
												id="house_number"
												label={
													translations.moveShares.addressFields.houseNumber
												}
												value={formik.values.house_number}
											/>
										</FormControl>
										<FormControl fullWidth>
											<TextField
												onChange={formik.handleChange}
												size="small"
												name="house_postfix"
												id="house_postfix"
												label={
													translations.moveShares.addressFields.numberExtension
												}
												value={formik.values.house_postfix}
											/>
										</FormControl>
										<FormControl fullWidth margin="normal">
											<TextField
												required
												onChange={formik.handleChange}
												size="small"
												name="city"
												id="city"
												label={translations.moveShares.addressFields.city}
												value={formik.values.city}
											/>
										</FormControl>
									</Stack>
									<FormControl fullWidth margin="normal">
										<TextField
											required
											onChange={formik.handleChange}
											size="small"
											name="street_name"
											id="street_name"
											label={translations.moveShares.addressFields.street}
											value={formik.values.street_name}
										/>
									</FormControl>
								</>
							)}
						</FormGroup>
					</CardContent>
					<CardActions>
						<Stack direction="row" justifyContent="space-between" spacing={2}>
							<Button
								variant="outlined"
								color="error"
								size="small"
								onClick={onClose}>
								{translations.cancel}
							</Button>
							<Button
								variant="outlined"
								disabled={!formik.isValid}
								type="submit"
								size="small">
								{translations.save}
							</Button>
						</Stack>
					</CardActions>
				</Card>
			</Form>
		</FormikProvider>
	);
}

function mapStateToProps(state) {
	return {
		translations: Object.assign(
			state.translations.marketPlace,
			state.translations.general,
			state.translations.sustainable
		),
	};
}

export default withRouter(
	connect(mapStateToProps)(closeableOverlay(MoveShares))
);
