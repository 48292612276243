import React from 'react';
import PropTypes from 'prop-types';

import './description-list.css';

export const PUSH_VALUE = 'push-value';
export const DEFAULT = 'default';

/**
 * A jsx version of the `dl` html5 element. This component only adds a className
 * to the `dl` element for styling purposes.
 */
const DescriptionList = ({
	children,
	className = '',
	displayMode = DEFAULT,
}) => (
	<dl
		className={`description-list ${className} ${
			displayMode !== DEFAULT ? `description-list--${displayMode}` : ''
		}`.trim()}>
		{children}
	</dl>
);

export default DescriptionList;

DescriptionList.propTypes = {
	/**
	 * The displayMode determines how the space is distributed between the key and the value
	 * By default the space is evenly distributed, but when you specify 'push-value' the value is pushed to the far right and the key gets the remaining space.
	 */
	displayMode: PropTypes.oneOf([PUSH_VALUE, DEFAULT]),
};
