import PropTypes from 'prop-types';

import './address-box.css';

const AddressBox = ({ address, children, selected, onClick, companies }) => (
	<div
		className="address-box-wrapper"
		onClick={() => {
			return onClick ? onClick(address) : () => {};
		}}>
		<div
			className={`address-box tooltip-wrapper ${
				selected ? 'address-box-selected' : ''
			}`.trim()}>
			<div className="address-box__daughter-companies">
				{companies &&
					[...new Set(companies)].map((company, i) => (
						<div key={company + i}>{company}</div>
					))}
			</div>
			<i className="material-icons">home</i>
			<div className="address-box__details">
				<div>
					<p>
						{address.street} {address.house_number || address.houseNumber}{' '}
						{address.house_number_extension || address.houseNumberExtension}
					</p>
				</div>
				<div>
					{address.zip_code || address.zipCode}{' '}
					<span className="address-box__details__city-name">
						{address.city}
					</span>
				</div>
				<div className="address-box-content">{children}</div>
			</div>
		</div>
	</div>
);

AddressBox.propTypes = {
	address: PropTypes.shape({
		city: PropTypes.string,
		house_number: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
		/** Example: II or b or 2 Hoog , */
		house_number_extension: PropTypes.string,
		street: PropTypes.string,
		zip_code: PropTypes.string,
	}),
	selected: PropTypes.bool,
	onClick: PropTypes.func,
	companies: PropTypes.arrayOf(PropTypes.string),
};

export default AddressBox;
