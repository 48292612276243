/**
 * Small utility to join path with base and preventing double slashes
 * @param {*} path
 * @param {*} base
 */
export default function(path, base) {
	if (!path || !base) {
		return null;
	}
	if (!path.startsWith('/')) {
		path = '/' + path;
	}
	if (base.endsWith('/')) {
		base = base.slice(0, -1);
	}
	return base + path.replace(/\/\//g, '/');
}
