import { Component } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import LocationNotifier from './components/location-notifier';
import Header from './components/header';
import MainNavigation from './components/main-navigation';
import Notifications from './components/notifications';
import Footer from './components/footer';

import getHostname from './utils/get-hostname';
import {
	ROUTES,
	OVERLAY_ROUTES,
	RenderRoutes,
	RenderOverlayRoutes,
} from './routes/routes';

import api from './api';
import getMenuItems, { getMyAccountMenu } from './menu-items';

import { action as menuAction } from './reducers/ui/menu';
import { action as phoneNumberAction } from './reducers/data/relations.phonenumber';
import { action as emailAddressAction } from './reducers/data/relations.email-address';
import { action as relationsAction } from './reducers/data/relations';
import { action as labelsLogoAction } from './reducers/data/labels.logo';
import { action as labelsInfoAction } from './reducers/data/labels.info';
import { action as labelsIdAction } from './reducers/data/labels.id';
import { action as agreementsAction } from './reducers/data/agreements';
import { action as viewport } from './reducers/ui/mobile-view';
import {
	action as userInfoAction,
	selector as userInfoSelector,
} from './reducers/data/user-info';

import './app.scss';
import { debounce } from 'lodash';
import isMobileView from './utils/is-mobile-view';
import ThemeConfig from './theme';
import { SUST_LABELS } from './constants';

/**
 * The main entrypoint of the application. This component houses all routes and
 * the Header component
 */
class App extends Component {
	constructor(props) {
		super(props);
		this.state = { synced: true };
	}

	componentDidMount() {
		window.addEventListener('resize', debounce(this.handleResize, 250));
		window.addEventListener('beforeunload', () =>
			localStorage.removeItem('basket')
		);
	}

	handleResize = () => {
		const {
			dispatch,
			ui: { mobileView },
		} = this.props;
		if (isMobileView() && !mobileView) {
			dispatch(viewport.view(true));
		} else if (!isMobileView() && mobileView) {
			dispatch(viewport.view(false));
		}
	};

	componentWillMount = () => {
		const { dispatch, authData } = this.props;

		if (authData) {
			dispatch(userInfoAction.data(authData));
		}

		api.labels.id
			.get(getHostname())
			.then((data) => dispatch(labelsIdAction.data(data)))
			.then((action) => {
				const labelServices = [
					api.labels.logo.get(action.data.label_key),
					api.labels.info.get(action.data.label_key),
				];
				if (!SUST_LABELS.includes(action.data.label_key)) {
					labelServices.push(api.agreements.get());
				}
				return Promise.all(labelServices);
			})
			.then(([logoData, infoData, agreementsResponse]) => {
				dispatch(labelsLogoAction.data(logoData));
				dispatch(labelsInfoAction.data(infoData));
				dispatch(agreementsAction.data(agreementsResponse));
			})
			.catch((err) => console.error(err));

		api.relations
			.get()
			.then((data) => dispatch(relationsAction.data(data)))
			.catch((err) => {
				dispatch(relationsAction.data(null));
				this.setState({ synced: false });
			});

		api.relations.emailAddress
			.get()
			.then((data) => data && dispatch(emailAddressAction.data(data)))
			.catch((err) => err);

		api.relations.phoneNumber
			.get()
			.then((data) => data && dispatch(phoneNumberAction.data(data)))
			.catch((err) => err);

		api.sustainable.sustainableOffers.activate('').catch((e) => {
			console.log(e);
		});

		if (typeof window !== 'undefined') {
			window.addEventListener(
				'resize',
				debounce(() => {
					dispatch(menuAction.close());
				}, 250)
			);
		}
	};

	render() {
		const {
			dispatch,
			ui,
			data,
			notifications,
			translations,
			isUserImpersonator,
		} = this.props;
		const menuToggle = () => dispatch(menuAction.toggle());
		const menuClose = () => dispatch(menuAction.close());
		const menuItems =
			data.labels && data.labels.id && data.labels.id.label_key
				? getMenuItems(translations, data.labels.id.label_key)
				: getMenuItems(translations);

		return (
			<ThemeConfig>
				<Router>
					<div className="app" onClick={this.onClickApp}>
						<LocationNotifier onLocationChange={() => menuClose()} />
						{isUserImpersonator && (
							<div className="impersonator-banner">
								{translations.general.impersonatorBannerText}
							</div>
						)}
						<Header
							className={`app__header${
								isUserImpersonator ? ' impersonator' : ''
							}`}
							menuClick={menuToggle}
							logo={data.labels.logo.url}
							labelName={data.labels.info.name}
							translations={translations}
							relations={data.relations}
							menuItems={getMyAccountMenu(translations)}
							dispatch={dispatch}
						/>
						{!this.state.synced ? (
							<div className={`app_relation_not_synced`}>
								<h2>{translations.general.relationNotSynced}</h2>
							</div>
						) : (
							<MainNavigation
								onClose={menuClose}
								className={`app__main-navigation${
									isUserImpersonator ? ' impersonator' : ''
								}`}
								isShown={ui.menu.isShown}
								items={menuItems}
							/>
						)}
						{!this.state.synced ? null : (
							<main className={`app__main-content highlight-bg-color`}>
								{notifications.length > 0 && api.relations !== null ? (
									<Notifications notifications={notifications} />
								) : null}
								{data.labels.id.label_key ? (
									<RenderRoutes {...this.props} routes={ROUTES} />
								) : null}
							</main>
						)}
						<RenderOverlayRoutes {...this.props} routes={OVERLAY_ROUTES} />
						{data.labels.id.privacy_and_cookie_url && (
							<Footer
								privacyTitle={translations.general.footer.privacyLink}
								privacyLink={data.labels.id.privacy_and_cookie_url}
							/>
						)}
					</div>
				</Router>
			</ThemeConfig>
		);
	}
}

App.propTypes = {
	/** The api base url */
	api: PropTypes.string.isRequired,
	test: PropTypes.bool,
};

function mapStateToProps(state, ownProps) {
	return {
		ui: state.ui,
		data: state.data,
		notifications: state.notifications,
		translations: state.translations,
		isUserImpersonator: userInfoSelector.isUserImpersonator(state.data),
	};
}

const ConnectedApp = connect(mapStateToProps)(App);
export default ConnectedApp;
