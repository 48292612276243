import {
	Box,
	FormControl,
	InputLabel,
	MenuItem,
	Pagination,
	Select,
	Skeleton,
	Stack,
	Typography,
} from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useTheme } from '@mui/material/styles';
import { useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import api from '../../api';

function Notifications({ translations }) {
	const [notifications, setNotifications] = useState([]);
	const [notificationsLoading, setNotificationsLoading] = useState(false);
	const [projects, setProjects] = useState([]);
	const [page, setPage] = useState(1);
	const [projectCode, setProjectCode] = useState('');
	const [expanded, setExpanded] = useState(false);
	const pageLimit = 5;
	const theme = useTheme();

	useEffect(() => {
		api.sustainable
			.get()
			.then((response) => {
				setProjects(response);
			})
			.catch(() => {
				setProjects([]);
			});

		api.sustainable.notifications
			.get()
			.then((response) => {
				setNotifications(response);
			})
			.catch(() => {
				setNotifications([]);
			})
			.finally(() => {
				setNotificationsLoading(false);
			});
	}, []);

	const handleProject = (value) => {
		if (projectCode !== value) {
			setPage(1);
		}
		setProjectCode(value);
	};

	const selectedProject = useMemo(() => {
		return !!projectCode
			? projects.find((project) => project.project_code === projectCode)
			: null;
	}, [projectCode, projects]);

	const filteredNotifications = useMemo(() => {
		return notifications.filter((notice) =>
			selectedProject
				? notice.projects.includes(selectedProject.project_name)
				: true
		);
	}, [selectedProject, notifications]);

	const numberOfPages = useMemo(() => {
		return Math.ceil(filteredNotifications.length / 5);
	}, [filteredNotifications]);

	const paginatedNotifications = useMemo(() => {
		const startingIndex = (page - 1) * 5;
		const endIndex = startingIndex + pageLimit;
		const pagedData = filteredNotifications.slice(startingIndex, endIndex);

		return pagedData;
	}, [page, filteredNotifications]);

	const handleChange = (panel) => (event, newExpanded) => {
		setExpanded(newExpanded ? panel : false);
	};

	return (
		<Box
			sx={{
				flexGrow: 1,
				pt: 4,
			}}>
			<Stack
				direction="row"
				justifyContent="space-between"
				alignItems="center"
				spacing={2}
				height={50}>
				<Typography variant="h4">{translations.notifications.title}</Typography>
				<Stack
					direction="row"
					justifyContent="flex-end"
					alignItems="center"
					spacing={2}
					height={50}>
					<FormControl>
						<InputLabel htmlFor="participation_identifier" size="small">
							Project
						</InputLabel>
						<Select
							id="participation_identifier"
							name="participation_identifier"
							label="Project"
							placeholder="Project"
							size="small"
							sx={{
								minWidth: 150,
							}}
							autoWidth
							value={projectCode}
							onChange={(event) => handleProject(event.target.value)}>
							<MenuItem value={''}>All</MenuItem>
							{projects &&
								projects.map((project) => {
									return [
										<MenuItem
											key={project.project_code}
											value={project.project_code}>
											{`${project.project_name}`}
										</MenuItem>,
									];
								})}
						</Select>
					</FormControl>
				</Stack>
			</Stack>
			{notificationsLoading && (
				<Skeleton
					variant="rectangular"
					width={220}
					height={33}
					sx={{
						mb: 2,
						mt: 2,
					}}
				/>
			)}
			{!notificationsLoading && (
				<Box
					direction="column"
					spacing={2}
					sx={{
						pb: 2,
						pt: 2,
					}}>
					{paginatedNotifications.length === 0 && (
						<span>{translations.notifications.noNotices}</span>
					)}
					{paginatedNotifications.map((notification) => (
						<Accordion
							key={notification.id}
							expanded={expanded === notification.id}
							onChange={handleChange(notification.id)}
							TransitionProps={{ unmountOnExit: true }}
							sx={{
								boxShadow: theme.customShadows.z16,
							}}>
							<AccordionSummary
								expandIcon={<ExpandMoreIcon />}
								aria-controls="panel1a-content"
								id="panel1a-header">
								<div>
									<Typography variant="h5">{`${notification.title}`}</Typography>
									{expanded !== notification.id && (
										<Typography variant="body1">
											<span>
												<div
													style={{}}
													dangerouslySetInnerHTML={{
														__html: notification.message.substring(0, 100),
													}}
												/>
											</span>
										</Typography>
									)}
								</div>
							</AccordionSummary>
							<AccordionDetails>
								<Box mb={2}>
									<div
										dangerouslySetInnerHTML={{ __html: notification.message }}
									/>
								</Box>
							</AccordionDetails>
						</Accordion>
					))}
				</Box>
			)}
			<Stack spacing={2}>
				<Pagination
					count={numberOfPages}
					onChange={(_, page) => setPage(page)}
					variant="outlined"
					shape="rounded"
				/>
			</Stack>
		</Box>
	);
}

function mapStateToProps(state) {
	return {
		translations: {
			general: state.translations.general,
			notifications: state.translations.notifications,
		},
	};
}

export default connect(mapStateToProps)(Notifications);
