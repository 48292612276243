import { DeleteOutline, ShoppingCart } from '@mui/icons-material';
import {
	Badge,
	Box,
	CircularProgress,
	Divider,
	IconButton,
	List,
	ListItem,
	ListItemIcon,
	ListItemText,
	Popover,
	Typography,
	Stack,
	Button,
} from '@mui/material';
import PropTypes from 'prop-types';
import { Fragment, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import api from '../../../api';
import { SUST_LABELS } from '../../../constants';
import { red } from '@mui/material/colors';

function MarketplaceCart({ translations, marketplace, labels }) {
	const history = useHistory();
	const label_key = labels.id.label_key;
	const dispatch = useDispatch();
	function handleClick(path) {
		history.push(path);
	}

	const [anchorEl, setAnchorEl] = useState(null);

	const open = Boolean(anchorEl);
	const handleClose = () => {
		setAnchorEl(null);
	};
	const id = open ? 'simple-popover' : undefined;

	const deleteOffer = (reference) => {
		api.sustainable.sustainableOffers.basket
			.delete(reference, marketplace.basket)
			.then((res) => {
				dispatch({
					type: 'ADD_OFFER',
					basket: res,
				});
				handleClose();
			});
	};

	return (
		SUST_LABELS.includes(label_key) && (
			<Box pt={1} pr={2}>
				{marketplace.isLoading ? (
					<CircularProgress />
				) : (
					<>
						<Box>
							<Badge
								badgeContent={marketplace.basket.items.length}
								color="primary">
								<IconButton
									sx={{
										color: '#444444',
									}}
									disabled={
										!(
											marketplace.basket.items &&
											marketplace.basket.items.length > 0
										)
									}
									variant="contained"
									aria-describedby={id}
									onClick={(event) => setAnchorEl(event.currentTarget)}>
									<ShoppingCart
										sx={{
											width: 24,
											height: 24,
										}}
									/>
								</IconButton>
							</Badge>
						</Box>
						<Popover
							id={id}
							open={open}
							anchorEl={anchorEl}
							onClose={handleClose}
							anchorOrigin={{
								vertical: 'bottom',
								horizontal: 'left',
							}}>
							<Stack spacing={2} direction="column" sx={{ padding: 2 }}>
								<List>
									{marketplace.basket.items.length > 0 &&
										marketplace.basket.items.map((offer) => (
											<Fragment key={offer.reference}>
												<ListItem>
													<ListItemIcon>
														<IconButton
															sx={{
																color: red[500],
															}}
															onClick={() => deleteOffer(offer.identifier)}>
															<DeleteOutline />
														</IconButton>
													</ListItemIcon>
													<ListItemText
														primary={offer.project_name}
														primaryTypographyProps={{
															variant: 'h6',
														}}
														secondaryTypographyProps={{
															variant: 'h7',
														}}
														secondary={`
										${offer.project_code} |
										${translations.marketPlace.numberOfShares}: ${offer.shares} |
										${translations.marketPlace.pricePerShare}: ${offer.price_per_share}
									`}
													/>
													<Typography>&euro;{offer.amount}</Typography>
												</ListItem>
												<Divider />
											</Fragment>
										))}
									<ListItem>
										<ListItemText
											primaryTypographyProps={{ variant: 'h5' }}
											primary={translations.marketPlace.totalCost}
										/>
										<Typography variant="h5" sx={{ fontWeight: 700 }}>
											&euro;{marketplace.basket.total_amount}
										</Typography>
									</ListItem>
								</List>
								<Button
									variant="outlined"
									onClick={() => handleClick('checkout')}>
									{translations.marketPlace.myCart}
								</Button>
							</Stack>
						</Popover>
					</>
				)}
			</Box>
		)
	);
}

MarketplaceCart.propTypes = {
	translations: PropTypes.object.isRequired,
	marketplace: PropTypes.object,
};

function mapStateToProps(state) {
	return {
		marketplace: state.marketplace,
		labels: state.data.labels,
	};
}

export default connect(mapStateToProps)(MarketplaceCart);
