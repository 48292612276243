import React, { Component } from 'react';
import PropTypes from 'prop-types';

export default class IconSnowFlake extends Component {
	static displayName = 'IconCode';

	getLarge() {
		return (
			<svg
				version="1.1"
				xmlns="http://www.w3.org/2000/svg"
				width="32"
				height="32"
				viewBox="0 0 32 32">
				<path d="M24.064 16.544c-0.128-0.48-0.608-0.768-1.088-0.64l-2.4 0.64-2.208-1.28 2.144-1.248 2.432 0.64c0.096 0.064 0.192 0.064 0.256 0.064 0.384 0 0.768-0.288 0.864-0.672 0.128-0.48-0.16-0.96-0.64-1.088l-0.704-0.192 0.896-0.512c0.416-0.224 0.576-0.8 0.32-1.216-0.224-0.416-0.8-0.576-1.216-0.32l-0.864 0.48 0.192-0.64c0.128-0.48-0.16-0.96-0.64-1.088s-0.96 0.16-1.088 0.64l-0.64 2.368-2.176 1.248v-2.368l1.76-1.76c0.352-0.352 0.352-0.928 0-1.28s-0.928-0.352-1.28 0l-0.48 0.48v-0.992c0-0.48-0.384-0.896-0.896-0.896-0.48 0-0.896 0.384-0.896 0.896v0.992l-0.48-0.48c-0.352-0.352-0.928-0.352-1.28 0s-0.352 0.928 0 1.28l1.76 1.792v2.368l-2.048-1.184-0.64-2.368c-0.128-0.48-0.608-0.768-1.088-0.64s-0.768 0.608-0.64 1.088l0.192 0.672-0.896-0.512c-0.416-0.256-0.96-0.096-1.216 0.32s-0.096 0.96 0.32 1.216l0.896 0.512-0.672 0.16c-0.48 0.128-0.768 0.608-0.64 1.088 0.096 0.416 0.48 0.672 0.864 0.672 0.064 0 0.16-0.032 0.224-0.032l2.432-0.64 2.048 1.184-2.048 1.152-2.432-0.64c-0.48-0.128-0.96 0.16-1.088 0.64s0.16 0.96 0.64 1.088l0.672 0.192-0.896 0.512c-0.416 0.224-0.576 0.8-0.32 1.216 0.16 0.288 0.448 0.448 0.768 0.448 0.16 0 0.32-0.032 0.448-0.128l0.896-0.512-0.192 0.704c-0.128 0.48 0.16 0.96 0.64 1.088 0.064 0.032 0.16 0.032 0.224 0.032 0.416 0 0.768-0.288 0.864-0.704l0.64-2.368 2.048-1.184v2.464l-1.76 1.76c-0.352 0.352-0.352 0.928 0 1.28s0.928 0.352 1.28 0l0.48-0.48v0.992c0 0.48 0.384 0.896 0.896 0.896s0.896-0.416 0.896-0.896v-0.992l0.48 0.48c0.192 0.16 0.416 0.256 0.64 0.256 0.256 0 0.48-0.064 0.64-0.256 0.352-0.352 0.352-0.928 0-1.28l-1.76-1.76v-2.464l2.176 1.248 0.64 2.4c0.096 0.416 0.48 0.672 0.864 0.672 0.064 0 0.128-0.032 0.224-0.032 0.48-0.128 0.768-0.608 0.64-1.088l-0.192-0.672 0.864 0.48c0.128 0.096 0.288 0.128 0.448 0.128 0.32 0 0.608-0.16 0.768-0.448 0.256-0.416 0.096-0.96-0.32-1.216l-0.864-0.48 0.672-0.192c0.48-0.128 0.768-0.608 0.64-1.088z" />
			</svg>
		);
	}

	getSmall() {
		return (
			<svg
				version="1.1"
				xmlns="http://www.w3.org/2000/svg"
				width="32"
				height="32"
				viewBox="0 0 32 32">
				<path d="M24.064 16.544c-0.128-0.48-0.608-0.768-1.088-0.64l-2.4 0.64-2.208-1.28 2.144-1.248 2.432 0.64c0.096 0.064 0.192 0.064 0.256 0.064 0.384 0 0.768-0.288 0.864-0.672 0.128-0.48-0.16-0.96-0.64-1.088l-0.704-0.192 0.896-0.512c0.416-0.224 0.576-0.8 0.32-1.216-0.224-0.416-0.8-0.576-1.216-0.32l-0.864 0.48 0.192-0.64c0.128-0.48-0.16-0.96-0.64-1.088s-0.96 0.16-1.088 0.64l-0.64 2.368-2.176 1.248v-2.368l1.76-1.76c0.352-0.352 0.352-0.928 0-1.28s-0.928-0.352-1.28 0l-0.48 0.48v-0.992c0-0.48-0.384-0.896-0.896-0.896-0.48 0-0.896 0.384-0.896 0.896v0.992l-0.48-0.48c-0.352-0.352-0.928-0.352-1.28 0s-0.352 0.928 0 1.28l1.76 1.792v2.368l-2.048-1.184-0.64-2.368c-0.128-0.48-0.608-0.768-1.088-0.64s-0.768 0.608-0.64 1.088l0.192 0.672-0.896-0.512c-0.416-0.256-0.96-0.096-1.216 0.32s-0.096 0.96 0.32 1.216l0.896 0.512-0.672 0.16c-0.48 0.128-0.768 0.608-0.64 1.088 0.096 0.416 0.48 0.672 0.864 0.672 0.064 0 0.16-0.032 0.224-0.032l2.432-0.64 2.048 1.184-2.048 1.152-2.432-0.64c-0.48-0.128-0.96 0.16-1.088 0.64s0.16 0.96 0.64 1.088l0.672 0.192-0.896 0.512c-0.416 0.224-0.576 0.8-0.32 1.216 0.16 0.288 0.448 0.448 0.768 0.448 0.16 0 0.32-0.032 0.448-0.128l0.896-0.512-0.192 0.704c-0.128 0.48 0.16 0.96 0.64 1.088 0.064 0.032 0.16 0.032 0.224 0.032 0.416 0 0.768-0.288 0.864-0.704l0.64-2.368 2.048-1.184v2.464l-1.76 1.76c-0.352 0.352-0.352 0.928 0 1.28s0.928 0.352 1.28 0l0.48-0.48v0.992c0 0.48 0.384 0.896 0.896 0.896s0.896-0.416 0.896-0.896v-0.992l0.48 0.48c0.192 0.16 0.416 0.256 0.64 0.256 0.256 0 0.48-0.064 0.64-0.256 0.352-0.352 0.352-0.928 0-1.28l-1.76-1.76v-2.464l2.176 1.248 0.64 2.4c0.096 0.416 0.48 0.672 0.864 0.672 0.064 0 0.128-0.032 0.224-0.032 0.48-0.128 0.768-0.608 0.64-1.088l-0.192-0.672 0.864 0.48c0.128 0.096 0.288 0.128 0.448 0.128 0.32 0 0.608-0.16 0.768-0.448 0.256-0.416 0.096-0.96-0.32-1.216l-0.864-0.48 0.672-0.192c0.48-0.128 0.768-0.608 0.64-1.088z" />
			</svg>
		);
	}

	getIcon() {
		let svg;
		svg = this.props.size === 'large' ? this.getLarge() : svg;
		svg = this.props.size === 'small' ? this.getSmall() : svg;
		return svg;
	}

	render() {
		return this.getIcon();
	}
}

IconSnowFlake.propTypes = {
	size: PropTypes.oneOf(['small', 'large']).isRequired,
};
