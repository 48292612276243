import React from 'react';
// import PropTypes from 'prop-types';

import './page-title.css';

const PageTitle = ({ children }) => <h1 className="page-title">{children}</h1>;

export default PageTitle;

PageTitle.propTypes = {};
