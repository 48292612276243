import React, { Component } from 'react';

import Row from '../../components/row';
import PageTitle from '../../components/page-title';
import RelationSummary from '../../components/relation-summary';

import './metermeasuring.css';
import { connect } from 'react-redux';

class Metermeasuring extends Component {
	render() {
		const { translations, relations } = this.props;
		return (
			<div className="view-metermeasuring">
				<Row>
					<PageTitle>{translations.title}</PageTitle>
				</Row>
				<Row>
					{relations && (
						<RelationSummary
							{...relations}
							debtor_number_label={translations.clientNumber}
						/>
					)}
				</Row>
			</div>
		);
	}
}

function mapStateToProps(state) {
	return {
		relations: state.data.relations,
		translations: Object.assign(
			state.translations.metermeasuring,
			state.translations.general
		),
	};
}

export default connect(mapStateToProps)(Metermeasuring);
