import React, { Component } from 'react';
import PropTypes from 'prop-types';

export default class IconMoon extends Component {
	static displayName = 'IconCode';

	getLarge() {
		return (
			<svg
				version="1.1"
				xmlns="http://www.w3.org/2000/svg"
				width="32"
				height="32"
				viewBox="0 0 32 32">
				<path d="M22.496 16.128c-0.192-0.16-0.48-0.224-0.736-0.16-0.448 0.128-0.896 0.192-1.312 0.192-2.688 0-4.864-2.176-4.864-4.864 0-0.16 0.032-0.288 0.032-0.416 0.032-0.256-0.064-0.544-0.288-0.704-0.192-0.16-0.48-0.224-0.736-0.16-2.816 0.8-4.768 3.36-4.768 6.272 0 3.584 2.912 6.496 6.496 6.496 3.36 0 6.208-2.624 6.464-5.952 0.032-0.256-0.064-0.544-0.288-0.704zM16.32 21.12c-2.688 0-4.864-2.176-4.864-4.832 0-1.792 0.992-3.424 2.528-4.256 0.384 3.392 3.424 6.016 6.944 5.728-0.64 1.952-2.496 3.36-4.608 3.36z" />
			</svg>
		);
	}

	getSmall() {
		return (
			<svg
				version="1.1"
				xmlns="http://www.w3.org/2000/svg"
				width="32"
				height="32"
				viewBox="0 0 32 32">
				<path d="M22.496 16.128c-0.192-0.16-0.48-0.224-0.736-0.16-0.448 0.128-0.896 0.192-1.312 0.192-2.688 0-4.864-2.176-4.864-4.864 0-0.16 0.032-0.288 0.032-0.416 0.032-0.256-0.064-0.544-0.288-0.704-0.192-0.16-0.48-0.224-0.736-0.16-2.816 0.8-4.768 3.36-4.768 6.272 0 3.584 2.912 6.496 6.496 6.496 3.36 0 6.208-2.624 6.464-5.952 0.032-0.256-0.064-0.544-0.288-0.704zM16.32 21.12c-2.688 0-4.864-2.176-4.864-4.832 0-1.792 0.992-3.424 2.528-4.256 0.384 3.392 3.424 6.016 6.944 5.728-0.64 1.952-2.496 3.36-4.608 3.36z" />
			</svg>
		);
	}

	getIcon() {
		let svg;
		svg = this.props.size === 'large' ? this.getLarge() : svg;
		svg = this.props.size === 'small' ? this.getSmall() : svg;
		return svg;
	}

	render() {
		return this.getIcon();
	}
}

IconMoon.propTypes = {
	size: PropTypes.oneOf(['small', 'large']).isRequired,
};
