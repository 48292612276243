import React, { Component } from 'react';
import PropTypes from 'prop-types';

export default class IconAccountCircle extends Component {
	static displayName = 'IconAccountCircle';

	getLarge() {
		return (
			<svg
				fill={this.props.color}
				xmlns="http://www.w3.org/2000/svg"
				width="48"
				height="48"
				viewBox="0 0 48 48">
				<path d="M24 4C12.95 4 4 12.95 4 24s8.95 20 20 20 20-8.95 20-20S35.05 4 24 4zm0 6c3.31 0 6 2.69 6 6 0 3.32-2.69 6-6 6s-6-2.68-6-6c0-3.31 2.69-6 6-6zm0 28.4c-5.01 0-9.41-2.56-12-6.44.05-3.97 8.01-6.16 12-6.16s11.94 2.19 12 6.16c-2.59 3.88-6.99 6.44-12 6.44z" />
			</svg>
		);
	}

	getSmall() {
		return (
			<svg
				fill={this.props.color}
				xmlns="http://www.w3.org/2000/svg"
				width="24"
				height="24"
				viewBox="0 0 24 24">
				<path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 3c1.66 0 3 1.34 3 3s-1.34 3-3 3-3-1.34-3-3 1.34-3 3-3zm0 14.2c-2.5 0-4.71-1.28-6-3.22.03-1.99 4-3.08 6-3.08 1.99 0 5.97 1.09 6 3.08-1.29 1.94-3.5 3.22-6 3.22z" />
			</svg>
		);
	}

	getIcon() {
		let svg;
		svg = this.props.size === 'large' ? this.getLarge() : svg;
		svg = this.props.size === 'small' ? this.getSmall() : svg;
		return svg;
	}

	render() {
		return this.getIcon();
	}
}

IconAccountCircle.propTypes = {
	size: PropTypes.oneOf(['small', 'large']).isRequired,
};
