import React, { Component } from 'react';
import PropTypes from 'prop-types';

export default class IconClear extends Component {
	static displayName = 'IconClear';

	getLarge() {
		return (
			<svg
				fill={this.props.color}
				xmlns="http://www.w3.org/2000/svg"
				width="48"
				height="48"
				viewBox="0 0 48 48">
				<path d="M38 12.83L35.17 10 24 21.17 12.83 10 10 12.83 21.17 24 10 35.17 12.83 38 24 26.83 35.17 38 38 35.17 26.83 24z" />
			</svg>
		);
	}

	getSmall() {
		return (
			<svg
				fill={this.props.color}
				xmlns="http://www.w3.org/2000/svg"
				width="24"
				height="24"
				viewBox="0 0 24 24">
				<path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z" />
			</svg>
		);
	}

	getIcon() {
		let svg;
		svg = this.props.size === 'large' ? this.getLarge() : svg;
		svg = this.props.size === 'small' ? this.getSmall() : svg;
		return svg;
	}

	render() {
		return this.getIcon();
	}
}

IconClear.propTypes = {
	size: PropTypes.oneOf(['small', 'large']).isRequired,
};
