import './energy-info-box.css';
import roundNumber from '../../../utils/round-number';
import dateFormatter from '../../../utils/date-formatter';

const EnergyInfoBox = ({
	translations,
	current_power,
	total_production,
	project_name: title,
	todays_production,
	project_ean_number: ean,
	unit_of_measurement: unit,
	project_end_date,
}) => {
	return (
		<div className="energy-info">
			<ul>
				<li>
					<span className="label">{translations.currentPower}</span>
					<span className="value">
						{renderNumber(current_power)}
						<span className="unit">{' kWh'}</span>
					</span>
				</li>
				<li>
					<span className="label">{translations.energyToday}</span>
					<span className="value">
						{renderNumber(todays_production)}{' '}
						<span className="unit">{unit && formatUnit(unit)}</span>
						<span className="unit">{' kWh'}</span>
					</span>
				</li>
				<li>
					<span className="label">{translations.totalEnergy}</span>
					<span className="value">
						{renderNumber(total_production)}{' '}
						<span className="unit">{unit && formatUnit(unit)}</span>
						<span className="unit">{' kWh'}</span>
					</span>
				</li>
				<li>
					<span className="label">{translations.ean}</span>
					<span className="value">{ean}</span>
				</li>
				<li>
					<span className="label">{translations.projectEndDate}</span>
					<span className="value">{dateFormatter(project_end_date)}</span>
				</li>
			</ul>
		</div>
	);
};

function isInt(n) {
	return n % 1 === 0;
}

const renderNumber = (number) =>
	isInt(number)
		? `${number}`
		: // number :
		  roundNumber(number, 2).toString();

const formatUnit = (unit) =>
	unit
		.split('')
		.map((item, i, arr) =>
			i === 0 || i === arr.length - 1 ? item.toLowerCase() : item
		);

export default EnergyInfoBox;
