import React from 'react';
import PropTypes from 'prop-types';

import './currency.css';

/**
 * Renders a currency value
 */
const Currency = props => {
	const { amount = 0 } = props;
	const [integer, decimals] = Math.abs(amount)
		.toFixed(2)
		.split('.');

	return (
		<span className={`currency ${props.className || ''}`.trim()}>
			€ {amount < 0 ? '-' : ''}
			{integer},<span className="currency__decimals">{decimals}</span>
		</span>
	);
};

export default Currency;

Currency.propTypes = {
	amount: PropTypes.number,
};
