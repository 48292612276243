import { useState } from 'react';
import TextField from '@mui/material/TextField';
import DateAdapter from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { useField, useFormikContext } from 'formik';
import MobileDatePicker from '@mui/lab/MobileDatePicker';
import nl from 'date-fns/locale/nl';

const DatePickerMobile = ({ name, label, minDate, maxDate, ...otherProps }) => {
	const { setFieldValue } = useFormikContext();
	const [currentValue, setCurrentValue] = useState(new Date());
	const [field, meta] = useField(name);

	if (!minDate) {
		minDate = new Date();
	}
	if (!maxDate) {
		maxDate = new Date();
		maxDate.setDate(maxDate.getDate() + 90);
	}
	const configDatePicker = {
		fullWidth: true,
		variant: 'outlined',
		InputLabelProps: {
			shrink: true,
		},
		...field,
		...otherProps,
	};
	if (meta && meta.touched && meta.error) {
		configDatePicker.error = true;
		configDatePicker.helperText = meta.error;
	}

	return (
		<LocalizationProvider dateAdapter={DateAdapter} locale={nl}>
			<MobileDatePicker
				label={label}
				value={currentValue}
				onChange={(newValue) => {
					setCurrentValue(newValue);
					setFieldValue(name, newValue);
				}}
				minDate={minDate}
				maxDate={maxDate}
				renderInput={(config) => (
					<TextField size="small" {...config} {...configDatePicker} />
				)}
			/>
		</LocalizationProvider>
	);
};

export default DatePickerMobile;
