import axios from 'axios';
import get from './methods/get';
import post from './methods/post';
import put from './methods/put';

export default {
	/**
	 * Perform get request on the relations endpoint. It retuns a promise which
	 * resolves to a JSON object on success and an Error object on a failure
	 * @param {Object} headers A headers object
	 * @return {Promise}       A Promise
	 */
	get: (headers) => get('relations', headers),
	emptyResponse: {
		company: {
			coc_number: '',
			vat_number: '',
			name: '',
		},
		debtor_number: '',
		relation_since: '',
		initials: '',
		prefix: '',
		last_name: '',
		email: '',
		phone_number: '',
		bank_account_number: '',
		bank_account_name: '',
		salutation: '',
		addresses: [
			{
				address_type: '',
				street: '',
				house_number: '',
				house_number_extension: '',
				zip_code: '',
				city: '',
			},
		],
	},

	contact: {
		post: (body, headers) => post('relations/contact', body, headers),
		emptyResponse: {},
	},

	phoneNumber: {
		get: (headers) => get('relations/phonenumber?request=status', headers),
		put: (data, headers) => put('relations/phonenumber', data, headers),

		emptyResponse: {
			phone_number: '',
		},
	},

	emailAddress: {
		get: (headers) => get('relations/email?request=status', headers),
		put: (data, headers) => put('relations/email', data, headers),

		emptyResponse: {
			change_email: '',
		},
	},

	bancAccountNumber: {
		get: (headers) =>
			get('relations/bankAccountNumber?request=STATUS', headers, true),
		put: (data, headers) => put('relations/bankAccountNumber', data, headers),
	},
	addresses: {
		get: (headers) => get('relations/addresses', headers),
		validateAddress: (postcode, houseNumber, houseNumberExtension) => {
			const url = `/lambda/address-validation/postcode`;
			const zipCode = postcode.toUpperCase().replace(/\s/g, '');

			const address = {
				zipCode,
				houseNumber,
				houseNumberExtension,
			};

			return axios({
				url,
				method: 'post',
				disableErrorMsg: true,
				data: {
					...address,
					zipCode: zipCode,
				},
			});
		},
	},
};
