import { Stack, Typography } from '@mui/material';

function View404(props) {
	return (
		<Stack
			alignContent="center"
			justifyContent="center"
			alignItems="center"
			direction="column">
			<Typography variant="h1">404</Typography>
			<Typography variant="h1">
				{props.translations.general?.error.notFound}
			</Typography>
		</Stack>
	);
}

export default View404;
