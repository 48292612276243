import React, { Component } from 'react';
import PropTypes from 'prop-types';

export default class IconFog extends Component {
	static displayName = 'IconCode';

	getLarge() {
		return (
			<svg
				version="1.1"
				xmlns="http://www.w3.org/2000/svg"
				width="32"
				height="32"
				viewBox="0 0 32 32">
				<path d="M21.984 18.88h-11.392c-0.48 0-0.896 0.384-0.896 0.896 0 0.48 0.384 0.896 0.896 0.896h11.424c0.48 0 0.896-0.416 0.864-0.896 0-0.48-0.384-0.896-0.896-0.896z" />
				<path d="M9.216 13.76h11.424c0.48 0 0.896-0.384 0.864-0.896 0-0.48-0.384-0.896-0.896-0.896h-11.392c-0.48 0-0.896 0.384-0.896 0.896 0 0.48 0.384 0.896 0.896 0.896z" />
				<path d="M24.128 16.608h-16.192c-0.48 0-0.896 0.384-0.896 0.896 0 0.48 0.384 0.896 0.896 0.896h16.16c0.512 0 0.896-0.416 0.928-0.896 0-0.48-0.384-0.896-0.896-0.896z" />
				<path d="M17.184 15.232c0-0.48-0.384-0.896-0.896-0.896h-9.536c-0.48 0-0.896 0.384-0.896 0.896 0 0.48 0.384 0.896 0.896 0.896h9.536c0.512 0 0.928-0.384 0.896-0.896z" />
				<path d="M25.312 14.336h-6.944c-0.48 0-0.896 0.384-0.896 0.896 0 0.48 0.384 0.896 0.896 0.896h6.944c0.512 0 0.896-0.384 0.896-0.896 0-0.48-0.384-0.896-0.896-0.896z" />
			</svg>
		);
	}

	getSmall() {
		return (
			<svg
				version="1.1"
				xmlns="http://www.w3.org/2000/svg"
				width="32"
				height="32"
				viewBox="0 0 32 32">
				<path d="M21.984 18.88h-11.392c-0.48 0-0.896 0.384-0.896 0.896 0 0.48 0.384 0.896 0.896 0.896h11.424c0.48 0 0.896-0.416 0.864-0.896 0-0.48-0.384-0.896-0.896-0.896z" />
				<path d="M9.216 13.76h11.424c0.48 0 0.896-0.384 0.864-0.896 0-0.48-0.384-0.896-0.896-0.896h-11.392c-0.48 0-0.896 0.384-0.896 0.896 0 0.48 0.384 0.896 0.896 0.896z" />
				<path d="M24.128 16.608h-16.192c-0.48 0-0.896 0.384-0.896 0.896 0 0.48 0.384 0.896 0.896 0.896h16.16c0.512 0 0.896-0.416 0.928-0.896 0-0.48-0.384-0.896-0.896-0.896z" />
				<path d="M17.184 15.232c0-0.48-0.384-0.896-0.896-0.896h-9.536c-0.48 0-0.896 0.384-0.896 0.896 0 0.48 0.384 0.896 0.896 0.896h9.536c0.512 0 0.928-0.384 0.896-0.896z" />
				<path d="M25.312 14.336h-6.944c-0.48 0-0.896 0.384-0.896 0.896 0 0.48 0.384 0.896 0.896 0.896h6.944c0.512 0 0.896-0.384 0.896-0.896 0-0.48-0.384-0.896-0.896-0.896z" />
			</svg>
		);
	}

	getIcon() {
		let svg;
		svg = this.props.size === 'large' ? this.getLarge() : svg;
		svg = this.props.size === 'small' ? this.getSmall() : svg;
		return svg;
	}

	render() {
		return this.getIcon();
	}
}

IconFog.propTypes = {
	size: PropTypes.oneOf(['small', 'large']).isRequired,
};
