import React from 'react';

import Panel from '../components/panel';
import Email from '../components/email';
import Phonenumber from '../components/phonenumber';
import closeableOverlay from '../components/closeable-overlay';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

class StopDateNotification extends React.Component {
	render() {
		const { translations, labelInfo } = this.props;
		return (
			<Panel title={translations.title} name="stop-date-notification">
				<p>{translations.content}</p>
				<Email>
					<a href={`mailto:${labelInfo.email}`}>{labelInfo.email}</a>
				</Email>
				<Phonenumber>{labelInfo.phone_number}</Phonenumber>
			</Panel>
		);
	}
}

function mapStateToProps(state) {
	return {
		labelInfo: state.data.labels.info,
		translations: Object.assign(
			state.translations.overlay.stopDateNotification,
			state.translations.general
		),
	};
}

export default withRouter(
	closeableOverlay(connect(mapStateToProps)(StopDateNotification))
);
