import React, { Fragment, Component } from 'react';
import ReactTooltip from 'react-tooltip';
import PropTypes from 'prop-types';
import { renderToStaticMarkup } from 'react-dom/server';

import Icon from '../../../components/icon';
import ExpandMore from '../../../components/icons/expand-more';
import ExpandLess from '../../../components/icons/expand-less';

export default class AddressTooltip extends Component {
	constructor(props) {
		super(props);
		this.tooltipRef = null;
		this.arrowClasses = {
			top: 'top-arrow',
			bottom: 'bottom-arrow',
		};
	}

	static getAddressToString = address => {
		return (
			address.street +
			' ' +
			address.house_number +
			(address.house_number_extension
				? '/' + address.house_number_extension
				: '') +
			' ' +
			address.city
		);
	};

	static createAddressesToolTipId = agreement =>
		agreement.agreement_identifier_with_sequence;

	repositionTooltipTop = (tooltipRef, top) =>
		top < 0 && this.setTooltipTop(tooltipRef);

	repositionTooltipBottom = (tooltipRef, height, top) =>
		height > window.innerHeight - Math.abs(top) &&
		this.setTooltipBottom(tooltipRef);

	repositionTooltipLeft = (tooltipRef, left) =>
		left < 0 && this.setTooltipLeft(tooltipRef);

	getTooltipOffsetEdge = () => ({
		top: window.innerHeight < window.innerWidth ? 50 : 100,
		left: 20,
		bottom: window.innerHeight < window.innerWidth ? 50 : 100,
	});

	onTooltipScroll = e => {
		if (e.target.scrollTop > 20) {
			e.target.classList.add(this.arrowClasses.top);
		} else {
			e.target.classList.remove(this.arrowClasses.top);
		}
		if (
			e.target.scrollTop >
			e.target.scrollHeight - e.target.offsetHeight - 50
		) {
			e.target.classList.remove(this.arrowClasses.bottom);
		} else {
			e.target.classList.add(this.arrowClasses.bottom);
		}
	};

	removeScrollListener(tooltipRef) {
		tooltipRef.removeEventListener('scroll', this.onTooltipScroll);
	}

	addScrollIndicators(tooltipNodeRef, arrowClasses) {
		const {
			scrollHeight,
			clientHeight,
			classList,
			scrollTop,
			childNodes,
		} = tooltipNodeRef;

		if (scrollHeight > clientHeight) {
			classList.add(arrowClasses.bottom);
			if (scrollTop > 0) {
				classList.add(arrowClasses.top);
			}
			childNodes[1].style.top = clientHeight - 43 + 'px';
			tooltipNodeRef.addEventListener('scroll', this.onTooltipScroll);
		}
	}

	adjustTooltip(tooltipRef, arrowClasses) {
		const { height, top, left } = tooltipRef.getBoundingClientRect();
		this.repositionTooltipTop(tooltipRef, top);
		this.repositionTooltipBottom(tooltipRef, height, top);
		this.repositionTooltipLeft(tooltipRef, left);
		this.addScrollIndicators(tooltipRef, arrowClasses);
	}

	setTooltipBottom(tooltipRef) {
		tooltipRef.style.bottom = `${this.getTooltipOffsetEdge().bottom}px`;
	}

	setTooltipTop(tooltipRef) {
		tooltipRef.style.top = `${this.getTooltipOffsetEdge().top}px`;
	}

	setTooltipLeft(tooltipRef) {
		tooltipRef.style.left = `${this.getTooltipOffsetEdge().left}px`;
	}

	getIcons = () =>
		renderToStaticMarkup(
			<Fragment>
				<Icon icon={ExpandLess} size="small" color="rgba(0, 0, 0, 0.54)" />
				<Icon icon={ExpandMore} size="small" color="rgba(0, 0, 0, 0.54)" />
			</Fragment>
		);

	setTooltipRef = el => (this.tooltipRef = el.tooltipRef);

	getTooltipContent = agreement =>
		agreement.addresses
			.slice(1)
			.map(address => AddressTooltip.getAddressToString(address))
			.reduce(
				(previous, current, i) =>
					i === 1
						? [`${this.getIcons()}${previous}`, `<br />${current}`]
						: [`${previous}`, `<br />${current}`]
			);

	render() {
		const { id, agreement, tooltipPosition } = this.props;
		return (
			<ReactTooltip
				ref={el => el && this.setTooltipRef(el)}
				className="extra-tooltip address-tooltip"
				id={id}
				place={tooltipPosition}
				effect="solid"
				type="light"
				html={true}
				border={true}
				afterShow={() => this.adjustTooltip(this.tooltipRef, this.arrowClasses)}
				afterHide={() =>
					this.removeScrollListener(this.tooltipRef, this.arrowClasses)
				}
				delayHide={100}
				getContent={() => this.getTooltipContent(agreement)}
			/>
		);
	}
}

AddressTooltip.propTypes = {
	id: PropTypes.string.isRequired,
	agreement: PropTypes.object.isRequired,
	position: PropTypes.oneOf(['left', 'bottom', 'top', 'right']),
};
