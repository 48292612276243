import React from 'react';
import PropTypes from 'prop-types';
import './input-termamount.css';
import IconEdit from '../icons/mode-edit';
import Icon from '../icon';

class InputTermAmount extends React.Component {
	constructor(props) {
		super(props);
		this.focusOnInput = this.focusOnInput.bind(this);
		this.handleBlur = this.handleBlur.bind(this);
		this.state = { value: 0, init: true };
	}

	componentWillMount() {
		if (this.state.value === 0)
			this.setState({
				value: parseFloat(this.props.newAmount).toFixed(2),
				init: false,
			});
	}

	componentWillUpdate(nextProps) {
		if (
			parseFloat(nextProps.newAmount).toFixed(2) !==
			parseFloat(this.state.value).toFixed(2)
		) {
			if (this.state.init === true) {
				this.setState({ value: parseFloat(nextProps.newAmount).toFixed(2) });
				this.setState({ init: false });
			} else {
				this.setState({ value: nextProps.newAmount });
			}
		}
	}

	focusOnInput() {
		this.focusInput.focus();
		this.focusInput.select();
	}

	onInputChange = event => {
		var rx = new RegExp(/^\d+(?:\.\d{1,2})?$/);
		if (
			rx.test(event.target.value) ||
			event.target.value === '' ||
			(event.target.value.slice(-1) === '.' &&
				!event.target.value.slice(0, -1).includes('.', 0) &&
				!event.target.value.charAt(0).includes('.', 0))
		) {
			this.setState({ value: event.target.value });

			if (event.target.value) {
				this.props.onChange(event);
			}
		}
	};

	handleBlur() {
		this.setState({ value: parseFloat(this.state.value).toFixed(2) });
	}

	render() {
		const { minAmount, maxAmount, termAmountName, stepper } = this.props;
		return (
			<div>
				<label onClick={this.focusOnInput}>
					{termAmountName}:&nbsp;€&nbsp;
				</label>
				<input
					className={'input-field'}
					ref={input => {
						this.focusInput = input;
					}}
					lang={'en'}
					type={'text'}
					step={'any'}
					pattern={'d*'}
					inputMode={'numeric'}
					onChange={e => this.onInputChange(e)}
					onBlur={this.handleBlur}
					value={this.state.value}
					min={minAmount}
					max={maxAmount}
				/>
				{stepper}
				<button className={'button-size'} onClick={this.focusOnInput}>
					<Icon size="inline" className="edit-button__icon" icon={IconEdit} />
				</button>
			</div>
		);
	}
}

export default InputTermAmount;

InputTermAmount.propTypes = {
	onChange: PropTypes.func.isRequired,
	newAmount: PropTypes.any,
	minAmount: PropTypes.number,
	maxAmount: PropTypes.number,
	termAmountName: PropTypes.string,
	stepper: PropTypes.object,
};
