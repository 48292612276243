import React from 'react';
import PropTypes from 'prop-types';
import Icon from '../icon';
import IconCheck from '../icons/check';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import './input.css';

export const Validity = {
	VALID: 'valid',
	INVALID: 'invalid',
	UNKOWN: null,
};

const Input = ({
	type = 'text',
	onChange = () => {},
	disabled,
	required,
	validity = Validity.UNKOWN,
	returnEvent,
	selected,
	placeholderText,
	...props
}) => {
	const typeClass = `input--${type}`;
	const disabledClass = disabled ? 'input--disabled' : '';
	const requiredClass = required ? 'input--required' : '';
	const valueClass =
		props.value && props.value.length ? 'input--has-value' : '';

	let validityClass = '';
	if (validity) {
		validityClass =
			validity === Validity.VALID ? 'input--valid' : 'input--invalid';
	}

	return (
		<span
			className={`input ${typeClass} ${valueClass} ${disabledClass} ${requiredClass} ${validityClass}`.trim()}>
			{type === 'textarea' && type !== 'custom-date' ? (
				<textarea
					{...props}
					type={type}
					onChange={(event) => {
						return returnEvent ? onChange(event) : onChange(event.target.value);
					}}
					disabled={disabled}
					required={required}
				/>
			) : type === 'custom-date' ? (
				<DatePicker
					{...props}
					type={type}
					placeholderText={placeholderText}
					onChange={(value) => onChange(value)}
					disabled={disabled}
					required={required}
					selected={selected}
				/>
			) : (
				<input
					{...props}
					type={type}
					onChange={(event) => {
						return returnEvent ? onChange(event) : onChange(event.target.value);
					}}
					disabled={disabled}
					required={required}
					data-date-format="dd/mm/yyyy"
				/>
			)}

			{validity === Validity.VALID && <Icon icon={IconCheck} size="small" />}
		</span>
	);
};

export default Input;

Input.propTypes = {
	onChange: PropTypes.func,
	onFocus: PropTypes.func,
	disabled: PropTypes.bool,
	required: PropTypes.bool,
	type: PropTypes.oneOf([
		'text',
		'password',
		'date',
		'textarea',
		'number',
		'email',
		'custom-date',
	]),
	validity: PropTypes.oneOf([Validity.VALID, Validity.INVALID, null]),
	selected: PropTypes.object,
};
