import MuiAccordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import dateFormatter from '../../../utils/date-formatter';

import { Stack, Typography, Box, Divider, Button, Alert } from '@mui/material';
import { OffersAccordion } from './offers-accordion';

function LineItem({ label, value }) {
	return (
		<>
			<Stack
				direction="row"
				justifyContent="space-between"
				alignItems="center"
				spacing={2}
				height={50}>
				<span>{label}</span>
				<span>{value}</span>
			</Stack>
			<Divider />
		</>
	);
}

export function OfferComponent({
	order,
	translations,
	cancelOrder,
	paylink,
	expand,
	toggleCollapsible,
	paylinkLoading,
}) {
	const paymentEnabled = true;

	return (
		<MuiAccordion key={order.deal_reference}>
			<AccordionSummary
				expandIcon={
					<ExpandMoreIcon
						sx={{
							pointerEvents: 'auto',
						}}
					/>
				}
				aria-controls="panel1a-content"
				sx={{
					pointerEvents: 'none',
				}}
				id="panel1a-header">
				{order.status === 'EXPIRED' ? (
					<>
						<Typography
							sx={{
								pointerEvents: 'auto',
							}}
							variant="h5">{`${translations.status.EXPIRED}`}</Typography>
						<Divider
							orientation="vertical"
							flexItem
							sx={{ marginLeft: 1, marginRight: 1 }}
						/>
					</>
				) : (
					<>
						{(order.status === 'CREATED' ||
							order.status === 'AWAITING_PAYMENT' ||
							order.status === 'BOUGHT') && (
							<Button
								variant="outlined"
								color="error"
								size="small"
								sx={{
									pointerEvents: 'auto',
								}}
								onClick={(event) => cancelOrder(order, event)}>
								{translations.cancelOrder}
							</Button>
						)}
						<Divider
							orientation="vertical"
							flexItem
							sx={{ marginLeft: 1, marginRight: 1 }}
						/>
						{paymentEnabled
							? order.status === 'AWAITING_PAYMENT' && (
									<>
										<Button
											variant="outlined"
											color="success"
											size="small"
											loading={paylinkLoading}
											sx={{
												pointerEvents: 'auto',
											}}
											onClick={(event) => paylink(order, event)}>
											{translations.paylink}
										</Button>
										<Divider
											orientation="vertical"
											flexItem
											sx={{ marginLeft: 1, marginRight: 1 }}
										/>
									</>
							  )
							: null}
					</>
				)}
				<Typography
					sx={{
						pointerEvents: 'auto',
					}}
					variant="h5">{`${order.deal_reference}`}</Typography>
			</AccordionSummary>
			<Box mb={2} p={2}>
				{order.status === 'EXPIRED' && (
					<Alert sx={{ width: '100%' }} severity="warning">
						{translations.expiredOrderWarning}
					</Alert>
				)}
				<LineItem
					label={`${translations.totalCost}`}
					value={`€ ${order.amount}`}
				/>
				<LineItem
					label={translations.transfer_date}
					value={dateFormatter(order.transfer_date)}
				/>
				{order.expiration_date && (
					<LineItem
						label={translations.expirationDate}
						value={dateFormatter(order.expiration_date)}
					/>
				)}
				<LineItem label={translations.numberOfShares} value={order.shares} />
				<LineItem
					label={translations.status.label}
					value={translations.status[order.status]}
				/>
			</Box>
			<AccordionDetails>
				<Box sx={{ borderLeft: 2, borderColor: 'primary.main' }}>
					<Typography variant="h6" sx={{ pl: 1 }}>
						{translations.menu.myOrders}:
					</Typography>
					<Divider />
					{order.offers.map((offer) => (
						<OffersAccordion
							myOffer
							translations={translations}
							expand={expand.includes(offer.reference)}
							toggle={toggleCollapsible}
							key={offer.reference}
							offer={offer}
						/>
					))}
				</Box>
			</AccordionDetails>
		</MuiAccordion>
	);
}
