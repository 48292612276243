import React from 'react';
import PropTypes from 'prop-types';

import './error-text.css';

const ErrorText = ({ children, align = 'center', size = 'normal' }) => (
	<div
		className={`error-text error-text--align-${align} error-text--size-${
			size
		}`}>
		{children}
	</div>
);

export default ErrorText;

ErrorText.propTypes = {
	align: PropTypes.oneOf(['center', 'left', 'right']),
	size: PropTypes.oneOf(['small', 'normal']),
};
