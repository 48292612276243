import React, { Component } from 'react';
//import PropTypes from 'prop-types';
import Pagination from 'react-js-pagination';
import lodash from 'lodash';

import './table.css';

export default class Table extends Component {
	constructor(props) {
		super(props);

		this.state = {
			activePage: 1,
		};
	}

	handlePageChange = pageNumber => {
		this.setState({ activePage: pageNumber });
	};

	render() {
		const {
			children,
			headers,
			headerContent,
			items,
			className,
			emptyText,
			itemsCountPerPage,
			pageRangeDisplayed,
			showPagination,
		} = this.props;
		const listOfItemsChunk = itemsCountPerPage
			? lodash.chunk(items, itemsCountPerPage)
			: null;

		return (
			<React.Fragment>
				<table className={`table ${className ? className : ''}`.trim()}>
					<thead>
						{!headerContent &&
							headers &&
							headers.map((header, index) => {
								return (
									<tr key={`${index}-${header}`}>
										{Object.keys(header).map(key => {
											return (
												<th key={`header-${index}-${key}`}>{header[key]}</th>
											);
										})}
									</tr>
								);
							})}
						{headerContent && headerContent}
					</thead>
					<tbody>
						{children &&
							listOfItemsChunk &&
							listOfItemsChunk[+this.state.activePage - 1].map(
								(item, index) => {
									const el = children(item);
									return el.props.key
										? el
										: React.cloneElement(el, { key: index });
								}
							)}
						{!itemsCountPerPage &&
							items &&
							items.length &&
							items.map((item, index) => {
								const el = item;
								return el.props.key
									? el
									: React.cloneElement(el, { key: index });
							})}
						{!children &&
							listOfItemsChunk &&
							listOfItemsChunk.length &&
							listOfItemsChunk[+this.state.activePage - 1].map(
								(item, index) => {
									const el = item;
									return el.props.key
										? el
										: React.cloneElement(el, { key: index });
								}
							)}
						{!children &&
							!items && (
								<tr>
									<td>{emptyText}</td>
								</tr>
							)}
					</tbody>
				</table>
				{showPagination && (
					<div className="pagination-wrapper">
						<Pagination
							firstPageText={<i className="material-icons">first_page</i>}
							lastPageText={<i className="material-icons">last_page</i>}
							prevPageText={
								<i className="material-icons">keyboard_arrow_left</i>
							}
							nextPageText={
								<i className="material-icons">keyboard_arrow_right</i>
							}
							activePage={this.state.activePage}
							itemsCountPerPage={itemsCountPerPage}
							totalItemsCount={items ? items.length : 0}
							pageRangeDisplayed={pageRangeDisplayed}
							onChange={this.handlePageChange}
						/>
					</div>
				)}
			</React.Fragment>
		);
	}
}

Table.propTypes = {
	// items: PropTypes.array.isRequired,
	// emptyText: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
	// className: PropTypes.string,
};
