export default (state = [], action) => {
	switch (action.type) {
		case 'LATEST_INVOICES_DATA':
			return action.data;
		default:
			return state;
	}
};

export const action = {
	data: (data) => ({ type: 'LATEST_INVOICES_DATA', data }),
};
