import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import DownloadLink from '../download-link';
import DateTime from '../date-time';
import Currency from '../currency';
import Tooltip from '../tooltip';
import './invoice.css';
import {
	FORMAT_DM_UTC,
	FORMAT_DMY,
	FORMAT_NO_DATE,
} from '../date-time/date-time';
import IconInfo from '../icons/info';
import Icon from '../../components/icon';

const Invoice = ({
	type,
	typeText,
	periodStartDate,
	periodEndDate,
	amount,
	balanceText,
	downloadLink,
	downloadLinkText,
	id,
	settlement,
	translations,
}) => {
	return (
		<tr className="invoice">
			<td>
				<span className="invoice__mobile-label">
					{translations.invoicesTable.body.period}:{' '}
				</span>
				<div>{getDate(periodStartDate, periodEndDate, type)}</div>
			</td>
			<td>
				<span className="invoice__mobile-label">
					{translations.invoicesTable.body.type}:{' '}
				</span>
				<span>{strongInvoice(type, typeText)}</span>
			</td>
			<td>
				<span className="invoice__mobile-label">
					{translations.invoicesTable.body.price}:{' '}
				</span>
				<span>
					<Currency amount={amount} />
				</span>
			</td>
			<td className="invoice__icon">
				<span className="invoice__mobile-label">
					{translations.invoicesTable.body.balance}:{' '}
				</span>
				<span className="invoice__balance">{balanceText}</span>
				{settlement && (
					<Fragment>
						<span className="info-icon" data-tip="" data-for={id}>
							<Icon icon={IconInfo} size="large" />
						</span>
						<Tooltip
							id={id}
							className="invoice-tooltip"
							position={'bottom'}
							offset={getTooltipOffset()}
							content={getTooltipContent(settlement, translations)}
						/>
					</Fragment>
				)}
			</td>
			<td>
				{downloadLink && (
					<DownloadLink link={downloadLink} className="primary-text-color">
						{downloadLinkText}
					</DownloadLink>
				)}
			</td>
		</tr>
	);
};

function getTooltipOffset() {
	return window.innerWidth <= 767 ? { left: 80 } : { left: 50 };
}

function getTooltipContent(settlement, translations) {
	const {
		title,
		invoice,
		price,
		type,
		restAmount,
		paymentReference,
	} = translations.invoiceTooltip;
	return (
		<div className="tooltip-content">
			<div className="headline">{title}</div>
			<table>
				<thead>
					<tr>
						<th>{invoice}</th>
						<th>{price}</th>
						<th>{type}</th>
					</tr>
				</thead>
				<tbody>
					<tr>
						<td>{settlement.receivable.invoice_reference}</td>
						<td>
							<Currency amount={settlement.receivable.amount_value} />
						</td>
						<td>
							{strongInvoice(
								settlement.receivable.invoice_type,
								translations.invoiceType[settlement.receivable.invoice_type]
							)}
						</td>
					</tr>
					{settlement.payables.map((payable) => {
						return (
							<tr key={payable.invoice_reference}>
								<td>{payable.invoice_reference}</td>
								<td>
									<Currency amount={-payable.amount_value} />
								</td>
								<td>
									{strongInvoice(
										payable.invoice_type,
										translations.invoiceType[payable.invoice_type]
									)}
								</td>
							</tr>
						);
					})}
				</tbody>
			</table>
			<hr />
			<div className="rest-amount">
				{restAmount}: <Currency amount={settlement.rest_amount} />
			</div>
			<div className="payment-reference">
				{paymentReference}: {settlement.payment_reference}
			</div>
		</div>
	);
}

function getDate(periodStartDate, periodEndDate, type) {
	return (
		<span className="invoice__date">
			<span className="input__date-row">
				<DateTime
					date={periodStartDate}
					format={
						periodStartDate === null
							? FORMAT_NO_DATE
							: type === 'INVOICE'
							? FORMAT_NO_DATE
							: type === 'PERIODICAL'
							? FORMAT_DM_UTC
							: FORMAT_DMY
					}
				/>
				{type === 'INVOICE' ? null : <span>&nbsp;-&nbsp;</span>}
				<DateTime
					date={periodEndDate}
					format={
						periodEndDate === null
							? FORMAT_NO_DATE
							: type === 'INVOICE'
							? FORMAT_NO_DATE
							: type === 'PERIODICAL'
							? FORMAT_DM_UTC
							: FORMAT_DMY
					}
				/>
			</span>
		</span>
	);
}

function strongInvoice(type, typeText) {
	if (type === 'FINAL' || type === 'ANNUAL')
		return (
			<strong className="invoice__type">
				<span>{typeText}</span>
			</strong>
		);
	else return <span className="invoice__type">{typeText}</span>;
}

export default Invoice;

Invoice.propTypes = {
	type: PropTypes.oneOf([
		'INVOICE',
		'PERIODICAL',
		'FINAL',
		'ANNUAL',
		'MAR',
		'MEAR',
		'DISCOUNT',
	]),
	typeText: PropTypes.string,
	issueDate: PropTypes.string,
	dueDate: PropTypes.string,
	amount: PropTypes.number,
	balanceText: PropTypes.string,
	downloadLink: PropTypes.string,
	downloadLinkText: PropTypes.string,
	periodStartDate: PropTypes.string,
	periodEndDate: PropTypes.string,
	translations: PropTypes.object.isRequired,
};
