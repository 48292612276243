import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Field, reduxForm, formValueSelector } from 'redux-form';
import moment from 'moment';
import dateFormat from '../../utils/date-iso-format';

import api from '../../api';

import Row from '../../components/row';
import PageTitle from '../../components/page-title';
import FieldSet from '../../components/fieldset';
import Input from '../../components/input';
import Panel from '../../components/panel';
import { action as notificationsAction } from '../../reducers/notifications';

import './iban.css';
import { withRouter } from 'react-router-dom';
import { selector as userInfoSelector } from '../../reducers/data/user-info';

class Iban extends Component {
	constructor(props) {
		super(props);

		this.state = {
			startDate: null,
			canEdit: false,
			cantEditDate: null,
			iban: null,
		};
	}

	componentDidMount() {
		this.setState({
			startDate: this.props.fromDate ? moment(this.props.fromDate) : null,
		});
		this.getIbanStatus();
	}

	getIbanStatus = () => {
		api.relations.bancAccountNumber.get().then((res) => {
			if (res.status === 204) {
				this.setState({ canEdit: true });
			} else {
				if (res.data) {
					this.setState({ canEdit: false });

					this.replaceCantEditMsg(res.data);
				}
			}
		});
	};

	replaceCantEditMsg = (res) => {
		const date = res.from_date.split('-');
		this.setState({
			cantEditDate: moment(new Date(date[0], +date[1] - 1, date[2])).format(
				'DD/MM/YYYY'
			),
			iban: res.change_bank_account_number,
		});
	};

	renderInputField = (field) => {
		return (
			<FieldSet
				label={field.label}
				focus={field.meta.active}
				name={field.name}
				className="text-area-placeholder"
				disabled={field.disabled}
				error={field.meta.touched ? field.meta.error : ''}
				validity={this.checkValidity(field)}
				hasValue={field.hasValue ? field.hasValue : field.input.value}
				required={true}>
				{field.input.name === 'ibanNumber' ? (
					<Input
						type={field.type}
						{...field.input}
						returnEvent={field.returnEvent}
						onChange={field.ibanOnChange}
					/>
				) : (
					<Input type={field.type} {...field.input} />
				)}
			</FieldSet>
		);
	};

	renderDateInputField = (field) => {
		return (
			<FieldSet
				label={field.label}
				focus={field.meta.active}
				name={field.name}
				disabled={field.disabled}
				error={field.meta.error}
				validity={this.checkValidity(field)}
				hasValue={field.hasValue ? field.hasValue : field.input.value}
				required={true}
				className="text-area-placeholder">
				<Input
					type={'custom-date'}
					{...field.input}
					selected={this.state.startDate}
					onChange={field.dateOnChange}
					onBlur={() => {}}
				/>
			</FieldSet>
		);
	};

	checkBankAccountNameValidity = (field) => {
		function _bankAccountNameTouchedValidity() {
			return !field.meta.error &&
				field.input.value &&
				field.input.value === field.inputValue
				? null
				: 'invalid';
		}

		if (field.meta.touched) {
			return !field.meta.error && field.input.value ? 'valid' : 'invalid';
		} else {
			if (!field.input.value) {
				return null;
			}
			_bankAccountNameTouchedValidity();
		}
	};

	checkInputValidity = (field) => {
		return field.meta.touched &&
			!field.meta.error &&
			field.input.value !== field.inputValue
			? 'valid'
			: 'invalid';
	};

	checkValidity = (field) => {
		if (field.input.name === 'bankAccountName') {
			this.checkBankAccountNameValidity(field);
		} else if (field.meta.touched) {
			this.checkInputValidity(field);
		}
		return null;
	};

	onSubmit = (state) => {
		const { dispatch, translations } = this.props;
		const { ibanNumber, bankAccountName, fromDate } = state;
		let formDateFormatted;
		let formDateSplitted;
		formDateSplitted = fromDate.split('/');
		formDateFormatted = `${formDateSplitted[2]}-${formDateSplitted[1]}-${
			formDateSplitted[0]
		}`;

		api.relations.bancAccountNumber
			.put({
				change_bank_account_number: ibanNumber,
				change_bank_account_name: bankAccountName,
				from_date: formDateFormatted,
			})
			.then((res) => {
				notificationsAction.add(
					dispatch,
					translations.validations.requestIsBeingProcessed
				);
				this.getIbanStatus();
			})
			.catch((err) => {
				notificationsAction.add(
					dispatch,
					translations.validations.ibanNotValid,
					'invalid'
				);
			});
	};

	ibanOnChange = (event) => {
		let value = event.target.value.replace(/[^a-z0-9]/gi, '');
		this.props.dispatch(this.props.change('ibanNumber', value.toUpperCase()));
	};

	dateOnChange = (value) => {
		this.props.dispatch(this.props.change('fromDate', dateFormater(value)));
		this.setState({ startDate: value });
	};

	renderIbanStatusMsg = () => {
		const { translations } = this.props;
		return (
			!this.state.canEdit &&
			this.state.iban && (
				<span>
					{translations.ibanStatus} <strong>{this.state.iban}</strong>{' '}
					{translations.ibanStatusAt} <strong>{this.state.cantEditDate}</strong>
				</span>
			)
		);
	};

	render() {
		const {
			translations,
			handleSubmit,
			invalid,
			pristine,
			relations,
			isUserImpersonator,
		} = this.props;
		let ibanChangeInProgress = true;
		if (!this.state.canEdit) {
			ibanChangeInProgress = false;
		} else {
			ibanChangeInProgress = !(invalid || pristine);
		}

		return (
			<div className="view-iban">
				<Row>
					<PageTitle>{translations.title}</PageTitle>
				</Row>
				<form onSubmit={handleSubmit(this.onSubmit)}>
					<Panel
						rightLinkText={translations.save}
						rightButtonHandler={
							!ibanChangeInProgress || isUserImpersonator ? null : () => {}
						}>
						{this.renderIbanStatusMsg()}
						{relations && (
							<Field
								name="ibanNumber"
								label={translations.ibanLabel}
								disabled={!this.state.canEdit}
								type="text"
								inputValue={relations.bank_account_number}
								ibanOnChange={(e) => this.ibanOnChange(e)}
								component={this.renderInputField}
								returnEvent={true}
							/>
						)}
						{relations && (
							<Field
								name="bankAccountName"
								label={translations.bankAccountName}
								disabled={!this.state.canEdit}
								type="text"
								inputValue={relations.bank_account_name}
								component={this.renderInputField}
							/>
						)}
						<Field
							name="fromDate"
							label={translations.fromDate}
							disabled={!this.state.canEdit}
							type="date"
							hasValue={true}
							component={this.renderDateInputField}
							dateOnChange={this.dateOnChange}
						/>
					</Panel>
				</form>
			</div>
		);
	}
}

function validate(values, props) {
	let errors = {};
	const { translations, relations } = props;
	const now = new Date();
	now.setDate(now.getDate() + 1);
	const currentDateTime = new Date(
		now.getFullYear(),
		now.getMonth(),
		now.getDate(),
		0,
		0,
		0
	);
	const formDateObject = new Date(dateFormat(values.fromDate));
	const fromDateFormated = values.fromDate
		? new Date(
				formDateObject.getFullYear(),
				formDateObject.getMonth(),
				formDateObject.getDate(),
				0,
				0,
				0
		  )
		: null;

	function _checkIbanNumber() {
		if (!values.ibanNumber) {
			errors.ibanNumber = translations.validations.ibanRequired;
		} else if (
			(relations && values.ibanNumber === relations.bank_account_number) ||
			values.ibanNumber.length < 15 ||
			values.ibanNumber.length > 31
		) {
			errors.ibanNumber = translations.validations.ibanNotValid;
		}
	}

	function _checkBankAccountName() {
		if (!values.bankAccountName) {
			errors.bankAccountName = translations.validations.bankAccountNameRequired;
		}

		if (values.bankAccountName && values.bankAccountName.length < 3) {
			errors.bankAccountName = translations.validations.bankAccountNameRequired;
		}

		if (values.bankAccountName && values.bankAccountName.length > 255) {
			errors.bankAccountName = translations.validations.bankAccountNameToLong;
		}
	}

	function _checkFromDate() {
		if (!values.fromDate) {
			errors.fromDate = translations.validations.startDateRequired;
		}
		if (
			values.fromDate &&
			fromDateFormated.getTime() < currentDateTime.getTime()
		) {
			errors.fromDate = translations.validations.startDateMessage;
		}
	}

	_checkIbanNumber();
	_checkBankAccountName();
	_checkFromDate();

	return errors;
}

Iban.propTypes = {
	/** Object containing the translations for this view and the general
	 * translations combined */
	translations: PropTypes.object.isRequired,
};

const selector = formValueSelector('selectingFormValues');

function dateFormater(value) {
	const date = new Date(value);

	return moment(
		new Date(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0)
	)
		.format('DD/MM/YYYY')
		.toString();
}

function mapStateToProps(state, ownProps) {
	/**
	 * set initial date for date input to be todays day +1
	 */
	const date = new Date();
	date.setDate(date.getDate() + 1);

	return {
		initialValues: {
			ibanNumber: state.data.relations
				? state.data.relations.bank_account_number
				: '',
			bankAccountName: state.data.relations
				? state.data.relations.bank_account_name
				: '',
			fromDate: dateFormater(date),
		},
		ibanNumber: selector(state, 'ibanNumber'),
		bankAccountName: selector(state, 'bankAccountName'),
		fromDate: selector(state, 'fromDate'),
		relations: state.data.relations,
		isUserImpersonator: userInfoSelector.isUserImpersonator(state.data),
		translations: Object.assign(
			state.translations.iban,
			state.translations.general
		),
	};
}

Iban = reduxForm({
	validate,
	form: 'IbanForm',
	enableReinitialize: true,
})(Iban);

export default withRouter(connect(mapStateToProps)(Iban, mapStateToProps));
