export default {
	menu: {
		home: {
			title: 'Home',
		},
		dashboard: {
			title: 'Dashboard',
		},
		usage: {
			title: 'Meterstanden',
		},
		finance: {
			title: 'Financieel',

			menu: {
				invoices: 'Facturen',
				agreements: 'Overeenkomsten',
				monthlyAmount: 'Termijnbedrag',
			},
		},
		administrative: {
			title: 'Online doorgeven',

			menu: {
				// metermeasuring: 'Meterstanden',
				move: 'Verhuizen',
				iban: 'IBAN wijzigen',
			},
		},
		contact: {
			title: 'Contact',
		},
		language: {
			title: 'Taal wijzigen',
			nl: 'Nederlands',
			en: 'English',
		},
		myAccount: {
			title: 'Mijn account',

			menu: {
				account: {
					title: 'Mijn gegevens',
				},
				logout: {
					title: 'Uitloggen',
				},
			},
		},
		production: {
			title: 'Productie Historie',
		},
	},
	postCodestRoomcooperatief: {
		home: {
			title: 'Home',
		},
		yourParticipation: 'Uw participatie',
		contact: {
			title: 'Contact',
		},
	},
	general: {
		locale: 'nl-NL',
		agreementSingular: 'Overeenkomst',
		agreementsPlural: 'Overeenkomsten',
		dateFormat: 'nl-NL',
		impersonatorBannerText: 'Je gebruikt dit als een impersonator',
		marketMessage_1024:
			'Leuk dat u (extra) participaties wil aanschaffen. Hou er rekening mee dat u niet meer energie kan opwekken met participaties dan u jaarlijks verbruikt. Heeft u ook zonnepanelen of Windvangers? Haal de productie van je zonnepanelen/Windvangers (125 kWh per stuk) ook van het verbruik af.',
		marketMessage_1071:
			'Leuk dat u (extra) Winddelen wil aanschaffen. Hou er rekening mee dat u niet meer energie kan opwekken met Winddelen dan u jaarlijks verbruikt. Heb je ook zonnepanelen? Haal de zonnepanelen-productie dan ook van het verbruik af.',
		commodities: {
			GAS: 'Gas',
			ELECTRICITY: 'Elektriciteit',
		},
		meterTypes: {
			SMART: 'Slimme meter',
			CONVENTIONAL: 'Conventionele meter',
		},
		clientNumber: 'Inlogcode',
		relationNotSynced:
			'Uw gegevens zijn tijdelijk niet beschikbaar. Probeer het later nog eens.',
		iban: 'IBAN',
		cocNumber: 'KVK nummer',
		vatNumber: 'BTW nummer',
		paymentMethod: 'Betaalwijze',
		paymentMethods: {
			DEFAULT: 'Gebruik standaard betaalmethode',
			DIRECT_DEBIT_BUSINESS: 'Zakelijke SEPA Automatische incasso',
			DIRECT_DEBIT_CONSUMER: 'Automatische incasso',
			IDEAL: 'iDEAL',
			INVOICE: 'Digitale factuur',
			LABEL: 'Betaalmethode',
			LABEL_DEFAULT: 'Standaard betaalmethode',
			LABEL_ITEM: 'Betaalmethode',
		},
		invoiceForm: 'Factuurvorm',
		invoiceBalance: {
			OUTSTANDING: 'Open',
			PAID: 'Betaald',
			REFUND: 'Teruggave',
			CANCELLED: 'Open',
			OPEN: 'Open',
			CLOSED: 'Betaald',
			SETTLED: 'Verrekend',
			WRITE_OFF: 'Open',
			INVALID: 'Open',
			IN_SETTLEMENT: 'Open',
			OVERPAYMENT: 'Betaald',
			PAYMENT_IN_PROGRESS: 'Betaling in uitvoering',
		},
		invoiceType: {
			INVOICE: 'Factuur',
			PERIODICAL: 'Termijnnota',
			ANNUAL: 'Jaarrekening',
			FINAL: 'Eindafrekening',
			MAR: 'Maandelijkse afrekening nota',
			MEAR: 'Eindafrekening',
			DISCOUNT: 'Welkomstkorting',
		},
		error: {
			introduction: 'Het spijt ons,',
			noData: 'deze data is niet beschikbaar',
			oops: 'Oeps...',
			dateFormat: 'Voer op de volgende manier de datum in: dd/mm/yyyy',
			required: 'Dit veld is verplicht',
			noPastDate: 'U kunt geen datum eerder dan vandaag invullen',
			minTwoDays: 'U kunt geen datum eerder dan over 2 dagen selecteren',
			invalidEmail: 'Vul aub een geldig emailadres in',
			startBeforeStop:
				'De datum waarop u de oude aansluiting deactiveert moet op of na het activeren van de nieuwe aansluiting plaatsvinden',
			badSituation: [
				'Op dit moment kunnen we uw huidige situatie niet goed weergeven. Dit komt voor als wij op meerdere adressen of aansluitingen voor u leveren, bijvoorbeeld tijdens een verhuizing.',
				'Is dit bij u niet het geval? Dan is het een bekende foutmelding en werken we hard aan een oplossing, houd de komende dagen onze website in de gaten om weer in te kunnen loggen.',
				'Onze excuses voor het ongemak.',
			],
			notFound: 'Pagina niet gevonden',
			invalidAddress: 'Ongeldig adres',
			invalidZipCode: 'Ongeldig postcode',
			noProjects:
				'Op dit moment zijn geen participaties in een project geregistreerd.',
		},
		save: 'opslaan',
		cancel: 'annuleren',
		search: 'Zoeken',
		confirm: 'ok',
		send: 'Verzenden',
		edit: 'Wijzigen',
		next: 'volgende',
		previous: 'vorige',
		startDate: 'Startdatum',
		stopDate: 'Einddatum',
		invoice: 'Factuur',
		invoiceDownloadText: 'FACTUUR',
		required: 'Dit veld is verplicht',
		months: [
			'januari',
			'februari',
			'maart',
			'april',
			'mei',
			'juni',
			'juli',
			'augustus',
			'september',
			'oktober',
			'november',
			'december',
		],
		shortMonths: [
			'jan',
			'feb',
			'mrt',
			'apr',
			'mei',
			'jun',
			'jul',
			'aug',
			'sep',
			'okt',
			'nov',
			'dec',
		],
		datePlaceholder: 'dd/mm/yyyy',
		onlyForSmartMeters:
			'Deze functionaliteit wordt voor conventionele meters niet ondersteund',
		footer: {
			privacyLink: 'Privacy- en cookiebeleid',
		},
		logout: 'Uitloggen',
		openingHoursDays: {
			'ma-vr': 'ma-vr',
			'maandag t/m vrijdag': 'maandag t/m vrijdag',
		},
		pendingText:
			'Uw aanvraag voor toegang tot de mijn-omgeving en winddelen-markt hebben wij in goede orde ontvangen. Deze zal worden beoordeeld door Greenchoice, en na goedkeuring ontvangt u inloggegevens per e-mail. Hiermee kunt u direct inloggen op de mijn-omgeving en inzicht krijgen in de markt.',
		downloadHistoricalDataLabel: 'Download historische data',
		newRegulations:
			'In november en december compenseert de overheid uw energierekening. Het gaat om twee keer een bedrag van €190,- dat eind november en eind december wordt verrekend. In oktober wordt uw normale termijnbedrag afgeschreven. U heeft van ons een e-mail ontvangen met meer informatie over de compensatie.',
		wrongVatMessage:
			"Als je een mail hebt ontvangen over een verkeerde berekening van de btw: je kan je correcte termijnbedrag vinden in je laatste factuur onder het tabje 'facturen'",
		priceCapMessage:
			'In onderstaande berekening is het prijsplafond voor energie meegenomen',
		sspOffline:
			'In de ochtend van 29 maart voeren we een gepland onderhoud aan de portal uit, deze is dan niet beschikbaar. Excuses voor het ongemak.',
	},
	overlay: {
		changePhoneNumber: {
			title: 'Wijzig telefoonnummer',
			new: 'Nieuw telefoonnummer',
			invalid: 'Vul een geldig telefoonnummer in.',
			phoneNumberErrorMessage:
				'Er is iets misgegaan bij het opslaan van het telefoonnummer.',
			phoneNumberErrorFakeNumberMessage:
				'Dit telefoonnummer is niet geldig. Voer een geldig telefoonnummer in.',
			search: 'Zoeken',
			save: 'opslaan',
			cancel: 'annuleren',
		},
		changeEmailAddress: {
			title: 'Wijzig e-mailadres',
			new: 'Nieuw e-mailadres',
			invalid: 'Het ingevulde e-mailadres is ongeldig',
		},
		changeAccountUsername: {
			title: 'Wijzig gebruikersnaam',
			new: 'Nieuwe gebruikersnaam',
			invalid: {
				length: 'De gebruikersnaam moet uit minimaal 6 tekens bestaan',
				unique: 'De gekozen gebruikersnaam is al in gebruik',
			},
		},
		changeAccountPassword: {
			title: 'Verander uw wachtwoord',
			oldPassword: 'Vul oude wachtwoord in',
			newPassword: 'Vul uw nieuwe wachtwoord in',
			confirmPassword: 'Bevestig nieuwe wachtwoord',
			matchMessage: 'Vul aub hetzelfde wachtwoord in.',
			patternMessage:
				'Het nieuwe wachtwoord moet minimaal bestaan uit 1 nummer, 1 hoofdletter en 1 kleine letter, en moet tussen de 6 en 50 tekens lang zijn.',
			successMessage: 'Wachtwoord succesvol aangepast',
		},

		monthlyAmountInfo: {
			title: 'Het termijnbedrag',
			content: [
				'Wij bepalen het termijnbedrag op basis van uw historische verbruik zoals dat door uw netbeheerder wordt bijgehouden. Met die inschatting proberen we te voorkomen dat u aan het einde van het jaar voor verrassingen komt te staan.',
				'Verwacht u meer of minder energie te gaan verbruiken of wilt u bijvoorbeeld liever een iets hoger termijnbedrag om zeker te weten dat u op de jaarrekening niet bij hoeft te betalen? Dan kunt u het termijnbedrag zelf aanpassen.',
			],
		},
		periodicalPayment: {
			title: 'Wijzig termijnbedrag',
			requested: 'Termijnbedrag',
			current: 'Huidig Termijnbedrag',
			proposed: 'Voorgesteld',
			errorMessage: 'Fout bij bijwerken van periodieke betaling!',
		},
		stopDateNotification: {
			title: 'Einddatum',
			content:
				'Wanneer u een datum invult, beëindigen wij op die dag de levering op uw oude adres. Weet u nog niet wanneer u de levering op het oude adres wilt beëindigen, vul dan niets in. De levering wordt dan gewoon voortgezet, ook op uw oude adres. Wanneer u weet wanneer de levering beëindigd moet worden, neem dan contact met ons op.',
		},
		stopDateAcknowledgeEffects: {
			title: 'Einddatum',
			content:
				'U heeft een einddatum ingevuld. Dit is optioneel en heeft gevolgen.',
		},
	},
	view404: {
		title: 'Pagina niet gevonden',
	},
	error: {
		title: 'Error',
	},
	verifyEmail: {
		verifiedMessage:
			'Het emailadres is succesvol bevestigd, onze dank hiervoor.',
		contactMessage:
			'Mochten er nog vragen zijn, kan er contact met ons opgenomen worden middels onderstaande contact gegevens.',
		alreadyVerifiedMessage: 'Dit emailadres is al geverifieerd.',
		unverifiedMessage:
			'Er is helaas iets misgegaan. Neem contact met ons op via onderstaande contactgegevens.',
	},
	confirmReactivation: {
		reactivatedMessage:
			'Er is met succes een mail gestuurd met daarin uw tijdelijke wachtwoord. Log in met deze gegevens ' +
			'en kies vervolgens zelf een nieuw wachtwoord om uw account volledig te activeren.',
		contactMessage:
			'Mochten er nog vragen zijn, kan er contact met ons opgenomen worden middels onderstaande contact gegevens.',
		alreadyReactivatedMessage: 'Dit account is al eerder geactiveerd.',
		reactivationFailedMessage:
			'Er is helaas iets misgegaan. Neem contact met ons op via onderstaande contactgegevens.',
	},
	underConstruction: {
		website: 'Deze website',
		mode: 'is in onderhoudsmodus',
		message:
			'We zijn maandag weer online. Probeer het dan of op een later moment nog eens.',
	},
	signIn: {
		signIn: 'Inloggen',
		username: 'Gebruikersnaam',
		password: 'Wachtwoord',
		forgotPassword: 'Wachtwoord vergeten?',
		reactivateAccount: 'Reactiveer account',
		migratedLabelLinkMsg: 'U kunt inloggen op uw persoonlijke account van',
		migratedLabelDetailsMsg:
			'Via e-mail zijn de nieuwe inloggegevens naar u toe gestuurd.',
		migratedLabelLinkMsg_1024:
			'Nam u energie af bij Windcentrale? Alles over energie kunt u vinden in uw persoonlijke ',
		migratedLabelLink_1024: 'Coolblue',
		migratedLabelDetailsMsg_1024:
			' account. Via e-mail zijn de Coolblue inloggegevens naar u toegestuurd.',
		impersonatorMessage:
			'Inloggen als impersonator kan niet. Om als impersonator in te loggen moet je dat aanklikken vanuit Multiforce.',
	},
	requireNewPassword: {
		title: 'Nieuw wachtwoord instellen',
		changePassword: 'Kies een nieuw wachtwoord',
		newPassword: 'Nieuw wachtwoord',
		submit: 'Opslaan',
	},
	passwordConfirm: {
		placeholder: 'Bevestig wachtwoord',
		matchMessage: 'Voer hetzelfde wachtwoord in',
	},
	forgotPassword: {
		sendCodeTitle: 'Wachtwoord vergeten',
		newPasswordTitle: 'nieuw wachtwoord instellen',
		enterUsernameText:
			'Vul de gebruikersnaam in. We sturen dan een e-mail met een verificatie code naar het bij ons bekende adres om een nieuw wachtwoord in te kunnen stellen.',
		username: 'Gebruikersnaam',
		lostUsername:
			'Gebruikersnaam kwijt? Neem dan contact op met de klantenservice: ',
		sendCode: 'stuur verificatie code',
		securityCode: 'Verificatie code',
		newPassword: 'Nieuw wachtwoord',
		changePassword: 'Code controleren en wachtwoord opslaan',
		backToSignInLink: 'Terug naar Inloggen',
		resendCode: 'Opnieuw versturen Verificatie code',
		verificationCodeText:
			'Vul de verificatie code uit de email in, en kies een nieuw wachtwoord.',
	},
	reactivateAccount: {
		title: 'Activeer het gebruik van uw account',
		successMessage:
			'Als het emailadres correct is, ontvangt u een email met instructies om uw account te (re)activeren.',
		emailAddressErrorMessage:
			'Sorry, we kunnen uw verzoek niet verwerken, neem aub contact op met de helpdesk',
		placeholderText: 'Vul uw emailadres in',
		buttonText: 'Verzend activatiemail',
		invalidEmailMessage: 'Vul aub een geldig emailadres in',
		explainMessage:
			'Is uw tijdelijk wachtwoord om uw account te activeren verlopen? Vul dan hier uw e-mailadres in om om een nieuwe activatiemail te ontvangen.' +
			"Als u uw wachtwoord bent vergeten, gan dan terug naar het inloggen en klik op 'Wachtwoord vergeten?'.",
	},
	dashboard: {
		title: 'Dashboard',
		customerService: {
			title: 'Klantenservice',
			contactForm: 'Contactformulier',
		},
		latestInvoices: {
			title: 'Facturen',
			buttonText: 'Alle facturen',
		},
		quickstart: {
			title: 'Snel naar',
			agreements: 'Overeenkomsten',
			account: 'Mijn gegevens',
			periodicalPayments: 'Termijnbedrag aanpassen',
			move: 'Verhuizing doorgeven',
			invoices: 'Facturen',
		},
	},
	account: {
		title: 'Mijn gegevens',
		collapsibles: {
			contactInfo: {
				title: 'Contactgegevens',
				changedEmail: 'Emailadres wordt gewijzigd naar',
				changedPhoneNumber: 'Telefoonnummer wordt gewijzigd naar',
			},
			companyInfo: {
				title: 'Bedrijfsgegevens',
			},
			privacyInfo: {
				title: 'Privacy',
				privacyConsentButtonLabel: 'Historische data',
				privacySectionTitleHistorical: 'Historische data toestemming',
				privacyConsentTooltip:
					'Door op deze knop te drukken geeft u toestemming om alle historische data tot een maximum van 24 maanden op te vragen voor alle connecties die bij uw contracten gebruikt zijn.',
				privacyConsentHelper: 'Je hebt al toestemming gegeven.',
				privacyGiveConsent: 'Geef toestemming',
				privacyRevokeConsent: 'Trek toestemming in',
				privacyConsentGivenButtonLabel: 'Haal meest recent historische data op',
				privacyConsentNotGivenButtonLabel: 'Haal historische data op',
				privacyConsentDateLabel: 'Eerder opgehaald op ',
			},
			shippingAddress: {
				title: 'Leveradres',
			},
			paymentDetails: {
				title: 'Betaalgegevens',
				bankAccountName: 'Tenaamstellling',
				debtorNumber: 'Debiteurnummer',
			},
			movings: {
				title: 'Verhuizingen',
				contactAddress: 'Contactadres',
				newAddress: 'Nieuw adres',
				movingDate: 'Verhuisdatum',
				noMoves: 'Er zijn (nog) geen verhuizingen',
			},
			orders: {
				ordersToExpire: 'Bijna verlopen orders',
			},
		},
	},
	contact: {
		title: 'Contact',
		form: {
			title: 'Stel een vraag',
			customerServiceTitle: 'Klantenservice',
			subject: 'Kies een onderwerp',
			yourMessage: 'Uw bericht',
			subjectOptions: [
				{ label: 'Betalingen', value: 'Betalingen' },
				{ label: 'Producten', value: 'Producten' },
				{ label: 'Verbruiken', value: 'Verbruiken' },
				{ label: 'Verhuizing', value: 'Verhuizing' },
				{
					label: 'Suggestie/klacht',
					value: 'Suggestie/klacht',
				},
				{ label: 'Iets anders', value: 'Iets anders' },
			],
		},
		feedback: 'Het bericht is verstuurd',
	},
	home: {
		title: 'Home',
		contactInfo: {
			title: 'Mijn gegevens',
			rightLinkText: 'meer',
			changedEmail: 'Emailadres wordt gewijzigd naar',
			changedPhoneNumber: 'Telefoonnummer wordt gewijzigd naar',
		},
		periodicalPayment: {
			title: 'Termijnbedrag',
			rightLinkText: 'meer',
			changeRequested: 'Wordt gewijzigd naar',
		},
		contact: {
			title: 'Contact',
			rightLinkText: 'Contactformulier',
		},
		invoices: {
			title: 'Facturen',
			rightLinkText: 'Bekijk alle facturen',
			noInvoices: 'Er zijn nog geen facturen',
		},
		move: {
			title: 'Verhuizen',
			rightLinkText: 'Doorgeven',
			content: 'Wij regelen alles zodat u niet zonder energie komt te zitten!',
		},
		metermeasurements: {
			metermeasurements: 'Meterstanden',
			rightLinkText: 'meer',
		},
	},
	invoices: {
		title: 'Facturen',
		invoicePeriodDate: 'periode',
		agreement: 'Overeenkomst',
		invoicesTable: {
			headers: [
				{
					period: 'Periode',
					type: 'Type',
					price: 'Bedrag',
					checked: '',
					link: '',
				},
			],
			body: {
				balance: 'Status',
				period: 'Periode',
				type: 'Type',
				price: 'Bedrag',
			},
		},
		invoiceTooltip: {
			title: 'Details verrekening',
			invoice: 'Factuur',
			price: 'Bedrag',
			type: 'Type',
			restAmount: 'Restbedrag',
			paymentReference: 'Betaalkenmerk',
		},
	},
	agreements: {
		title: 'Overeenkomsten',
		downloadLinkText: 'Download tariefblad',
		fromDate: 'De overeenkomst loopt van',
		throughDate: 't/m',
	},
	metermeasuring: {
		title: 'Meterstanden',
	},
	monthlyAmount: {
		title: 'Termijnbedrag',
		agreement: {
			fromDate: 'Periode',
			throughDate: 't/m',
		},
		periodicalPayment: {
			title: 'Huidig Termijnbedrag',
			edit: 'Wijzigen',
			viewMore: 'Lees meer',
			changeRequested: 'Wordt gewijzigd naar',
			info:
				'Met het termijnbedrag wordt iedere maand alvast een deel van de jaarlijkse energienota betaald.',
		},
	},
	move: {
		title: 'Verhuizen',
		noAgreementsMessage:
			'Alle overeenkomsten zijn beëindigd. Wilt u een nieuwe overeenkomst op een ander adres? Neem dan contact op met onze klantenservice.',
		connections: {
			electricity: 'Elektriciteit',
			gas: 'Gas',
		},
		form: {
			chooseOldAddress: 'Adressen',
			enterNewAddress: 'Verhuisdata',
			confirm: 'Bevestigen',
			step0: {
				title: 'Overeenkomst',
				chooseAgreementTitle: 'Kies de overeenkomst',
				showDuplicates: 'Toon overeenkomst met hetzelfde adres',
				agreementMsg:
					'Kies de overeenkomst voor de verhuizing. Met een nieuwe adres ontvangt u ook nieuwe overeenkomst, ' +
					'met dezelfde voorwaarden. Dit ontvangt u per email na het bevestiging van de wijziging van het adres.',
				chooseAgreementHeadline: 'Kies de overeenkomst voor de verhuizing.',
				multipleAddressMsg: {
					part1:
						'Het is online niet mogelijk om een verhuizing door te geven voor een overeenkomst met meerdere adressen.',
					part2: 'Neem aub contact op met de klantenservice ',
					part3: 'zodat we de verhuizing voor u kunnen doorvoeren ',
				},
				multipleAddress: {
					part1:
						'Er bestaat al een overeenkomst met hetzelfde adres, we kunnen online de verhuizing niet doorvoeren.',
					part2: 'Neem aub contact op met de klantenservice',
					part3:
						'en we helpen u bij het verhuizen van het adres voor de ' +
						'voor overeenkomst ',
				},
			},

			step1: {
				title: 'Huidig adres',
				electricity: 'Elektriciteit',
				gas: 'Gas',
				findAddress: 'Zoek adres op basis van postcode.',
				postalCode: 'Postcode',
				houseNumber: 'Huisnummer',
				connections: 'aansluitingen',
				connection: 'aansluiting',
				newAddress: 'Nieuw adres',
				selectExtensionPlaceholder: 'Kies toevoeging',
				noActiveAddresses: 'U heeft geen actieve aansluitadressen',
				movePostalAddress: 'Verhuis postadres mee naar nieuw adres',
				moveInvoiceAddress: 'Verhuis factuuradres mee naar nieuw adres',
				residentialAddress: 'Hier kan gewoond worden',
				newAddressMsg: 'Vul de postcode en huisnummer hieronder in',
				multipleAddressConnection:
					'Verhuizen naar een adres met meerdere aansluitingen kunnen we online niet verwerken, neem aub contact op met de klantenservice om u te helpen bij deze verhuizing.',
				differentAddressConnection:
					'Helaas kunnen we de verhuizing naar dit adres online niet doorvoeren, bel aub de helpdesk om u hierbij te helpen',

				searchedAddressTitle: 'Adres voor deze postcode.',
				availableConnections: 'Aanwezige aansluitingen op dit adres:',
				nonExistingAddressError:
					'Adres niet gevonden. Als u zeker weet dat dit adres klopt neem dan contact op het de klantenservice.',
				multipleAddressFound: 'Kies aub de juiste toevoeging.',
				addresses: {
					label: 'Voor welk adres wilt u een verhuizing doorgeven?',
					name: 'old_address',
				},
				altAddressMsg:
					'Meerdere adressen zijn gevonden voor het ingevulde adres. Kies het juiste adres waar naar verhuisd gaat worden uit de lijst hieronder',
				addressFields: [
					{
						label: 'Postcode',
						name: 'new_address.zip_code',
					},
					{
						label: 'Nummer',
						name: 'new_address.house_number',
					},
					{
						label: 'Toevoeging',
						name: 'new_address.house_number_extension',
					},
					{
						label: 'Straat',
						name: 'new_address.street',
					},
					{
						label: 'Plaats',
						name: 'new_address.city',
					},
				],
				commodityList: 'Op dit adres ontvangt u nu',
				whichOldAddress: 'Uw oude adres',
				selectAddress: 'Selecteer adres',
				optional: 'Optioneel',
			},
			step2: {
				title: 'Kies verhuisdata',
				explainMessage:
					'Wanneer u hier een datum invult zullen we levering op uw oude adres stopzetten. ' +
					'Als u nog niet weet wanneer u levering op het oude adres wilt stoppen, vul dan niets in. ' +
					'Levering zal in dat geval gewoon doorgaan, op zowel uw oude als nieuwe adres.',
				whatsTheNewAddress: 'Wat is het nieuwe adres?',
				moveOutDateTitle: 'Afsluitdatum oude adres (deactiveren)',
				moveInDateTitle: 'Aansluitdatum nieuwe adres (activeren)',
				shipping: 'Levering',
				selectDate: 'Kies datum',
				optional: 'optioneel',
				mandatory: 'verplicht*',
				chooseDate: 'Kies datum',
				endDateTitle:
					'Wanneer wilt u de aansluiting op uw huidige adres deactiveren?',
				endDateMessage:
					'Als u nog niet weet wanneer de aansluiting op uw huidge adres gedeactiveerd kan worden, vul dan niets in.',
				start_date: {
					label: 'Op welke datum moet de levering op dit adres starten?',
					name: 'start_date',
				},
				stop_date: {
					label:
						'Wanneer u een datum invult, beëindigen wij op die dag de levering op uw oude adres. Weet u nog niet wanneer u de levering op het oude adres wilt beëindigen, vul dan niets in.',
					name: 'stop_date',
				},
				confirm: 'Wij zullen de levering op uw oude adres stopzetten op',
			},
			step3: {
				title: 'Controleer en verzend',
				infoMessage:
					'U ontvangt energie op het nieuwe adres met dezelfde voorwaarden.',
				noEndDate:
					'Er is geen datum gekozen voor wanneer levering moet starten.',
				noEndDateSelected: 'Verhuisdatum van oude adres is niet geselecteerd.',
				moveOut: 'Verhuizen van oude adres',
				moveIn: 'Verhuizen naar nieuwe adres',
				until: 'Tot',
				from: 'Vanaf',
				deliveryMsg: 'levering van energie ontvangen op:',
				willBeCanceledWithDate: 'De levering wordt gestopt op',
				willBeCanceledWithoutDate:
					'Er is geen datum opgegeven wanneer de levering moet stoppen op',
				on: 'op',
				willReceive: 'Wilt u energie geleverd krijgen op',
				currentAddress: 'Huidige adres',
				newAddress: 'Nieuw adres',
				stopDateMsg:
					'Datum waarop de aansluiting op dit adres wordt gedeactiveerd',
				startDateMsg:
					'Datum waarop de aansluiting op dit adres wordt geactiveerd',
			},
			step4: {
				title: 'Verzoek verzonden',
				thankYou:
					'Bedankt voor het doorgeven. Wij nemen uw verhuizing zo spoedig mogelijk in behandeling.',
				info:
					'Hou uw mail in de gaten / er wordt contact opgenomen / u hoeft verder niets te doen.',
				error4xx:
					'Er lijkt iets aan de hand te zijn met de ingevulde waarden. Controleer deze alsjeblieft.',
				error5xx: 'Er is iets misgegaan. Probeer het later nog eens.',
			},
		},
	},
	products: {
		title: 'Producten',
	},
	usage: {
		title: 'Meterstanden',

		meterDataMissing: 'Voor deze meter zijn helaas geen standen beschikbaar',

		ean: 'EAN',
		meterType: 'Metertype',
		meterNumber: 'Meternummer',
		capacity: 'Capaciteit',

		smartMeter: 'Slimme meter',
		conventionalMeter: 'Conventionele meter',

		electricity: 'Elektriciteit',
		gas: 'Gas',

		connectionDetails: 'Aansluitgegevens',
		measurements: 'Meterstanden',
		date: 'Datum',
		usage: 'Levering',
		production: 'Teruglevering',
		low: 'Dal',
		standard: 'Normaal',
		unit: {
			KWH: 'kWh',
			KWT: 'KW',
			MTQ: 'm³',
			M3N: 'm³',
		},
		usageTable: {
			headers: [
				{
					source: '',
					ean: 'EAN',
					standard: 'Normaal',
					low: 'Dal',
					details: '',
				},
			],
			body: {
				meterDetailsHistory: 'Meer details & historie',
			},
		},
		usageHistory: {
			title: 'Details & Historie',
			cancel: 'Sluit',
			connectionDetails: 'Aansluiting details',
			connectionHistory: 'Aansluiting historie',
			historyTable: {
				headers: [
					{
						usage: 'Levering',
						production: 'Teruglevering',
						date: 'Datum',
						low: 'Dal',
						standard: 'Normaal',
					},
				],
			},
		},
	},
	iban: {
		title: 'IBAN wijzigen',
		ibanLabel: 'IBAN',
		bankAccountName: 'Tenaamstelling',
		fromDate: 'Kies datum',
		validations: {
			ibanRequired: 'Vul een IBAN in.',
			ibanNotValid:
				'Deze IBAN lijkt niet te bestaan. Controleer nogmaals, of neem contact op met de klantenservice.',
			bankAccountNameRequired: 'Vul de tenaamstelling in.',
			bankAccountNameToLong: 'Deze tenaamstelling is te lang.',
			startDateRequired: 'Kies een startdatum.',
			startDateMessage: 'De eerst mogelijke datum is morgen.',
			requestIsBeingProcessed: 'De aanvraag wordt verwerkt.',
		},
		ibanStatus: 'IBAN wordt gewijzigd naar: ',
		ibanStatusAt: 'op',
		priceAgreementNotFound: 'Deze informatie is helaas niet beschikbaar.',
	},
	sustainable: {
		participation: {
			title: 'Uw participatie',
			peak: 'Normaal',
			offPeak: 'Laag',
			endDate: 'Tot',
			startDate: 'Van',
			peakUnitOfMesure: 'kWh',
			offPeakUnitOfMesure: 'kWh',
			normalUnitOfMesure: 'kWh',
			project: 'Project',
			mijnParticipation: 'Mijn participatie',
			mutation_date: 'Wijzigingsdatum',
			greenchoice_id: 'Greenchoice nummer',
			participation_identifier: 'Participatie nummer',
			address: 'Adres',
			ean: 'Project aansluiting',
			share: 'Aantal certificaten',
			supplier: 'Leverancier elektra',
			supplierFromDate: 'Loopt vanaf',
			supplierToDate: 'Loopt tot',
			currentPower: 'Afgelopen uur',
			energyToday: 'Opwek vandaag',
			totalEnergy: 'Totale opwek project',
			energyMonth: 'Uw opwek deze maand',
			period: 'Uw opwek',
			weatherStatus: 'Weerstatus',
			temperature: 'Temperatuur',
			wind: 'Windkracht',
			noWeatherData: 'Weergegevens zijn op dit moment niet beschikbaar',
			projectEndDate: 'Project einddatum',
		},
		moveShares: {
			title: 'Participaties verhuizen',
			existingAddress: 'Bestaand adres',
			newAddress: 'Nieuw adres',
			addressFields: {
				postcode: 'Postcode',
				houseNumber: 'Nummer',
				numberExtension: 'Toevoeging',
				street: 'Straat',
				city: 'Plaats',
			},
		},
	},
	weatherStatusMap: {
		onbewolkt: 'onbewolkt',
		zon: 'zon',
		Zonnig: 'Zonnig',
		'licht bewolkt': 'licht bewolkt',
		'half bewolkt': 'half bewolkt',
		bewolkt: 'bewolkt',
		'geheel bewolkt': 'geheel bewolkt',
		'zwaar bewolkt': 'zwaar bewolkt',
		'droog na regen': 'droog na regen',
		regen: 'regen',
		'lichte regen': 'lichte regen',
		motregen: 'motregen',
		'dichte motregen': 'dichte motregen',
		'motregen en regen': 'motregen en regen',
		'lichte motregen en regen': 'lichte motregen en regen',
		'af en toe lichte regen': 'af en toe lichte regen',
		onweer: 'onweer',
		bliksem: 'bliksem',
		maan: 'maan',
		sneeuw: 'sneeuw',
		mist: 'mist',
		hagel: 'hagel',
	},
	amplify: {
		'User does not exist': 'Gebruikersnaam of wachtwoord niet correct',
		'Username/client id combination not found.': 'Gebruiker bestaat niet',
		'Incorrect username or password':
			'Gebruikersnaam of wachtwoord niet correct',
		'null failed with error Generate callenges lambda cannot be called..':
			"Veld 'wachtwoord' mag niet leeg zijn",
		'Username cannot be empty': "Veld 'gebruikersnaam' mag niet leeg zijn",
		'Code cannot be empty': "Veld 'code' mag niet leeg zijn",
		'Password cannot be empty': "Veld 'wachtwoord' mag niet leeg zijn",
		'Custom auth lambda trigger is not configured for the user pool.':
			'Gebruikersnaam of wachtwoord niet correct',
		'Temporary password has expired and must be reset by an administrator.':
			'Het tijdelijke wachtwoord is verlopen, en moet gereset worden door een administrator, neem contact met ons op.',
		'Invalid verification code provided, please try again.':
			'Ongeldige verificatie code aangeleverd, probeer opnieuw alstublieft.',
		'Invalid password format': 'Ongeldige tekens gebruikt voor wachtwoord',
		'Password does not conform to policy: Password not long enough':
			'Het wachtwoord voldoet niet aan de eisen; het is niet lang genoeg (minimaal 8 karakters).',
		'Password does not conform to policy: Password must have lowercase characters':
			'Het wachtwoord voldoet niet aan de eisen; het moet kleine letters bevatten.',
		'Password does not conform to policy: Password must have uppercase characters':
			'Het wachtwoord voldoet niet aan de eisen; het moet hoofdletters bevatten.',
		'Password does not conform to policy: Password must have numeric characters':
			'Het wachtwoord voldoet niet aan de eisen; het moet cijfers bevatten.',
		'Password attempts exceeded':
			'Maximum aantal pogingen voor wachtwoord invoeren bereikt',
		'Invalid session for the user, session is expired.':
			'Ongeldige sessie, de sessie is verlopen.',
		'Attempt limit exceeded, please try after some time.':
			'Maximum aantal pogingen bereikt, probeer later opnieuw.',
		'User password cannot be reset in the current state.':
			'Ongeldige actie; log eerst in met het tijdelijke wachtwoord wat we u per mail hebben gezonden.',
		'CustomMessage invocation failed due to error Socket timeout while invoking Lambda function.':
			'Er is iets misgegaan met het verzenden van de e-mail, probeer het opnieuw.',
		'Incorrect username or password.':
			'Gebruikersnaam of wachtwoord is niet correct',
		'PreAuthentication failed with error You are unable to log in into your self-service portal. For questions, please contact our customer service.':
			'Inloggen in uw mijn-omgeving is niet mogelijk. Voor vragen neem contact op met onze klantenservice.',
	},
	marketPlace: {
		addToCart: 'In mijn winkelwagen',
		myCart: 'Mijn winkelwagen',
		title: 'Markt',
		menu: {
			availaleOffers: 'Beschikbare participaties',
			myOffers: 'Mijn aangeboden participaties',
			createOffer: 'Participatie verkopen',
			myOrders: 'Mijn orders',
			personalOffers: 'Persoonlijk aanbod',
		},
		proceeds: 'Te ontvangen bedrag na aftrek administratiekosten',
		administrationCosts: 'Administratiekosten per participatie',
		minimumSellPrice: 'Minimale verkoopprijs',
		maximumSellPrice: 'Maximale prijs voor participatie',
		economicValue: 'Economische waarde participatie',
		sellingNotAllowed: 'Verkoop niet toegestaan voor dit project',
		pricePerShare: 'Prijs per participatie',
		numberOfShares: 'Aantal participaties',
		date: 'Aanbieden vanaf',
		transferDate: 'Datum overdracht',
		myDate: 'Aangeboden vanaf',
		project: 'Project',
		projectEndDate: 'Project einddatum',
		expirationDate: 'Vervaldatum',
		status: {
			label: 'Status',
			CREATED: 'In afwachting van goedkeuring Greenchoice',
			APPROVED: 'Goedgekeurd door Greenchoice',
			ACTIVE: 'Participaties beschikbaar op de markt',
			SOLD: 'Participaties verkocht',
			REJECTED: 'Afgewezen door Greenchoice',
			REVOKED: 'Aangeboden participaties ingetrokken',
			AVAILABLE: 'Beschikbaar',
			RESERVED: 'Gereserveerd',
			CLOSED: 'Gesloten',
			AWAITING_PAYMENT: 'In afwachting van betaling',
			BOUGHT: 'Gekocht',
			COMPLETED: 'Voltooid',
			TRANSFERRED: 'Overgedragen',
			CANCELLED: 'Geannuleerd',
			EXPIRED: 'Verlopen',
		},
		offerPublicationType: {
			PUBLIC: 'Public',
			DIRECT: 'Direct',
			PRIVATE: 'Private',
		},
		reason: {
			label: 'Reden van afwijzing',
			PARTICIPATION_UNKNOWN: 'Participatie onbekend',
			MULTIPLE_PARTICIPATIONS_PROJECT: 'Meerdere participaties op project',
			INSUFFICIENT_SHARES: 'Onvoldoende participaties',
			REJECTED_GREENCHOICE: 'Afgewezen door Greenchoice',
			SELLING_NOT_ALLOWED: 'Verkoop niet toegestaan voor dit project',
		},
		isOfferOnMartket: 'Aangeboden op de markt?',
		sharesToBuy: 'Aantal participaties kopen',
		items: 'participatie(s)',
		orderSummary: 'Samenvatting order',
		productDetails: 'Product details',
		quantity: 'Aantal',
		price: 'Prijs',
		total: 'Totaal',
		totalCost: 'Totaalbedrag',
		order: 'Bestellen',
		createOfferSuccess:
			'Uw aanbieding is ingesteld. Je kunt het vinden in `Mijn aangeboden participaties`',
		reference: 'Aanbod id',
		participation_identifier: 'Participatie Id',
		yes: 'Ja',
		no: 'Nee',
		adres: 'Adres',
		old_address: 'Vorig adres',
		transfer_date: 'Datum overdracht',
		termsconditions: 'Algemene voorwaarden & statuten van project ',
		iAccept: 'Ik ga akkoord met de  ',
		iRead: ', en verklaar deze gelezen te hebben.',
		ofProject: 'van project',
		member_agreement: 'ledenovereenkomst ',
		member_agreement3: 'van project ',
		statutes: 'statuten ',
		placeOrder: 'Order plaatsen',
		cancelOrder: 'Annuleer order',
		orderCancelled: 'Bestelling is succesvol geannuleerd',
		retractOffer: 'Aanbod intrekken',
		withdrawelPeriodCheckboxContent:
			'Op mijn aankoop is 14 dagen herroepingstermijn van toepassing, vanaf het moment dat de betaling is gedaan.',
		retractNumberOfUnits: 'Aantal participaties intrekken',
		noOrders:
			'Op dit moment zijn geen orders geplaatst. Voor een overzicht van Uw winddelen, ga naar Uw Participatie.',
		noOffers:
			'Op dit moment is er nog geen aanbod geplaatst. Voor een overzicht van Uw winddelen, ga naar Uw Participatie.',
		maxPriceError: 'Dit getal overschrijdt de maximaal instelbare prijs',
		minPriceError: 'Dit getal overschrijdt de minimaal instelbare prijs',
		thankYou: {
			thankYouTextFirst: 'Uw betaling van € ',
			thankYouTextSecond:
				' is met succes door ons ontvangen. U kunt hier weer terug naar ',
			thankYouTextEnding: ', daar vindt u een overzicht van uw order.',
			accountLinkText: 'uw persoonlijke account',
			cancelled:
				'U heeft de betaling van uw winddeel geannuleerd, dat vinden we natuurlijk heel jammer. U kunt hier weer terug naar ',
		},
		paylink: 'Betalen',
		personalOfferEmailDisclaimer:
			'E-mailadres van degene aan wie u uw winddelen persoonlijk wilt overdragen.',
		personalOfferDisclaimer:
			'Let op: Het overdragen van persoonlijke winddelen is enkel toegestaan als het (nieuwe) lid een actief energiecontract voor stroom heeft.',
		personalOfferDisclaimer_1024:
			'Let op: Het overdragen van persoonlijke winddelen vanuit Windcentrale is enkel toegestaan als het (nieuw) lid een actief energiecontract voor stroom heeft bij Greenchoice.',
		personalOfferDisclaimer_1071:
			'Let op: Het overdragen van persoonlijke winddelen is enkel toegestaan als het (nieuw) lid een actief energiecontract voor stroom heeft met samenwerkende energieleveranciers van Winddelen.',
		personalOffer: 'Persoonlijk aanbod',
		publicOffer: 'Openbaar aanbod',
		offerType: 'Soort aanbod',
		personalOffers: 'Persoonlijk aanbod',
		directOfferCode: 'Persoonlijke aanbiedingscode',
		expireMessage:
			'Vergeet niet uw betaling voor de winddelen order te voldoen. De reservering van uw order blijft maximaal 14 dagen staan. Indien u op betalen heeft geklikt, blijft de betaalink maximaal 7 dagen geldig. Daarna komt uw reservering te vervallen.',
		expiredOrderWarning:
			'Uw winddelen order is verlopen. Hierdoor is uw reservering voor deze specifieke winddelen komen te vervallen. Kijk op de Markt of deze en/of andere winddelen nog beschikbaar zijn.',
		redeemOffer: 'Persoonlijk aanbod ophalen',
		noMyOrders:
			'Op dit moment zijn geen orders geplaatst. Voor een overzicht van uw winddelen, ga naar Uw Participatie.',
		noMyOffers:
			'Op dit moment heeft u nog geen participaties aangeboden op de markt. Voor een overzicht van uw winddelen, ga naar Uw Participatie.',
		noPersonalOffers:
			'Op dit moment is er geen persoonlijk aanbod op uw e-mailadres. Heeft u een code voor persoonlijk aanbod ontvangen? Voer deze code in om uw persoonlijke aanbod in te zien.',
		get: 'Ophalen',
		notEnoughShares:
			'Er zijn niet genoeg participaties beschikbaar. Pas het aantal aan',
	},
	register: {
		title: 'Account maken',
		notAvailable: 'Voor dit label is geen aanvraagformulier beschikbaar.',
		ageValidation: 'U moet 18 jaar of ouder zijn.',
		fields: {
			queryText: 'Zoeken',
			address: 'Adres',
			relatedRelations: 'Gerelateerde relaties',
			relatedCustomers: 'Gerelateerde klanten',
			relatedCustomerCompany: 'Moederbedrijf', // Request change by PO: 'Bedrijven'
			relatedLegalGuardian: 'Bewindvoerder',
			relatedEmployer: 'Werkgever',
			relatedParentCompany: 'Moederbedrijf',
			countryCode: 'Landcode',
			lastName: 'Achternaam',
			gender: 'Geslacht',
			genders: {
				male: 'Man',
				female: 'Vrouw',
			},
			salutation: 'Adressering',
			salutations: {
				MR: 'Dhr.',
				MRS: 'Mevr.',
				THEHEIRSOF: 'De erven van',
			},
			type: 'Klanttype',
			domain: 'Domein',
			domains: 'Domeinen',
			birthDate: 'Geboortedatum',
			street: 'Straatnaam',
			city: 'Woonplaats',
			phone: 'Telefoonnummer',
			label: 'Label',
			labelName: 'Labelnaam',
			companyName: 'Bedrijfsnaam',
			RelationId: 'Relatie ID',
			labelKey: 'Label key',
			locationDescription: 'Locatieomschrijving',
			debtorNumber: 'Debiteurnummer',
			status: 'Status',
			relationStatus: 'Relatie status',
			verificationStatus: 'Verificatiestatus',
			emailType: 'Welk emailadres',
			processType: 'Verzonden bericht',
			verificationSendDate: 'Verzenddatum bericht',
			reminderSendDate: 'Verzenddatum herinnering verificatieverzoek',
			action: 'Actie',
			phoneNumber: 'Telefoonnummer',
			customerCompany: 'Bedrijven',
			parentCompany: 'Moederbedrijf',
			company: {
				name: 'Bedrijfsnaam',
				cocNumber: 'KvK-nummer',
				cocNumberFull: 'Kamer van Koophandel nummer',
				vatNumber: 'BTW-nummer',
				eanCode: 'EAN code',
				captarCodePrefix: 'CapTar code voorvoegsel',
				timeOfUsePlaceholder: 'Selecteer tijd van gebruik',
				timeOfUse: 'Tijd van gebruik',
				timesOfUse: {
					T_7_21: '7-21h',
					T_7_23: '7-23h',
					T_0_24: '0-24h',
				},
			},
			cocNumber: 'KvK-nummer',
			cocNumberFull: 'Kamer van Koophandel nummer',
			vatNumber: 'BTW-nummer',
			eanCode: 'EAN code',
			streetName: 'Straatnaam',
			houseNumber: 'Huisnummer',
			house: 'Huisnummer',
			houseExtension: 'Toevoeging',
			debtor: 'Debiteurnummer',
			seller: 'Verkoper',
			email: 'E-mailadres',
			emailVerification: 'E-mailadres Verificatiestatus',
			emailInvoice: 'Factuur e-mailadres',
			emailInvoiceVerification: 'Factuur e-mailadres Verificatiestatus',
			nickname: 'Roepnaam',
			postcode: 'Postcode',
			client: 'Klantnummer',
			bankAccountName: 'Tenaamstelling bankrekening',
			bankAccountNumber: 'Bankrekeningnummer (IBAN)',
			bankAccount: 'IBAN',
			initials: 'Initialen',
			shippingAddress: 'Contactadres',
			invoiceAddress: 'Factuuradres',
			postalAddress: 'Postadres',
			prefix: 'Tussenvoegsel(s)',
			validFrom: 'Startdatum',
			validTo: 'Einddatum',
			debtorName: 'Debiteurnaam',
			documentUri: 'Btw-uitzondering PDF',
			relation: 'Relatie',
			vatExceptions: 'Btw-uitzonderingen',
			preferredName: 'Voornaam',
			membershipReference: 'Lidmaatschap referentie',
			labelCustomerNumber: 'Extern klantnummer',
			sendEnergyTermInvoice:
				'Deze relatie wil alle energiefacturen, waaronder ook termijnfacturen, per e-mail ontvangen.',
			listEnergyTermInvoice: 'Energiefactuur',
			zipcode: 'Postcode',
			search: 'Zoeken',
			communicationByMail: 'Postklant',
		},
	},
	production: {
		totalWind: 'Totaal van de windmolen',
		totalShares: 'Totaal van mijn winddelen',
		total: 'Totaal',
		perHour: 'Per uur',
		perWeek: 'Per week',
		perMonth: 'Per maand',
		perDay: 'Per dag',
		perYear: 'Per jaar',
		noData: 'Geen data',
		noHourlyDataOne: 'De data van ',
		noHourlyDataTwo: ' is nog niet binnen. Kom later terug.',
		period: 'Periode',
		title: 'Productie Historie',
	},
	notifications: {
		title: 'Berichten',
		noNotices: 'Er zijn op dit moment geen berichten.',
	},
};
