import React from 'react';
import Email from '../../email';
import IconSchedule from '../../icons/schedule';
import Phonenumber from '../../phonenumber';
import OpeningHours from '../../opening-hours';
import Icon from '../../icon';
import ChatBubble from '../../icons/forum-rounded';
import './customer-service.css';
import Button from '../../button';
import { withRouter } from 'react-router-dom';

const CustomerService = ({ translations, labels }) => {
	return (
		<div className="customer-service">
			{labels.info.email && (
				<Email iconSize="small">
					<a
						className="primary-text-color"
						href={`mailto:${labels.info.email}`}>
						{labels.info.email}
					</a>
				</Email>
			)}
			<div className="number-and-time">
				{labels.info.phone_number && (
					<Phonenumber
						iconSize="small"
						phone_number={labels.info.phone_number}
						isLink={true}
					/>
				)}
				{labels.info.opening_hours.length > 0 && (
					<div className="time">
						<Icon icon={IconSchedule} size="small" />
						<OpeningHours
							translations={translations}
							opening_hours={labels.info.opening_hours}
						/>
					</div>
				)}
			</div>
			<ContactButton>{translations.customerService.contactForm}</ContactButton>
		</div>
	);
};

const ContactButton = withRouter(({ history, children }) => (
	<Button
		kind="transparent"
		onClick={() => {
			history.push('/contact');
		}}>
		<Icon icon={ChatBubble} size="small" />
		{children}
	</Button>
));

export default CustomerService;
