import { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { withRouter, useLocation, useHistory } from 'react-router-dom';
import { OffersAccordion } from './components/offers-accordion';

import api from '../../api';

import { action as collapsibleAction } from '../../reducers/ui/participation';
import { Typography, Stack, Skeleton, Button } from '@mui/material';
import { DoNotDisturb } from '@mui/icons-material';

function PersonalOffers({ translations, expand, relations }) {
	const [loading, setLoading] = useState(true);
	const [data, setData] = useState([]);
	const [addresses, setAddresses] = useState([]);
	const [projects, setProjects] = useState([]);

	const dispatch = useDispatch();
	const history = useHistory();
	const location = useLocation();

	const toggleCollapsible = (reference) =>
		dispatch(collapsibleAction.toggle(reference));

	const stringfy = (value) => String(value);

	useEffect(() => {
		async function fetchProjects() {
			setLoading(true);
			try {
				const projects = await api.sustainable.get();
				setProjects(projects);
			} catch {
				setProjects([]);
			} finally {
				setLoading(false);
			}
		}
		fetchProjects();
	}, [location.key]);

	useEffect(() => {
		const addresses =
			projects.length === 0
				? relations.addresses
						.filter((address) => address.address_type === 'SHIPPING')
						.map((address) => {
							const {
								city,
								house_number,
								house_number_extension,
								zip_code,
								street,
							} = address;

							return {
								...address,
								label: `${street || ''} ${house_number ||
									''} ${house_number_extension || ''}, ${zip_code ||
									''} ${city || ''}`,
								value: `${stringfy(zip_code)}${stringfy(
									house_number
								)}${stringfy(house_number_extension)}`,
								address: {
									city,
									house_number,
									house_postfix: house_number_extension,
									postal_code: zip_code,
									street_name: street,
								},
							};
						})
				: projects
						.map((project) =>
							project.participations.map((participation) => {
								const { address } = participation;
								const {
									city,
									house_number,
									house_postfix,
									postal_code,
									street_name,
								} = address;

								const label = `${street_name || ''} ${house_number ||
									''} ${house_postfix || ''}, ${postal_code || ''} ${city ||
									''}`;
								const value = `${stringfy(postal_code)}${stringfy(
									house_number
								)}${stringfy(house_postfix)}`;

								return (
									address && {
										label,
										value,
										address,
									}
								);
							})
						)
						.flat()
						.filter(
							(v, i, s) => s.findIndex((sa) => sa.value === v.value) === i
						);

		setAddresses(addresses);
	}, [projects, relations.addresses]);

	useEffect(() => {
		async function fetchData() {
			setLoading(true);
			try {
				const response = await api.sustainable.sustainableOffers.personalOffers();
				setData(response);
				setLoading(false);
			} catch (error) {
				if (error.response.status === 404) {
					const relationAddresses = relations.addresses
						.filter((address) => address.address_type === 'SHIPPING')
						.map((address) => {
							const {
								city,
								house_number,
								house_number_extension,
								zip_code,
								street,
							} = address;

							return {
								...address,
								label: `${street || ''} ${house_number ||
									''} ${house_number_extension || ''}, ${zip_code ||
									''} ${city || ''}`,
								value: `${stringfy(zip_code)}${stringfy(
									house_number
								)}${stringfy(house_number_extension)}`,
								address: {
									city,
									house_number,
									house_postfix: house_number_extension,
									postal_code: zip_code,
									street_name: street,
								},
							};
						});
					setAddresses(relationAddresses);
					setLoading(false);
				} else {
					throw error;
				}
			}
		}
		fetchData();
	}, [relations, location.key]);

	return (
		<div className="w-full px-4 pt-16">
			<Stack
				direction="row"
				justifyContent="space-between"
				alignItems="center"
				spacing={2}
				height={50}>
				<Typography variant="h4">{translations.menu.personalOffers}</Typography>
				<Button
					variant="outlined"
					size="small"
					onClick={() => history.push('/personal-offers/redeem-offer')}>
					{translations.redeemOffer}
				</Button>
			</Stack>
			{loading && (
				<>
					<Skeleton height={52} />
					<Skeleton height={52} />
					<Skeleton height={52} />
				</>
			)}
			{!loading && data.length === 0 && (
				<Stack
					direction="column"
					justifyContent="space-between"
					alignItems="flex-start"
					spacing={2}
					height={50}>
					<DoNotDisturb />
					{data.length === 0 && (
						<Typography variant="h4">
							{translations.noPersonalOffers}
						</Typography>
					)}
					{projects.length === 0 && (
						<Typography variant="h4">
							{translations.error.noProjects}
						</Typography>
					)}
				</Stack>
			)}

			{!loading &&
				data.length > 0 &&
				data
					.filter((o) => o.my_offer === false)
					.map((offer) => (
						<OffersAccordion
							myOffer={offer.myOffer}
							translations={translations}
							expand={expand.includes(offer.reference)}
							toggle={toggleCollapsible}
							key={offer.identifier}
							offer={offer}
							addresses={addresses}
						/>
					))}
		</div>
	);
}

function mapStateToProps(state) {
	return {
		translations: Object.assign(
			state.translations.marketPlace,
			state.translations.general
		),
		isMobile: state.ui.mobileView,
		expand: state.ui.participation.collapsibles,
		marketplace: state.marketplace,
		relations: state.data.relations,
	};
}

export default withRouter(connect(mapStateToProps)(PersonalOffers));
