import React, { Component, Fragment } from 'react';
import Accordion from '../../components/accordion';
import Row from '../../components/row';
import Quickstart from '../../components/dashboard-items/quickstart';
import CustomerService from '../../components/dashboard-items/customer-service';
import Invoces from '../../components/dashboard-items/invoices';
import PageTitle from '../../components/page-title';
import ArrowForward from '../../components/icons/arrow-forward';
import ChatBubble from '../../components/icons/forum-rounded';
import Panel from '../../components/panel';
import Icon from '../../components/icon';
import './dashboard.css';
import Invoice from '../../components/icons/description-outlined';
import { connect } from 'react-redux';
import api from '../../api';
import { action as latestInvoces } from '../../reducers/data/invoices.latest';

class Dashboard extends Component {
	componentWillMount() {
		api.invoices.latest
			.get()
			.then((data) => data && this.props.dispatch(latestInvoces.data(data)))
			.catch((err) => err);
	}

	render() {
		const { translations, isMobile } = this.props;
		return (
			<div className="dashboard">
				<Row>
					<PageTitle>{translations.title}</PageTitle>
				</Row>
				{isMobile ? (
					<DashboardMobileView {...this.props} />
				) : (
					<DashboardDesktopView {...this.props} />
				)}
			</div>
		);
	}
}

const DashboardMobileView = ({ translations, labels, latestInvoices }) => (
	<Accordion>
		<div
			title={translations.quickstart.title}
			icon={ArrowForward}
			expanded={true}>
			<Quickstart translations={translations} />
		</div>
		<div title={translations.customerService.title} icon={ChatBubble}>
			<CustomerService translations={translations} labels={labels} />
		</div>
		<div
			className="latest-invoices-content"
			title={translations.latestInvoices.title}
			icon={Invoice}>
			<Invoces translations={translations} latestInvoices={latestInvoices} />
		</div>
	</Accordion>
);

const DashboardDesktopView = ({ translations, labels, latestInvoices }) => (
	<Row>
		<div className="dashboard-tiles">
			<Panel
				title={
					<DashboardPanelTitle
						title={translations.quickstart.title}
						icon={ArrowForward}
					/>
				}>
				<Quickstart translations={translations} />
			</Panel>
			<Panel
				title={
					<DashboardPanelTitle
						title={translations.customerService.title}
						icon={ChatBubble}
					/>
				}>
				<CustomerService translations={translations} labels={labels} />
			</Panel>
			<Panel
				additionalClass="latest-invoices-desktop"
				title={
					<DashboardPanelTitle
						title={translations.latestInvoices.title}
						icon={Invoice}
					/>
				}>
				<Invoces translations={translations} latestInvoices={latestInvoices} />
			</Panel>
		</div>
	</Row>
);

const DashboardPanelTitle = ({ title, icon }) => (
	<Fragment>
		<Icon icon={icon} size="small" />
		{title}
	</Fragment>
);

function mapStateToProps(state) {
	return {
		isMobile: state.ui.mobileView,
		labels: state.data.labels,
		latestInvoices: state.data.latestInvoices,
		translations: Object.assign(
			state.translations.dashboard,
			state.translations.general
		),
	};
}

export default connect(mapStateToProps)(Dashboard);
