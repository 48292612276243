export default (state = [], action) => {
	switch (action.type) {
		case 'INVOICES_DATA':
			state[action.agreementId] = action.data;
			return state;
		default:
			return state;
	}
};

export const action = {
	data: (data, agreementId) => ({ type: 'INVOICES_DATA', data, agreementId }),
};
