export const SOLAR_LABELS = ['1060'];
export const WIND_LABELS = ['1024', '1071'];
export const SUST_LABELS = [...SOLAR_LABELS, ...WIND_LABELS];

export const isLabelWind = (labelKey) => WIND_LABELS.includes(labelKey);
export const isLabelSolar = (labelKey) => SOLAR_LABELS.includes(labelKey);

export const labelType = (labelKey) =>
	isLabelWind(labelKey) ? 'wind' : 'solar';

export const isLabelSustainable = (labelKey) => {
	return SUST_LABELS.includes(labelKey);
};
