import React from 'react';
import Switch from 'react-switch';

const UsageSwitch = ({ delivery, agreement, translations, changeDelivery }) => {
	return (
		<label className="view-usage__usage-production-switch">
			<span
				style={{
					color: !delivery[agreement.agreement_identifier]
						? 'black'
						: 'lightgray',
					paddingRight: '10px',
				}}>
				{translations.usage}
			</span>
			<Switch
				checked={!!delivery[agreement.agreement_identifier]}
				onChange={() => {
					changeDelivery(agreement.agreement_identifier);
				}}
				onColor="#DFDFDF"
				offColor="#DFDFDF"
				onHandleColor="#fff"
				offHandleColor="#fff"
				handleDiameter={20}
				uncheckedIcon={false}
				checkedIcon={false}
				height={24}
				width={46}
				className="react-switch"
			/>
			<span
				style={{
					color: !delivery[agreement.agreement_identifier]
						? 'lightgray'
						: 'black',
				}}>
				{translations.production}
			</span>
		</label>
	);
};

export default UsageSwitch;
