import React, { Component } from 'react';
import PropTypes from 'prop-types';

export default class IconFileDownload extends Component {
	static displayName = 'IconFileDownload';

	getLarge() {
		return (
			<svg
				fill={this.props.color}
				xmlns="http://www.w3.org/2000/svg"
				width="48"
				height="48"
				viewBox="0 0 48 48">
				<path d="M38 18h-8V6H18v12h-8l14 14 14-14zM10 36v4h28v-4H10z" />
			</svg>
		);
	}

	getSmall() {
		return (
			<svg
				fill={this.props.color}
				xmlns="http://www.w3.org/2000/svg"
				width="24"
				height="24"
				viewBox="0 0 24 24">
				<path d="M19 9h-4V3H9v6H5l7 7 7-7zM5 18v2h14v-2H5z" />
			</svg>
		);
	}

	getIcon() {
		let svg;
		svg = this.props.size === 'large' ? this.getLarge() : svg;
		svg = this.props.size === 'small' ? this.getSmall() : svg;
		return svg;
	}

	render() {
		return this.getIcon();
	}
}

IconFileDownload.propTypes = {
	size: PropTypes.oneOf(['small', 'large']).isRequired,
};
