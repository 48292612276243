/**
 * Empty object in http request will break it,
 * so every empty object needs to be converted to an empty string
 * @param {string or object} obj
 */

import _ from 'lodash';

export const convertEmptyObjToString = function(obj) {
	return _.each(obj, (value, key) => {
		if (typeof obj[key] === 'object') {
			if (
				!obj[key] ||
				(Object.keys(obj[key]).length === 0 && obj[key].constructor === Object)
			) {
				obj[key] = '';
			}

			convertEmptyObjToString(obj[key]);
		}
	});
};
