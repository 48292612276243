import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Row from '../../components/row';
import PageTitle from '../../components/page-title';

import './error.css';

class Error extends Component {
	render() {
		const { translations, error } = this.props;
		return (
			<div className="view-error">
				<Row>
					<PageTitle>{translations.title}</PageTitle>
				</Row>
				{error && <Row>{error}</Row>}
			</div>
		);
	}
}

Error.propTypes = {
	/** Object containing the translations for this view and the general
	 * translations combined */
	translations: PropTypes.object.isRequired,
	error: PropTypes.string,
};

export default Error;
