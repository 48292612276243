import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import menu from './menu';
import account from './account';
import agreements from './agreements';
import usage from './usage';
import monthlyPayment from './monthly-payment';
import invoices from './invoices';
import move from './move';
import participation from './participation';
import panels from './panels';
import mobileView from './mobile-view/';

import requestPeriodicalPayment from './overlays/request-periodical-payment';
import changePhoneNumber from './overlays/change-phonenumber';
import changeEmailAddress from './overlays/change-email-address';

import contactInputField from './contact/input-field';
import contactSelect from './contact/select';
import contactSubmitted from './contact/submitted';

const ui = combineReducers({
	menu,
	account,
	agreements,
	monthlyPayment,
	usage,
	invoices,
	mobileView,
	contact: combineReducers({
		inputField: contactInputField,
		select: contactSelect,
		submitted: contactSubmitted,
	}),
	overlays: combineReducers({
		requestPeriodicalPayment,
		changePhoneNumber,
		changeEmailAddress,
	}),
	move: combineReducers({
		move,
		wizard: formReducer,
	}),
	participation,
	panels,
});

export default ui;
