import get from './methods/get';
import post from './methods/post';
import deleteMethod from './methods/delete';

export default {
	/**
	 * Perform get request on the sustainable endpoint. It returns a promise which
	 * resolves to a JSON object on success and an Error object on a failure
	 * @param {Object} headers A headers object
	 * @return {Promise}       A Promise
	 */

	get: (headers) => get('sustainable/projects', headers),
	sustainableProjectReport: (headers, projectId) =>
		get(`sustainable/projects/${projectId}/report`, headers),
	emptyResponse: {},
	sustainableOffers: {
		get: (headers) => get('sustainable/offers', headers),
		post: (body) => post('sustainable/offers', body),
		activate: (code, body) =>
			post(`sustainable/activation/direct/${code}`, body),
		retract: (body) => post('sustainable/offers/retract', body),
		moveShares: (body) => post('sustainable/transfers', body),
		marketPlace: (
			projectCode = null,
			page = 1,
			pagination = {
				size: 10,
				page: 1,
				sort: `price,asc&sort=projectName,asc`,
			}
		) =>
			//sort=price,asc&sort=projectName,asc
			post(
				`sustainable/market?size=${pagination.size}&page=${page - 1}&sort=${
					pagination.sort
				}`,
				{
					filters: {
						project_code: projectCode,
						my_offer: false,
					},
				}
			),
		personalOffers: (headers) =>
			get('sustainable/market?personal=true', headers),
		basket: {
			get: (headers) => get('sustainable/basket'),
			post: (body) => post('sustainable/basket', body),
			delete: (reference, body) =>
				post(`sustainable/basket/${reference}/delete`, body),
		},
		orders: {
			get: (headers) => get('sustainable/orders'),
			cancel: (deal_identifier) =>
				deleteMethod(`sustainable/orders/${deal_identifier}`),
			paylink: (deal_identifier) =>
				get(`sustainable/orders/${deal_identifier}/paylink`),
		},
		order: {
			post: (body) =>
				post('sustainable/basket/order', {
					waiveWithdrawalPeriod: true,
					basket: body,
				}),
		},
		projects: {
			get: (project_codes = [], headers) =>
				get(
					`sustainable/projects?projectCodes=${project_codes.join(',')}`,
					headers
				),
		},
		marketProjects: {
			get: () => get('sustainable/market/projects'),
		},
	},
	notifications: {
		get: () => get('sustainable/notices'),
	},
	production: {
		get: (
			project_code = 'WND-WJ',
			view_type = 'TOTAL_PROJECT',
			type = 'DAY_HOURS',
			offset = 0
		) =>
			get(
				`sustainable/production/${project_code}?timeframe_type=${type}&timeframe_offset=${offset}&view_type=${view_type}`
			),
	},
};
