import { combineReducers } from 'redux';
import agreements from './agreements';
import connections from './connections';
import agreementsPeriodical from './agreements.periodical';
import relations from './relations';
import labels from './labels';
import periodicalPayments from './periodical-payments';
import relationsPhoneNumber from './relations.phonenumber';
import relationsEmailAddress from './relations.email-address';
import usage from './usage';
import invoices from './invoices';
import bankAccountDetails from './bank.account.details';
import accountUsername from './account-username';
import latestInvoices from './invoices.latest';
import userInfo from './user-info';

export default combineReducers({
	connections,
	agreements,
	agreementsPeriodical,
	relations,
	labels,
	periodicalPayments,
	relationsPhoneNumber,
	relationsEmailAddress,
	usage,
	invoices,
	bankAccountDetails,
	accountUsername,
	latestInvoices,
	userInfo,
});
