import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import Panel from '../../../components/panel';
import AgreementTitle from '../../../components/agreement-title/agreement-title';
import DateTime from '../../../components/date-time';
import Currency from '../../../components/currency';
import { selector } from '../../../reducers/data/agreements';
import AddressTooltip from './address-tooltip';

import Icon from '../../../components/icon';
import More from '../../../components/icons/more';

import './periodical-payment.css';

const PeriodicalPayment = ({
	periodicalPayment,
	translations,
	agreement,
	tooltipPosition,
	isUserImpersonator,
}) => {
	if (!agreement || !periodicalPayment) {
		return null;
	}

	return (
		<Panel
			additionalClass="periodical-payment"
			title={
				<AgreementTitle agreementIdentifier={agreement.agreement_identifier} />
			}
			rightLinkText={getEditButton(translations.periodicalPayment.edit)}
			rightLinkUrl={getEditUrl(periodicalPayment, isUserImpersonator)}>
			<div className="periodical-payment-agreement-details">
				{agreement.addresses && agreement.addresses.length > 0 && (
					<span className="periodical-payment-agreement-address">
						<div>
							<div>
								{AddressTooltip.getAddressToString(agreement.addresses[0])}
							</div>
							{agreement.addresses.length > 1 && (
								<span
									className="address-link"
									data-tip=""
									data-for={AddressTooltip.createAddressesToolTipId(agreement)}>
									<Icon icon={More} size="small" color="rgba(0, 0, 0, 0.54)" />
									<AddressTooltip
										id={AddressTooltip.createAddressesToolTipId(agreement)}
										tooltipPosition={tooltipPosition}
										agreement={agreement}
									/>
								</span>
							)}
						</div>
					</span>
				)}
				<span className="periodical-payment-agreement-name">
					{agreement.name}
				</span>
				<div className="periodical-payment-agreement-details-period">
					<span className="periodical-payment-agreement-period-label">
						{translations.agreement.fromDate}
					</span>{' '}
					<span className="periodical-payment-agreement-period">
						<DateTime shortMonths={true} date={agreement.period.from} />{' '}
						{agreement.period.to && (
							<span>
								{translations.agreement.throughDate}{' '}
								<DateTime shortMonths={true} date={agreement.period.to} />
							</span>
						)}
					</span>
				</div>
			</div>
			<div className="periodical-payment-amount-details">
				<span className="periodical-payment-current-label">
					{translations.periodicalPayment.title}
				</span>{' '}
				<Currency
					className="periodical-payment-amount"
					amount={periodicalPayment.current_amount}
				/>
				{periodicalPayment.requested_new_amount && (
					<small>
						({translations.periodicalPayment.changeRequested}
						{': '}
						<Currency amount={periodicalPayment.requested_new_amount} />)
					</small>
				)}
			</div>
		</Panel>
	);
};

const getEditButton = (title) => (
	<span className="periodical-payment-edit-button">
		<i className="material-icons">create</i>
		{title}
	</span>
);

const getEditUrl = (periodicalPayment, isUserImpersonator) =>
	periodicalPayment.requested_new_amount || isUserImpersonator
		? null
		: `/periodical-payments/update/${periodicalPayment.agreement_identifier_with_sequence}`;

PeriodicalPayment.propTypes = {
	periodicalPayment: PropTypes.object.isRequired,
};

const mapStateToProps = (state, { periodicalPayment }) => ({
	agreement: selector.getAgreementByAgreementIdentifierWithSequence(
		state,
		periodicalPayment.agreement_identifier_with_sequence
	),
});

export default connect(mapStateToProps)(PeriodicalPayment);
