import axios from 'axios';

// If we import axios directly, we get a new instance every time. Because we want to be able to
// set some defaults (like auth headers, base url) globally, we re-export one instance here.
// NOTE: Some defaults are set or changed during runtime.
const instance = axios.create();

instance.defaults.baseURL = process.env.REACT_APP_API;
instance.defaults.headers.common['Content-Type'] = 'application/json';
// instance.defaults.headers.common['X-PORTAL-BRANDING'] =
// 	process.env.REACT_APP_DOMAIN;
instance.defaults.headers.common['X-PORTAL-BRANDING'] =
	window.location.hostname;

export default instance;
