const initialState = {
	username: '',
	conflict: false,
};

export default (state = initialState, action) => {
	switch (action.type) {
		case 'ACCOUNT_USERNAME_DATA':
			return {
				...state,
				username: action.data.user_name,
			};
		case 'CONFLICT_ACCOUNT_USERNAME':
			return {
				...state,
				conflict: action.flag,
			};
		default:
			return state;
	}
};

export const action = {
	data: (data) => ({ type: 'ACCOUNT_USERNAME_DATA', data }),
	conflict: (flag) => ({ type: 'CONFLICT_ACCOUNT_USERNAME', flag }),
};
