import uniq from 'lodash/uniq';
import { createReducer, createActions } from '../tabs';

const initialState = {
	selectedTab: 0,
	hiddenDates: [],
};

const tabReducer = createReducer('USAGE');
const getElectricityDates = data =>
	data
		.filter(item => item.commodity === 'ELECTRICITY')
		.map(item => `${item.ean_number}-${item.measurement_date}`);

const toggleDateReducer = (state = initialState.hiddenDates, action) => {
	switch (action.type) {
		case 'USAGE_DATE_TOGGLE':
			if (state.includes(action.date)) {
				return state.filter(item => item !== action.date);
			} else {
				return uniq(state.concat(action.date));
			}
		case 'ADD_USAGE_DATA':
			const electricityDates = getElectricityDates(action.data);
			if (state.length === 0 && electricityDates.length) {
				return electricityDates.slice(1);
			} else {
				return state;
			}
		default:
			return state;
	}
};

export default (state = initialState, action) => {
	return Object.assign({}, state, {
		selectedTab: tabReducer(state.selectedTab, action),
		hiddenDates: toggleDateReducer(state.hiddenDates, action),
	});
};

export const action = {
	...createActions('USAGE'),
	toggleDate: date => ({ type: 'USAGE_DATE_TOGGLE', date }),
};
