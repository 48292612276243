const initialState = {
	GAS: {},
	ELECTRICITY: {},
};

export default (state = initialState, action) => {
	switch (action.type) {
		case 'ADD_USAGE_DATA':
			action.data.forEach(element => {
				const list = state[element.commodity][element.ean_number] || [];
				if (!list.map(JSON.stringify).includes(JSON.stringify(element))) {
					list.push(element);
				}
				state[element.commodity][element.ean_number] = list;
			});
			return state;
		default:
			return state;
	}
};

export const action = {
	data: data => ({ type: 'ADD_USAGE_DATA', data }),
};
