import React from 'react';
import PropTypes from 'prop-types';

import './request-periodical-payment.css';

class RequestPeriodicalPayment extends React.Component {
	render() {
		const { requestedAmount, slider } = this.props;
		return (
			<section className="request-periodical-payment">
				<div className="request-periodical-payment__header">
					<div className="request-periodical-payment__requested">
						{requestedAmount}
					</div>
				</div>
				{slider}
			</section>
		);
	}
}

export default RequestPeriodicalPayment;

RequestPeriodicalPayment.propTypes = {
	requestedAmount: PropTypes.object,
	/** An instance of `SliderLabeled` */
	slider: PropTypes.node.isRequired,
};
