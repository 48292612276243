import React from 'react';
import DescriptionList from '../description-list';
import PropTypes from 'prop-types';

import './opening-hours.css';

const OpeningHours = ({ opening_hours = [], translations }) => (
	<DescriptionList className="opening-hours__times">
		{opening_hours.map(({ text, from, to }, index) => {
			return [
				<dt key={`dl-dt-${index}`}>
					{translations.openingHoursDays[text] || text}
				</dt>,
				<dd key={`dl-dd-${index}`}>
					{from} - {to}
				</dd>,
				<br key={index} />,
			];
		})}
	</DescriptionList>
);

export default OpeningHours;

OpeningHours.propTypes = {
	opening_hours: PropTypes.arrayOf(
		PropTypes.shape({
			text: PropTypes.string,
			from: PropTypes.string,
			to: PropTypes.string,
		})
	),
};
