import React, { useState } from 'react';
import Icon from '../icon';
import Home from '../icons/home';
import Info from '../icons/info';
import './agreement-box.scss';
import getAddressCommodityMap from '../../utils/get-address-commodity-map';
import { CSSTransition } from 'react-transition-group';
import Link from '../link';

const toggleSelectedClass = (a, b) => (a === b ? 'selected' : '');
const toggleInfoMessageClass = (addresses, duplicates) =>
	addresses.length > 1 || duplicates ? 'multiple-address' : '';

const isAgreementSelectable = (addresses, duplicates) =>
	addresses && addresses.length === 1 && !duplicates;

const Address = ({ address }) => (
	<div className="address">
		<Icon size="inline" className="home" icon={Home} />
		<div className="address-text">
			<span>
				{address.street} {address.house_number}
				{address.house_number_extension
					? ` ${address.house_number_extension},`
					: ','}
			</span>
			<span>
				{address.zip_code} {address.city}
			</span>
		</div>
	</div>
);

const MultipleAddressInfoBox = ({
	text,
	agreementIdentifier,
	buttonAction,
	buttonText,
}) => (
	<div className="multiple-address-info">
		<Icon size="inline" className="home" icon={Info} />
		<span>
			<span>
				{text.part1}{' '}
				<Link to="/contact" target="_blank">
					{text.part2}{' '}
				</Link>
				{text.part3} {agreementIdentifier}.
			</span>
			{buttonAction && (
				<button className="showDuplicates" onClick={buttonAction}>
					{buttonText}
				</button>
			)}
		</span>
	</div>
);

const AgreementBox = ({
	agreement,
	getCommodityIcons,
	selectHandler,
	selectedAgreement,
	translations,
}) => {
	const {
		addresses,
		agreement_identifier_with_sequence,
		agreement_identifier,
		name,
		duplicates,
		period,
	} = agreement;
	const text = translations.form.step0;
	const [showDuplicatedAgreements, setShowDuplicatedAgreements] = useState(
		false
	);
	return (
		<div
			className={`agreement-box-wrapper
			${toggleInfoMessageClass(addresses, duplicates)}
			${toggleSelectedClass(selectedAgreement, agreement_identifier_with_sequence)}`}>
			{addresses.length > 1 && (
				<MultipleAddressInfoBox
					text={text.multipleAddressMsg}
					agreementIdentifier={agreement_identifier}
				/>
			)}
			{duplicates && duplicates.length && (
				<MultipleAddressInfoBox
					text={text.multipleAddress}
					agreementIdentifier={agreement_identifier}
					buttonAction={() =>
						setShowDuplicatedAgreements(!showDuplicatedAgreements)
					}
					buttonText={text.showDuplicates}
				/>
			)}
			<div
				className="agreement-box"
				onClick={
					isAgreementSelectable(addresses, duplicates)
						? () =>
								selectHandler(addresses[0], agreement_identifier_with_sequence)
						: null
				}>
				{isAgreementSelectable(addresses, duplicates) && (
					<div className="radio-button"></div>
				)}
				<div className="agreement-id">{agreement_identifier}</div>
				<div className="address-list">
					{addresses.map((address, i) => {
						return (
							<div
								className="agreement-info"
								key={agreement_identifier_with_sequence + i}>
								<Address address={address} />
								<div className="additional-info">
									<div className="name">{name}</div>
									<div className="date">
										{period.from} t/m {period.to}
									</div>
								</div>
								<div className="connection-icons">
									{getCommodityIcons(getAddressCommodityMap(address))}
								</div>
							</div>
						);
					})}
				</div>
			</div>
			{duplicates &&
				duplicates.map((duplicate) => {
					return (
						<CSSTransition
							key={duplicate.agreement_identifier_with_sequence}
							in={showDuplicatedAgreements}
							timeout={300}
							classNames="my-node"
							unmountOnExit>
							<div className="agreement-box duplicates">
								<div className="agreement-id">
									{duplicate.agreement_identifier}
								</div>
								<div className="address-list">
									{duplicate.addresses.map((address, i) => {
										return (
											<div
												className="agreement-info"
												key={duplicate.agreement_identifier_with_sequence + i}>
												<Address address={address} />
												<div className="additional-info">
													<div className="name">{duplicate.name}</div>
													<div className="date">
														{duplicate.period.from} t/m {duplicate.period.to}
													</div>
												</div>
												<div className="connection-icons">
													{getCommodityIcons(getAddressCommodityMap(address))}
												</div>
											</div>
										);
									})}
								</div>
							</div>
						</CSSTransition>
					);
				})}
		</div>
	);
};

export default AgreementBox;
