import React, { Fragment } from 'react';
import Link from '../../link';

import Icon from '../../icon';
import Description from '../../icons/description';
import AccountCircle from '../../icons/account-circle';
import EuroSymbol from '../../icons/euro-symbol';
import Location from '../../icons/location-on';
import Invoice from '../../icons/description-outlined';

import './quickstart.css';

const Quickstart = ({ translations }) => {
	return (
		<Fragment>
			<QuickStartLink icon={Description} path="/agreements">
				{translations.quickstart.agreements}
			</QuickStartLink>
			<QuickStartLink icon={AccountCircle} path="/account">
				{translations.quickstart.account}
			</QuickStartLink>
			<QuickStartLink icon={EuroSymbol} path="/periodical-payments">
				{translations.quickstart.periodicalPayments}
			</QuickStartLink>
			<QuickStartLink icon={Location} path="/move">
				{translations.quickstart.move}
			</QuickStartLink>
			<QuickStartLink icon={Invoice} path="/invoices">
				{translations.quickstart.invoices}
			</QuickStartLink>
		</Fragment>
	);
};

const QuickStartLink = ({ children, path, icon }) => (
	<div className="quickstart-link">
		<Icon icon={icon} size="small" />
		<Link to={path}>{children}</Link>
	</div>
);

export default Quickstart;
