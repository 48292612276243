import React, { useEffect, useState } from 'react';
import './confirm-reactivation.css';
import api from '../../api';
import Spinner from '../spinner';
import { useSelector, useDispatch } from 'react-redux';
import LangSwitch from '../lang-switch';
import isUuidValid from 'uuid-validate';

const getUuidFromUrl = (url) => url.substring(url.lastIndexOf('/') + 1);

const ConfirmReactivation = ({ logo, info }) => {
	const states = {
		reactivated: false,
		alreadyReactivated: false,
		reactivationFailed: false,
	};
	const translations = useSelector((state) => state.translations);
	const dispatch = useDispatch();
	const [reactivation, setReactivation] = useState(states);

	useEffect(() => {
		const uuid = getUuidFromUrl(window.location.href);
		if (!isUuidValid(uuid)) {
			setReactivation({ ...states, reactivationFailed: true });
			return;
		}
		api.reactivateAccount
			.finish(uuid)
			.then(() => setReactivation({ ...states, reactivated: true }))
			.catch((err) =>
				err.response.status === 412
					? setReactivation({ ...states, alreadyReactivated: true })
					: setReactivation({ ...states, reactivationFailed: true })
			);
		// eslint-disable-next-line
	}, []);

	return (
		<div className="confirm-reactivation">
			<LangSwitch dispatch={dispatch} translations={translations} />
			<a href={window.location.origin}>
				<div className="logo">
					{logo && <img src={logo} alt={info.name || 'Logo'} />}
				</div>
			</a>
			{reactivation.reactivated ? (
				<div className="message">
					<p>{translations.confirmReactivation.reactivatedMessage}</p>
					<p>{translations.confirmReactivation.contactMessage}</p>
				</div>
			) : reactivation.alreadyReactivated ? (
				<div className="message">
					{translations.confirmReactivation.alreadyReactivatedMessage}
				</div>
			) : reactivation.reactivationFailed ? (
				<div className="error-message">
					{translations.confirmReactivation.reactivationFailedMessage}
				</div>
			) : (
				<Spinner />
			)}

			<footer>
				<span>{info.name}</span>
				<span>{info.email}</span>
				<span>{info.phone_number}</span>
			</footer>
		</div>
	);
};

export default ConfirmReactivation;
