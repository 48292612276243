import isUserImpersonator from '../../utils/is-impersonator';

export default (state = { attributes: {} }, action) => {
	switch (action.type) {
		case 'USER_INFO':
			return {
				attributes: action.data.attributes,
			};
		default:
			return state;
	}
};

export const action = {
	data: (data) => ({ type: 'USER_INFO', data }),
};

export const selector = {
	isUserImpersonator: (state) => isUserImpersonator(state.userInfo),
};
