import React from 'react';

import {
	Accordion as ReactAccordion,
	AccordionItem,
	AccordionItemTitle,
	AccordionItemBody,
} from 'react-accessible-accordion';
import Icon from '../../components/icon';
import ExpandLess from '../../components/icons/expand-less';

import './accordion.css';

const Accordion = ({ children, accordion }) => {
	return (
		<ReactAccordion accordion={accordion}>
			{children
				.filter(Boolean)
				.map(({ props: { title, children, expanded, icon, className } }) => {
					return (
						<AccordionItem key={title} expanded={expanded}>
							<AccordionItemTitle hideBodyClassName="collapsed">
								{icon && <Icon icon={icon} size="small" />}
								<h3>{title}</h3>
								<Icon icon={ExpandLess} size="small" />
							</AccordionItemTitle>
							<AccordionItemBody className={className}>
								{children}
							</AccordionItemBody>
						</AccordionItem>
					);
				})}
		</ReactAccordion>
	);
};

export default Accordion;
