import React from 'react';
import PropTypes from 'prop-types';
import Icon from '../icon';
import IconEdit from '../icons/mode-edit';
import Link from '../link';

import './edit-button.css';

/**
 * EditButton can be used to mark a value as changeable by displaying a edit icon and a label.
 * If you specify a linkUrl then it will display a link. Otherwise it will act as a button.
 */
const EditButton = ({
	title,
	linkUrl,
	linkHandler = () => {},
	disabled = false,
}) => {
	const isDisabled = disabled;
	const isLink = !!linkUrl;
	const isButton = !!linkHandler;

	const getDisabled = () => (
		<span className="disabled-link">
			<Icon size="inline" className="edit-button__icon" icon={IconEdit} />
			{title}
		</span>
	);

	const getLink = () => (
		<Link to={linkUrl}>
			<Icon size="inline" className="edit-button__icon" icon={IconEdit} />
			{title}
		</Link>
	);

	const getButton = () => (
		<button onClick={linkHandler}>
			<Icon size="inline" className="edit-button__icon" icon={IconEdit} />
			{title}
		</button>
	);

	const getDefault = () => <span />;

	const getVersion = () => {
		if (isDisabled) {
			return getDisabled();
		} else if (isLink) {
			return getLink();
		} else if (isButton) {
			return getButton();
		} else {
			return getDefault();
		}
	};

	return (
		<span
			className={`edit-button primary-text-color ${
				isDisabled ? 'edit-button--disabled' : ''
			}`.trim()}>
			{getVersion()}
		</span>
	);
};

export default EditButton;

EditButton.propTypes = {
	title: PropTypes.string.isRequired,
	linkUrl: PropTypes.string,
	linkHandler: PropTypes.func,
	disabled: PropTypes.bool,
};
