import React from 'react';
import PropTypes from 'prop-types';
import AddressBox from '../../../components/address-box/index';
import TariffSheet from '../../../components/tariff-sheet/index';
import ConnectionLinksTooltip from '../../../components/connection-links-tooltip/index';
import DateTime from '../../../components/date-time/index';

import './agreement.css';

const Agreement = ({ translations, agreement }) => {
	return (
		<div className="agreement">
			<div className="agreement-details">
				<span className="agreement-name">{agreement.name}</span>
				{agreement.period && (
					<div className="agreement-details-period">
						<span className="agreement-period-label">
							{translations.fromDate}
						</span>{' '}
						<span className="agreement-period">
							<DateTime date={agreement.period.from} />{' '}
							{agreement.period.to && (
								<span>
									{translations.throughDate}{' '}
									<DateTime date={agreement.period.to} />
								</span>
							)}
						</span>
					</div>
				)}
				<div className="tariff-sheet-links">
					{agreement.tariff_sheets &&
						agreement.tariff_sheets.map((tariffSheet, index) => (
							<TariffSheet
								key={index}
								retrievalId={tariffSheet.retrieval_id}
								downloadLinkText={translations.downloadLinkText}
							/>
						))}
				</div>
			</div>
			<div className="agreement-address-list ellipsis-list">
				{agreement.addresses &&
					agreement.addresses.map((address) => (
						<AddressBox
							key={`address-${address.zip_code}-${address.house_number}`}
							translations={translations}
							address={address}
							companies={[agreement.company_name]}>
							{address.connections && (
								<ConnectionLinksTooltip
									connections={address.connections}
									agreementId={agreement.agreement_identifier}
								/>
							)}
						</AddressBox>
					))}
			</div>
		</div>
	);
};

Agreement.propTypes = {
	translations: PropTypes.object.isRequired,
	agreement: PropTypes.object.isRequired,
};

export default Agreement;
