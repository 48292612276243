import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

const withTranslationsGeneral = Component => {
	const C = props => {
		const { wrappedComponentRef, ...remainingProps } = props;
		return <Component {...remainingProps} ref={wrappedComponentRef} />;
	};

	C.displayName = `withTranslationsGeneral(${Component.displayName ||
		Component.name})`;
	C.WrappedComponent = Component;
	C.propTypes = {
		wrappedComponentRef: PropTypes.func,
	};

	return connect(mapStateToProps)(C);
};

function mapStateToProps(state, ownProps) {
	return Object.assign(
		{},
		{ translationsGeneral: state.translations.general },
		ownProps
	);
}

export default withTranslationsGeneral;
