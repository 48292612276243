import React from 'react';
import App from './app';
import { isMultiforceReferrer } from './utils/checkReferrer';
import { Auth } from 'aws-amplify';
import isUserImpersonator from './utils/is-impersonator';

const logoutIfImpersonatorIsNotComingFromMultiForce = (props) => {
	if (isUserImpersonator(props.authData) && !isMultiforceReferrer()) {
		Auth.signOut();
	}
};

const logoutIfPreviousSessionOpenWhenComingAsImpersonator = () => {
	if (window.location.search.includes('impersonator')) {
		Auth.signOut();
	}
};

export default function AppAuthWrapper(props) {
	if (props.authState === 'signedIn') {
		logoutIfImpersonatorIsNotComingFromMultiForce(props);
		logoutIfPreviousSessionOpenWhenComingAsImpersonator();
		return <App {...props} />;
	} else {
		return null;
	}
}
