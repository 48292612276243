import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch, connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import api from '../../api';

import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import {
	FormControlLabel,
	Checkbox,
	FormControl,
	TextField,
	List,
	ListItem,
	ListItemText,
	IconButton,
	ListItemIcon,
	Stack,
	Divider,
	Link,
	useTheme,
	CircularProgress,
} from '@mui/material';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import nl from 'date-fns/locale/nl';
import DatePicker from '@mui/lab/MobileDatePicker';
import { addDays } from 'date-fns';
import { DeleteOutline } from '@mui/icons-material';
import { red } from '@mui/material/colors';

const Alert = React.forwardRef(function Alert(props, ref) {
	return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function MyShoppingCart({ marketplace, translations }) {
	const [isLoading, setIsLoading] = useState(false);
	const [projectsLoading, setProjectsLoading] = useState(false);

	const [projectsConditions, setProjectConditions] = useState([]);

	const [withdrawelPeriodChecked, setWithdrawelPeriodChecked] = useState(false);
	// const [termsconditionsChecked, setTermsconditionsChecked] = useState(false);

	const [date, setDate] = useState(addDays(new Date(), 31));
	const [first_valid_start_date, setfirst_valid_start_date] = useState(
		addDays(new Date(), 31)
	);
	const [open, setOpen] = useState(false);
	const [severity, setSeverity] = useState('success');
	const [message, setMessage] = useState('Order placed!');

	const dispatch = useDispatch();
	const history = useHistory();
	const theme = useTheme();

	const handleClose = (_, reason) => {
		if (reason === 'clickaway') {
			return;
		}

		setOpen(false);
	};

	useEffect(() => {
		async function fetchMyOrders() {
			setProjectsLoading(true);
			const ordersProjectCodes = marketplace.basket.items.map(
				(i) => i.project_code
			);
			const { first_valid_start_date } = marketplace.basket;
			setfirst_valid_start_date(new Date(first_valid_start_date));
			setDate(new Date(first_valid_start_date));

			const ordersProjectCodesSet = new Set(ordersProjectCodes);

			const response = await api.sustainable.sustainableOffers.projects.get([
				...ordersProjectCodesSet,
			]);
			const _projectsConditions = response.map((pc) => ({
				...pc,
				statutes_location_isChecked: false,
				member_agreement_location_isChecked: false,
				statutes_location:
					pc.statutes_location || `${window.location.origin}/404`,
				member_agreement_location:
					pc.member_agreement_location || `${window.location.origin}/404`,
			}));
			setProjectConditions(_projectsConditions);
			setProjectsLoading(false);
		}
		fetchMyOrders();
	}, [marketplace]);

	function handleOrder(path) {
		setIsLoading(true);
		api.sustainable.sustainableOffers.order
			.post({
				...marketplace.basket,
				start_date: date,
			})
			.then((response) => {
				setMessage(response.status);
				if (response.status === 'SUCCESS') {
					setSeverity('success');
				} else {
					setSeverity('error');
					setMessage(response.status);
				}
				setOpen(true);
				dispatch({
					type: 'ADD_OFFER',
					basket: response.basket || {
						items: [],
					},
				});
			})
			.catch((error) => {
				setSeverity('error');
				setMessage(error.message);
				setOpen(true);
			})
			.finally(() => {
				setIsLoading(false);
				if (marketplace.basket.items.length === 0) {
					history.push(path);
				}
			});
	}

	const deleteOffer = (reference) => {
		api.sustainable.sustainableOffers.basket
			.delete(reference, marketplace.basket)
			.then((res) => {
				dispatch({
					type: 'ADD_OFFER',
					basket: res,
				});
			});
	};

	const toggleCondition = (value, project_code, key) => {
		const newProjectConditions = projectsConditions.map((pc) => {
			if (pc.project_code === project_code) {
				pc[`${key}`] = value;
			}

			return pc;
		});

		setProjectConditions(newProjectConditions);
	};

	return (
		<Box pt={10}>
			<Snackbar
				open={open}
				autoHideDuration={5000}
				onClose={handleClose}
				anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
				<Alert onClose={handleClose} severity={severity} sx={{ width: '100%' }}>
					{message}
				</Alert>
			</Snackbar>
			{marketplace.basket.items.length === 0 ? (
				<Box
					sx={{
						p: 2,
						bgcolor: 'background.default',
						display: 'grid',
						gridTemplateColumns: { md: '1fr 1fr' },
						gap: 2,
					}}>
					<Paper>
						<Typography>
							<p>Geen items</p>
							<br />
							<a href="/offers">{translations.menu.availaleOffers}</a>
						</Typography>
					</Paper>
				</Box>
			) : (
				<Paper sx={{ padding: 6, boxShadow: theme.customShadows.z16 }}>
					<Typography variant="h5" gutterBottom>
						{translations.orderSummary}
					</Typography>
					<List disablePadding>
						{marketplace.basket.items.map((offer) => {
							const { address } = offer;
							const {
								city,
								house_number,
								house_postfix,
								postal_code,
								street_name,
							} = address || {};

							const addressLabel = `${street_name || ''} ${house_number ||
								''} ${house_postfix || ''}, ${postal_code || ''} ${city || ''}`;
							return (
								<Fragment key={offer.reference}>
									<ListItem>
										<ListItemIcon>
											<IconButton
												sx={{
													color: red[500],
												}}
												variant="outlined"
												onClick={() => deleteOffer(offer.identifier)}>
												<DeleteOutline />
											</IconButton>
										</ListItemIcon>
										<ListItemText
											primary={`${offer.project_name} | ${offer.project_code}`}
											primaryTypographyProps={{
												variant: 'h6',
											}}
											secondaryTypographyProps={{
												variant: 'h7',
											}}
											secondary={`
										${translations.adres}: ${addressLabel} |
										${translations.numberOfShares}: ${offer.shares} |
										${translations.pricePerShare}: ${offer.price_per_share}
									`}
										/>
										<Typography>&euro;{offer.amount}</Typography>
									</ListItem>
									<Divider />
								</Fragment>
							);
						})}
						<ListItem>
							<ListItemText
								primaryTypographyProps={{ variant: 'h5' }}
								primary={translations.totalCost}
							/>
							<Typography variant="h5" sx={{ fontWeight: 700 }}>
								&euro;{marketplace.basket.total_amount}
							</Typography>
						</ListItem>
					</List>
					<Divider />
					<Stack spacing={2} direction="column" sx={{ padding: 2 }}>
						{projectsLoading && <CircularProgress />}
						{!projectsLoading &&
							projectsConditions.map((pc) => (
								<Fragment key={pc.project_code}>
									<FormControlLabel
										style={{
											marginLeft: 0,
										}}
										control={
											<Checkbox
												checked={pc.statutes_location_isChecked}
												onChange={(_, checked) =>
													toggleCondition(
														checked,
														pc.project_code,
														'statutes_location_isChecked'
													)
												}
												name="statutes_location"
											/>
										}
										label={
											<Typography>
												{`${translations.iAccept}`}
												<Link
													href={pc.member_agreement_location}
													target="_blank"
													rel="noreferrer"
													style={{
														color: '#3673a0',
														fontWeight: 500,
														textDecoration: 'none',
													}}>
													{`${translations.member_agreement}`}
												</Link>
												{` & `}
												<Link
													href={pc.statutes_location}
													target="_blank"
													rel="noreferrer"
													style={{
														color: '#3673a0',
														fontWeight: 500,
														textDecoration: 'none',
													}}>
													{`${translations.statutes}`}
												</Link>
												{`${translations.ofProject} ${pc.project_name}${translations.iRead}`}
											</Typography>
										}
									/>
								</Fragment>
							))}
						{!projectsLoading && (
							<FormControlLabel
								control={
									<Checkbox
										checked={withdrawelPeriodChecked}
										onChange={(_, checked) =>
											setWithdrawelPeriodChecked(checked)
										}
										name="withdrawelPeriod"
									/>
								}
								label={
									<Typography>
										{`${translations.withdrawelPeriodCheckboxContent}`}
									</Typography>
								}
							/>
						)}
						<FormControl fullWidth required>
							<LocalizationProvider dateAdapter={AdapterDateFns} locale={nl}>
								<DatePicker
									size="small"
									label={translations.transferDate}
									value={date}
									onChange={(newValue) => {
										setDate(newValue);
									}}
									minDate={first_valid_start_date}
									renderInput={(params) => (
										<TextField {...params} size="small" />
									)}
								/>
							</LocalizationProvider>
						</FormControl>
						<Divider />
						<Button
							loading={isLoading.toString()}
							variant="outlined"
							disabled={
								!projectsConditions.every(
									(pc) => pc.statutes_location_isChecked
								) || !withdrawelPeriodChecked
							}
							onClick={() => handleOrder('offers')}>
							{translations.placeOrder}
						</Button>
					</Stack>
				</Paper>
			)}
		</Box>
	);
}

function mapStateToProps(state) {
	return {
		marketplace: state.marketplace,
		translations: Object.assign(
			state.translations.marketPlace,
			state.translations.general
		),
	};
}

export default connect(mapStateToProps)(MyShoppingCart);
