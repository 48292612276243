import {
	FormLabel,
	FormControlLabel,
	RadioGroup,
	Radio,
	FormControl,
} from '@mui/material';
import { useField, useFormikContext } from 'formik';

const RadioGroupWrapper = ({ name, label, options, ...props }) => {
	const [field, meta] = useField(name);
	const { setFieldValue } = useFormikContext();
	const handleChange = (event) => {
		setFieldValue(name, event.target.checked);
	};
	const configRadioGroup = {
		onChange: handleChange,
		...props,
		...field,
	};

	const configFormControl = {};

	if (meta && meta.touched && meta.error) {
		configFormControl.error = true;
	}

	return (
		<FormControl {...configFormControl} required={props.required}>
			<FormLabel required={props.required} component="legend">
				{label}
			</FormLabel>
			<RadioGroup {...configRadioGroup} name={name}>
				{options.map(({ value, label }, index) => {
					return (
						<FormControlLabel
							key={index}
							value={value}
							control={<Radio />}
							label={label}
						/>
					);
				})}
			</RadioGroup>
		</FormControl>
	);
};

export default RadioGroupWrapper;
