import React, { Component } from 'react';
import PropTypes from 'prop-types';

export default class IconExpandLess extends Component {
	static displayName = 'IconExpandLess';

	getLarge() {
		return (
			<svg
				fill={this.props.color}
				xmlns="http://www.w3.org/2000/svg"
				width="48"
				height="48"
				viewBox="0 0 48 48">
				<path d="M24 16L12 28l2.83 2.83L24 21.66l9.17 9.17L36 28z" />
			</svg>
		);
	}

	getSmall() {
		return (
			<svg
				fill={this.props.color}
				xmlns="http://www.w3.org/2000/svg"
				width="24"
				height="24"
				viewBox="0 0 24 24">
				<path d="M12 8l-6 6 1.41 1.41L12 10.83l4.59 4.58L18 14z" />
			</svg>
		);
	}

	getIcon() {
		let svg;
		svg = this.props.size === 'large' ? this.getLarge() : svg;
		svg = this.props.size === 'small' ? this.getSmall() : svg;
		return svg;
	}

	render() {
		return this.getIcon();
	}
}

IconExpandLess.propTypes = {
	size: PropTypes.oneOf(['small', 'large']).isRequired,
};
