import React from 'react';
import Panel from '../components/panel';
import ErrorText from '../components/error-text';
import { Validity } from '../components/input';
import Fieldset from '../components/fieldset';
import closeableOverlay from '../components/closeable-overlay';
import api from '../api';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/dist/style.css';

import { action as phoneNumberAction } from '../reducers/ui/overlays/change-phonenumber';
import { action as relationsPhoneNumberAction } from '../reducers/data/relations.phonenumber';
import { action as relationsAction } from '../reducers/data/relations';
import { action as notificationsAction } from '../reducers/notifications';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

const phoneNumberInternationalRegex = new RegExp('^[+][0-9]{9,12}$');
const phoneNumberRegexForUserInput = new RegExp(
	/^((\+|00(\s|\s?-\s?)?)31(\s|\s?-\s?)?(\(0\)[-\s]?)?|0)[1-9]((\s|\s?-\s?)?[0-9])((\s|\s?-\s?)?[0-9])((\s|\s?-\s?)?[0-9])\s?[0-9]\s?[0-9]\s?[0-9]\s?[0-9]\s?[0-9]$/gm
);
const stateCodeRegex = /^((\+|00(\s|\s?-\s?)?)31(\s|\s?-\s?)?(\(0\)[-\s]?)?)/gm;
const phoneNumberRequestRegex = /^((\(\(0\)[-\s]?)?|0)[1-9]((\s|\s?-\s?)?[0-9])((\s|\s?-\s?)?[0-9])((\s|\s?-\s?)?[0-9])\s?[0-9]\s?[0-9]\s?[0-9]\s?[0-9]\s?[0-9]$/gm;
class ChangePhoneNumber extends React.Component {
	componentWillMount() {
		this.props.dispatch(phoneNumberAction.change(null));
	}

	savePhoneNumber = () => {
		const { dispatch, translations } = this.props;

		const phoneNumber = this.getEnteredPhoneNumber();
		if (!this.isValidRequestFormat(phoneNumber)) {
			notificationsAction.add(dispatch, translations.phoneNumberErrorMessage);
			return;
		}
		const phoneNumberPut = api.relations.phoneNumber
			.put({
				phone_number: phoneNumber,
			})
			.catch((err) => {
				notificationsAction.add(
					dispatch,
					err.response.status === 406
						? translations.phoneNumberErrorFakeNumberMessage
						: translations.phoneNumberErrorMessage
				);
			});

		const phoneNumberGet = phoneNumberPut
			.then(() => api.relations.phoneNumber.get())
			.then((phoneNumberData) =>
				dispatch(relationsPhoneNumberAction.data(phoneNumberData))
			);

		const relationsGet = phoneNumberPut
			.then(() => api.relations.get())
			.then((relationsData) => dispatch(relationsAction.data(relationsData)));

		Promise.all([phoneNumberGet, relationsGet]).then(() => {
			this.props.onClose();
		});
	};

	cancel = () => {
		this.props.onClose();
	};

	onChange = (value, countryData) => {
		if (value.startsWith('+')) {
			this.props.dispatch(phoneNumberAction.change(value));
		} else {
			this.props.dispatch(
				phoneNumberAction.change('+'.concat(countryData.dialCode))
			);
		}
	};

	onBlur = (e) => {
		const phoneNumber = this.cleanPhoneNumber(e.target.value);
		this.props.dispatch(phoneNumberAction.change(phoneNumber));
	};

	cleanPhoneNumber(phoneNumber) {
		return phoneNumber !== null
			? phoneNumber.replace(/[-\s]/g, '').replace(stateCodeRegex, '0')
			: '';
	}

	hasChanged() {
		const enteredPhoneNumber = this.getEnteredPhoneNumber();
		return (
			this.getEnteredPhoneNumber() !== null &&
			this.cleanPhoneNumber(enteredPhoneNumber) !==
				this.cleanPhoneNumber(this.getSavedPhoneNumber())
		);
	}

	getEnteredPhoneNumber() {
		return this.props.ui.overlays.changePhoneNumber;
	}

	getSavedPhoneNumber() {
		const { data } = this.props;
		return (
			data.relationsPhoneNumber.phone_number || data.relations.phone_number
		);
	}

	getMostRecentPhoneNumber() {
		return this.getEnteredPhoneNumber() !== null
			? this.getEnteredPhoneNumber()
			: this.cleanPhoneNumber(this.getSavedPhoneNumber());
	}

	getDutchOrInternationalPhoneNumber = (phoneNumber) => {
		return phoneNumber.startsWith('+')
			? phoneNumber
			: '+31'.concat(phoneNumber.substring(1));
	};

	isValidDutch() {
		return !!this.getMostRecentPhoneNumber().match(
			phoneNumberRegexForUserInput
		);
	}

	isValidInternational() {
		const enteredNumber = this.getMostRecentPhoneNumber().replace(/ /g, '');
		return enteredNumber.match(phoneNumberInternationalRegex);
	}

	isValidRequestFormat() {
		return !!(
			this.getMostRecentPhoneNumber().match(phoneNumberRequestRegex) ||
			this.getMostRecentPhoneNumber().match(phoneNumberInternationalRegex)
		);
	}

	getWidth = () => {
		if (window.innerWidth < 330) {
			return {
				width: '250px',
			};
		}
	};

	render() {
		const { translations } = this.props;
		const phoneNumber = this.getDutchOrInternationalPhoneNumber(
			this.getMostRecentPhoneNumber()
		);
		const isValid =
			this.hasChanged() && (this.isValidDutch() || this.isValidInternational());

		return (
			<Panel
				name="change-phonenumber"
				title={translations.title}
				leftLinkText={translations.cancel}
				leftButtonHandler={this.cancel}
				rightLinkText={translations.save}
				rightButtonHandler={isValid ? this.savePhoneNumber : null}>
				<Fieldset
					label={translations.new}
					focus={true}
					maxLength="20"
					type="text"
					validity={isValid ? Validity.VALID : Validity.UNKOWN}>
					<PhoneInput
						defaultCountry={'nl'}
						inputStyle={{ width: '100%' }}
						dropdownStyle={this.getWidth()}
						regions={'europe'}
						value={phoneNumber}
						enableSearchField
						searchPlaceholder={translations.search}
						preferredCountries={['nl', 'be', 'de', 'fr']}
						countryCodeEditable={false}
						onBlur={this.onBlur}
						onChange={(value, countryData) => this.onChange(value, countryData)}
					/>
				</Fieldset>

				{this.getEnteredPhoneNumber() !== null && !isValid && (
					<ErrorText
						className="change-phonenumber__error"
						align="left"
						size="small">
						{translations.invalid}
					</ErrorText>
				)}
			</Panel>
		);
	}
}

function mapStateToProps(state) {
	return {
		data: state.data,
		ui: state.ui,
		translations: Object.assign(state.translations.overlay.changePhoneNumber),
	};
}

export default withRouter(
	connect(mapStateToProps)(closeableOverlay(ChangePhoneNumber))
);
