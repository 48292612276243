import { combineReducers } from 'redux';
import id from './labels.id';
import logo from './labels.logo';
import info from './labels.info';

export default combineReducers({
	id,
	logo,
	info,
});
