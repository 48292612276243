import get from './methods/get';

// const domain = 'mijn.windcentrale.nl.ssp-site-1ops.tst.aws.servicehouse.nl';

export default {
	/**
	 * Perform get request on the id endpoint to get the label id for the given domain.
	 * It returns a promise which resolves to a JSON object containing the label_key on success and an Error object on a failure
	 * @param {String} domain  The hostname for which you want to get the label id
	 * @param {Object} headers A headers object
	 * @return {Promise}       A Promise
	 */
	id: {
		get: (domain, headers) => get(`labels/key?domain=${domain}`),
		emptyResponse: {
			label_key: '',
			client_id: '',
			issuer: '',
			authorization_endpoint: '',
			logout_redirect_url: '',
			privacy_and_cookie_url: '',
		},
	},

	/**
	 * Perform get request on the style endpoint to get the label styling for the given label id.
	 * It returns a promise which resolves to a JSON object on success and an Error object on a failure
	 */
	style: {
		get: (key, headers) => get(`labels/${key}/style`),
		emptyResponse: {
			header_bg_color: '',
			page_title: '',
			primary_color: '',
			secondary_color: '',
			tertiary_color: '',
			font_family_web: '',
		},
	},

	/**
	 * The logo api returns a promise which resolves to a url pointing to the logo image for the given label id
	 */
	logo: {
		get: async (key) => {
			if (typeof window !== 'undefined' && window.__LABEL_LOGO__) {
				return window.__LABEL_LOGO__;
			} else {
				return {
					url: `${process.env.REACT_APP_API}/labels/${key}/logo`,
				};
			}
		},
		emptyResponse: {
			url: '',
		},
	},
	/**
	 * The favicon api returns a promise which resolves to a url pointing to the favicon image for the given label id
	 */
	favicon: {
		get: async (key) => {
			if (typeof window !== 'undefined' && window.__LABEL_FAVICON__) {
				return window.__LABEL_FAVICON__;
			} else {
				return {
					url: `${process.env.REACT_APP_API}/labels/${key}/favicon`,
				};
			}
		},
		emptyResponse: {
			url: '',
		},
	},

	info: {
		get: (key, headers) => get(`labels/${key}/info`),
		emptyResponse: {
			email: '',
			name: '',
			opening_hours: [],
			phone_number: '',
		},
	},
};
