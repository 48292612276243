import './mijn-participation-box.css';
import roundNumber from '../../../utils/round-number';

const MijnParticipationBox = ({ translations, participation }) => {
	const {
		share,
		participation_identifier,
		greenchoice_id,
		supplier,
		address,
		production,
		mutation_date,
	} = participation;
	return (
		<div className="my-participation">
			<ul>
				<li>
					<span className="label">{translations.share}</span>
					<span className="value">{share}</span>
				</li>
				<li>
					<span className="label">{translations.mutation_date}</span>
					<span className="value">{formatDate(mutation_date)}</span>
				</li>
				<li>
					<span className="label">{translations.participation_identifier}</span>
					<span className="value">{participation_identifier}</span>
				</li>
				{greenchoice_id && (
					<li>
						<span className="label">{translations.greenchoice_id}</span>
						<span className="value">{greenchoice_id}</span>
					</li>
				)}
				<li>
					<span className="label">{translations.supplier}</span>
					<span className="value">{supplier}</span>
				</li>
			</ul>
			{address && (
				<div>
					<br />
					<b className="label">{`${translations.address}: `}</b>
					<span className="value">{`${address.street_name} ${
						address.house_number
					} ${address.house_postfix || ''}, ${address.postal_code} ${
						address.city
					}`}</span>
				</div>
			)}
			{production && (
				<>
					<div className="period">
						{[
							translations.period,
							translations.startDate.toLowerCase(),
							formatDate(production.period_start_date),
							translations.endDate.toLowerCase(),
							formatDate(production.period_end_date),
						].join(' ')}
					</div>
					<div
						className={
							(production.peak || production.peak === 0) &&
							(production.off_peak || production.off_peak === 0)
								? 'peak-values'
								: 'normal-value'
						}>
						{(production.peak || production.peak === 0) && (
							<div className="peak">
								{translations.peak}{' '}
								<span>{roundNumber(production.peak, 2)} kWh</span>
							</div>
						)}
						{(production.off_peak || production.off_peak === 0) && (
							<div className="off-peak">
								{translations.offPeak}{' '}
								<span>{roundNumber(production.off_peak, 2)} kWh</span>
							</div>
						)}
					</div>
				</>
			)}
		</div>
	);
};

const formatDate = (date) =>
	date
		? new Date(date).toLocaleDateString('nl-NL', {
				day: '2-digit',
				month: '2-digit',
				year: 'numeric',
		  })
		: null;

export default MijnParticipationBox;
