import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

const withPlatform = Component => {
	const C = props => {
		const { wrappedComponentRef, ...remainingProps } = props;
		return <Component {...remainingProps} ref={wrappedComponentRef} />;
	};

	C.displayName = `withPlatform(${Component.displayName || Component.name})`;
	C.WrappedComponent = Component;
	C.propTypes = {
		wrappedComponentRef: PropTypes.func,
	};

	return connect(mapStateToProps)(C);
};

function mapStateToProps(state, ownProps) {
	return Object.assign({}, { platform: state.platform }, ownProps);
}

export default withPlatform;
