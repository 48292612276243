const changePhoneNumber = (state = null, action) => {
	switch (action.type) {
		case 'CHANGE_PHONE_NUMBER':
			return action.value;
		default:
			return state;
	}
};

export default changePhoneNumber;

export const action = {
	change: value => ({ type: 'CHANGE_PHONE_NUMBER', value }),
};
