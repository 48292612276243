import relations from '../../api/relations';

export default (state = relations.emailAddress.emptyResponse, action) => {
	switch (action.type) {
		case 'RELATIONS_EMAIL_ADDRESS_SET':
			return Object.assign({}, state, { change_email: action.value });
		case 'RELATIONS_EMAIL_ADDRESS_DATA':
			return action.data;
		default:
			return state;
	}
};

export const action = {
	set: value => ({ type: 'RELATIONS_EMAIL_ADDRESS_SET', value }),
	data: data => ({ type: 'RELATIONS_EMAIL_ADDRESS_DATA', data }),
};
