const initialState = {
	offers: [],
	isLoading: false,
	basket: {
		items: [],
		start_date: null,
	},
	projects: [],
};

const marketplace = (state = initialState, action) => {
	switch (action.type) {
		case 'ADD_PROJECTS':
			return {
				...state,
				projects: action.projects,
			};
		case 'TOGGLE_LOADING':
			return {
				...state,
				isLoading: !state.isLoading,
			};
		case 'ADD_OFFER':
			localStorage.setItem(
				'basket',
				JSON.stringify({
					...state,
					basket: action.basket,
				})
			);
			return {
				...state,
				basket: action.basket,
			};
		case 'REMOVE_OFFER':
			console.log('REMOVE_OFFER', action);
			return state;
		default:
			return JSON.parse(localStorage.getItem('basket')) || state;
	}
};

export default marketplace;
