import React, { Component } from 'react';
import PropTypes from 'prop-types';

export default class IconThermometer extends Component {
	static displayName = 'IconCode';

	getLarge() {
		return (
			<svg
				version="1.1"
				xmlns="http://www.w3.org/2000/svg"
				width="32"
				height="32"
				viewBox="0 0 32 32">
				<path d="M19.648 19.904v-10.016c0-0.032 0-0.096 0-0.128s0-0.096 0-0.128c0-1.856-1.472-3.36-3.264-3.36s-3.264 1.504-3.264 3.36c0 0.064 0 0.16 0.032 0.224 0 0 0 0.032 0 0.032v9.856c-0.384 0.576-0.576 1.344-0.576 2.144 0 2.112 1.728 3.84 3.84 3.84s3.808-1.696 3.776-3.904c0-0.768-0.32-1.504-0.544-1.92zM16.384 23.872c-1.12 0-2.016-0.896-2.016-2.016 0-0.448 0.096-0.864 0.32-1.184 0.16-0.16 0.256-0.384 0.256-0.608v-10.176c0-0.064 0-0.16-0.032-0.224 0 0 0-0.032 0-0.032 0-0.864 0.672-1.568 1.472-1.568s1.472 0.736 1.472 1.568c0 0.032 0 0.096 0 0.128s0 0.096 0 0.128v10.112c-0.032 0.224 0 0.448 0.128 0.64 0.256 0.416 0.416 0.896 0.416 1.216 0 1.12-0.896 2.016-2.016 2.016z" />
				<path d="M16.416 14.080c-0.48 0-0.896 0.384-0.896 0.896v7.136c0 0.48 0.384 0.896 0.896 0.896 0.48 0 0.896-0.416 0.896-0.896v-7.136c0-0.48-0.384-0.896-0.896-0.896z" />
			</svg>
		);
	}

	getSmall() {
		return (
			<svg
				version="1.1"
				xmlns="http://www.w3.org/2000/svg"
				width="32"
				height="32"
				viewBox="0 0 32 32">
				<path d="M19.648 19.904v-10.016c0-0.032 0-0.096 0-0.128s0-0.096 0-0.128c0-1.856-1.472-3.36-3.264-3.36s-3.264 1.504-3.264 3.36c0 0.064 0 0.16 0.032 0.224 0 0 0 0.032 0 0.032v9.856c-0.384 0.576-0.576 1.344-0.576 2.144 0 2.112 1.728 3.84 3.84 3.84s3.808-1.696 3.776-3.904c0-0.768-0.32-1.504-0.544-1.92zM16.384 23.872c-1.12 0-2.016-0.896-2.016-2.016 0-0.448 0.096-0.864 0.32-1.184 0.16-0.16 0.256-0.384 0.256-0.608v-10.176c0-0.064 0-0.16-0.032-0.224 0 0 0-0.032 0-0.032 0-0.864 0.672-1.568 1.472-1.568s1.472 0.736 1.472 1.568c0 0.032 0 0.096 0 0.128s0 0.096 0 0.128v10.112c-0.032 0.224 0 0.448 0.128 0.64 0.256 0.416 0.416 0.896 0.416 1.216 0 1.12-0.896 2.016-2.016 2.016z" />
				<path d="M16.416 14.080c-0.48 0-0.896 0.384-0.896 0.896v7.136c0 0.48 0.384 0.896 0.896 0.896 0.48 0 0.896-0.416 0.896-0.896v-7.136c0-0.48-0.384-0.896-0.896-0.896z" />
			</svg>
		);
	}

	getIcon() {
		let svg;
		svg = this.props.size === 'large' ? this.getLarge() : svg;
		svg = this.props.size === 'small' ? this.getSmall() : svg;
		return svg;
	}

	render() {
		return this.getIcon();
	}
}

IconThermometer.propTypes = {
	size: PropTypes.oneOf(['small', 'large']).isRequired,
};
