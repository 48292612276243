import post from './methods/post';

export default {
	begin: (email, labelKey) =>
		post(
			'/reactivate/begin',
			{
				email,
				labelKey,
			},
			{},
			true
		),
	finish: (id) => post('/reactivate/finish/' + id, {}, {}, true),
};
