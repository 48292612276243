import React, { Component } from 'react';

import api from '../../api';

import Row from '../../components/row';
import Panel from '../../components/panel';
import Email from '../../components/email';
import Phonenumber from '../../components/phonenumber';
import PageTitle from '../../components/page-title';
import Select from '../../components/select';
import Input from '../../components/input';
import FieldSet from '../../components/fieldset';
import OpeningHours from '../../components/opening-hours';
import AskAQuestion from '../../components/ask-a-question';
import Icon from '../../components/icon';
import IconSchedule from '../../components/icons/schedule';

import './contact.css';

import { action as inputFieldAction } from '../../reducers/ui/contact/input-field';
import { action as selectAction } from '../../reducers/ui/contact/select';
import { action as submittedAction } from '../../reducers/ui/contact/submitted';
import { action as notificationsAction } from '../../reducers/notifications';
import { connect } from 'react-redux';

class Contact extends Component {
	dispatch = this.props.dispatch;

	componentWillMount() {
		this.dispatch(submittedAction.reset());
	}

	focusInputField = () => this.dispatch(inputFieldAction.focus());
	blurInputField = () => this.dispatch(inputFieldAction.blur());

	valueInputField = (value) => {
		this.dispatch(inputFieldAction.value(value));

		if (value) {
			this.dispatch(inputFieldAction.validity('valid'));
		} else {
			this.dispatch(inputFieldAction.validity('invalid'));
		}
	};

	valueSelect = (value) => {
		this.dispatch(selectAction.value(value));
		this.dispatch(selectAction.validity('valid'));
	};

	save = (e) => {
		e.preventDefault();

		const { inputField, select } = this.props;

		const subject = select.value;
		const input = inputField.value;

		if (!subject) {
			this.dispatch(selectAction.validity('invalid'));
		}

		if (!input) {
			this.dispatch(inputFieldAction.validity('invalid'));
		}

		if (!subject || !input) {
			return;
		}

		api.relations.contact
			.post({ subject, input })
			.then(() => {
				this.dispatch(submittedAction.submit());
			})
			.catch((err) => {
				notificationsAction.add(
					this.dispatch,
					'Error sending contact message!'
				);
			});
	};

	createSubjectFieldSet() {
		const { translations, select } = this.props;

		return (
			<FieldSet
				label={translations.form.subject}
				onChange={this.valueSelect}
				{...select}>
				<Select options={translations.form.subjectOptions} />
			</FieldSet>
		);
	}

	createInputFieldSet() {
		const { translations, inputField } = this.props;

		return (
			<FieldSet
				label={translations.form.yourMessage}
				{...inputField}
				onFocus={this.focusInputField}
				onBlur={this.blurInputField}
				className="text-area-placeholder"
				onChange={this.valueInputField}>
				<Input type="textarea" rows="6" maxLength="2000" />
			</FieldSet>
		);
	}

	render() {
		const { translations, labels, submitted } = this.props;
		return (
			<div className="view-contact">
				<Row>
					<PageTitle>{translations.title}</PageTitle>
				</Row>
				<Row>
					<div className="contact-form">
						<div className="contact-form__ask-form">
							<Panel name="form" title={translations.form.title} transparent>
								<AskAQuestion
									submitted={submitted}
									title={translations.form.title}
									submitButtonLabel={translations.send}
									onSubmit={this.save}
									submitFeedback={translations.feedback}
									subjectFieldSet={this.createSubjectFieldSet()}
									inputFieldSet={this.createInputFieldSet()}
									buttonKind="transparent"
								/>
							</Panel>
						</div>
						<div className="contact-info">
							{labels.info.phone_number && (
								<Panel
									name="label-contact"
									additionalClass="label-contact"
									topBorder={true}
									title={translations.form.customerServiceTitle}>
									{labels.info.email && (
										<Email>
											<a
												className="primary-text-color"
												href={`mailto:${labels.info.email}`}>
												{labels.info.email}
											</a>
										</Email>
									)}
									<div className="number-and-time">
										{labels.info.phone_number && (
											<Phonenumber
												phone_number={labels.info.phone_number}
												isLink={true}
											/>
										)}
										{labels.info.opening_hours.length > 0 && (
											<div className="time">
												<Icon icon={IconSchedule} size="inline" />
												<OpeningHours
													opening_hours={labels.info.opening_hours}
													translations={translations}
												/>
											</div>
										)}
									</div>
								</Panel>
							)}
						</div>
					</div>
				</Row>
			</div>
		);
	}
}

function mapStateToProps(state) {
	return {
		ui: state.ui.contact,
		relations: state.data.relations,
		labels: state.data.labels,
		inputField: state.ui.contact.inputField,
		select: state.ui.contact.select,
		submitted: state.ui.contact.submitted,
		translations: Object.assign(
			state.translations.contact,
			state.translations.general
		),
	};
}

export default connect(mapStateToProps)(Contact);
