import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import * as ibantools from 'ibantools';

import nl from 'date-fns/locale/nl';
import {
	Typography,
	FormControl,
	FormLabel,
	Stack,
	Divider,
	Grid,
	Container,
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';

import isEmailValidator from 'validator/lib/isEmail';

import { useFormik, FormikProvider, Form } from 'formik';
import * as Yup from 'yup';
import 'yup-phone';
import TextFieldWrapper from '../../components/formik-components/textfield';
import DatePickerMobile from '../../components/formik-components/datepicker';
import api from '../../api';
import React, { useEffect, useState } from 'react';
import getHostname from '../../utils/get-hostname';
import RadioGroupWrapper from '../../components/formik-components/radioGroup';
import LangSwitch from '../../components/lang-switch';
import { useSnackbar } from 'notistack';

function dateFormater(value) {
	const date = new Date(value);
	const formattedDate = moment(
		new Date(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0)
	)
		.format('YYYY-MM-DD')
		.toString();

	return formattedDate;
}

function getAge(dateString) {
	var today = new Date();
	var birthDate = new Date(dateString);
	var age = today.getFullYear() - birthDate.getFullYear();
	var m = today.getMonth() - birthDate.getMonth();
	if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
		age--;
	}
	return age;
}

const Register = (props) => {
	const translations = useSelector((state) => state.translations);
	const [labelKey, setLabelKey] = useState('');
	const [url, setUrl] = useState('');
	const [fullAddress, setFullAddress] = useState('');
	const [loading, setLoading] = useState(false);
	const [info, setInfo] = useState({
		email: '',
		name: '',
		opening_hours: {},
		phone_number: '',
	});

	const history = useHistory();
	const dispatch = useDispatch();
	const { enqueueSnackbar } = useSnackbar();

	const registerFormSchema = Yup.object().shape({
		preferred_name: Yup.string()
			.matches(
				/^(?![0-9])(?!.*[0-9]$)(?!.*\d_)(?!.*_\d)[a-zA-Z0-9]+$/,
				'No special characters, spaces, or numbers allowed.'
			)
			.required(translations.general.error.required),
		last_name: Yup.string()
			.matches(
				/^(?![0-9])(?!.*[0-9]$)(?!.*\d_)(?!.*_\d)[a-zA-Z0-9]+$/,
				'No special characters, spaces, or numbers allowed.'
			)
			.required(translations.general.error.required),
		label_key: Yup.string(),
		gender: Yup.string().required(translations.general.error.required),
		initials: Yup.string().required(translations.general.error.required),
		prefix: Yup.string(),
		birth_date: Yup.string()
			.required(translations.general.error.required)
			.test(
				'is-valid',
				(_) => translations.register.ageValidation,
				(value) =>
					value
						? getAge(value.toString()) >= 18
						: new Yup.ValidationError(translations.register.ageValidation)
			),
		phone_number: Yup.string()
			.phone('NL')
			.required(translations.general.error.required),
		email: Yup.string()
			.email(translations.general.error.invalidEmail)
			.required(translations.general.error.required)
			.test(
				'is-valid',
				(message) => `${message.path} is invalid`,
				(value) =>
					value
						? isEmailValidator(value)
						: new Yup.ValidationError(translations.general.error.invalidEmail)
			),
		//address
		house_number: Yup.string().required(translations.general.error.required),
		house_number_extension: Yup.string(),
		zip_code: Yup.string()
			.label('Postcode')
			.length(6, translations.general.error.invalidZipCode)
			.required(translations.general.error.required),
		bank_account_number: Yup.string()
			.trim()
			.test('bank_account_number', 'Ongeldige IBAN', (value) =>
				value ? ibantools.isValidIBAN(value) : true
			)
			.required(translations.general.error.required),
		bank_account_name: Yup.string().required(
			translations.general.error.required
		),
	});

	const showError = () => {
		enqueueSnackbar(
			translations.confirmReactivation.reactivationFailedMessage,
			{
				variant: 'error',
				anchorOrigin: {
					vertical: 'top',
					horizontal: 'center',
				},
			}
		);
	};

	const formik = useFormik({
		initialValues: {
			preferred_name: '',
			last_name: '',
			initials: '',
			prefix: '',
			birth_date: dateFormater(new Date().toString()),
			phone_number: '',
			email: '',
			gender: '',
			house_number: '',
			house_number_extension: '',
			zip_code: '',
			bank_account_number: '',
			bank_account_name: '',
			label_key: '',
		},
		validationSchema: registerFormSchema,
		onSubmit: async (values) => {
			try {
				values.label_key = labelKey;
				const { zip_code, house_number, house_number_extension } = values;
				setLoading(true);
				await api.relations.addresses.validateAddress(
					zip_code,
					house_number,
					house_number_extension
				);

				await api.account.request(values);
				history.push('/pending-page');
			} catch (error) {
				showError();
				if (error.response.status === 404) {
					formik.setErrors({
						zip_code: translations.general.error.invalidAddress,
						house_number: translations.general.error.invalidAddress,
						house_number_extension: values.house_number_extension
							? translations.general.error.invalidAddress
							: null,
					});
				}
			} finally {
				setLoading(false);
			}
		},
	});

	const formikValues = formik.values;
	const { setErrors } = formik;

	useEffect(() => {
		api.labels.id
			.get(getHostname())
			.then((data) => {
				setLabelKey(data.label_key);
				return Promise.all([
					api.labels.logo.get(data.label_key),
					api.labels.info.get(data.label_key),
				]);
			})
			.then(([logoData, infoData]) => {
				setUrl(logoData.url);
				setInfo(infoData);
			})
			.catch((err) => err);
	}, []);

	useEffect(() => {
		async function validateAddress() {
			try {
				const { house_number, house_number_extension, zip_code } = formikValues;
				if (house_number && zip_code.length > 5) {
					const { data } = await api.relations.addresses.validateAddress(
						zip_code,
						house_number,
						house_number_extension
					);
					const {
						street,
						houseNumber,
						houseNumberAddition,
						city,
						postcode,
					} = data;
					const validAddress = `${street} ${houseNumber} ${houseNumberAddition} ,${postcode} ${city}`;
					setFullAddress(validAddress);
				}
			} catch (error) {
				if (error.response.status === 404) {
					setErrors({
						zip_code: translations.general.error.invalidAddress,
						house_number: translations.general.error.invalidAddress,
						house_number_extension: formikValues.house_number_extension
							? translations.general.error.invalidAddress
							: null,
					});
				}
			}
		}
		validateAddress();
	}, [formikValues, setErrors, translations]);

	return (
		<React.StrictMode>
			<Container>
				<Stack direction="row" spacing={2} justifyContent="flex-end">
					<LangSwitch dispatch={dispatch} translations={translations} />
				</Stack>
				<FormikProvider className="highlight-bg-color" value={formik}>
					<Form disabled={loading}>
						<Grid container spacing={2} p={2}>
							<Grid item xs={12}>
								<div
									className="logo"
									onClick={() => this.setState({ showReactivate: false })}>
									{url && <img src={url} alt={info.name || 'Logo'} />}
								</div>
								<Typography
									justifySelf="left"
									gutterBottom
									variant="h5"
									component="div">
									{info.name} - {translations.register.title}
								</Typography>
								<Divider />
							</Grid>
							{labelKey === '1024' && (
								<Grid container xs={12} spacing={2} padding={1}>
									<Grid item xs={12} sm={12} md={6}>
										<TextFieldWrapper
											name="initials"
											label={translations.register.fields.initials}
											size="small"
											variant="outlined"
											required
										/>
										<TextFieldWrapper
											name="preferred_name"
											label={translations.register.fields.preferredName}
											size="small"
											variant="outlined"
											required
										/>
										<TextFieldWrapper
											name="last_name"
											label={translations.register.fields.lastName}
											size="small"
											variant="outlined"
											required
										/>
										<TextFieldWrapper
											name="prefix"
											label={translations.register.fields.prefix}
											size="small"
											variant="outlined"
										/>
										<FormControl
											name="birth_date"
											required
											fullWidth
											margin="normal">
											<FormLabel required>
												{translations.register.fields.birthDate}
											</FormLabel>
											<DatePickerMobile
												size="small"
												name="birth_date"
												onChange={(date) => {
													formik.setFieldValue(
														'birth_date',
														dateFormater(date.toString())
													);
												}}
												minDate={new Date(1900, 0)}
												maxDate={new Date()}
												locale={nl}
											/>
										</FormControl>
										<TextFieldWrapper
											name="phone_number"
											label={translations.register.fields.phoneNumber}
											size="small"
											variant="outlined"
											error={
												formik.touched.phone_number &&
												Boolean(formik.errors.phone_number)
											}
											helperText={
												formik.touched.phone_number &&
												formik.errors.phone_number
											}
											required
										/>
										<TextFieldWrapper
											name="email"
											label={translations.register.fields.email}
											size="small"
											variant="outlined"
											required
										/>
										<RadioGroupWrapper
											options={[
												{
													label: translations.register.fields.genders.male,
													value: 'MALE',
												},
												{
													label: translations.register.fields.genders.female,
													value: 'FEMALE',
												},
											]}
											name="gender"
											label={translations.register.fields.gender}
											size="small"
											variant="outlined"
											required
										/>
										<TextFieldWrapper
											name="bank_account_number"
											label={translations.register.fields.bankAccountNumber}
											size="small"
											variant="outlined"
											required
										/>
										<TextFieldWrapper
											name="bank_account_name"
											label={translations.register.fields.bankAccountName}
											size="small"
											variant="outlined"
											required
										/>
										<TextFieldWrapper
											name="house_number"
											label={translations.register.fields.houseNumber}
											size="small"
											variant="outlined"
											required
										/>
										<TextFieldWrapper
											name="house_number_extension"
											label={translations.register.fields.houseExtension}
											size="small"
											variant="outlined"
										/>
										<TextFieldWrapper
											name="zip_code"
											label={translations.register.fields.zipcode}
											size="small"
											variant="outlined"
											required
										/>
										{fullAddress && <span>{fullAddress}</span>}
									</Grid>
								</Grid>
							)}
							{labelKey && labelKey !== '1024' && (
								<Typography sx={{ p: 2 }} variant="h3">
									{translations.register.notAvailable}
								</Typography>
							)}
						</Grid>
						{labelKey === '1024' && (
							<Stack p={2}>
								<LoadingButton
									size="small"
									loading={loading}
									variant="outlined"
									sx={{ width: 150 }}
									type="submit">
									{translations.general.save}
								</LoadingButton>
							</Stack>
						)}
					</Form>
				</FormikProvider>
				<Stack
					direction="column"
					justifyContent="space-between"
					alignItems="center"
					spacing={2}>
					<span>{info.name}</span>
					<span>{info.email}</span>
					<span>{info.phone_number}</span>
				</Stack>
			</Container>
		</React.StrictMode>
	);
};

export default Register;
