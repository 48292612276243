import React from 'react';
import PropTypes from 'prop-types';

import Link from '../link';

import './panel-footer.scss';

/**
 * The `PanelFooter` component is used in the `Panel` component to render its
 * footer. It houses two links, one on the left, and one on the right
 */
const PanelFooter = ({
	leftLinkText,
	leftLinkUrl,
	leftButtonHandler,
	rightLinkText,
	rightLinkUrl,
	rightButtonHandler,
}) => {
	const isEmpty = !(leftLinkText || rightLinkText);
	const isEmptyClass = isEmpty ? ' panel-footer--is-empty' : '';
	const getLeftLink = () =>
		leftLinkText ? (
			<span className="panel-footer__link panel-footer__link--left">
				{leftButtonHandler ? (
					<button
						className="panel-footer__button primary-text-color"
						onClick={leftButtonHandler}>
						{leftLinkText}
					</button>
				) : (
					<Link to={leftLinkUrl} className="primary-text-color">
						{leftLinkText}
					</Link>
				)}
			</span>
		) : null;

	const getRightLink = () =>
		rightLinkText ? (
			<span className="panel-footer__link panel-footer__link--right">
				{rightButtonHandler ? (
					<button
						className="panel-footer__button primary-text-color"
						onClick={rightButtonHandler}>
						{rightLinkText}
					</button>
				) : (
					<Link to={rightLinkUrl} className="primary-text-color">
						{rightLinkText}
					</Link>
				)}
			</span>
		) : null;

	return (
		<footer className={`panel-footer${isEmptyClass}`}>
			{getLeftLink()}
			{getRightLink()}
		</footer>
	);
};

export default PanelFooter;

PanelFooter.propTypes = {
	leftLinkText: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
	leftLinkUrl: PropTypes.string,
	leftButtonHandler: PropTypes.func,
	rightLinkText: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
	rightLinkUrl: PropTypes.string,
	rightButtonHandler: PropTypes.func,
};
