import * as Sentry from '@sentry/browser';
import { Integrations } from '@sentry/tracing';

// const ENV_REGEX_TST = /tst[0-9]+\./;
// const ENV_REGEX_ACC = /acc[0-9]+\./;
// const ENV_REGEX_STG = /acc[0-9]+\./;
const ENV_REGEX_DEV = /localhost*/;

function getEnvironment() {
	if (window.location.host.includes('.tst.aws')) {
		return 'tst';
	} else if (window.location.host.includes('.acc.aws')) {
		return 'acc';
	} else if (window.location.host.includes('.stg.aws')) {
		return 'stg';
	} else if (window.location.host.match(ENV_REGEX_DEV)) {
		return 'dev';
	}
	return 'prd';
}

const environment = getEnvironment();

if (environment === 'prd') {
	Sentry.init({
		dsn: 'https://ae74a24245494090821e4dbea37c763d@sentry.io/248367',
		environment: 'prd',
		integrations: [new Integrations.BrowserTracing()],
		tracesSampleRate: 1.0,
	});
}
