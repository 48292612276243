import connections from '../../api/connections';
import { createSelector } from 'reselect';

const setConnections = (state = connections.emptyResponse, action) => {
	switch (action.type) {
		case 'CONNECTIONS_DATA':
			return action.data;
		default:
			return state;
	}
};

export default setConnections;

const getGasConnection = createSelector(
	[state => state.data.connections],
	connections =>
		connections.filter(connection => connection.commodity === 'GAS')
);

const getElectricityConnection = createSelector(
	[state => state.data.connections],
	connections =>
		connections.filter(connection => connection.commodity === 'ELECTRICITY')
);

export const selector = {
	getGasConnection,
	getElectricityConnection,
};

export const action = {
	data: data => ({ type: 'CONNECTIONS_DATA', data }),
};
