const initialState = false;

const submitted = (state = initialState, action) => {
	switch (action.type) {
		case 'CONTACT_SUBMIT':
			return true;
		case 'CONTACT_RESET':
			return false;
		default:
			return state;
	}
};

export default submitted;

export const action = {
	submit: () => ({ type: 'CONTACT_SUBMIT' }),
	reset: () => ({ type: 'CONTACT_RESET' }),
};
