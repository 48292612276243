import React from 'react';
import PropTypes from 'prop-types';

import Icon from '../icon';
import IconAccount from '../icons/account-box';
import './account-username.css';

/**
 * This component renders an account username
 */
const AccountUsername = ({ children, username, iconSize }) => (
	<div className="accountUsername">
		<div>
			<Icon icon={IconAccount} size={iconSize || 'inline'} />
			<span>{username}</span>
		</div>{' '}
		{children}
	</div>
);

export default AccountUsername;

AccountUsername.propTypes = {
	username: PropTypes.string.isRequired,
	children: PropTypes.any,
};
