import dateIsoFormat from './date-iso-format';
import moment from 'moment';
const today = () => {
	const now = new Date();
	return moment(
		`${now.getFullYear()}-${now.getMonth() + 1}-${now.getDate()}`,
		'YYYY-MM-DD'
	).valueOf();
};

const addDaysFromToday = (numberOfDays) => {
	return moment(today())
		.add(numberOfDays, 'd')
		.valueOf();
};

export const validateStep1 = (values) => {
	const errors = {};

	if (!values.old_address) {
		errors.old_address = 'required';
	}

	if (!values.new_address || !values.new_address.house_number) {
		errors.new_address = Object.assign({}, errors.new_address, {
			house_number: 'required',
		});
	}

	if (!values.new_address || !values.new_address.zip_code) {
		errors.new_address = Object.assign({}, errors.new_address, {
			zip_code: 'required',
		});
	}

	return errors;
};

export const validateStep2 = (values) => {
	const errors = {};

	if (
		values.stop_date &&
		isNaN(new Date(dateIsoFormat(values.stop_date)).getTime())
	) {
		errors.stop_date = 'dateFormat';
	}

	if (
		values.stop_date &&
		new Date(dateIsoFormat(values.stop_date)).getTime() < today()
	) {
		errors.stop_date = 'noPastDate';
	}

	if (!values.start_date) {
		errors.start_date = 'required';
	}

	if (
		values.start_date &&
		isNaN(new Date(dateIsoFormat(values.start_date)).getTime())
	) {
		errors.start_date = 'dateFormat';
	}

	if (
		values.start_date &&
		new Date(dateIsoFormat(values.start_date)).getTime() < addDaysFromToday(2)
	) {
		errors.start_date = 'minTwoDays';
	}

	if (
		new Date(dateIsoFormat(values.start_date)).getTime() >
		new Date(dateIsoFormat(values.stop_date)).getTime()
	) {
		errors.stop_date = 'startBeforeStop';
	}

	if (
		values.start_date &&
		new Date(dateIsoFormat(values.start_date)).getTime() < today()
	) {
		errors.start_date = 'noPastDate';
	}

	return errors;
};
