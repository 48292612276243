import React, { Fragment } from 'react';
import './invoices.css';
import Icon from '../../icon';
import Invoice from '../../icons/description-outlined';
import Spinner from '../../../components/spinner';
import { withRouter } from 'react-router-dom';
import Button from '../../button';
import Currency from '../../currency';

const invoices = ({ latestInvoices, translations }) => {
	if (!latestInvoices.length) {
		return <Spinner />;
	}
	return (
		<Fragment>
			{latestInvoices &&
				latestInvoices.map(({ invoice_type, amount, payment_status }) => {
					return (
						<div className="latest-invoices" key={amount}>
							<Icon icon={Invoice} size="small" />
							<p>{translations.invoiceType[invoice_type]}</p>
							<span>
								<Currency amount={amount} />
							</span>
							<p>{translations.invoiceBalance[payment_status]}</p>
						</div>
					);
				})}
			<AllInvocesButton>
				{translations.latestInvoices.buttonText}
			</AllInvocesButton>
		</Fragment>
	);
};

const AllInvocesButton = withRouter(({ history, children }) => (
	<Button
		className="all-invoices-button"
		kind="transparent"
		onClick={() => {
			history.push('/invoices');
		}}>
		<Icon icon={Invoice} size="small" />
		{children}
	</Button>
));

export default invoices;
