import labels from '../../api/labels';

export default (state = labels.info.emptyResponse, action) => {
	switch (action.type) {
		case 'LABELS_INFO_DATA':
			return action.data;
		default:
			return state;
	}
};

export const action = {
	data: data => ({ type: 'LABELS_INFO_DATA', data }),
};
