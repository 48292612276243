import React from 'react';
import PropTypes from 'prop-types';

import './icon.css';

/**
 * The `Icon` component is used to display icons in a uniform way. It acceps an
 * `icon` and a `size` prop.
 *
 * **Never use the icon sources directly!**
 */
const Icon = ({ color, icon, size, className = '' }) => {
	const ProvidedIcon = icon;
	const iconName = ProvidedIcon.displayName
		.replace('Icon', 'Icon--')
		.toLowerCase();
	const classSize = `icon--${size}`;
	const iconSize = size === 'inline' ? 'small' : size;

	return (
		<span className={`icon ${iconName} ${classSize} ${className}`.trim()}>
			<ProvidedIcon color={color} icon={icon} size={iconSize} />
		</span>
	);
};

export default Icon;

Icon.propTypes = {
	className: PropTypes.string,
	color: PropTypes.string,
	/** The Icon to display */
	icon: PropTypes.func,
	/** Small: 24px, Large: 48px, Inline: 1em */
	size: PropTypes.oneOfType([
		PropTypes.oneOf(['small', 'large', 'inline']),
		PropTypes.number,
	]).isRequired,
};
