import 'autotrack/lib/plugins/url-change-tracker';

function configureGoogleAnalytics(label) {
	const ga =
		window.ga ||
		function() {
			(ga.q = ga.q || []).push(arguments);
		};
	ga.l = +new Date();
	// track all traffic for each label and submit to ServiceHouse tracking id
	ga('create', 'UA-133022121-2', 'auto');
	ga('require', 'urlChangeTracker');
	ga('set', 'anonymizeIp', true);
	ga('send', 'pageview', window.location.pathname);

	if (label.ga_tracking_id) {
		ga('create', label.ga_tracking_id, 'auto', 'portalTracker');
		ga('portalTracker.require', 'urlChangeTracker');
		ga('portalTracker.set', 'anonymizeIp', true);
		ga('portalTracker.send', 'pageview', window.location.pathname);
	}
}

export default configureGoogleAnalytics;
