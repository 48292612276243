import React from 'react';
import PropTypes from 'prop-types';

import './notifications.css';

const Notifications = props => (
	<div className="row notifications">
		{props.notifications.map(notification => {
			return (
				<div
					className={`panel ${notification[1] ? notification[1] : ''}`}
					key={notification[0]}>
					<ul>
						<li>{notification[0]}</li>
					</ul>
				</div>
			);
		})}
	</div>
);

export default Notifications;

Notifications.propTypes = {
	notifications: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.string)),
};
