const initialState = null;

const requestPeriodicalPayment = (state = initialState, action) => {
	switch (action.type) {
		case 'REQUEST_PERIODICAL_PAYMENT':
			return action.value !== null ? parseFloat(action.value) : null;
		case 'REQUEST_PERIODICAL_PAYMENT_ON_BLUR':
			return action.value !== null ? parseFloat(action.value).toFixed(2) : null;
		default:
			return state;
	}
};

export default requestPeriodicalPayment;

export const action = {
	change: value => ({ type: 'REQUEST_PERIODICAL_PAYMENT', value }),
	blur: value => ({ type: 'REQUEST_PERIODICAL_PAYMENT_ON_BLUR', value }),
};
