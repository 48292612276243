import React from 'react';
import Icon from '../icon';
import ArrowLeft from '../icons/keyboard-arrow-left';
import ArrowRight from '../icons/keyboard-arrow-right';
import PropTypes from 'prop-types';

import './currency-stepper.css';

const CurrencyStepper = ({
	amount,
	className = '',
	step = 1,
	min = 0,
	max = Infinity,
	onChange = () => {},
}) => {
	const onClickLess = () =>
		onChange(parseFloat(Math.max(amount - step, min)).toFixed(2));
	const onClickMore = () =>
		onChange(parseFloat(Math.min(amount + step, max)).toFixed(2));
	const constraintAmount = Math.min(Math.max(amount, min), max);

	return (
		<div className={'currency-stepper'}>
			<button
				disabled={constraintAmount <= min}
				className="currency-stepper__button"
				onClick={onClickLess}>
				<Icon icon={ArrowLeft} size="small" />
			</button>
			<button
				disabled={constraintAmount >= max}
				className="currency-stepper__button"
				onClick={onClickMore}>
				<Icon icon={ArrowRight} size="small" />
			</button>
		</div>
	);
};

export default CurrencyStepper;

CurrencyStepper.propTypes = {
	amount: PropTypes.number.isRequired,
	step: PropTypes.number,
	min: PropTypes.number,
	max: PropTypes.number,
	onChange: PropTypes.func,
};
