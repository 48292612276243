import closeableOverlay from '../components/closeable-overlay';
import { connect, useDispatch } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {
	FormControl,
	TextField,
	Button,
	Stack,
	Typography,
	FormGroup,
} from '@mui/material';
import api from '../api';

import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';

import { useFormik } from 'formik';
import { action as notificationsAction } from '../reducers/notifications';

import * as yup from 'yup';

const redeemFormSchema = yup.object({
	code: yup
		.string()
		.min(11, 'Ongeldige code')
		.max(11, 'Ongeldige code')
		.required('Ongeldige code'),
});

function RedeemOffer({ translations, onClose }) {
	const dispatch = useDispatch();

	const formik = useFormik({
		initialValues: {
			code: '',
		},
		validationSchema: redeemFormSchema,
		onSubmit: (values) => {
			formik.validateForm();
			api.sustainable.sustainableOffers
				.activate(values.code)
				.then(() => {
					notificationsAction.add(dispatch, 'Success');
				})
				.catch((err) => {
					console.log(err);
					notificationsAction.add(dispatch, 'Ongeldige code');
				})
				.finally(() => {
					onClose();
				});
		},
	});

	const handleCancel = () => {
		onClose();
	};

	return (
		<form onSubmit={formik.handleSubmit}>
			<Card>
				<CardContent>
					<Typography gutterBottom variant="h5" component="div">
						{translations.redeemOffer}
					</Typography>
					<FormGroup row>
						<FormControl fullWidth margin="normal" required>
							<TextField
								id="code"
								name="code"
								label="Code"
								size="small"
								variant="outlined"
								required
								value={formik.values.code}
								onChange={formik.handleChange}
								error={formik.touched.code && Boolean(formik.errors.code)}
								helperText={formik.touched.code && formik.errors.code}
							/>
							{formik.errors.code ? (
								<Typography
									sx={{ color: 'red' }}
									variant="caption"
									display="block"
									gutterBottom>
									{formik.errors.code}
								</Typography>
							) : null}
						</FormControl>
					</FormGroup>
				</CardContent>
				<CardActions>
					<Stack direction="row-reverse" spacing={1}>
						<Button variant="contained" type="submit" size="small">
							{translations.get}
						</Button>
						<Button variant="contained" onClick={handleCancel} size="small">
							{translations.cancel}
						</Button>
					</Stack>
				</CardActions>
			</Card>
		</form>
	);
}

function mapStateToProps(state) {
	return {
		translations: Object.assign(
			state.translations.marketPlace,
			state.translations.general
		),
	};
}

export default withRouter(
	connect(mapStateToProps)(closeableOverlay(RedeemOffer))
);
