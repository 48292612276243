import React from 'react';
import PropTypes from 'prop-types';

import './header.css';

import Icon from '../icon';
import Button from '../button';
import Menu from '../icons/menu';
import Link from '../link';
import AccountNavigation from '../../components/account-navigation';
import LangSwitch from '../lang-switch';
import MarketplaceCart from '../../views/marketplace/components/cart';

/**
 * The `Header` component displays a fixed bar on the top of the page. It is
 * used to house the logo, menu, translation, log-out, and search icons
 */
const Header = ({
	nonSticky,
	className,
	menuClick,
	logo,
	labelName,
	translations,
	menuItems,
	dispatch,
}) => {
	/** nonSticky should only be used in documentation */
	const nonStickyClass = nonSticky ? ' header--non-sticky' : '';

	return (
		<header className={`header${nonStickyClass} ${className || ''}`}>
			<Button
				className="header__button header__button--menu"
				onClick={menuClick}
				title="Menu"
				transparent={true}>
				<Icon icon={Menu} size={'small'} className="primary-icon" />
			</Button>

			{logo && (
				<Link to={'/'}>
					<img src={logo} alt={labelName || 'Logo'} className="header__logo" />
				</Link>
			)}

			<div className="right-menu">
				<MarketplaceCart translations={translations} dispatch={dispatch} />
				<LangSwitch dispatch={dispatch} translations={translations} />
				<AccountNavigation
					dispatch={dispatch}
					children={menuItems}
					translations={translations}
				/>
			</div>
		</header>
	);
};

export default Header;

Header.propTypes = {
	/** @ignore */
	nonSticky: PropTypes.bool,
	className: PropTypes.string,
	menuClick: PropTypes.func,
	logo: PropTypes.string,
	labelName: PropTypes.string,
};
