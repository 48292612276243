import React from 'react';
import PropTypes from 'prop-types';
import kebabCase from 'lodash/kebabCase';

import Icon from '../icon';
import IconArrowDropDown from '../icons/arrow-drop-down';

import './select.css';

const Select = ({
	options,
	disabled,
	required,
	validity,
	value,
	onChange = () => {},
	...props
}) => {
	const disabledClass = disabled ? 'select--disabled' : '';
	const requiredClass = required ? 'select--required' : '';
	const validityClass = validity ? `select--${validity}` : '';
	return (
		<div
			className={`select ${disabledClass} ${requiredClass} ${
				validityClass
			}`.trim()}>
			<select
				{...props}
				disabled={disabled}
				value={value}
				required={required}
				onChange={event => onChange(event.target.value)}>
				<option disabled defaultValue />
				{options.map((option, index) => (
					<option value={option.value || kebabCase(option.label)} key={index}>
						{option.label}
					</option>
				))}
			</select>
			<Icon icon={IconArrowDropDown} size="small" />
		</div>
	);
};

export default Select;

Select.propTypes = {
	options: PropTypes.arrayOf(
		PropTypes.shape({
			label: PropTypes.string.isRequired,
			value: PropTypes.string,
		})
	).isRequired,
	disabled: PropTypes.bool,
	value: PropTypes.string,
	validity: PropTypes.string,
};
