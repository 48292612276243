import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
// import PropTypes from 'prop-types';

import './link.css';

/**
 * Link to another page
 */
const Link = props => {
	const disabledClass = !props.to ? 'link--disabled' : '';
	return (
		<span className={`link ${disabledClass}`}>
			{props.to ? (
				<RouterLink {...props}>{props.children}</RouterLink>
			) : (
				props.children
			)}
		</span>
	);
};

export default Link;

Link.propTypes = {};
