import React from 'react';

import './card-header.css';

const CardHeader = ({ children }) => (
	<h3 className="card-header">{children}</h3>
);

export default CardHeader;

CardHeader.propTypes = {};
