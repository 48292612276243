const initialState = {
	isShown: false,
};

const toggleMenu = (state = initialState, action) => {
	switch (action.type) {
		case 'MENU_OPEN':
			return Object.assign({}, state, { isShown: true });
		case 'MENU_CLOSE':
			return Object.assign({}, state, { isShown: false });
		case 'MENU_TOGGLE':
			return Object.assign({}, state, { isShown: !state.isShown });
		default:
			return state;
	}
};

export default toggleMenu;

export const action = {
	close: () => ({ type: 'MENU_CLOSE' }),
	open: () => ({ type: 'MENU_OPEN' }),
	toggle: () => ({ type: 'MENU_TOGGLE' }),
};
