import React, { Component } from 'react';

import Panel from '../../components/panel';
import Row from '../../components/row';
import ContactInfo from '../../components/contact-info';
import ErrorText from '../../components/error-text';
import OpeningHours from '../../components/opening-hours';
import Email from '../../components/email';
import Phonenumber from '../../components/phonenumber';
import Invoice from '../../components/invoice';
import SectionTitle from '../../components/section-title';
import Icon from '../../components/icon';
import LightbulbOutline from '../../components/icons/lightbulb-outline';
import Flame from '../../components/icons/flame';
import MeterMeasurementsGas from '../../components/meter-measurements-gas';
import MeterMeasurementsElectricity from '../../components/meter-measurements-electricity';

import urlJoin from '../../utils/url-join';

import './home.css';
import { connect } from 'react-redux';
import { selector as connectionSelector } from '../../reducers/data/connections';

class Home extends Component {
	createInvoice = ({
		amount,
		invoice_type,
		invoice_link,
		balance,
		issue_date,
		due_date,
		period_start_date,
		period_end_date,
	}) => {
		const translations = this.props.translations;

		return (
			<Invoice
				amount={amount}
				downloadLink={urlJoin(invoice_link, this.props.downloadLinkBaseHref)}
				downloadLinkText={translations.invoice}
				type={invoice_type}
				typeText={translations.invoiceType[invoice_type]}
				dueDate={due_date}
				issueDate={issue_date}
				periodStartDate={period_start_date}
				periodEndDate={period_end_date}
				balanceText={translations.invoiceBalance[balance]}
				balance={balance}
			/>
		);
	};

	render() {
		const {
			relations,
			relationsPhoneNumber = {},
			relationsEmailAddress = {},
			labels,
			// invoices,
			translations,
			usage,
			electricityConnection,
			gasConnection,
		} = this.props;
		const changedPhoneNumber = relationsPhoneNumber.phone_number;
		const changedEmailAddress = relationsEmailAddress.change_email;
		// let invoicesOrdered = invoices.slice();
		// const invoiceMar = invoicesOrdered.find(
		// 	invoice => invoice.invoice_type === 'MAR'
		// );

		// if (invoiceMar) {
		// 	invoicesOrdered.splice(
		// 		invoicesOrdered.indexOf(invoiceMar),
		// 		invoicesOrdered.length - 1
		// 	);
		// 	invoicesOrdered.splice(3, 0, invoiceMar);
		// }

		return (
			<div className="view-home">
				<Row>
					<Panel
						name="contact-info"
						title={translations.contactInfo.title}
						rightLinkText={translations.contactInfo.rightLinkText}
						rightLinkUrl={'/account'}>
						{relations && <ContactInfo relations={relations} />}

						{changedPhoneNumber && (
							<p>
								{translations.contactInfo.changedPhoneNumber}:{' '}
								<strong>{changedPhoneNumber}</strong>
							</p>
						)}

						{changedEmailAddress && (
							<p>
								{translations.contactInfo.changedEmail}:{' '}
								<strong>{changedEmailAddress}</strong>
							</p>
						)}

						{!relations && (
							<ErrorText>
								{translations.error.introduction} {translations.error.noData}
							</ErrorText>
						)}
					</Panel>
				</Row>
				{labels && labels.id && labels.id.label_key !== '1060' && (
					<div>
						<Row>
							<Panel
								name="invoices"
								title={translations.invoices.title}
								rightLinkText={translations.invoices.rightLinkText}
								rightLinkUrl="/invoices">
								{/* <List
										items={invoicesOrdered.slice(0, 4)}
										emptyText={translations.invoices.noInvoices}>
										{this.createInvoice}
									</List> */}
							</Panel>
						</Row>
						<Row highlight wrapReverse>
							{/* <Panel
									transparent
									name="periodical-payment"
									title={translations.periodicalPayment.title}
									rightLinkText={translations.periodicalPayment.rightLinkText}
									rightLinkUrl={currentAgreement && '/monthly-amount'}>
									{currentAgreement && (
										<Currency
											className="view-home__periodical-payment-amount"
											amount={
												currentAgreement.periodical_payment.details
													.current_amount
											}
										/>
									)}
									{!currentAgreement && (
										<ErrorText>
											{translations.error.introduction}{' '}
											{translations.error.noData}
										</ErrorText>
									)}
									{agreementsPeriodical &&
										agreementsPeriodical.request_periodical_payment_amount &&
										agreementsPeriodical.request_periodical_payment_amount !==
											currentAgreement.periodical_payment.details
												.current_amount && (
											<small className="view-home__periodical-payment-amount-change-text">
												({translations.periodicalPayment.changeRequested}
												{': '}
												<Currency
													amount={
														agreementsPeriodical.request_periodical_payment_amount
													}
												/>)
											</small>
										)}
								</Panel> */}
						</Row>
						<Row>
							<Panel
								name="usage"
								title={translations.measurements}
								rightLinkText={translations.metermeasurements.rightLinkText}
								rightLinkUrl="/usage">
								<section>
									{electricityConnection.length && (
										<SectionTitle>
											<Icon icon={LightbulbOutline} size="inline" />
											{translations.electricity}
										</SectionTitle>
									)}

									{electricityConnection.length &&
									usage['ELECTRICITY'][electricityConnection.ean_code] ? (
										<MeterMeasurementsElectricity
											translations={translations}
											measurements={
												usage['ELECTRICITY'][electricityConnection.ean_code]
											}
											itemsLimit={1}
										/>
									) : (
										translations.noData
									)}
								</section>
								<section>
									{gasConnection.length && (
										<SectionTitle>
											<Icon icon={Flame} size="inline" />
											{translations.gas}
										</SectionTitle>
									)}

									{gasConnection.length &&
									usage['GAS'][gasConnection.ean_code] ? (
										<MeterMeasurementsGas
											translations={translations}
											measurements={usage['GAS'][gasConnection.ean_code]}
											itemsLimit={1}
										/>
									) : (
										translations.noData
									)}
								</section>
							</Panel>
						</Row>
						<Row highlight wrapReverse>
							<Panel
								transparent
								name="move"
								title={translations.move.title}
								rightLinkText={translations.move.rightLinkText}
								rightLinkUrl={'/move'}>
								<p>{translations.move.content}</p>
							</Panel>
						</Row>
					</div>
				)}
				<Row>
					<Panel
						title={translations.contact.title}
						name="opening-hours"
						rightLinkText={translations.contact.rightLinkText}
						rightLinkUrl="contact">
						{labels && labels.info && labels.info.email && (
							<Email>
								<a
									className="primary-text-color"
									href={`mailto:${labels.info.email}`}>
									{labels.info.email}
								</a>
							</Email>
						)}
						<br />
						{labels && labels.info && labels.info.phone_number && (
							<Phonenumber
								phone_number={labels.info.phone_number}
								isLink={true}
							/>
						)}
						{labels && labels.info && labels.info.opening_hours && (
							<OpeningHours
								translations={translations}
								opening_hours={labels.info.opening_hours}
							/>
						)}
					</Panel>
				</Row>
			</div>
		);
	}
}

function mapStateToProps(state) {
	return {
		agreementsPeriodical: state.data.agreementsPeriodical,
		relations: state.data.relations,
		relationsPhoneNumber: state.data.relationsPhoneNumber,
		relationsEmailAddress: state.data.relationsEmailAddress,
		connections: state.data.connections,
		labels: state.data.labels,
		downloadLinkBaseHref: process.env.REACT_APP_API,
		electricityConnection: connectionSelector.getElectricityConnection(state),
		gasConnection: connectionSelector.getGasConnection(state),
		translations: Object.assign(
			state.translations.home,
			state.translations.general,
			state.translations.usage,
			state.translations.error
		),
	};
}

export default connect(mapStateToProps)(Home);
