import { Paper, Skeleton, Typography } from '@mui/material';
import LineChart from './line-chart';

const chartContainer = (props) => {
	const {
		data,
		loading,
		onNext,
		onPrev,
		type,
		title,
		translations,
		dataKey,
		prevDisabled,
		nextDisabled,
	} = props;

	return (
		<>
			{loading && <Skeleton variant="rectangular" width={492} height={364} />}
			{!loading && (
				<Paper
					elevation={2}
					sx={{
						p: 1,
						width: '100%',
					}}>
					<Typography
						variant="h4"
						sx={{
							m: 1,
						}}>
						{title}
					</Typography>
					<LineChart
						data={data}
						type={type}
						onNext={onNext}
						onPrev={onPrev}
						translations={translations}
						dataKey={dataKey}
						prevDisabled={prevDisabled}
						nextDisabled={nextDisabled}
					/>
				</Paper>
			)}
		</>
	);
};

export default chartContainer;
