import React, { Component } from 'react';
import PropTypes from 'prop-types';

export default class IconClouds extends Component {
	static displayName = 'IconCode';

	getLarge() {
		return (
			<svg
				version="1.1"
				xmlns="http://www.w3.org/2000/svg"
				width="32"
				height="32"
				viewBox="0 0 32 32">
				<path d="M26.24 12.992c0-0.096 0-0.224 0-0.352 0-3.264-2.656-5.92-5.92-5.92-2.24 0-4.288 1.216-5.312 3.264-1.728 0.224-3.264 1.184-4.224 2.624-0.256-0.064-0.512-0.096-0.8-0.096-3.008 0-5.44 2.432-5.44 5.44 0 0 0 0 0 0s0 0 0 0.032c0 3.008 2.432 5.44 5.44 5.44h9.44c1.92 0 3.552-1.408 3.872-3.232h0.576c0.032 0 0.032 0 0.064 0s0.032 0 0.064 0c2.176 0 3.968-1.792 3.968-4 0-1.248-0.672-2.464-1.728-3.2zM19.456 21.632h-9.472c-1.952 0-3.552-1.568-3.616-3.52 0-0.032 0.032-0.096 0.032-0.128 0-0.992 0.384-1.888 1.024-2.528s1.536-1.056 2.56-1.056c0.192 0 0.384 0 0.576 0.032 0.544 0.128 1.088 0.448 1.472 0.736 0.416 0.256 0.96 0.16 1.248-0.256 0.256-0.416 0.16-0.96-0.256-1.248-0.16-0.096-0.32-0.224-0.512-0.32 0.768-0.992 1.952-1.6 3.264-1.6 2.272 0 4.128 1.856 4.128 4.128 0 0.224-0.032 0.448-0.064 0.704-0.064 0.384 0.128 0.8 0.512 0.96 0.768 0.352 1.248 1.12 1.248 1.952 0 1.184-0.96 2.144-2.144 2.144zM24 18.432c-0.032 0-0.032 0-0.064 0s-0.032 0-0.064 0h-0.672c-0.256-0.864-0.768-1.664-1.504-2.176 0-0.096 0-0.224 0-0.352 0-2.848-1.984-5.184-4.608-5.792 0.768-0.96 1.952-1.568 3.232-1.568 2.272 0 4.128 1.856 4.128 4.128 0 0.224-0.032 0.448-0.064 0.704-0.064 0.384 0.128 0.8 0.512 0.96 0.768 0.352 1.248 1.12 1.248 1.952 0 1.184-0.96 2.144-2.144 2.144z" />
			</svg>
		);
	}

	getSmall() {
		return (
			<svg
				version="1.1"
				xmlns="http://www.w3.org/2000/svg"
				width="32"
				height="32"
				viewBox="0 0 32 32">
				<path d="M26.24 12.992c0-0.096 0-0.224 0-0.352 0-3.264-2.656-5.92-5.92-5.92-2.24 0-4.288 1.216-5.312 3.264-1.728 0.224-3.264 1.184-4.224 2.624-0.256-0.064-0.512-0.096-0.8-0.096-3.008 0-5.44 2.432-5.44 5.44 0 0 0 0 0 0s0 0 0 0.032c0 3.008 2.432 5.44 5.44 5.44h9.44c1.92 0 3.552-1.408 3.872-3.232h0.576c0.032 0 0.032 0 0.064 0s0.032 0 0.064 0c2.176 0 3.968-1.792 3.968-4 0-1.248-0.672-2.464-1.728-3.2zM19.456 21.632h-9.472c-1.952 0-3.552-1.568-3.616-3.52 0-0.032 0.032-0.096 0.032-0.128 0-0.992 0.384-1.888 1.024-2.528s1.536-1.056 2.56-1.056c0.192 0 0.384 0 0.576 0.032 0.544 0.128 1.088 0.448 1.472 0.736 0.416 0.256 0.96 0.16 1.248-0.256 0.256-0.416 0.16-0.96-0.256-1.248-0.16-0.096-0.32-0.224-0.512-0.32 0.768-0.992 1.952-1.6 3.264-1.6 2.272 0 4.128 1.856 4.128 4.128 0 0.224-0.032 0.448-0.064 0.704-0.064 0.384 0.128 0.8 0.512 0.96 0.768 0.352 1.248 1.12 1.248 1.952 0 1.184-0.96 2.144-2.144 2.144zM24 18.432c-0.032 0-0.032 0-0.064 0s-0.032 0-0.064 0h-0.672c-0.256-0.864-0.768-1.664-1.504-2.176 0-0.096 0-0.224 0-0.352 0-2.848-1.984-5.184-4.608-5.792 0.768-0.96 1.952-1.568 3.232-1.568 2.272 0 4.128 1.856 4.128 4.128 0 0.224-0.032 0.448-0.064 0.704-0.064 0.384 0.128 0.8 0.512 0.96 0.768 0.352 1.248 1.12 1.248 1.952 0 1.184-0.96 2.144-2.144 2.144z" />
			</svg>
		);
	}

	getIcon() {
		let svg;
		svg = this.props.size === 'large' ? this.getLarge() : svg;
		svg = this.props.size === 'small' ? this.getSmall() : svg;
		return svg;
	}

	render() {
		return this.getIcon();
	}
}

IconClouds.propTypes = {
	size: PropTypes.oneOf(['small', 'large']).isRequired,
};
