export function createReducer(namespace) {
	const CLOSE_ACTION = `${namespace}/CLOSE_COLLAPSIBLE`;
	const OPEN_ACTION = `${namespace}/OPEN_COLLAPSIBLE`;
	const TOGGLE_ACTION = `${namespace}/TOGGLE_COLLAPSIBLE`;
	const CLOSE_ALL_ACTION = `${namespace}/CLOSE_ALL`;

	const toggleCollapsible = (state, action) => {
		if (state.includes(action.id)) {
			return expandCollapsibles(state, {
				id: action.id,
				type: CLOSE_ACTION,
			});
		} else {
			return expandCollapsibles(state, {
				id: action.id,
				type: OPEN_ACTION,
			});
		}
	};

	function expandCollapsibles(state = [], action) {
		switch (action.type) {
			case OPEN_ACTION:
				return [...state, action.id];
			case CLOSE_ACTION:
				return state.filter(item => item !== action.id);
			case TOGGLE_ACTION:
				return toggleCollapsible(state, action);
			case CLOSE_ALL_ACTION:
				return [];
			default:
				return state;
		}
	}

	return expandCollapsibles;
}

export const createActions = namespace => ({
	open: id => ({ type: `${namespace}/OPEN_COLLAPSIBLE`, id }),
	closeAll: () => ({ type: `${namespace}/CLOSE_ALL` }),
	close: id => ({ type: `${namespace}/CLOSE_COLLAPSIBLE`, id }),
	toggle: id => ({ type: `${namespace}/TOGGLE_COLLAPSIBLE`, id }),
});
