import React from 'react';
import PropTypes from 'prop-types';

import './slider.css';

const valuePercentage = (min, max, value) => (value - min) / (max - min) * 100;

/**
 * Slider displays an generic range slider
 * This component uses internal state to add a focus ring when the hidden input
 * is focussed. This does not violate the idea of the UI being a representation
 * of state.
 */
class Slider extends React.Component {
	state = {
		isFocused: false,
	};

	render() {
		const { min, max, value, step, onChange = () => {} } = this.props;
		const isFocused = this.state.isFocused;
		return (
			<div
				className={`slider ${isFocused ? 'slider--is-focussed' : ''}`.trim()}>
				<div className="slider__track">
					<span
						className="slider__handle"
						style={{
							transform: `translateX(${valuePercentage(min, max, value)}%)`,
						}}>
						<span className="slider__handle-circle primary-bg-color primary-text-color" />
					</span>
				</div>
				<input
					type="range"
					className="slider__input"
					min={min}
					max={max}
					step={step}
					value={value}
					onFocus={() => this.setState({ isFocused: true })}
					onBlur={() => this.setState({ isFocused: false })}
					onChange={e => onChange(e.target.value)}
				/>
			</div>
		);
	}
}
export default Slider;

Slider.propTypes = {
	min: PropTypes.number,
	max: PropTypes.number,
	value: PropTypes.number,
	step: PropTypes.number,
	onChange: PropTypes.func,
};
