import React from 'react';
import './under-construction.css';
import { useSelector, useDispatch } from 'react-redux';
import LangSwitch from '../../components/lang-switch';
import Icon from '../../components/icon';
import Build from '../../components/icons/build';

const UnderConstruction = ({ logo, info }) => {
	const translations = useSelector((state) => state.translations);
	const dispatch = useDispatch();

	return (
		<div className="under-construction">
			<LangSwitch dispatch={dispatch} translations={translations} />
			<div className="logo">
				{logo && <img src={logo} alt={info.name || 'Logo'} />}
			</div>
			<div className="content">
				<div className="title">
					<Icon className="icon" icon={Build} size="large" />
					<h1>
						<span>{translations.underConstruction.website}</span>
						<br />
						{translations.underConstruction.mode}
					</h1>
				</div>
				<hr />
				<div className="message">{translations.underConstruction.message}</div>
			</div>
			<footer>
				<span>{info.name}</span>
				<span>{info.email}</span>
				<span>{info.phone_number}</span>
			</footer>
		</div>
	);
};

export default UnderConstruction;
