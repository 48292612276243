import React from 'react';
import PropTypes from 'prop-types';

import DateTime from '../../components/date-time';
import withTranslationsGeneral from '../../components/with-translations-general';

import './meter-measurements-electricity.css';

const removeZeroValues = (mm) =>
	!!(mm.consumption_low || mm.consumption_normal) ||
	!!(mm.feedin_low || mm.feedin_normal);

const MeterMeasurementsElectricity = ({
	measurements,
	translations,
	itemsLimit,
	toggleDate = () => {},
	hiddenDates = [],
}) => (
	<table className="meter-measurements-electricity">
		<thead className="meter-measurements-electricity__table-head">
			<tr>
				<th rowSpan="2">{translations.date}</th>
				<th colSpan="2">{translations.usage}</th>
				<th colSpan="2">{translations.production}</th>
			</tr>
			<tr>
				<th>{translations.standard}</th>
				<th>{translations.low}</th>
				<th>{translations.standard}</th>
				<th>{translations.low}</th>
			</tr>
		</thead>
		<tbody className="meter-measurements-electricity__table-body">
			{measurements
				.filter(removeZeroValues)
				.slice(0, itemsLimit || measurements.length)
				.map((measurement, i) => (
					<tr
						key={i}
						className={`meter-measurements-electricity__row ${
							hiddenDates.includes(measurement.measurement_date)
								? 'meter-measurements-electricity__row--hidden'
								: ''
						}`.trim()}
						onClick={() => toggleDate(measurement.measurement_date)}>
						<td className="meter-measurements-electricity__cell meter-measurements-electricity__date">
							<DateTime
								date={measurement.measurement_date}
								format="format_written"
							/>
						</td>
						<td
							className="meter-measurements-electricity__cell meter-measurements-electricity__usage meter-measurements-electricity__standard"
							data-usage={translations.usage}
							data-standard={translations.standard}>
							{measurement.consumption_normal} kWh
						</td>
						<td
							className="meter-measurements-electricity__cell meter-measurements-electricity__low"
							data-low={translations.low}>
							{measurement.consumption_low} kWh
						</td>
						<td
							className="meter-measurements-electricity__cell meter-measurements-electricity__production meter-measurements-electricity__standard"
							data-production={translations.production}
							data-standard={translations.standard}>
							{measurement.feedin_normal} kWh
						</td>
						<td
							className="meter-measurements-electricity__cell meter-measurements-electricity__low"
							data-low={translations.low}>
							{measurement.feedin_low} kWh
						</td>
					</tr>
				))}
		</tbody>
	</table>
);

export default withTranslationsGeneral(MeterMeasurementsElectricity);

MeterMeasurementsElectricity.propTypes = {
	translations: PropTypes.object.isRequired,
	measurements: PropTypes.array.isRequired,
	itemsLimit: PropTypes.number,
	meterType: PropTypes.string.isRequired,
};
