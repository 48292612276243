import React from 'react';
import { connect } from 'react-redux';
import Link from '../../../components/link';
import LightbulbOutline from '../../../components/icons/lightbulb-outline';
import Flame from '../../../components/icons/flame';
import Icon from '../../../components/icon';
import ReactTooltip from 'react-tooltip';
import RenderValue from '../../../components/render-value';
import Spinner from '../../../components/spinner';

import api from '../../../api';

import './usage-row.css';
import ErrorText from '../../../components/error-text';

class UsageRow extends React.Component {
	constructor(props) {
		super(props);
		const { connection } = this.props;
		const context = this;

		this.state = {
			readings: null,
		};

		if (connection) {
			api.usage
				.get(
					connection.agreement_identifier,
					connection.location_id,
					connection.ean_code
				)
				.then((response) => {
					context.setState({ readings: response });
				})
				.catch((err) => {
					context.setState({ readings: [] });
					console.log(err);
				});
		}
	}

	createToolTipId = (connection) =>
		`${connection.ean_code}-${connection.meter_number}`;

	getTooltip = (connection, id, translations) => (
		<ReactTooltip
			className="extra-tooltip"
			id={id}
			place="bottom"
			effect="solid"
			delayHide={100}>
			{this.createTooltipContent(connection, translations)}
		</ReactTooltip>
	);

	createTooltipContent = (connection, translations) => (
		<div className="connection-link">
			<span>
				<Link
					to={{
						pathname: `invoices`,
					}}>
					{connection.ean_code}
				</Link>
			</span>
			{connection.meter_type && (
				<span>{translations.meterTypes[connection.meter_type]}</span>
			)}
		</div>
	);

	getCommodityIcon = (commodity) => {
		return commodity === 'ELECTRICITY' ? (
			<Icon icon={LightbulbOutline} size="small" color="rgba(0, 0, 0, 0.75)" />
		) : (
			<Icon icon={Flame} size="small" color="rgba(0, 0, 0, 0.75)" />
		);
	};

	getReadings = (reading) => {
		const { delivery, connection } = this.props;
		return delivery[connection.agreement_identifier]
			? this.getReadingsValue(
					this.getLatestReadings(this.state.readings)[`feedin_${reading}`]
			  )
			: this.getReadingsValue(
					this.getLatestReadings(this.state.readings)[`consumption_${reading}`]
			  );
	};

	getLatestReadings(readings) {
		return readings.sort(
			(a, b) => new Date(b.measurement_date) - new Date(a.measurement_date)
		)[0];
	}

	getReadingsValue = (value) => {
		const { connection } = this.props;
		const commodityUnitOfMesure = this.getCommodityUnitOfMesure(
			connection.commodity
		);

		return value ? (
			value + ' ' + commodityUnitOfMesure
		) : (
			<RenderValue value="" />
		);
	};

	getCommodityUnitOfMesure = (commodity) => {
		const { translations } = this.props;
		return commodity === 'GAS' ? translations.unit.M3N : translations.unit.KWH;
	};

	render() {
		const { connection, translations, delivery } = this.props;

		if (!this.state.readings) {
			return (
				<tr>
					<td colSpan="5">
						<Spinner />
					</td>
				</tr>
			);
		}

		if (this.state.readings && this.state.readings.length === 0) {
			return (
				<tr>
					<td colSpan="5">
						<ErrorText>
							{translations.error.introduction} {translations.error.noData}
						</ErrorText>
					</td>
				</tr>
			);
		}

		return (
			<tr className="usage-row">
				<td>{this.getCommodityIcon(connection.commodity)}</td>
				<td className="tooltip-wrapper">
					<span>{connection.ean_code}</span>
					<div className="tooltip-wrapper">
						<div
							className="tooltip-link usage__ean-truncated"
							data-tip=""
							data-for={this.createToolTipId(connection)}>
							<span>
								<span>{connection.ean_code}</span>
							</span>
							{this.getTooltip(
								connection,
								this.createToolTipId(connection),
								translations
							)}
						</div>
					</div>
				</td>
				<td>{this.getReadings('normal')}</td>
				<td>{this.getReadings('low')}</td>
				<td>
					<Link
						to={`/usage/usage-history/${btoa(
							JSON.stringify(
								Object.assign({}, connection, { delivery: delivery })
							)
						)}`}>
						<span className="usage-row__history">
							{translations.usageTable.body.meterDetailsHistory}
						</span>
						<i className="material-icons">zoom_in</i>
					</Link>
				</td>
			</tr>
		);
	}
}

function mapStateToProps(state, ownProps) {
	return Object.assign({}, state, ownProps, {
		translations: Object.assign(state.translations.usage),
	});
}

export default connect(mapStateToProps)(UsageRow);

UsageRow.propTypes = {};
