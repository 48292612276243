import React, { Component } from 'react';
import PropTypes from 'prop-types';

export default class IconSyncProblem extends Component {
	static displayName = 'IconSyncProblem';

	getLarge() {
		return (
			<svg
				fill={this.props.color}
				xmlns="http://www.w3.org/2000/svg"
				width="48"
				height="48"
				viewBox="0 0 48 48">
				<path d="M6 24c0 4.42 1.82 8.4 4.71 11.29L6 40h12V28l-4.47 4.47C11.35 30.3 10 27.31 10 24c0-5.22 3.34-9.65 8-11.3V8.52C11.1 10.3 6 16.55 6 24zm16 10h4v-4h-4v4zM42 8H30v12l4.47-4.47C36.65 17.7 38 20.69 38 24c0 5.22-3.34 9.65-8 11.3v4.17C36.9 37.7 42 31.45 42 24c0-4.42-1.82-8.4-4.71-11.29L42 8zM22 26h4V14h-4v12z" />
			</svg>
		);
	}

	getSmall() {
		return (
			<svg
				fill={this.props.color}
				xmlns="http://www.w3.org/2000/svg"
				width="24"
				height="24"
				viewBox="0 0 24 24">
				<path d="M3 12c0 2.21.91 4.2 2.36 5.64L3 20h6v-6l-2.24 2.24C5.68 15.15 5 13.66 5 12c0-2.61 1.67-4.83 4-5.65V4.26C5.55 5.15 3 8.27 3 12zm8 5h2v-2h-2v2zM21 4h-6v6l2.24-2.24C18.32 8.85 19 10.34 19 12c0 2.61-1.67 4.83-4 5.65v2.09c3.45-.89 6-4.01 6-7.74 0-2.21-.91-4.2-2.36-5.64L21 4zm-10 9h2V7h-2v6z" />
			</svg>
		);
	}

	getIcon() {
		let svg;
		svg = this.props.size === 'large' ? this.getLarge() : svg;
		svg = this.props.size === 'small' ? this.getSmall() : svg;
		return svg;
	}

	render() {
		return this.getIcon();
	}
}

IconSyncProblem.propTypes = {
	size: PropTypes.oneOf(['small', 'large']).isRequired,
};
