export const getLanguage = () => {
	return localStorage.getItem('language') || getBrowserLocale();
};

export const getBrowserLocale = () => {
	return navigator.languages
		? shorten(navigator.languages[0])
		: navigator.language || navigator.userLanguage;
};

const shorten = (language) => {
	return language && language.includes('-') ? language.split('-')[0] : language;
};

export default getLanguage;
