import React from 'react';
import { reduxForm, formValueSelector } from 'redux-form';
import { connect, useSelector } from 'react-redux';
import Panel from '../../components/panel';
import './move-wizard-step-3.scss';
import { selector as userInfoSelector } from '../../reducers/data/user-info';
import { AddressForDisplay } from '../move-wizard/move-wizard';
import Icon from '../icon';
import Info from '../icons/info';
import Home from '../icons/home';
import CardReveal from '../card-reveal';

let MoveWizardStep3 = (props) => {
	const { handleSubmit, previousPage, translations, formValues } = props;

	const isUserImpersonator = useSelector((state) =>
		userInfoSelector.isUserImpersonator(state.data)
	);

	return (
		<div className="move-wizard-step-3">
			<Panel
				leftLinkText={translations.previous}
				leftButtonHandler={previousPage}
				rightLinkText={translations.send}
				rightButtonHandler={isUserImpersonator ? null : handleSubmit}>
				<h3>{translations.form.step3.title}</h3>
				<div className="info-message">
					<Icon icon={Info} size="inline" />
					<span>{translations.form.step3.infoMessage}</span>
				</div>
				{!formValues.stopDate && (
					<div className="no-end-date-info">
						{translations.form.step3.noEndDate}
					</div>
				)}
				<form onSubmit={handleSubmit} noValidate>
					<section className="move-wizard-step-3__section">
						<CardReveal revelOn={true}>
							<div className="move-out">
								<span className="title">
									<Icon icon={Home} size="small" />
									{translations.form.step3.moveOut}
								</span>
								{formValues.stopDate ? (
									<div className="delivery">
										{translations.form.step3.until}{' '}
										<strong>{formValues.stopDate}</strong>{' '}
										{translations.form.step3.deliveryMsg}
									</div>
								) : (
									<span className="no-end-date">
										{translations.form.step3.noEndDateSelected}
									</span>
								)}
								{formValues.oldAddress && (
									<AddressForDisplay
										className="address"
										address={formValues.oldAddress}
										twoLine
									/>
								)}
							</div>
							<div className="move-in">
								<span className="title">
									<Icon icon={Home} size="small" />
									{translations.form.step3.moveIn}
								</span>
								<div className="delivery">
									{translations.form.step3.from}{' '}
									<strong>{formValues.startDate}</strong>{' '}
									{translations.form.step3.deliveryMsg}
								</div>
								{formValues.newAddress && (
									<AddressForDisplay
										className="address"
										address={formValues.newAddress}
										twoLine
									/>
								)}
							</div>
						</CardReveal>
					</section>
				</form>
			</Panel>
		</div>
	);
};

MoveWizardStep3 = reduxForm({
	form: 'move-wizard',
	destroyOnUnmount: false,
	forceUnregisterOnUnmount: true,
	getFormState: (state) => state.ui.move.wizard,
})(MoveWizardStep3);

const selector = formValueSelector(
	'move-wizard',
	(state) => state.ui.move.wizard
);
MoveWizardStep3 = connect((state) => {
	const {
		old_address: oldAddress,
		start_date: startDate,
		stop_date: stopDate,
		new_address,
		is_residential: isResidential,
	} = selector(
		state,
		'old_address',
		'new_address',
		'start_date',
		'stop_date',
		'is_residential'
	);
	const newAddress = {
		address_type: 'SHIPPING',
		...new_address,
	};
	return {
		formValues: { oldAddress, newAddress, startDate, stopDate, isResidential },
	};
})(MoveWizardStep3);

export default MoveWizardStep3;
