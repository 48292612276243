import React from 'react';
import PropTypes from 'prop-types';

import './row.css';

/**
 * The `Row` component is used to layout elements on the page. It renders its
 * children based on FlexBox.
 */
const Row = ({ highlight, wrapReverse, marginBottom, className, children }) => {
	const highlightClass = highlight ? 'row--highlight highlight-bg-color' : '';
	const wrapReverseClass = wrapReverse ? 'row--wrap-reverse' : '';
	const marginBottomClass = marginBottom ? 'row--margin-bottom' : '';
	return (
		<div
			className={`row ${highlightClass} ${wrapReverseClass} ${
				marginBottomClass
			} ${className || ''}`.trim()}>
			{children}
		</div>
	);
};

export default Row;

Row.propTypes = {
	/** Should the row be highlighted */
	highlight: PropTypes.bool,
	/** Should the order of the elements be reverse when wrapped */
	wrapReverse: PropTypes.bool,
	/** Add an extra margin at the bottom */
	marginBottom: PropTypes.bool,
};
