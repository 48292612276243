import { combineReducers } from 'redux';
import platform from './platform';
import ui from './ui';
import data from './data';
import translations from './translations';
import notifications from './notifications';
import marketplace from './marketplace';
import { reducer as formReducer } from 'redux-form';

const rootApp = (state, action) => {
	if (action.type === 'RESET_STATE') {
		state = undefined;
	}
	return app(state, action);
};

let app = combineReducers({
	platform,
	notifications,
	data,
	ui,
	translations,
	marketplace,
	form: formReducer,
});

export const action = {
	resetState: () => ({ type: 'RESET_STATE' }),
};

export default rootApp;
