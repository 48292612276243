import React, { Component } from 'react';
import PropTypes from 'prop-types';

export default class IconSun extends Component {
	static displayName = 'IconCode';

	getLarge() {
		return (
			<svg
				version="1.1"
				xmlns="http://www.w3.org/2000/svg"
				width="32"
				height="32"
				viewBox="5 5 32 32">
				<path d="M17.504 16.576c0-1.12-0.928-2.048-2.048-2.048-0.48 0-0.896 0.416-0.896 0.896s0.384 0.896 0.896 0.896c0.16 0 0.256 0.128 0.256 0.256 0 0.16-0.128 0.256-0.256 0.256h-10.88c-0.48 0-0.896 0.384-0.896 0.896 0 0.48 0.384 0.896 0.896 0.896h10.88c1.12 0 2.048-0.928 2.048-2.048z" />
				<path d="M19.328 21.696h-8.16c-0.48 0-0.896 0.416-0.896 0.896s0.384 0.896 0.896 0.896h8.16c0 0 0 0 0 0 0.16 0 0.256 0.096 0.256 0.256s-0.128 0.256-0.256 0.256c-0.48 0-0.896 0.416-0.896 0.896s0.384 0.896 0.896 0.896c1.152 0 2.048-0.928 2.048-2.048s-0.928-2.048-2.048-2.048z" />
				<path d="M22.656 13.248c-2.144 0-3.872 1.728-3.872 3.872 0 0.48 0.384 0.896 0.896 0.896 0.48 0 0.896-0.384 0.896-0.896 0-1.152 0.928-2.080 2.080-2.080s2.080 0.928 2.080 2.080-0.928 2.080-2.080 2.080h-14.464c-0.48 0-0.896 0.384-0.896 0.896 0 0.48 0.384 0.896 0.896 0.896h14.464c2.144 0 3.872-1.728 3.872-3.872s-1.728-3.872-3.872-3.872z" />
			</svg>
		);
	}

	getSmall() {
		return (
			<svg
				version="1.1"
				xmlns="http://www.w3.org/2000/svg"
				width="32"
				height="32"
				viewBox="5 5 32 32">
				<path d="M17.504 16.576c0-1.12-0.928-2.048-2.048-2.048-0.48 0-0.896 0.416-0.896 0.896s0.384 0.896 0.896 0.896c0.16 0 0.256 0.128 0.256 0.256 0 0.16-0.128 0.256-0.256 0.256h-10.88c-0.48 0-0.896 0.384-0.896 0.896 0 0.48 0.384 0.896 0.896 0.896h10.88c1.12 0 2.048-0.928 2.048-2.048z" />
				<path d="M19.328 21.696h-8.16c-0.48 0-0.896 0.416-0.896 0.896s0.384 0.896 0.896 0.896h8.16c0 0 0 0 0 0 0.16 0 0.256 0.096 0.256 0.256s-0.128 0.256-0.256 0.256c-0.48 0-0.896 0.416-0.896 0.896s0.384 0.896 0.896 0.896c1.152 0 2.048-0.928 2.048-2.048s-0.928-2.048-2.048-2.048z" />
				<path d="M22.656 13.248c-2.144 0-3.872 1.728-3.872 3.872 0 0.48 0.384 0.896 0.896 0.896 0.48 0 0.896-0.384 0.896-0.896 0-1.152 0.928-2.080 2.080-2.080s2.080 0.928 2.080 2.080-0.928 2.080-2.080 2.080h-14.464c-0.48 0-0.896 0.384-0.896 0.896 0 0.48 0.384 0.896 0.896 0.896h14.464c2.144 0 3.872-1.728 3.872-3.872s-1.728-3.872-3.872-3.872z" />
			</svg>
		);
	}

	getIcon() {
		let svg;
		svg = this.props.size === 'large' ? this.getLarge() : svg;
		svg = this.props.size === 'small' ? this.getSmall() : svg;
		return svg;
	}

	render() {
		return this.getIcon();
	}
}

IconSun.propTypes = {
	size: PropTypes.oneOf(['small', 'large']).isRequired,
};
