import React, { useState, useEffect } from 'react';
import { reduxForm, formValueSelector } from 'redux-form';
import { connect } from 'react-redux';
import Panel from '../../components/panel';
import { validateStep1 as validate } from '../../utils/move-wizard-validation';
import AgreementBox from '../../components/agreement-box';
import './move-wizard-step-0.scss';

let MoveWizardStep0 = (props) => {
	const {
		translations,
		handleSubmit,
		agreements,
		getCommodityIcons,
		isStepValid,
	} = props;
	const text = translations.form.step0;
	const dispatch = props.dispatch;
	const [selectedAgreement, setSelectedAgreement] = useState(0);

	useEffect(() => {
		isStepValid(0, selectedAgreement);
	}, [isStepValid, selectedAgreement]);

	const selectOldAddressHandler = (
		address,
		agreement_identifier_with_sequence
	) => {
		dispatch(props.change('old_address', address));
		setSelectedAgreement(agreement_identifier_with_sequence);
	};

	return (
		<div className="move-wizard-step-0">
			<Panel
				rightLinkText={agreements && agreements.length && translations.next}
				rightButtonHandler={selectedAgreement ? handleSubmit : null}>
				<h3>{text.title}</h3>
				<p>{text.agreementMsg}</p>
				<h3>{text.chooseAgreementTitle}</h3>
				<p>{text.chooseAgreementHeadline}</p>
				<div className="agreement-box-list">
					{agreements && agreements.length ? (
						agreements.map((agreement) => {
							return (
								<AgreementBox
									key={agreement.agreement_identifier_with_sequence}
									agreement={agreement}
									getCommodityIcons={getCommodityIcons}
									selectHandler={selectOldAddressHandler}
									selectedAgreement={selectedAgreement}
									translations={translations}
								/>
							);
						})
					) : (
						<div className="no-agreements-message">
							{translations.noAgreementsMessage}
						</div>
					)}
				</div>
			</Panel>
		</div>
	);
};

MoveWizardStep0 = reduxForm({
	form: 'move-wizard',
	destroyOnUnmount: false,
	forceUnregisterOnUnmount: false,
	initialValues: { is_residential: true },
	getFormState: (state) => state.ui.move.wizard,
	validate,
})(MoveWizardStep0);

const selector = formValueSelector(
	'move-wizard',
	(state) => state.ui.move.wizard
);
MoveWizardStep0 = connect((state) => {
	const oldAddress = selector(state, 'old_address') || '';
	return {
		formValues: { oldAddress },
	};
})(MoveWizardStep0);

export default MoveWizardStep0;
