import { useState, useEffect, useMemo, forwardRef } from 'react';
import closeableOverlay from '../components/closeable-overlay';
import { connect, useDispatch } from 'react-redux';
import { withRouter, useHistory } from 'react-router-dom';
import moment from 'moment';

import api from '../api';

import { action as notificationsAction } from '../reducers/notifications';

// MaterialUI
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import FormGroup from '@mui/material/FormGroup';
import Select from '@mui/material/Select';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';
import FormHelperText from '@mui/material/FormHelperText';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import { IMaskInput } from 'react-imask';

import nl from 'date-fns/locale/nl';
import {
	ListSubheader,
	FormControlLabel,
	FormLabel,
	RadioGroup,
	Radio,
	Alert,
	TextField,
} from '@mui/material';

import { useFormik, FormikProvider, Form } from 'formik';
import DatePickerMobile from '../components/formik-components/datepicker';
import currency from 'currency.js';

import * as yup from 'yup';

const offerFormSchema = yup.object().shape({
	participation_identifier: yup.string().required(),
	offer_type: yup.string().required(),
	buyer_email_address: yup.string().when('offer_type', {
		is: (value) => value && value.length > 0 && value === 'personal',
		then: yup.string().required(),
	}),
	date: yup.string().required(),
	number_of_shares: yup
		.number()
		.positive()
		.required()
		.min(1),
	price: yup.number().required(),
});

function dateFormater(value) {
	const date = new Date(value);

	return moment(
		new Date(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0)
	)
		.format('YYYY-MM-DD')
		.toString();
}

const TextMaskCustom = forwardRef(function TextMaskCustom(props, ref) {
	const { onChange, ...other } = props;
	return (
		<IMaskInput
			{...other}
			mask={Number}
			scale={2}
			signed={false}
			unmask={true}
			normalizeZeros={false}
			padFractionalZeros={false}
			thousandsSeparator="."
			inputRef={ref}
			max={props.max}
			onAccept={(value, mask) => {
				onChange({ target: { name: props.name, value: mask.typedValue } });
			}}
			overwrite
		/>
	);
});

function CreateOffer({ translations, labels, onClose }) {
	const dispatch = useDispatch();
	const history = useHistory();
	const [projectsResponse, setProjectsResponse] = useState([]);
	const [projects, setProjects] = useState([]);

	const formik = useFormik({
		initialValues: {
			participation_identifier: '',
			offer_type: 'public',
			buyer_email_address: '',
			date: new Date().toString(),
			number_of_shares: 0,
			price: '',
		},
		validationSchema: offerFormSchema,
		onSubmit: () => {
			saveOffer();
		},
	});

	useEffect(() => {
		api.sustainable
			.get()
			.then((response) => {
				const options = response.map((project) => ({
					label: `${project.project_code} - ${project.project_name}`,
					...project,
					participations: project.participations.map((participation) => ({
						project_code: project.project_code,
						...participation,
						value: {
							project_code: project.project_code,
							participation_identifier: participation.participation_identifier,
						},
					})),
				}));
				setProjects(options);
				setProjectsResponse(response);
			})
			.catch((err) => {
				setProjects([]);
				setProjectsResponse([]);
			});
	}, []);

	const toCurrency = (value) => {
		return currency(value, {
			symbol: '€',
			separator: '.',
			decimal: ',',
		}).format();
	};

	const saveOffer = () => {
		api.sustainable.sustainableOffers
			.post({
				date: dateFormater(formik.values.date),
				price: formik.values.price,
				number_of_shares: formik.values.number_of_shares,
				participation_identifier: formik.values.participation_identifier,
				project_code: selectedProject.project_code,
				buyer_email_address: formik.values.buyer_email_address,
			})
			.then(() => {
				onClose();
				notificationsAction.add(dispatch, translations.createOfferSuccess);
				history.push('my-offers');
			})
			.catch(() => {
				onClose();
				notificationsAction.add(dispatch, 'Error');
			});
	};

	const selectedProject = useMemo(() => {
		const projects = projectsResponse;
		let project = projects.filter(
			(project) =>
				project.participations.findIndex(
					(p) =>
						p.participation_identifier ===
						formik.values.participation_identifier
				) > -1
		)[0];

		if (project) {
			const { member_offer_settings, administration_costs } = project;
			project.member_offer_settings = member_offer_settings
				.map((setting) => {
					setting.from_date = new Date(setting.from_date);
					return setting;
				})
				.sort((a, b) => b.from_date - a.from_date);
			project.administration_costs = administration_costs
				.map((cost) => {
					cost.from_date = new Date(cost.from_date);
					return cost;
				})
				.sort((a, b) => b.from_date - a.from_date);
		}

		return project;
	}, [projectsResponse, formik]);

	const selectedParticipation = useMemo(() => {
		const selected =
			selectedProject &&
			selectedProject.participations.find(
				(p) =>
					p.participation_identifier === formik.values.participation_identifier
			);
		if (selected) {
			selected.availableShares = selected.share - (selected.offered_share || 0);
		}
		return selected;
	}, [selectedProject, formik]);

	const selectedProjectAdministrationCost = useMemo(() => {
		return selectedProject ? selectedProject.administration_costs[0] : {};
	}, [selectedProject]);

	const selectedProjectMemberOfferSetting = useMemo(() => {
		return selectedProject ? selectedProject.member_offer_settings[0] : {};
	}, [selectedProject]);

	const selectedProjectMemberOfferSettingAllowSelling = useMemo(() => {
		return selectedProject && selectedProject.member_offer_settings[0]
			? selectedProject.member_offer_settings[0].allow_selling
			: false;
	}, [selectedProject]);

	function between(x, min, max) {
		return x >= min && x <= max;
	}

	const isPriceLowerThanMin = useMemo(() => {
		const minPrice =
			formik.values.offer_type === 'personal'
				? -1
				: selectedProjectMemberOfferSetting.min_price;

		return formik.values.price < minPrice;
	}, [selectedProjectMemberOfferSetting, formik]);

	const isPriceHigherThanMax = useMemo(() => {
		return formik.values.price > selectedProjectMemberOfferSetting.max_price;
	}, [selectedProjectMemberOfferSetting, formik]);

	const isPriceValid = useMemo(() => {
		const minPrice =
			formik.values.offer_type === 'personal'
				? -1
				: selectedProjectMemberOfferSetting.min_price;
		return between(
			formik.values.price,
			minPrice,
			selectedProjectMemberOfferSetting.max_price
		);
	}, [selectedProjectMemberOfferSetting, formik]);

	const isNumberOfSharesValid = useMemo(() => {
		return selectedParticipation
			? between(
					formik.values.number_of_shares,
					1,
					selectedParticipation.availableShares
			  )
			: true;
	}, [formik, selectedParticipation]);

	const isFormValid = useMemo(() => {
		const { offer_type, number_of_shares, price, date } = formik.values;
		const min_sell_price =
			offer_type === 'personal'
				? 0
				: selectedProjectMemberOfferSetting.min_price || 0;
		if (
			selectedProjectAdministrationCost &&
			selectedProjectMemberOfferSettingAllowSelling
		) {
			return (
				selectedParticipation.availableShares >= number_of_shares &&
				number_of_shares > 0 &&
				(selectedProjectMemberOfferSetting.max_price >= price &&
					price >= min_sell_price) &&
				!!date
			);
		} else {
			return false;
		}
	}, [
		selectedProjectAdministrationCost,
		selectedProjectMemberOfferSettingAllowSelling,
		formik,
		selectedProjectMemberOfferSetting,
		selectedParticipation,
	]);

	const personalOfferDisclaimer = () => {
		const labelKey = labels.id.label_key;
		if (labelKey === '1024' || labelKey === '1071') {
			return translations[`personalOfferDisclaimer_${labelKey}`];
		}
		return translations.personalOfferEmailDisclaimer;
	};

	return (
		<FormikProvider value={formik}>
			<Form>
				<Card>
					<CardContent>
						<Typography gutterBottom variant="h5" component="div">
							{translations.menu.createOffer}
						</Typography>
						<FormGroup row>
							<FormControl fullWidth required margin="normal">
								<InputLabel htmlFor="project-code-select" size="small">
									{projects.length === 0
										? translations.error.noProjects
										: 'Project'}
								</InputLabel>
								<Select
									id="participation_identifier"
									name="participation_identifier"
									label="Project"
									size="small"
									disabled={projects.length === 0}
									value={formik.values.participation_identifier}
									error={
										formik.touched.participation_identifier &&
										Boolean(formik.errors.participation_identifier)
									}
									onChange={formik.handleChange}>
									{projects &&
										projects.map((project) => {
											const { participations } = project;
											return [
												<ListSubheader>{project.label}</ListSubheader>,
												participations.map((participation, j) => {
													return (
														<MenuItem
															key={j}
															value={participation.participation_identifier}>
															{`${participation.participation_identifier} | ${participation.supplier} (${participation.share})`}
														</MenuItem>
													);
												}),
											];
										})}
								</Select>
							</FormControl>
							<FormControl
								fullWidth
								disabled={!selectedProjectMemberOfferSettingAllowSelling}
								component="fieldset"
								style={{
									paddingLeft: 5,
									marginTop: 10,
								}}>
								<FormLabel component="legend">
									{translations.offerType}
								</FormLabel>
								<RadioGroup
									id="offer_type"
									name="offer_type"
									onChange={formik.handleChange}
									row
									value={formik.values.offer_type}
									onBlur={formik.handleBlur}
									error={
										formik.touched.offer_type &&
										Boolean(formik.errors.offer_type)
									}>
									<FormControlLabel
										value="public"
										control={<Radio />}
										label={translations.publicOffer}
									/>
									<FormControlLabel
										value="personal"
										control={<Radio />}
										label={translations.personalOffer}
									/>
								</RadioGroup>
							</FormControl>
							{formik.values.offer_type === 'personal' && (
								<FormControl fullWidth>
									<Alert severity="info">{personalOfferDisclaimer()}</Alert>
									<br />
									<TextField
										required
										disabled={!selectedProjectMemberOfferSettingAllowSelling}
										onChange={formik.handleChange}
										type={'email'}
										size="small"
										name="buyer_email_address"
										id="buyer_email_address"
										onBlur={formik.handleBlur}
										label={translations.personalOfferEmailDisclaimer}
										value={formik.values.buyer_email_address}
									/>
								</FormControl>
							)}
							<FormControl fullWidth margin="normal">
								<DatePickerMobile
									disabled={!selectedProjectMemberOfferSettingAllowSelling}
									size="small"
									name="date"
									id="date"
									onChange={(date) => {
										formik.setFieldValue('date', date.toString());
									}}
									minDate={new Date()}
									locale={nl}></DatePickerMobile>
							</FormControl>
							<FormControl
								fullWidth
								required
								margin="normal"
								error={formik.touched.price && !isPriceValid}>
								<InputLabel htmlFor="price-per-share">
									{translations.pricePerShare}
								</InputLabel>
								<OutlinedInput
									disabled={!selectedProjectMemberOfferSettingAllowSelling}
									id="price"
									name="price"
									value={formik.values.price.toString()}
									size="small"
									inputProps={{
										inputMode: 'numeric',
										pattern: '/^-?d+(?:.d+)?$/g',
									}}
									onBlur={(e) => {
										const eventValue = Math.floor(
											Number(e.target.value.toString().replace(',', '.'))
										)
											.toFixed(2)
											.toString()
											.replace('.00', ',00');
										e.target.value = eventValue;
										formik.handleBlur(e);
									}}
									placeholder={translations.pricePerShare}
									onChange={(e) => {
										const eventValue = e.target.value;
										e.target.value = Math.floor(eventValue);
										formik.handleChange(e);
									}}
									startAdornment={
										<InputAdornment position="start">€</InputAdornment>
									}
									max={selectedProjectMemberOfferSetting.max_price}
									label={translations.pricePerShare}
									helperText={
										formik.touched.price && !isPriceValid
											? 'Ongeldige invoer'
											: null
									}
									inputComponent={TextMaskCustom}
								/>
								{formik.touched.price && !isPriceValid && (
									<FormHelperText error id="price-per-share">
										{isPriceHigherThanMax && translations.maxPriceError}
										{isPriceLowerThanMin && translations.minPriceError}
									</FormHelperText>
								)}
							</FormControl>
							<FormControl fullWidth required margin="normal">
								<TextField
									disabled={!selectedProjectMemberOfferSettingAllowSelling}
									id="number_of_shares"
									name="number_of_shares"
									size="small"
									label={translations.numberOfShares}
									type="number"
									onBlur={formik.handleBlur}
									error={
										formik.touched.number_of_shares && !isNumberOfSharesValid
									}
									helperText={
										formik.touched.number_of_shares &&
										!isNumberOfSharesValid &&
										selectedParticipation
											? formik.values.number_of_shares >
											  selectedParticipation.share
												? translations.notEnoughShares
												: 'Ongeldige invoer'
											: null
									}
									value={formik.values.number_of_shares}
									onChange={formik.handleChange}
								/>
							</FormControl>
						</FormGroup>
						{selectedProject && selectedProjectAdministrationCost && (
							<Box m={1}>
								<Typography className="offer-box-info">
									{!selectedProjectMemberOfferSettingAllowSelling && (
										<>
											<Stack
												direction="row"
												justifyContent="space-between"
												alignItems="center"
												spacing={2}
												height={50}>
												<span>
													<b>{translations.sellingNotAllowed}</b>
												</span>
											</Stack>
											<Divider />
										</>
									)}
									<Divider />
									<Stack
										direction="row"
										justifyContent="space-between"
										alignItems="center"
										spacing={2}
										height={50}>
										<span>{translations.minimumSellPrice}:</span>
										<span>
											{toCurrency(selectedProjectMemberOfferSetting.min_price)}
										</span>
									</Stack>
									<Divider />
									<Stack
										direction="row"
										justifyContent="space-between"
										alignItems="center"
										spacing={2}
										height={50}>
										<span>{translations.maximumSellPrice}:</span>
										<span>
											{toCurrency(selectedProjectMemberOfferSetting.max_price)}
										</span>
									</Stack>
									<Divider />
									<Stack
										direction="row"
										justifyContent="space-between"
										alignItems="center"
										spacing={2}
										height={50}>
										<span>{translations.administrationCosts}:</span>
										<span>
											{toCurrency(selectedProjectAdministrationCost.amount)}
										</span>
									</Stack>
									<Divider />
									<Stack
										direction="row"
										justifyContent="space-between"
										alignItems="center"
										spacing={2}
										height={50}>
										<span>{translations.proceeds}:</span>
										<span>
											{toCurrency(
												Number(
													(formik.values.price -
														selectedProjectAdministrationCost.amount) *
														formik.values.number_of_shares
												) || 0
											)}
										</span>
									</Stack>
									<Divider />
								</Typography>
							</Box>
						)}
					</CardContent>
					<CardActions>
						<Stack direction="row" justifyContent="space-between" spacing={2}>
							<Button
								variant="outlined"
								color="error"
								size="small"
								onClick={onClose}>
								{translations.cancel}
							</Button>
							<Button
								variant="outlined"
								disabled={!isFormValid}
								type="submit"
								size="small">
								{translations.save}
							</Button>
						</Stack>
					</CardActions>
				</Card>
			</Form>
		</FormikProvider>
	);
}

function mapStateToProps(state) {
	return {
		translations: Object.assign(
			state.translations.marketPlace,
			state.translations.general
		),
		labels: state.data.labels,
	};
}

export default withRouter(
	connect(mapStateToProps)(closeableOverlay(CreateOffer))
);
