import 'whatwg-fetch';
import CssBaseline from '@mui/material/CssBaseline';
import ReactDOM from 'react-dom';
import { createStore } from 'redux';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import { INDEPENDENT_ROUTES, RenderIndependentRoutes } from './routes/routes';
import './sentry.config';

import reducer from './reducers';
import { action as platformAction } from './reducers/platform';
import App from './app';
import api from './api';
import getHostname from './utils/get-hostname';
import configureGoogleAnalytics from './utils/google-analytics';
import Error from './views/error';

import './_variables.css';
import './index.css';
import './branding.css';
import './typography.css';
import Amplify from '@aws-amplify/core';
import CognitoSignIn from './components/cognito-signin';
import CognitoForgotPassword from './components/cognito-forgot-password';
import CognitoRequireNewPassword from './components/cognito-require-new-password';
import {
	Authenticator,
	SignIn,
	Greetings,
	ForgotPassword,
	RequireNewPassword,
} from 'aws-amplify-react';
import AppAuthWrapper from './appAuthWrapper';
import { applyLabelStyle, setFavicon } from './label-style-information';
import { ThemeProvider, createTheme } from '@mui/material';
import { SnackbarProvider } from 'notistack';

const underConstruction = process.env.REACT_APP_UNDER_CONSTRUCTION === 'true';
const store = createStore(
	reducer,
	window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);
// Add ios class if we are on a ios device. iOS devices need a custom
// font-size on input fields, with this class we can do that
if (/iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream) {
	document.querySelector('html').classList.add('ios');
	store.dispatch(platformAction.platform('ios'));
}

function isIndependentView() {
	const views = [
		'verify',
		'reactivation',
		'under-construction',
		'thank-you',
		'payment-cancelled',
		'register',
	];
	return views.some((view) => window.location.pathname.includes(view));
}

const theme = createTheme();

function startApp(logoUrl, labelName, info, labelKey) {
	const skipLogin = JSON.parse(localStorage.getItem('skipLogin'));

	if (underConstruction || isIndependentView()) {
		ReactDOM.render(
			<Provider store={store}>
				<SnackbarProvider maxSnack={3}>
					<Router>
						<RenderIndependentRoutes
							logo={logoUrl}
							info={info}
							routes={INDEPENDENT_ROUTES}
						/>
					</Router>
				</SnackbarProvider>
			</Provider>,
			document.getElementById('root')
		);
		return;
	}

	ReactDOM.render(
		!skipLogin ? (
			<Provider store={store}>
				<Authenticator
					hide={[SignIn, Greetings, ForgotPassword, RequireNewPassword]}>
					<CognitoSignIn
						logo={logoUrl}
						labelName={labelName}
						email={info.email}
						labelKey={labelKey}
					/>
					<CognitoRequireNewPassword
						logo={logoUrl}
						labelName={labelName}
						email={info.email}
					/>
					<CognitoForgotPassword
						logo={logoUrl}
						labelName={labelName}
						email={info.email}
						phone={info.phone_number}
					/>
					<AppAuthWrapper api={process.env.REACT_APP_API} />
				</Authenticator>
			</Provider>
		) : (
			<Provider store={store}>
				<ThemeProvider theme={theme}>
					<CssBaseline />
					<App api={process.env.REACT_APP_API} />
				</ThemeProvider>
			</Provider>
		),
		document.getElementById('root')
	);
}

function showError(err) {
	const translations = store.getState().translations;
	const error = process.env.NODE_ENV === 'development' ? err : '';
	ReactDOM.render(
		<Error
			translations={Object.assign({}, translations.error, translations.general)}
			error={error.message}
		/>,
		document.getElementById('root')
	);
}

(async () => {
	const label = await api.labels.id.get(getHostname());
	const styleResponse = await api.labels.style.get(label.label_key);
	document.body.style.fontFamily = styleResponse.font_family_web
		? styleResponse.font_family_web
		: 'Open Sans';
	applyLabelStyle(styleResponse);

	let info = {};
	let logo = {};
	const skipLogin = JSON.parse(localStorage.getItem('skipLogin'));
	if (!skipLogin) {
		info = await api.labels.info.get(label.label_key);
		logo = await api.labels.logo.get(label.label_key);
		Amplify.configure({
			Auth: {
				region: label.region,
				userPoolId: label.user_pool_id,
				userPoolWebClientId: label.client_id,
				clientMetadata: {
					// Custom data so accountservice+cognito lambdas can identify the originating portal
					portal: 'ENERGY_SSP',
				},
			},
		});
	}
	startApp(logo.url, info.name, info, label.label_key);

	setFavicon(label);
	document.title = info.name;
	if (process.env.NODE_ENV !== 'development') {
		configureGoogleAnalytics(label);
	}
})().catch((err) => {
	console.log(err);
	showError(err);
	return err;
});
